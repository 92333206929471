 import moment, { weekdays } from 'moment';
 import Utils from "../utils/utils";

class UserSeries {
    buildYesterdaySeries = (date, productivity) => {
        const day = {dayOfYear: date.dayOfYear(), year: date.year(), month: date.month() + 1};
        const series = [];
        productivity.forEach(element => {
            const yearProductivity = element.years.find(y => y.year === day.year);
            const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === day.month) : null;
            const dayProductivity = ((monthProductivity !== undefined)&&(monthProductivity != null)) ? monthProductivity.days.find(d => d.day === day.dayOfYear) : null;
            if(dayProductivity) {
                series.push({
                    x: element.userId,
                    y: ((dayProductivity.totalWorkingHoursPerDay / (1000 * 60 * 60)) ).toFixed(2)
                });
            }
        });
        return series;
    }

    buildLastWeekSeries = (startDate, endDate, productivity) => {
        return this.buildCustomRange(startDate, endDate, productivity);
    }

    buildMonthlySeries = (startDate, endDate, productivity) => {
        const data = [];
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        productivity.forEach(element => {
            const yearProductivity = element.years.find(y => y.year === start.year);
            const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === start.month) : null;
            if(monthProductivity) {
                data.push({
                    x: element.userId,
                    y: ((monthProductivity.totalWorkingHoursPerMonth / (1000 * 60 * 60))).toFixed(2)
                });
            }
        });
        return data;
    }

    buildLastYearSeries = (startDate, endDate, productivity) => {
        const data = [];
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        productivity.forEach(element => {
            const yearProductivity = element.years.find(y => y.year === start.year);
            if(yearProductivity) {
                data.push({
                    x: element.userId,
                    y: ((yearProductivity.totalWorkingHoursPerYear / (1000 * 60 * 60))).toFixed(2)
                });
            }
        });
        return data;
    }

    buildCurrentYearSeries = (startDate, endDate, productivity) => {
        const data = [];
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        productivity.forEach(element => {
            const yearProductivity = element.years.find(y => y.year === start.year);
            if(yearProductivity) {
                data.push({
                    x: element.userId,
                    y: ((yearProductivity.totalWorkingHoursPerYear / (1000 * 60 * 60))).toFixed(2)
                });
            }
        });
        return data;
    }

    buildUserDaySeries = (selectedUserId, date, productivity) => {
        if(selectedUserId === null || selectedUserId === undefined || selectedUserId === '') {
            throw 'UserId is required';
        }
        const day = {dayOfYear: date.dayOfYear(), year: date.year(), month: date.month() + 1};
        const data = [];
        const userProductivity = productivity.find(p => p.userId === selectedUserId);
        userProductivity.tools.forEach(element => {
            const yearProductivity = element.years.find(y => y.year ===  day.year);
            const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === day.month) : null;
            const dayProductivity = ((monthProductivity !== undefined)&&(monthProductivity != null)) ? monthProductivity.days.find(m => m.day === day.dayOfYear) : null;
            if(dayProductivity) {
                data.push({
                    x: element.toolName,
                    y: ((dayProductivity.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                });
            }
        });
        return data;
    }

    buildUserWeeklySeries = (selectedUserId, startDate, endDate, productivity) => {
        if(selectedUserId === null || selectedUserId === undefined || selectedUserId === '') {
            throw 'UserId is required';
        }
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        const data = [];
        const userProductivity = productivity.find(p => p.userId === selectedUserId);
        const yearProductivity = start.year === end.year ? userProductivity.years.find( y => y.year === start.year) : userProductivity.years.filter( y => y.year === end.year || y.year === start.year);
        const monthProductivity = yearProductivity !== undefined && start.year == end.year ? yearProductivity.months?.filter(m => start.month <= m.month && m.month <= end.month)  
        : start.year != end.year ? yearProductivity?.map(t => {
            const months = t.months?.filter(m => start.month == m.month && start.year == t.year || m.month == end.month && end.year == t.year)
            return months;
        }) : null;
        start.year == end.year ? monthProductivity.map(element => {
        const days = element.days?.filter(d => start.dayOfYear <= d.day && d.day <= end.dayOfYear)
            if(days){
                days.sort((a, b) => a.day - b.day);
                days.forEach(day => {
                    data.push({
                        x:  moment(day.date).format('DD-MM-YYYY'),
                        y: ((day.totalWorkingHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                    });
                });
            }
        }) : monthProductivity.map(element => {
            const days = element[0].days?.filter(d => start.dayOfYear <= d.day && element[0].year == start.year || d.day <= end.dayOfYear && element[0].year == end.year)
                if(days){
                    days.sort((a, b) => a.day - b.day);
                    days.forEach(day => {
                        data.push({
                            x:  moment(day.date).format('DD-MM-YYYY'),
                            y: ((day.totalWorkingHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                        });
                    });
                }
            })
        return data;
    }

    buildUserMonthlySeries = (selectedUserId, startDate, endDate, productivity) => {
        if(selectedUserId === null || selectedUserId === undefined || selectedUserId === '') {
            throw 'UserId is required';
        }
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        const data = [];
        const userProductivity = productivity.find(p => p.userId === selectedUserId);
        const yearProductivity = userProductivity && userProductivity.years.find(y => y.year === start.year);
        const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === start.month) : null;

       if(monthProductivity) {
        monthProductivity &&   monthProductivity.days.sort((a, b) => a.day - b.day)
        monthProductivity.days.forEach(element => {
            data.push({
                x: moment(element.date).format('DD-MM-YYYY'),
                y: ((element.totalWorkingHoursPerDay / (1000 * 60 * 60))).toFixed(2)
            });
        });
        return data;
       }

       return data;
    }

    buildCustomUserMonthlySeries = (selectedUserId, startDate, endDate , date , productivity) => {
        if(selectedUserId === null || selectedUserId === undefined || selectedUserId === '') {
            throw 'UserId is required';
        }
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        const diffmonths = {dayOfYear: date.dayOfYear(), year: date.year(), month: date.month() + 1};
        const data = [];
        const calendardate = new Date()
        let currentfullyear = calendardate.getFullYear();
        let prevfullyear = currentfullyear-1;
        const userProductivity = productivity.find(p => p.userId === selectedUserId);
        const yearProductivity = userProductivity.years.find(y => y.year === start.year);
        let month = [];
        if(start.year == end.year){
            if(start.month == diffmonths.month && start.month != end.month){
                const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === start.month) : null;
                month = monthProductivity.days.filter(d=> d.day >= start.dayOfYear)
                if(month){
                    month.sort((a, b) => a.day - b.day);
    
                    month.forEach(element => {
                        data.push({
                            x: moment(element.date).format('DD-MM-YYYY'),
                            y: ((element.totalWorkingHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                        });
                    });
                }
            } else if (end.month == diffmonths.month && start.month != end.month) {
                const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === end.month) : null;
                month = monthProductivity.days.filter(d=> d.day <= end.dayOfYear)
    
                if(month){
                    month.sort((a, b) => a.day - b.day);
    
                    month.forEach(element => {
                        data.push({
                            x: moment(element.date).format('DD-MM-YYYY'),
                            y: ((element.totalWorkingHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                        });
                    });
                }
            } else if (start.month == diffmonths.month && start.month == end.month) {
                const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === end.month) : null;
                month = monthProductivity.days.filter(d=> d.day >= start.dayOfYear && d.day <= end.dayOfYear)
    
                if(month){
                    month.sort((a, b) => a.day - b.day);
    
                    month.forEach(element => {
                        data.push({
                            x: moment(element.date).format('DD-MM-YYYY'),
                            y: ((element.totalWorkingHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                        });
                    });
                }
            } else {
                const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === diffmonths.month) : null;
                if(monthProductivity){
                    monthProductivity.days.sort((a, b) => a.day - b.day);
    
                    monthProductivity.days.forEach(element => {
                        data.push({
                            x: moment(element.date).format('DD-MM-YYYY'),
                            y: ((element.totalWorkingHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                        });
                    });
                }
            }
        } else if (prevfullyear == start.year && currentfullyear == end.year) {
            const yearProductivity = userProductivity.years.filter(y => y.year >= start.year && y.year <= end.year);
            let months = []
            let years = []
            years = yearProductivity.find(y => y.year == diffmonths.year)
            months = years.months.find(m => m.month == diffmonths.month)

            let element = [];
            if(start.month == months.month && start.year == diffmonths.year){
                element = months.days.filter(d => d.day >= start.dayOfYear)
                if(element){
                    element.sort((a,b) => a.day - b.day);
                    element.map( d => {
                        data.push({
                            x: moment(d.date).format('DD-MM-YYYY'),
                            y: ((d.totalWorkingHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                        });
                    })
                }
            } else if (end.month == months.month && end.year == diffmonths.year){
                element = months.days.filter(d => d.day <= end.dayOfYear)
                if(element){
                    element.sort((a,b) => a.day - b.day);
                    element.map( d => {
                        data.push({
                            x: moment(d.date).format('DD-MM-YYYY'),
                            y: ((d.totalWorkingHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                        });
                    })
                }
            } else {
                element = months.days;
                if(element){
                    element.sort((a,b) => a.day - b.day);
                    element.map( d => {
                        data.push({
                            x: moment(d.date).format('DD-MM-YYYY'),
                            y: ((d.totalWorkingHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                        });
                    })
                }
            }
        }
        return data;
    }

    buildUserYearlySeries = (selectedUserId, date, productivity) => {
        if(selectedUserId === null || selectedUserId === undefined || selectedUserId === '') {
            throw 'UserId is required';
        }
        const start = {dayOfYear: date.dayOfYear(), year: date.year(), month: date.month() + 1};
        const data = [];
        const userProductivity = productivity.find(p => p.userId === selectedUserId);
        const yearProductivity = userProductivity.years.find(y => y.year ===  start.year);
        if(yearProductivity){
            yearProductivity.months.sort((a, b) => a.month - b.month);
            yearProductivity.months.forEach(element => {
                data.push({
                    x: `${Utils.getMonthName(element.month)} ${start.year}`,
                    y: ((element.totalWorkingHoursPerMonth / (1000 * 60 * 60)) ).toFixed(2),
                });
            });
        }
        return data;
    }

    buildUserCurrentYearlySeries = (selectedUserId, date, productivity) => {
        if(selectedUserId === null || selectedUserId === undefined || selectedUserId === '') {
            throw 'UserId is required';
        }
        const start = {dayOfYear: date.dayOfYear(), year: date.year(), month: date.month() + 1};
        const data = [];
        const userProductivity = productivity.find(p => p.userId === selectedUserId);
        const yearProductivity = userProductivity.years.find(y => y.year ===  start.year);
        if(yearProductivity){
            yearProductivity.months.sort((a, b) => a.month - b.month);
            yearProductivity.months.forEach(element => {
                data.push({
                    x: `${Utils.getMonthName(element.month)} ${start.year}`,
                    y: ((element.totalWorkingHoursPerMonth / (1000 * 60 * 60)) ).toFixed(2),
                });
            });
        }
        return data;
    }

    buildCustomRange = (startDate, endDate, productivity) => {
        const data = [];
        const date = new Date()
        let currentfullyear = date.getFullYear();
        let prevfullyear = currentfullyear-1;
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        let monthworkhrs = 0;
        if(start.year === end.year){
            productivity.forEach(element => {
                const years = element.years.filter(y => start.year === y.year);
                const months = years.length !== 0 ?  years[0].months.filter(m=> m.month >= start.month && m.month <= end.month) : null ;
                monthworkhrs = 0;
                months?.forEach(m => {
                        if(m.month === start.month || m.month === end.month){
                            m = m.days.filter(d => d.day >= start.dayOfYear && d.day <= end.dayOfYear)
                            m.map(d => {
                                monthworkhrs = monthworkhrs+d.totalWorkingHoursPerDay
                            })
                        } else {
                            monthworkhrs += m.totalWorkingHoursPerMonth
                        }
                        const index = data.findIndex(value => value.x === element.userId);
                        if(index === -1) {
                            if(monthworkhrs != 0){
                                data.push({
                                    x: element.userId,
                                    y: ((monthworkhrs / (1000 * 60 * 60)) ).toFixed(2)
                                });
                            }
                        } else {
                            data[index].y = parseInt(((monthworkhrs / (1000 * 60 * 60)) ).toFixed(2));
                        }
                });
            });
        } else if (prevfullyear === start.year && currentfullyear === end.year) {
            productivity.forEach(element => {
                const years = element.years.filter(y => start.year <= y.year && y.year <= end.year);
                years.forEach(year => {
                    let months = []
                    if(year.year === start.year){
                        months = year.months.filter(m => m.month >= start.month);
                    } else {
                        months  = year.months.filter(m =>  m.month <= end.month);
                    }
                    months?.forEach(m => {
                        monthworkhrs = 0;
                        if(m.month === start.month && m.year === start.year){
                            m = m.days.filter(d => d.day >= start.dayOfYear)
                            m.map( d => {
                                monthworkhrs = monthworkhrs+d.totalWorkingHoursPerDay
                            })
                        } else if (m.month === end.month && m.year === end.year){
                            m = m.days.filter(d => d.day <= end.dayOfYear)
                            m.map( d => {
                                monthworkhrs = monthworkhrs+d.totalWorkingHoursPerDay
                            })
                        } else {
                            monthworkhrs += m.totalWorkingHoursPerMonth
                        }

                        const index = data.findIndex(value => value.x === element.userId);
                        if(index === -1) {
                            data.push({
                                x: element.userId,
                                y: parseInt(((monthworkhrs / (1000 * 60 * 60)) ).toFixed(2))
                            });
                        } else {
                            data[index].y += parseInt(((monthworkhrs / (1000 * 60 * 60)) ).toFixed(2));
                        }
                    })
                });
            });
        }
            
        return data;
    }

    buildUserCustomRange = (selectedUserId, startDate, endDate, productivity) => {
        if(selectedUserId === null || selectedUserId === undefined || selectedUserId === '') {
            throw 'UserId is required';
        }
        const data = [];
        const date = new Date()
        let monthworkhrs = 0;
        let currentfullyear = date.getFullYear();
        let prevfullyear = currentfullyear-1;
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        const userProductivity = productivity.find(p => p.userId === selectedUserId);
        const yearProductivity = userProductivity.years.filter(y => start.year <= y.year && y.year <= end.year);
        if(start.year === end.year){
            const monthProductivity = yearProductivity && yearProductivity?.length > 0 ? yearProductivity[0].months.filter(m => m.month >= start.month && m.month <= end.month) : null;
            if(monthProductivity){
                monthProductivity.sort((a,b) => a.month -b.month)
                monthProductivity.forEach(element => {
                    let month = element.days.filter(d => d.day >= start.dayOfYear && d.day <= end.dayOfYear)
                    let monthworkhrs = 0;
                    month?.map(d => {
                        monthworkhrs = monthworkhrs+d.totalWorkingHoursPerDay
                    })
                    data.push({
                        x: `${Utils.getMonthName(element.month)} ${start.year}`,
                        y: parseInt(((monthworkhrs / (1000 * 60 * 60))) ).toFixed(2),
                    });
                });
            }
        } else if (prevfullyear === start.year && currentfullyear === end.year) {
            yearProductivity.forEach(year => {
                let months = []
                if(year.year === start.year){
                    months = year.months.filter(m => m.month >= start.month);
                    months.sort((a,b) => a.month - b.month);
                } else {
                    months  = year.months.filter(m =>  m.month <= end.month);
                    months.sort((a,b) => a.month - b.month);
                }
                
                if(months){
                    months?.forEach(m => {
                        monthworkhrs = 0;
                        if(m.month === start.month && m.year === start.year){
                            let element = [];
                            element = m.days.filter(d => d.day >= start.dayOfYear)
                            element.map( d => {
                                monthworkhrs = monthworkhrs+d.totalWorkingHoursPerDay
                            })
                        } else if (m.month === end.month && m.year === end.year){
                            let element = [];
                            element = m.days.filter(d => d.day <= end.dayOfYear)
                            element.map( d => {
                                monthworkhrs = monthworkhrs+d.totalWorkingHoursPerDay
                            })
                        } else {
                            monthworkhrs += m.totalWorkingHoursPerMonth
                        }
    
                        data.push({
                            x: `${Utils.getMonthName(m.month)} ${year.year}`,
                            y: parseInt(((monthworkhrs / (1000 * 60 * 60)) )).toFixed(2)
                        });
    
                    })
                }
            })
        }
        
        
        return data;
    }
}


const userSeries = new UserSeries();
export default userSeries;