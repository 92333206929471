import {
    SET_COLLABORATION_MESSAGE_ERROR,
    SET_COLLABORATION_MESSAGE_LOADING,
    SET_COLLABORATION_MESSAGE_READ_SUCCESS,
    SET_COLLABORATION_MESSAGE_SEND_SUCCESS,
} from "../constants/collabration";

const initialState = {

    messages: [],
    messageObj: {},
    messageOnError: null,
    messageOnLoading: false,
}

export default (state = initialState , action) => {
    switch(action.type) {

        case SET_COLLABORATION_MESSAGE_LOADING: {
            return Object.assign({}, state, {
                messageOnLoading: action.loading
            });
        }

        case SET_COLLABORATION_MESSAGE_ERROR: {
            return Object.assign({}, state, {
                messageOnError: action.error
            });
        }

        case SET_COLLABORATION_MESSAGE_SEND_SUCCESS: {
            return Object.assign({}, state, {
                messageObj: action.success
            });
        }

        case SET_COLLABORATION_MESSAGE_READ_SUCCESS: {
            return Object.assign({}, state, {
                messages: action.success
            });
        }

        default :
            return {...state}
    }
}