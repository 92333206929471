import { SET_TOOLS_LIST } from "../constants/vai";

const initialState = {
    vaiToolsData:[],
    
    
}
export default (state = initialState, action) => {
    switch(action.type) {

        case SET_TOOLS_LIST: {
            return Object.assign({}, state, {
				vaiToolsData: action.response
			});
        }
        
        default:
            return {...state} 
    }
}