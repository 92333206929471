import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector  } from "react-redux";
import { Link, useLocation , useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import {
  setLogout,
  setOffcanvas,
  toggleMegamenu,
  toggleNotificationBar,
  toggleSearchBar,
} from "../../actions/settings";
import storageService from "../../services/storage";
import { closeWindows } from "../../utils/childWindowUtils";
import { withTranslation } from "react-i18next";
import i18n from "../../lang/i18n";
import collaboration from "../../services/collaboration";
import { submitProfileFrom } from "../../actions/profile";
import Selecttag from "../Authentication/Selecttag";
import { logoutApi} from "../../services/logout";
import handleApiError from './ErrorHandling';
import _ from 'lodash';

export const logout = async (setLogout = false, props = {history:{push: () => {return}}}) => {
    try {
        const response = await logoutApi();
        if (!response || !response.hasOwnProperty("data") || _.isEmpty(response.data)) return; 
        const userData = sessionStorage.getItem("userdata");
        let parseUserData = JSON.parse(window.atob(userData));
        if (window && window.process && window.process.type) {
            const { ipcRenderer } = window.require('electron');
            ipcRenderer.send('closeallchildwindows');
        } else {
            closeWindows(parseUserData?.userId);
        }
        props.history.push("/email");
    } catch (error) {
        handleApiError(error, props);
    }
}

const Header = ({ setLogout, setOffcanvas, offcanvas, userData , t}) => {
  const dispatch = useDispatch();
  let userInfo = _.cloneDeep(userData)
  const { profiledata } = useSelector((state) => state.profile);
  const { routeModules } = useSelector((state) => state.organization);
  const [scrolled, setScrolled] = useState(0);
  const history = useHistory()


  userInfo.firstName = userInfo.firstName ? userInfo.firstName : ''
  userInfo.lastName = userInfo.lastName ? userInfo.lastName : ''
  
  const historyProp = {
    history: history,
    t : t
  }

  const scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;

    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolleda = `${(scrollPx / winHeightPx) * 100}%`;
    setScrolled(scrolleda);
  };
  window.addEventListener("scroll", scrollProgress);

  const { pathname } = useLocation();

  const progressBarStyle = {
    width: scrolled,
  };

  useEffect(() => {
    if(routeModules.length > 0){
      dispatch(submitProfileFrom());
    }
  }, [routeModules])

  const getHeaderTitle = () => {
    let title = pathname.slice(1) === "Vai" ? "Cruiser" : pathname.slice(1);
    switch (title) {
      case "Dashboard":
        return i18n.t("labels.dashboard");
      case "Timesheets":
        return i18n.t("labels.timesheet");
      case "Collaboration":
        return i18n.t("labels.collabration");
      case "Collaboration/settings":
        return i18n.t("labels.collabration") + "/" + i18n.t("headers.settings");
      case "Tasks":
        return i18n.t("labels.task");
      case "Productivity":
        return i18n.t("labels.productivity");
      case "Project" : 
        return i18n.t("labels.project");
      case "Cruiser":
        return i18n.t("labels.vai");
      case "Settings":
        return i18n.t("headers.settings");
      case "Profile":
        return i18n.t("Employee.profile");
      case "Task" :
        return i18n.t("labels.task");
      case "Faq" :
        return i18n.t("labels.faq");
        case "FileTransfer" :
        return i18n.t("labels.fileTransfer");
      default:
        return title;
    }
  };
  return (
    <>
      <nav className="navbar top-navbar">
        <div className="container-fluid">
          <div className="navbar-left d-flex align-items-center">
            <div className="navbar-btn">
              <button type="button" name="mobileoffcanvas" className="btn-toggle-offcanvas"
                onClick={() => setOffcanvas(!offcanvas)}><i className="lnr lnr-menu fa fa-bars"></i>
              </button>  
            </div>

            <ul className="nav navbar-nav">
              <li>
                <div className=" text-capitalize text-alignment page-name">
                  <span className="mb-0 font-size-18">
                    {getHeaderTitle()}
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="navbar-right">
            <div id="navbar-menu">
              <ul className="nav navbar-nav">


                <li className="mr-3">
                  <Selecttag />
                </li>
                <li>
                  <div className="user-account d-flex ">


                    <span className="mr-1 mr-1 text-capitalize user-name-hide-show" title={(userInfo.firstName + " " + userInfo.lastName)}>

                      {(userInfo.firstName + userInfo.lastName).length <= 15 ? (userInfo.firstName + " " + userInfo.lastName).toLowerCase()
                        : (userInfo.firstName + " " + userInfo.lastName).substring(0, 15) + "...".toLowerCase()}


                    </span>
                    <div className="user_div user-img-hide-show">
                      <img
                        src="../assets/images/ic-default-user.jpg"
                        className="user-photo"
                        alt="User Profile"
                        title={`${userInfo.firstName} ${userInfo.lastName}`}
                      />
                    </div>

                    <div className="dropdown mr-2">

                      <Dropdown className="ml-0 Logout-header ">
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="user-name left_dropdown_btn mt-1 "
                        ></Dropdown.Toggle>
                        <Dropdown.Menu className="">


                          <div className=" header-profile">

                            <div className="border-bottom mb-2">
                              <div className="d-flex align-items-center pb-2">
                                {profiledata?.userIcon == null ? <img src="../assets/images/ic-default-user.jpg" alt="" id="img" className="w48 h48 rounded rounded-circle " /> : <img src={profiledata?.userIcon} alt="" id="img" className="w48 h48 rounded rounded-circle" />}
                                {/* <img src="../assets/images/xs/avatar2.jpg" data-toggle="tooltip" data-placement="top" title="" alt="Avatar" className="w35 h35 rounded" /> */}
                                <div className="ml-2 text-left line-height-18">
                                  <span title="" className="text-capitalize">{`${userInfo.firstName} ${userInfo.lastName}`}</span>
                                  <p className="mb-0 font-size-12 text-muted">{`${userInfo?.email}`}</p>
                                </div>
                              </div>
                            </div>


                            <div className="ml-2">
                              <Dropdown.Item to="/Profile">
                                <Link className="dropdown-item" to="/Profile">
                                  <i className="icon-user"></i>
                                  {i18n.t("headers.my_profile")}
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item to="/Settings">
                                <Link className="dropdown-item" to="/Settings">
                                  <i className="icon-settings"></i>
                                  {i18n.t("headers.settings")}
                                </Link>
                              </Dropdown.Item>
                              <Dropdown.Item >
                                <Link
                                  id="Logout"
                                  className="dropdown-item"
                                  to=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    logout(setLogout, historyProp);
                                  }}
                                >
                                  <i className="icon-power"></i>
                                  {i18n.t("headers.log_out")}
                                </Link>
                              </Dropdown.Item>

                            </div>
                          </div>

                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="progress-container">
          <div
            style={progressBarStyle}
            className="progress-bar"
            id="myBar"
          ></div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => ({
  isMegaMenu: state.settings.isMegaMenu,
  offcanvas: state.settings.offcanvas,
  // userData: state.login.userData,
  userData: state.twofactorauth.userData,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMegamenu: (e) => dispatch(toggleMegamenu(e)),
  toggleSearchBar: (e) => dispatch(toggleSearchBar(e)),
  toggleNotificationBar: (e) => dispatch(toggleNotificationBar(e)),
  setOffcanvas: (e) => dispatch(setOffcanvas(e)),
  setLogout: () => dispatch(setLogout()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Header));
