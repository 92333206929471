export const SET_ORG_CREATE_SUCCESS = "SET_ORG_CREATE_SUCCESS";
export const SET_ORG_LIST_SUCCESS = "SET_ORG_LIST_SUCCESS";
export const SET_DEPT_LIST_SUCCESS = "SET_DEPT_LIST_SUCCESS";
export const SET_CREATE_USER_SUCCESS = "SET_CREATE_USER_SUCCESS";
export const SET_USER_LIST_SUCCESS = "SET_USER_LIST_SUCCESS";
export const SET_ENABLE_ORG_SUCCESS = "SET_ENABLE_ORG_SUCCESS";
export const SET_DISABLE_ORG_SUCCESS = "SET_DISABLE_ORG_SUCCESS";
export const SET_DEPT_BY_ORG_SUCCESS = "SET_DEPT_BY_ORG_SUCCESS";
export const SET_ORG_MODULES_BY_ID_SUCCESS = "SET_ORG_MODULES_BY_ID_SUCCESS";
export const SET_MODULES_ROUTE_SUCCESS = "SET_MODULES_ROUTE_SUCCESS";
//Auth type
export const SET_AUTH_TYPE_CREATE_SUCCESS = "SET_AUTH_TYPE_CREATE_SUCCESS";
export const SET_AUTH_TYPE_LIST_SUCCESS = "SET_AUTH_TYPE_LIST_SUCCESS";
export const SET_ORG_CREATE_DOMAIN_SUCCESS = "SET_ORG_CREATE_DOMAIN_SUCCESS";
export const SET_ORG_LIST_ERROR = "SET_ORG_LIST_ERROR";
export const SET_ORG_DOMAINS = "SET_ORG_DOMAINS";
