import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import $ from 'jquery';
import moment from 'moment';

class Bizunit extends Component {


    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id:'machinestatusperyear',
                    type: 'bar',
                    height: 300,
                    stacked: true,
                    toolbar: {
                        show: true,
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        
                        width: 5,
                    },
                },
                fill: {
                    colors: ['#8FC555','#F8B912']
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                        }
                    },
                },
                yaxis: {
                    axisTicks: { show: true },
                    axisBorder: { show: true },
                   
                },
                xaxis: {
                    axisTicks: { show: true },
                    axisBorder: { show: true },
                    categories: []
                },
                plotOptions: {
                   
                    bar: {
                        horizontal: true,
                        barHeight:20 ,
                       
                      
                       
                    }
                },
                legend: {
                    show: false
                },
                grid: {
                    bottom: 20,
                    top: 0,
                    left: 6,
                    show: false,
                }
            },
            series: []
        };
    }
   
    
    render() {
        

        {
            
            let uniqueYearList=[];
            let allocatedArray=[];
            let deAllocatedArray=[];
            const options = JSON.parse(JSON.stringify(this.state.options));
            if(!$.isEmptyObject(this.props.data)){
               let yearArray = this.props.data.machines.map(q => moment(q.createdDate).format('YYYY'));
                 uniqueYearList = [...new Set(yearArray)];

                 uniqueYearList.map(yearObj=>{
                     let allocatedCount=0;
                     let deAllocatedCount=0;
                    this.props.data.machines.map(machineObj=>{
                        if(yearObj===moment(machineObj.createdDate).format('YYYY')){
                        if(machineObj.status==="Active"){
                            allocatedCount=allocatedCount+1;
                        }else{
                            deAllocatedCount=deAllocatedCount+1;
                        }
                    }
                    })
                    allocatedArray.push(allocatedCount);
                    deAllocatedArray.push(deAllocatedCount);
                 })
                
               
            }
            const series = [
                {
                    name: "Allocated",
                    data: allocatedArray
                },
                {
                    name: "De-Allocated",
                    data: deAllocatedArray
                }
            ]
            options.xaxis.categories = uniqueYearList;
            

        return (
            <div>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height="420px"
                />
            </div>
        );
        }
    }
}

export default Bizunit;