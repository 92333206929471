import { toast, Zoom } from "react-toastify";
import {
  SET_CREATE_CHANNEL_SUCCESS,
  SET_CREATE_GROUP_SUCCESS,
  SET_LIST_CHANNEL_SUCCESS,
  SET_SELECTED_CHANNEL_SUCCESS,
  SET_UPDATE_CHANNEL_SUCCESS,
  SET_DELETE_CHANNEL_SUCCESS,
  SET_CHANNEL_ERROR,
  SET_SELECTED_CHAT_SUCCESS,
  SET_CHANNEL_SUBSCRIBERS_SUCCESS,
  SET_CHAT_HISTORY_SUCCESS,
  SET_BROWSER_LIST_CHANNEL_SUCCESS,
  SET_SUBSCRIBE_USER_TO_PUBLIC_CHANNEL_SUCCESS,
  SET_UNSUBSCRIBE_USER_TO_CHANNEL_SUCCESS,
  SET_CREATE_DIRECT_ROOM_SUCCESS,
} from "../constants/collabration";
import collaboration from "../services/collaboration";
import { getListOfParticipantsForRoom } from "../utils/collaboration";
import { setNewMessage } from "./collaboration";

export const setCreateDirectRoomSuccess = (payload) => {
  return {
    type: SET_CREATE_DIRECT_ROOM_SUCCESS,
    payload,
  };
};

export const setCreateChannelSuccess = (payload) => {
  return {
    type: SET_CREATE_CHANNEL_SUCCESS,
    payload,
  };
};

export const setCreateGroupSuccess = (payload) => {
  return {
    type: SET_CREATE_GROUP_SUCCESS,
    payload,
  };
};

export const setListChannelSuccess = (payload) => {
  return {
    type: SET_LIST_CHANNEL_SUCCESS,
    payload,
  };
};

export const setBrowserListChannelSuccess = (payload) => {
  return {
    type: SET_BROWSER_LIST_CHANNEL_SUCCESS,
    payload,
  };
};

export const setSubscribeUserToPublicChannel = (payload) => {
  return {
    type: SET_SUBSCRIBE_USER_TO_PUBLIC_CHANNEL_SUCCESS,
    payload,
  };
};

export const setUnSubscribeUserToChannel = (payload) => {
  return {
    type: SET_UNSUBSCRIBE_USER_TO_CHANNEL_SUCCESS,
    payload,
  };
};

export const setSelectedChannelSuccess = (payload) => {
  return {
    type: SET_SELECTED_CHANNEL_SUCCESS,
    payload,
  };
};

export const setChatHistorySuccess = (payload) => {
  return {
    type: SET_CHAT_HISTORY_SUCCESS,
    payload,
  };
};

export const setUpdateChannelSuccess = (payload) => {
  return {
    type: SET_UPDATE_CHANNEL_SUCCESS,
    payload,
  };
};

export const setDeleteChannelSuccess = (payload) => {
  return {
    type: SET_DELETE_CHANNEL_SUCCESS,
    payload,
  };
};

export const setSelectedChatSuccess = (payload) => {
  return {
    type: SET_SELECTED_CHAT_SUCCESS,
    payload,
  };
};

export const setChannelError = (error) => {
  return {
    type: SET_CHANNEL_ERROR,
    error,
  };
};

export const setChannelSubscribersSuccess = (payload) => {
  return {
    type: SET_CHANNEL_SUBSCRIBERS_SUCCESS,
    payload,
  };
};

export const setSelectedChat = (data) => (dispatch) => {
  dispatch(setChannelError(null));
  dispatch(setSelectedChatSuccess(data));
};

export const createDirectRoom = (data) => (dispatch) => {
  dispatch(setChannelError(null));
  collaboration.channels.createDirect(
    data,
    (response) => {
      dispatch(setCreateDirectRoomSuccess(response.data));
    },
    (error) => {
      dispatch(setChannelError(error));
    }
  );
};

export const createChannel =
  (data, handleIncomingMessage, setAllRoomsChat) => (dispatch) => {
    dispatch(setChannelError(null));
    collaboration.channels.createChannel(
      data,
      (response) => {
        if (Object.keys(response?.data).length) {
          let channels = [];
          channels.push(response?.data);
          const newChannel = {
            [response?.data?.roomId]: { content: [], newMessageCount: 0 },
          };
          setAllRoomsChat((allRoomsChat) => {
            return { ...allRoomsChat, ...newChannel };
          });
          // channels = getListOfParticipantsForRoom(channels, "roomId");
          if (response?.data?.roomId) {
            collaboration.socket.subscribe(
              response?.data?.roomId,
              (message) => {
                const data = {
                  isNewComingMessage: true,
                  message: message,
                };
                handleIncomingMessage(data);
              },
              (whiteboard) => console.log(whiteboard)
            );
          }
          dispatch(setCreateChannelSuccess(channels[0]));
        }
      },
      (error) => {
        dispatch(setChannelError(error));
      }
    );
  };

export const createGroup =
  (data, openAddUsersModal, handleIncomingMessage, setAllRoomsChat) =>
  (dispatch) => {
    dispatch(setChannelError(null));
    collaboration.channels.createGroup(
      data,
      (response) => {
        if (Object.keys(response?.data).length) {
          let channels = [];
          channels.push(response?.data);
          const newChannel = {
            [response?.data?.roomId]: { content: [], newMessageCount: 0 },
          };
          setAllRoomsChat &&
            setAllRoomsChat((allRoomsChat) => {
              return { ...allRoomsChat, ...newChannel };
            });
          if (response?.data?.roomId) {
            if (openAddUsersModal) {
              openAddUsersModal(response?.data);
            }
            collaboration.socket.subscribe(
              response?.data?.roomId,
              (message) => {
                const data = {
                  isNewComingMessage: true,
                  message: message,
                };
                console.log({ ...data });
                handleIncomingMessage(data);
              },
              (whiteboard) => console.log(whiteboard)
            );
          }
          dispatch(setCreateChannelSuccess(channels[0]));
        }
      },
      (error) => {
        dispatch(setChannelError(error));
      }
    );
  };

export const getUserSubscribedChannelList = (data, callback) => (dispatch) => {
  dispatch(setChannelError(null));
  collaboration.channels.getSubscribedChannelList(
    data,
    callback && callback(),
    (error) => {
      dispatch(setChannelError(error));
    }
  );
};

export const subscribeUserToPublicChannel = (data) => (dispatch) => {
  dispatch(setChannelError(null));
  collaboration.channels.subscribe(
    data,
    (response) => {
      dispatch(setSubscribeUserToPublicChannel(data));
      collaboration.socket.subscribe(data?.roomId, (message) => {
        const data = {
          isNewComingMessage: true,
          message: JSON.parse(message),
        };
        dispatch(setNewMessage(data));
      });
    },
    (error) => {
      error?.message &&
        toast(error?.message, {
          type: "error",
          theme: "colored",
          autoClose: 2000,
          position: "bottom-right",
          transition: Zoom,
        });
    }
  );
};

export const unSubscribeUserToChannel = (data) => (dispatch, getState) => {
  dispatch(setChannelError(null));
  const { twofactorauth } = getState();
  const { userData } = twofactorauth;
  collaboration.channels.unSubscribe(
    data,
    (response) => {
      dispatch(
        setUnSubscribeUserToChannel({ ...data, userId: userData?.userId })
      );
    },
    (error) => {
      error?.message &&
        toast(error?.message, {
          type: "error",
          theme: "colored",
          autoClose: 2000,
          position: "bottom-right",
          transition: Zoom,
        });
      // dispatch(setChannelError(error));
    }
  );
};

export const getCollaborationSearch = (data) => (dispatch) => {
  dispatch(setChannelError(null));
  collaboration.channels.getCollaborationSearch(
    data,
    (response) => {
      console.log("responsesearch", response);
    },
    (error) => {
      dispatch(setChannelError(error));
    }
  );
};

export const getChannelList = (data) => (dispatch) => {
  dispatch(setChannelError(null));
  collaboration.channels.get(
    data,
    (response) => {
      dispatch(setListChannelSuccess(response.data?.content));
      if (response.data?.content?.length > 0) {
        dispatch(setSelectedChat(response?.data?.content[0]));
      }
    },
    (error) => {
      dispatch(setChannelError(error));
    }
  );
};

export const getBrowserChannelList = (data) => (dispatch) => {
  dispatch(setChannelError(null));
  collaboration.channels.getBrowserList(
    data,
    (response) => {
      let channels = response?.data;
      // channels = getListOfParticipantsForRoom(channels, "id");
      dispatch(setBrowserListChannelSuccess(channels));
    },
    (error) => {
      dispatch(setChannelError(error));
    }
  );
};

export const getChatHistory = (data) => (dispatch) => {
  dispatch(setChannelError(null));
  collaboration.socket.get(
    data,
    (response) => {
      dispatch(setChatHistorySuccess(response?.data?.content));
    },
    (error) => {
      dispatch(setChannelError(error));
    }
  );
};

export const updateChannel = (data) => (dispatch) => {
  dispatch(setChannelError(null));
  collaboration.channels.update(
    data,
    (response) => {
      dispatch(setUpdateChannelSuccess(response.data));
    },
    (error) => {
      dispatch(setChannelError(error));
    }
  );
};

export const deleteChannel = (data) => (dispatch) => {
  dispatch(setChannelError(null));
  collaboration.channels.delete(
    data,
    (response) => {
      dispatch(setDeleteChannelSuccess(data));
    },
    (error) => {
      toast(error?.message, {
        type: "error",
        theme: "colored",
        autoClose: 2000,
        position: "bottom-right",
        transition: Zoom,
      });
    }
  );
};
