import { SET_APPROVE_REQUEST_SUCCESS, SET_CREATE_REQUEST_SUCCESS,
    SET_REJECT_REQUEST_SUCCESS,
    SET_SELF_REQUEST_SUCCESS,
    SET_TEAM_REQUEST_SUCCESS,
    SET_TOOLLIST_SUCCESS,
    SET_REQUEST_ERROR } from '../constants/request';

    const initialState = {

        toolListResponse:[],
        createRequestResponse:{},
        selfRequestResponse:[],
        teamRequestResponse:[],
        approveRequestResponse:'',
        rejectRequestResponse:'',
        onError:{},
    
    };
    
    export default (state = initialState, action) => {
        switch(action.type) {
    
            case SET_TOOLLIST_SUCCESS: {
                return Object.assign({}, state, {
                    toolListResponse:action.response
                });
            }

            case SET_CREATE_REQUEST_SUCCESS: {
                return Object.assign({}, state, {
                    createRequestResponse:action.response
                });
            }

            case SET_SELF_REQUEST_SUCCESS: {
                return Object.assign({}, state, {
                    selfRequestResponse:action.response
                });
            }

            case SET_TEAM_REQUEST_SUCCESS: {
                return Object.assign({}, state, {
                    teamRequestResponse:action.response
                });
            }

            case SET_APPROVE_REQUEST_SUCCESS: {
                return Object.assign({}, state, {
                    approveRequestResponse:action.response
                });
            }

            case SET_REJECT_REQUEST_SUCCESS: {
                return Object.assign({}, state, {
                    rejectRequestResponse:action.response
                });
            }
    
            case SET_REQUEST_ERROR: {
                return Object.assign({}, state, {
                    onError: action.error
                });
            }
    
            default:
                return {...state}
        }
    }