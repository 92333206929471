import { error } from 'jquery';
import {SET_PROFILE_SUCCESS , SET_PROFILE_UPDATE_SUCCESS , SET_PROFILE_ERROR} from '../constants/profile';
import {profileService , profileUpdateService } from '../services/profile';


export const setProfileSuccess = (success) => {
	return {
		type: SET_PROFILE_SUCCESS,
		success
	};
}

const setProfileUpdateSuccess = (success) => {
	return {
		type: SET_PROFILE_UPDATE_SUCCESS,
		success
	};
}

const setProfileError = (error) => {
	return {
		type: SET_PROFILE_ERROR,
		error
	};
}

export const submitProfileFrom = () => dispatch => {
	dispatch(setProfileError(null));
	dispatch(setProfileSuccess(null));
	profileService((response) => {
		dispatch(setProfileError(null));
		dispatch(setProfileSuccess(response.data));
		
	}, (error) => {
		dispatch(setProfileError(error));
	});
}

export const submitProfileUpdateFrom = (data) => dispatch => {
	dispatch(setProfileError(null));
	dispatch(setProfileUpdateSuccess(null));
	profileUpdateService(data, (response) => {
		console.log("profile updteresp", response)
		dispatch(setProfileError(null));
		dispatch(setProfileUpdateSuccess(response.data));
	} , (error) => {
		dispatch(setProfileError(error));
	});
}