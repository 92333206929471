import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOrgLicenseModules } from '../../../actions/license';
import { getOrgList } from "../../../actions/organization";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import $  from 'jquery';
import Select from 'react-select';
import Button from '../../Shared/Button';
import ScaleLoaderSpinner from '../../Shared/ScaleLoader';

class OrgLicenseCreate extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
      isConnecting: false,
      createOrgLicense: {
        id :  "",
        organizationId :  "",
        organizationName:  "",
        startDate: "",
        expiryDate: "",
        adminEmail:  "",
        numberofUsers: 0,
        modules: [],
      },
      moduleList : [],
      organisationList: [],
      selectedOrg: null,
      selectedOrgError:""
    }
  }

  componentDidMount(){
    this.setState({ isConnecting: true });
    this.props.getOrgList();
    this.props.getOrgLicenseModules();
  }

  componentDidUpdate(prevProps){

    if(this.props.orgModulesList !== prevProps.orgModulesList && this.props.orgModulesList && this.props.orgModulesList?.length > 0 && $.isEmptyObject(this.props.orgLicenseEditList)){
      let moduleList = [];
      moduleList = this.props.orgModulesList?.map((obj) => ({ ...obj, isChecked: obj.name === "Dashboard", isDisabled:  obj.name === "Dashboard"}));
      this.setState({ moduleList: moduleList, isConnecting: false });
      const selectedModule = moduleList.filter(module => module.code === "dashboard").map(module => module.id);
      this.setState(prevState => ({
        createOrgLicense: {
          ...prevState.createOrgLicense,
          modules : selectedModule
        }
      }));
    } else if (this.props.orgModulesList?.length === 0 && this.state.isConnecting){
      this.setState({isConnecting : false});
    }

    if(this.props.orgList !== prevProps.orgList && this.props.orgList){
      if(this.props.orgList?.length > 0){
        const list = this.props.orgList.map(org => ({ label: org.name, value: org.id }))
        this.setState({organisationList : list , isConnecting : false});
      } else {
        this.setState({isConnecting : false});
      }
    }
  }

  handleOrgLicCreate = (e, field) => {
    if (e && field) {
      const value = e.target.value;
      this.setState((prevState) => {
        const updatedCreateOrgLicense = { ...prevState.createOrgLicense };
        switch (field) {

          case "startdate" : {
            updatedCreateOrgLicense.startDate = value;
            break;
          }

          case "enddate" : {
            updatedCreateOrgLicense.expiryDate = value;
            break;
          }

          case "usercount" : {
            updatedCreateOrgLicense.numberofUsers = parseInt(value);
            break;
          }

          case "orgId" : {
            updatedCreateOrgLicense.organizationId = value;
            break;
          }

          case "orgname" : {
            updatedCreateOrgLicense.organizationName = value;
            break;
          }

          case "adminEmail" : {
            updatedCreateOrgLicense.adminEmail = value;
            break;
          }
        }
        return { ...prevState, createOrgLicense: updatedCreateOrgLicense };
      });
    }
  };

  handleChecked = (e , Module) => {
    if(e.target.checked){
      let modules = this.state.createOrgLicense?.modules?.length > 0 ? this.state.createOrgLicense?.modules : [] ;
      modules.push(Module?.id);
      this.setState(prevState => ({
        createOrgLicense: {
          ...prevState.createOrgLicense,
          modules : modules
        }
      }));
    } else {
      let modules = this.state.createOrgLicense?.modules?.length > 0 ? this.state.createOrgLicense?.modules : [] ;
      modules = modules?.filter((id) => {return id !== Module?.id});
      this.setState(prevState => ({
        createOrgLicense: {
          ...prevState.createOrgLicense,
          modules : modules
        }
      }));
    }
  
    let moduleList = this.state.moduleList;
    moduleList = moduleList?.map((obj) => {
      if(obj.id === Module.id){
        obj.isChecked =  e.target.checked
        return obj;
      } else return obj;
    })
    this.setState({moduleList : moduleList});
  }

/**
 * Validate hours & days in no of weeks ,no of days
 * @param {string} e - The input value for no of weeks or days
 */
keyCheckHandlar = (e) => {
  const { key, shiftKey } = e;
  if (shiftKey || (!key.match(/^[0-9]$/) && key !== 'Backspace' && !['ArrowLeft', 'ArrowRight', 'Tab', 'Delete'].includes(key))) {
    e.preventDefault();
  }
}

  handleSubmit = () => {
    $("#orgLicenseCreate").parsley().validate();
    if (this.validateForm() && $("#orgLicenseCreate").parsley().isValid()) {
      if(new Date(this.state.createOrgLicense.expiryDate) > new Date(this.state.createOrgLicense.startDate)){
        
        if(this.state.createOrgLicense.modules?.length === 0){
          toast(this.props.t('Modules.add_modules'), {
            type: 'info',
            theme: 'colored',
            autoClose: 2000,
            position: 'bottom-right',
            transition: Zoom,
            toastId: '1'
          });
          return;
        }

        this.props.handleLicenseCreate(this.state.createOrgLicense);

        /* if ($.isEmptyObject(this.props.orgLicenseEditList)) {
          this.props.handleLicenseCreate(this.state.createOrgLicense);
        } else {
          const newStateLicense = {
            organizationName: this.props.orgLicenseEditList.organizationName,
            numberofUsers: this.props.orgLicenseEditList.numberofUsers,
            expiryDate:  `${this.props.orgLicenseEditList.expiryDate[0]}-${String(this.props.orgLicenseEditList.expiryDate[1]).padStart(2, '0')}-${String(this.props.orgLicenseEditList.expiryDate[2]).padStart(2, '0')}`,
            startDate:  `${this.props.orgLicenseEditList.startDate[0]}-${String(this.props.orgLicenseEditList.startDate[1]).padStart(2, '0')}-${String(this.props.orgLicenseEditList.startDate[2]).padStart(2, '0')}`,
            adminEmail : this.props.orgLicenseEditList.adminEmail,
          };
        
          if (
            !(
              this.state.createOrgLicense.organizationName === newStateLicense.organizationName &&
              this.state.createOrgLicense.numberofUsers === newStateLicense.numberofUsers &&
              this.state.createOrgLicense.expiryDate === newStateLicense.expiryDate &&
              this.state.createOrgLicense.startDate === newStateLicense.startDate &&
              this.state.createOrgLicense.adminEmail === newStateLicense.adminEmail
            )
          ) {
            this.props.handleLicenseUpdate(this.state.createOrgLicense);
          } else {
            toast(this.props.t('License.edit_updt'), {
              type: 'info',
              theme: 'colored',
              autoClose: 2000,
              position: 'bottom-right',
              transition: Zoom,
              toastId: '1'
            });
          }
        } */
        
      } else {
        toast(this.props.t('License.valid_date'), { type: 'info', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom , toastId : '1'});
      }
    }
  }

  handleSelectorg = (selected) => {
    this.setState({ selectedOrg: selected, selectedOrgError: '' }, () => {
      const { selectedOrg } = this.state;
      if (selectedOrg) {
        this.setState((prevState) => ({
          createOrgLicense: {
            ...prevState.createOrgLicense,
            organizationId: selectedOrg.value,
            organizationName: selectedOrg.label,
          },
        }));
      }
    });
  };

  validateForm = () => {
    const { selectedOrg } = this.state;
    if (!selectedOrg) {
      this.setState({ selectedOrgError: this.props.t("errorMessages.select_org_err") });
      return false;
    }
    return true;
  };
    render() {
        const {t} = this.props;
        return (
            <div>
              <ToastContainer/>
            {this.state.isConnecting && (
              <div className="main-spinner-div">
                <div className="vertical-align-middle">
                  <div className="text-center">
                    <i className="fa fa-spinner fa-spin font-28"></i>{" "}
                    <span>{t("labels.loading")}</span>
                  </div>
                </div>
              </div>
            )}
              <div id="rightbar" className="modal-content slide-show rightbar open delay-100">
                <div className="modal-header ">
                  <h5 className="modal-title h4">
                    <b>{t("labels.licensecreation")}</b>
                  </h5>
                  <span id="orgCreationModal Close" onClick = {() => {this.props.handleorgcloser()}}  >
                    <i className="fas fa-times font-size-14 text-muted cursor-pointer"></i>
                  </span>
                </div>
                <div className="modal-body  orgcreation-scrollbox">
                  <div className="scrollbox scrollbox_delayed request-body col-12 px-0">
                    <div className="scrollbox-content">
                      <div className='px-0 py-2'>
                        <form id='orgLicenseCreate'>
                          <div className='row px-0 mx-0'>
                            <div className='form-group col-md-12 px-1 mb-2'>
                              <label className='control-label font-size-14 mb-4-px required'>{t("pomodoro.organization")}</label>
                              <span className='field-mandotary mr-2'/>
                              <Select
                                placeholder={t("pomodoro.organization")}
                                value={this.state.selectedOrg}
                                options={this.state.organisationList}
                                onChange={this.handleSelectorg}
                                maxMenuHeight={200}
                              /> 
                              {this.state.selectedOrgError && <span className="text-danger">{this.state.selectedOrgError}</span>}
                              {/* <input name="orgName" autoComplete="off" type="text" 
                                className="form-control" id=""  
                                value = {this.state.createOrgLicense.organizationName} 
                                placeholder={t("pomodoro.organization")}
                                title={t("pomodoro.organization")}
                                onChange={(e) => this.handleOrgLicCreate(e , 'orgname')}
                                data-parsley-trigger="change"
                                data-parsley-required="true"
                                data-parsley-error-message={t("errorMessages.org_Name")}
                                required 
                              /> */}
                            </div>
                            <div className='form-group col-md-12 px-1 mb-2'>
                              <label className='control-label font-size-14 mb-4-px required'>{t("labels.email")}</label>
                              <span className='field-mandotary mr-2'/> 
                              <input name="adminEmail" autoComplete="off" type="email" 
                                className="form-control" id=""  
                                value = {this.state.createOrgLicense.adminEmail} 
                                placeholder={t("labels.email")}
                                title={t("labels.email")}
                                onChange={(e) => this.handleOrgLicCreate(e , 'adminEmail')}
                                data-parsley-trigger="change"
                                data-parsley-required="true"
                                data-parsley-error-message={t("errorMessages.email")}
                                required 
                              />
                            </div>
                            {/* <div className='form-group col-md-12 px-1 mb-2'>
                              <label className='control-label font-size-14 mb-4-px required'>{t('License.orgId')}</label>
                              <span className='field-mandotary mr-2'/> 
                              <input name="orgId" autoComplete="off" type="text" 
                                className="form-control" id=""  
                                placeholder={t('License.orgId')}
                                title={t('License.orgId')}
                                onChange={(e) => this.handleOrgLicCreate(e , 'orgId')}
                                data-parsley-trigger="change"
                                data-parsley-required="true"
                                data-parsley-error-message={t("errorMessages.org_Id")}
                                required 
                              />
                            </div> */}
                            <div className='form-group col-md-6 px-1 mb-2'>
                              <label className='control-label font-size-14 mb-4-px'>{t("Projects.startdate")}</label>
                              <span className='field-mandotary mr-2'/>
                              <input name="orgStartDate" autoComplete="off" type="date" className="form-control"
                                onChange = {(e) => this.handleOrgLicCreate(e , 'startdate')}
                                defaultValue={this.state.createOrgLicense.startDate}
                                data-parsley-trigger="change"
                                data-parsley-required="true"
                                data-parsley-error-message={t("errorMessages.startdate")}
                              />
                            </div>
                            <div className='form-group col-md-6 px-1 mb-2'>
                              <label className='control-label font-size-14 mb-4-px'>{t("tasks.enddate")}</label>
                              <span className='field-mandotary mr-2'/>
                              <input name="orgEndDate" autoComplete="off" type='date' className="form-control"
                                onChange = {(e) => this.handleOrgLicCreate(e , 'enddate')}
                                defaultValue = {this.state.createOrgLicense.expiryDate}
                                data-parsley-trigger="change"
                                data-parsley-required="true"
                                data-parsley-error-message={t("errorMessages.Duedate")}
                              />
                            </div>
                            <div className='form-group col-md-6 px-1 mb-2'>
                              <label className='control-label font-size-14 mb-4-px'>{t('labels.userCount')}</label>
                              <span className='field-mandotary mr-2'/>
                              <input name="orgUserCount" autoComplete="off" type="text" className="form-control" placeholder={t("placeholders.user_count")} title = {t("placeholders.user_count")}
                                defaultValue = {this.state.createOrgLicense && this.state.createOrgLicense.numberofUsers ? this.state.createOrgLicense.numberofUsers : ""}
                                onChange = {(e) => this.handleOrgLicCreate(e , 'usercount')}
                                onKeyDown={this.keyCheckHandlar}
                                min={1}
                                data-parsley-validate 
                                data-parsley-pattern="^\d+$"
                                data-parsley-trigger="change"
                                data-parsley-required="true"
                                data-parsley-error-message={t("errorMessages.users_Count")} 
                              />
                            </div>
                            <div className='form-group col-md-12 px-1 mb-2 mt-2'>                              
                              <h4 className='font-size-16' >{this.props.t('Settings.modules')}<span className='field-mandotary mr-2'/></h4>
                              {this.state.moduleList?.length > 0 && 
                              this.state.moduleList?.map((moduleObj , Index) => {
                                return (
                              <div className='maindiv-orgcreative-checkbox' key={`$orglicensecreate-${Index}`}>
                                <label className="checkbox-main-div">
                                  <input type="checkbox" checked={moduleObj.isChecked} onChange={(e) => this.handleChecked(e , moduleObj)} disabled={moduleObj.isDisabled}/>
                                  <span className="checkmark"></span>
                                  <span className='spantag-labelname'>{moduleObj?.name}</span>
                                </label>
                              </div>
                                  )
                                })      
                              }
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer request-footer">
                  <div className="text-right">
                    <button name="licenseCreationModalCancel" onClick = {() => {this.props.handleorgcloser()}}
                     className="btn bg_darkgray mr-1">{t("labels.cancel")}</button>
                  {/* <button name="licenseCreationSubmit" type="button" onClick={() => this.handleSubmit()} className="btn bg_indgo ml-2" hidden="">{!$.isEmptyObject(this.props?.orgLicenseEditList) ? t("buttons.update") : t("labels.create")}</button> */}
                  <Button
                    onClick={() => this.handleSubmit()}
                    isLoading={this.props.isCreateButtonLoading}
                    buttonText={t("labels.create")}
                    type={"button"}
                  />
                  </div>
                </div>
              </div> 
            </div>
        );
    }
}


const mapStateToProps = function (state) {
    return {
      orgModulesList : state.license.orgModulesList,
      orgLicenseList : state.license.orgLicenseList,
      orgList: state.organization.orgList,
    }
  };
  
  /**
   * Map the actions to props.
   */
  const mapDispatchToProps = (dispatch) => ({
    getOrgLicenseModules:() => dispatch(getOrgLicenseModules()),
    getOrgList: () => dispatch(getOrgList()),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(withTranslation() (OrgLicenseCreate));