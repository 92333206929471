import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getEmpDashboardData } from '../../../actions/employeeDashboard';
import Utils from "../../../utils/utils";
import $ from 'jquery';
import moment from 'moment';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import SwitchTab from '../../common/Switchtoggle';
import OrgLicenseCreate from './orgcreatemodal';
import {
  createOrgLicense, updateOrgLicense, actDeactModuleOrgLicense, getOrgLicenseById,
  addModulesOrgLicense, getOrgLicenseModules, activateOrgLicense, deactivateOrgLicense
} from "../../../actions/license";
import Multiselect from 'multiselect-react-dropdown';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PaginatedList from '../../Shared/pagination';
import ScaleLoaderSpinner from '../../Shared/ScaleLoader';
import { getOrgLicenseServiceData } from "../../../services/license";
import handleApiError from "../../Shared/ErrorHandling";
import ContentLoader from "../../Shared/ContentLoader";
import { pageSizeDropdownOptions } from '../../Shared/HelperFunctions/pageSizeOptions'

class SuperAdminDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isConnecting: false,
      isMinus: false,
      modulesAdd: false,
      modulesView: false,
      licenseEdit: false,
      orgModulesView: false,
      orgLicCreateOpen: false,
      creationCloser: false,
      orgLicenseList: {},
      orgLicenseEditList: {},
      orgLicenseViewList: {},
      modulelist: [],
      orgModuleList: [],
      moduleIds: [],
      actDeactModuleList: [],
      showActDeactModal: false,
      licenseActDeactId: '',
      licenseActDeact: false,
      licenseActDeactList: {},
      moduleAddList: [],
      activeModuleIds: [],
      inactiveModuleIds: [],
      updateOrgLicense: {
        id: "",
        organizationId: "",
        startDate: "",
        expiryDate: "",
        adminEmail: "",
        numberofUsers: 0,
      },
      pageSize: 10,
      page: 0,
      size: 10,
      isCreateButtonLoading: false,
      totalRecords: 0,
      filterObj: {
        organizationName: "",
      },
      DataContentLoader: false
    }
    this.tableRef = React.createRef();
  }



  componentDidMount() {
    const modules = JSON.parse(sessionStorage.getItem("moduleStatus"))
    if ((modules?.activeModuleIds?.length > 0 || modules?.inactiveModuleIds?.length > 0) && !$.isEmptyObject(modules)) {
      var continueEvent = new CustomEvent("moduleStatusChange", { detail: true });
      window.dispatchEvent(continueEvent);
      this.setState({ modulesView: modules.modulesView, orgModulesView: modules.orgModulesView, orgLicenseViewList: modules.orgLicenseViewList, activeModuleIds: modules.activeModuleIds, inactiveModuleIds: modules.inactiveModuleIds, actDeactModuleList: modules.actDeactModuleList, orgModuleList: modules.orgModuleList });
    }
    this.setState({ isConnecting: true });
    // this.props.getEmpDashboardDataFunc();
    this.getOrgLicenseServiceDataCall(this.state.filterObj, this.state.page, this.state.size)
  }

  componentWillUnmount() {
    const modules = JSON.parse(sessionStorage.getItem("moduleStatus"))
    if ((modules?.activeModuleIds?.length > 0 || modules?.inactiveModuleIds?.length > 0) && !$.isEmptyObject(modules)) {
      var continueEvent = new CustomEvent("moduleStatusChange", { detail: true });
      window.dispatchEvent(continueEvent);
    } else {
      sessionStorage.removeItem("moduleStatus");
      var continueEvent = new CustomEvent("moduleStatusChange", { detail: false });
      document.dispatchEvent(continueEvent);
    }
  }


  componentDidUpdate(prevProps, prevState) {
    const modules = JSON.parse(sessionStorage.getItem("moduleStatus"))

    if (this.props.onError !== prevProps.onError && this.props.onError) {
      toast(this.props?.onError?.message ? this.props?.onError?.message : this.props.t("errorMessages.server_error"), { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
      this.setState({ isConnecting: false, isCreateButtonLoading: false });
    }
    if (this.props.data !== prevProps.data && this.props.data) {
      if (!$.isEmptyObject(this.props.data)) {
        this.setState({ isConnecting: false });
      }
    }

    if (this.props.orgLicenseList !== prevProps.orgLicenseList && !$.isEmptyObject(this.props.orgLicenseList) && !this.props.licenseOnError) {

      const existingContent = this.state.orgLicenseList?.content || [];
      const newContent = this.props.orgLicenseList?.content || [];
      const contentMap = new Map(existingContent.map(item => [item.id, item]));
      newContent.forEach(item => contentMap.set(item.id, item));
      const updatedContent = Array.from(contentMap.values());

      const updatedOrgLicenseList = {
        ...this.state.orgLicenseList,
        content: updatedContent,
      };

      this.setState({ orgLicenseList: updatedOrgLicenseList, isConnecting: false });

    } else if (!$.isEmptyObject(this.props.orgLicenseList) && this.state.isConnecting) {
      this.setState({ isConnecting: false });
    }

    if (this.props.orgLicenseCreate !== prevProps.orgLicenseCreate && !$.isEmptyObject(this.props.orgLicenseCreate) && this.props.orgLicenseCreate?.id?.length > 0) {
      this.handleorgcloser();
      toast(this.props.t('License.create'), { type: 'success', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom, toastId: "1" });
      this.setState({ page: 0, orgLicenseList: {}, isCreateButtonLoading: false });
      this.handleLicenseTableData();
    }

    if (this.props.orgLicenseUpdate !== prevProps.orgLicenseUpdate && this.props.orgLicenseUpdate) {
      this.handleorgcloser();
      toast(this.props.orgLicenseUpdate, { type: 'success', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom, toastId: "1" });
      this.setState({ page: 0, orgLicenseList: {}, orgModulesView: false, licenseEdit: false });
      this.handleLicenseTableData();
    }


    if (this.props.orgLicenseById !== prevProps.orgLicenseById && this.props.orgLicenseById) {
      this.setState((prevState) => ({
        modulelist: this.props.orgLicenseById?.modules,
        orgLicenseViewList: this.props.orgLicenseById,
        updateOrgLicense: {
          ...prevState.updateOrgLicense,
          id: this.props.orgLicenseById?.id ? this.props.orgLicenseById?.id : "",
          organizationId: this.props.orgLicenseById?.organizationId ? this.props.orgLicenseById?.organizationId : "",
          startDate: !$.isEmptyObject(this.props.orgLicenseById) ? `${this.props.orgLicenseById.startDate[0]}-${String(this.props.orgLicenseById.startDate[1]).padStart(2, '0')}-${String(this.props.orgLicenseById.startDate[2]).padStart(2, '0')}` : "",
          expiryDate: !$.isEmptyObject(this.props.orgLicenseById) ? `${this.props.orgLicenseById.expiryDate[0]}-${String(this.props.orgLicenseById.expiryDate[1]).padStart(2, '0')}-${String(this.props.orgLicenseById.expiryDate[2]).padStart(2, '0')}` : "",
          adminEmail: this.props.orgLicenseById && this.props.orgLicenseById.adminEmail ? this.props.orgLicenseById.adminEmail : "",
          numberofUsers: !$.isEmptyObject(this.props.orgLicenseById) ? this.props.orgLicenseById?.numberofUsers : "",
        },
      }));


      this.setState({ modulelist: this.props.orgLicenseById?.modules, orgLicenseViewList: this.props.orgLicenseById });
    }

    if (this.props.orgLicenseModuleActivateDeactivate !== prevProps.orgLicenseModuleActivateDeactivate && this.props.orgLicenseModuleActivateDeactivate) {
      toast(this.props.orgLicenseModuleActivateDeactivate, { type: 'success', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom, toastId: "1" });
      this.setState({ page: 0 });
      this.handleLicenseTableData();
    }

    if (this.props.orgModulesList !== prevProps.orgModulesList && this.props.orgModulesList) {
      if (this.props.orgModulesList?.length > 0) {
        let modulelist = [...this.props.orgModulesList];
        let selectedIds = [];
        modulelist = modulelist?.map((modObj) => ({ ...modObj, active: false }));
        let viewList = _.cloneDeep(this.state.orgLicenseViewList);
        viewList.modules = Array.from(
          new Map(
            this.state.orgLicenseViewList?.modules
              ?.map(obj => [obj.moduleName, obj])
          )?.values()
        );
        modulelist?.forEach((modObj) => {
          const matchModulesObj = viewList?.modules?.find((orgObj) => { return orgObj.moduleName === modObj.name });

          if (!$.isEmptyObject(matchModulesObj)) {
            modObj.active = matchModulesObj.active;
            if (matchModulesObj.active) {
              selectedIds.push(modObj.id);
            }
            return modObj;
          }
          return modObj;
        })
        this.setState({ orgModuleList: modulelist, orgLicenseViewList: viewList, isConnecting: false });
      }

      if ((modules?.activeModuleIds?.length > 0 || modules?.inactiveModuleIds?.length > 0) && !$.isEmptyObject(modules)) {
        var continueEvent = new CustomEvent("moduleStatusChange", { detail: true });
        window.dispatchEvent(continueEvent);
      }
      this.setState({ isConnecting: false });
    }

    if (this.props.addOrgLicenseModules !== prevProps.addOrgLicenseModules && this.props.addOrgLicenseModules) {
      toast(this.props.addOrgLicenseModules, { type: 'success', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom, toastId: "1" });
      this.setState({ page: 0, orgLicenseList: {}, moduleIds: [], modulesAdd: false, orgModulesView: false, modulesView: false });
      this.handleLicenseTableData();
    }

    if (this.props.orgLicenseActivate !== prevProps.orgLicenseActivate && this.props.orgLicenseActivate) {
      toast(this.props.orgLicenseActivate, { type: 'success', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
      this.setState({ page: 0, orgLicenseList: {}, moduleIds: [], modulesAdd: false, orgModulesView: false, modulesView: false });
      this.handleLicenseTableData();
    }

    if (this.props.orgLicenseDeactivate !== prevProps.orgLicenseDeactivate && this.props.orgLicenseDeactivate) {
      toast(this.props.orgLicenseDeactivate, { type: 'success', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
      this.setState({ page: 0, orgLicenseList: {}, moduleIds: [], modulesAdd: false, orgModulesView: false, modulesView: false });
      this.handleLicenseTableData();
    }

    if (this.props.licenseOnError !== prevProps.licenseOnError && this.props.licenseOnError) {
      toast(this.props.licenseOnError.message ? this.props.licenseOnError.message : this.props.t("errorMessagesserver_error"), { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom, toastId: "1" });
      this.setState({ isConnecting: false, modulesAdd: false, orgModulesView: false, modulesView: false, orgLicCreateOpen: false });
    }

    if (this.props.orgOnError !== prevProps.orgOnError && this.props.orgOnError) {
      toast(this.props.orgOnError?.message ? this.props.orgOnError?.message : this.props.t("errorMessagesserver_error"), { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom, toastId: "1" });
      this.setState({ isConnecting: false });
    }

    if (this.state.activeModuleIds !== prevState.activeModuleIds || this.state.inactiveModuleIds !== prevState.inactiveModuleIds) {
      sessionStorage.setItem("moduleStatus", JSON.stringify(this.state));
    }
  }

  /**
   * Using the function to call the data 
   * after the update in componentDidUpdate
   */
  handleLicenseTableData = () => {
    this.getOrgLicenseServiceDataCall(this.state.filterObj, this.state.page, this.state.size)
    this.tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  handleActDeact = (value, id) => {
    let licenseList = this.props.orgLicenseList?.content?.find(licObj => { return licObj.id === id });
    this.setState({ licenseActDeactId: id, showActDeactModal: true, licenseActDeact: value, licenseActDeactList: licenseList });
  }

  handleisMinus = () => {
    this.setState({ isMinus: !this.state.isMinus });
  }

  handleModulesAdd = () => {
    let modulesAdd = [];

    if (this.props.orgLicenseById && this.props.orgLicenseById.modules && this.props.orgLicenseById.modules.length === 0) {
      modulesAdd = [...this.state.orgModuleList]
    } else {
      modulesAdd = this.state.orgModuleList?.filter(listObj => !this.state.orgLicenseViewList?.modules?.some(modObj => modObj.moduleId === listObj.id));
    }

    this.setState({ modulesAdd: !this.state.modulesAdd, modulesView: false, moduleAddList: modulesAdd, licenseEdit: false });
  }

  /**
   * click to handle the license Edit
   * @param {Object} license - The license which needs to be updated
   */
  handleLicenseEdit = (license) => {
    this.setState({ orgLicenseEditList: license, licenseEdit: !this.state.licenseEdit, modulesAdd: false, modulesView: false });
  }

  handleModules = () => {
    this.setState({ modulesView: !this.state.modulesView, modulesAdd: false, licenseEdit: false });
  }

  handleorgopen = () => {
    this.setState({ orgLicCreateOpen: true });
  }

  handleorgcloser = () => {
    this.setState({ orgLicCreateOpen: false, orgLicenseEditList: {} });
  }

  handleLicenseCreate = (create) => {
    if (!$.isEmptyObject(create)) {
      this.props.createOrgLicense(create);
      this.setState({ isCreateButtonLoading: true });
    }
  }

  handleLicenseUpdate = (update) => {
    if (!$.isEmptyObject(update)) {
      this.props.updateOrgLicense(update);
      this.setState({ isConnecting: true });
    }
  }

  handleUpdateOrgLic = (license) => {
    if (!$.isEmptyObject(license)) {
      this.props.getOrgLicenseById({ id: license.id });
    }
    const License = _.cloneDeep(license);
    this.props.getOrgLicenseModules();
    this.setState({ orgModulesView: !this.state.orgModulesView, orgLicenseViewList: !$.isEmptyObject(license) ? License : {}, modulesView: false, moduleIds: [], modulesAdd: false, activeModuleIds: [], inactiveModuleIds: [], licenseEdit: false });
  }

  actDeactLicense = () => {

    if (this.state.licenseActDeact === true) {
      this.props.activateOrgLicense({ id: this.state.licenseActDeactId });
    } else {
      this.props.deactivateOrgLicense({ id: this.state.licenseActDeactId });
    }
    this.setState({ licenseActDeactId: '', showActDeactModal: false, licenseActDeact: false, licenseActDeactList: {}, isConnecting: true });    //need to change the setstate
  }


  handleModuleActDeact = (e, module) => {
    let { activeModuleIds = [], inactiveModuleIds = [] } = this.state;
    let moduleList = _.cloneDeep(activeModuleIds?.length !== 0 || inactiveModuleIds?.length !== 0 ? this.state.orgLicenseViewList : this.props.orgLicenseById);
    let moduleIds = _.cloneDeep(this.state.actDeactModuleList?.length > 0 ? this.state.actDeactModuleList : this.state.orgLicenseViewList.modules);

    moduleIds = moduleIds.map(modObj =>
      modObj.moduleId === module.moduleId
        ? { ...modObj, active: !modObj.active }
        : modObj
    );

    moduleList.modules = moduleList?.modules?.map(modObj => {
      if (modObj.moduleId === module.moduleId) {
        modObj.active = e.target.checked;
      }
      return modObj;
    });

    const defaultStatus = this.state.modulelist?.find(modObj => modObj.moduleId === module.moduleId)?.active;

    if (e.target.checked) {
      activeModuleIds = defaultStatus === false ? [...activeModuleIds, module.moduleId] : activeModuleIds.filter(id => id !== module.moduleId);
      inactiveModuleIds = inactiveModuleIds.filter(id => id !== module.moduleId);
    } else {
      inactiveModuleIds = defaultStatus === true ? [...inactiveModuleIds, module.moduleId] : inactiveModuleIds.filter(id => id !== module.moduleId);
      activeModuleIds = activeModuleIds.filter(id => id !== module.moduleId);
    }

    this.setState({
      activeModuleIds,
      inactiveModuleIds,
      orgLicenseViewList: moduleList,
      actDeactModuleList: moduleIds
    });

  }

  onModulesAddRemove = (event) => {
    let moduleIds = Array.isArray(event) ? event.map(x => x.id) : [];
    this.setState({ moduleIds: moduleIds });
  }

  handleAddModulesSubmit = () => {
    if (this.state.moduleIds?.length > 0) {
      this.props.addModulesOrgLicense({ id: this.state.orgLicenseViewList?.id, modules: this.state.moduleIds, tenantId: this.state.orgLicenseViewList?.tenantId });
    } else {
      toast(this.props.t('Modules.modules_add'), { type: 'info', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom, toastId: "1" });
    }
  }

  handleActDeactSubmit = () => {

    const inactiveAll = this.state.orgLicenseViewList?.modules?.every((modObj) => modObj.active === false);

    if (inactiveAll) {
      toast(this.props.t('Modules.atleast_one'), { type: 'info', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom, toastId: "1" });
      return;
    }

    if (this.state.activeModuleIds?.length > 0 || this.state.inactiveModuleIds?.length > 0) {
      this.props.actDeactModuleOrgLicense({ id: this.state.orgLicenseViewList?.id, modules: this.state.actDeactModuleList, tenantId: this.state.orgLicenseViewList?.tenantId, organizationId: this.state.orgLicenseViewList?.organizationId })
      this.setState({ orgModulesView: false, page: 0, size: this.state.size, isConnecting: true, activeModuleIds: [], inactiveModuleIds: [], modulesView: false, modulesAdd: false });
      this.tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      toast(this.props.t('License.edit_module'), { type: 'info', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom, toastId: "1" });
      return;
    }
  }

  handlePageClick = (page) => {
    this.getOrgLicenseServiceDataCall(this.state.filterObj, page.selected, this.state.size);
  }

  handlePageSizeChange = (size) => {
    this.setState({ pageSize: size }, () => { 
      this.getOrgLicenseServiceDataCall(this.state.filterObj, 0, size);
    })
  };

  handleSearchInputs = (e, field) => {
    let searchInputs = { ...this.state.filterObj }
    searchInputs[field] = e.target.value
    this.getOrgLicenseServiceDataCall(searchInputs, 0, this.state.size);
  }

  /* getOrgLicenseService call with Pagination api*/

  getOrgLicenseServiceDataCall = async (searchInputs = {}, page = 0, size = 10) => {
    let filters = []
    Object.keys(searchInputs).forEach(key => {
      if (searchInputs[key]) {
        let obj = {}
        obj.fieldName = key;
        obj.valueOne = searchInputs[key];
        filters.push(obj)
        }
    })
    this.setState({ filterObj: searchInputs, page: page, size: size, DataContentLoader: true });

    let payload = {
      pageNumber: page,
      size: size,
      pageNationRequired: true,
      filters: filters
    }

    try {
      const response = await getOrgLicenseServiceData(payload);
      if (response.data) {
        this.setState({
          isConnecting: false,
          totalRecords: response.data.totalRecords,
          orgLicenseList: response.data ? response.data : {},
          DataContentLoader: false
        })
      }
    } catch (error) {
      this.setState({
        DataContentLoader: false
      })
      handleApiError(error, this.props);
    }

  }

  /**
   * Validate hours & days in no of weeks ,no of days
   * @param {string} e - The input value for no of weeks or days
   */
  keyCheckHandlar = (e) => {
    const { key, shiftKey } = e;
    if (shiftKey || (!key.match(/^[0-9]$/) && key !== 'Backspace' && !['ArrowLeft', 'ArrowRight', 'Tab', 'Delete'].includes(key))) {
      e.preventDefault();
    }
  }

  /**
   * 
   * @param {string} e - The input value for license update
   * @param {string} field - The value which determines which needs to be updated
   */

  handleOrgLicUpdate = (e, field) => {
    if (e && field) {
      const value = e.target.value;
      this.setState((prevState) => {
        const updatedCreateOrgLicense = { ...prevState.updateOrgLicense };
        switch (field) {

          case "enddate": {
            updatedCreateOrgLicense.expiryDate = value;
            break;
          }

          case "usercount": {
            updatedCreateOrgLicense.numberofUsers = parseInt(value);
            break;
          }

          case "adminEmail": {
            updatedCreateOrgLicense.adminEmail = value;
            break;
          }
        }
        return { ...prevState, updateOrgLicense: updatedCreateOrgLicense };
      });
    }
  };

  handleSubmit = () => {
    $("#orgLicenseUpdate").parsley().validate();
    if ($("#orgLicenseUpdate").parsley().isValid()) {
      if (new Date(this.state.updateOrgLicense.expiryDate) > new Date(this.state.updateOrgLicense.startDate)) {
        const newStateLicense = {
          numberofUsers: this.props.orgLicenseById.numberofUsers,
          expiryDate: `${this.props.orgLicenseById.expiryDate[0]}-${String(this.props.orgLicenseById.expiryDate[1]).padStart(2, '0')}-${String(this.props.orgLicenseById.expiryDate[2]).padStart(2, '0')}`,
          adminEmail: this.props.orgLicenseById.adminEmail,
        };

        if (
          !(
            this.state.updateOrgLicense.numberofUsers === newStateLicense.numberofUsers &&
            this.state.updateOrgLicense.expiryDate === newStateLicense.expiryDate &&
            this.state.updateOrgLicense.adminEmail === newStateLicense.adminEmail
          )
        ) {
          this.props.updateOrgLicense(this.state.updateOrgLicense);
          this.setState({ isConnecting: true });
        } else {
          toast(this.props.t('License.edit_updt'), {
            type: 'info',
            theme: 'colored',
            autoClose: 2000,
            position: 'bottom-right',
            transition: Zoom,
            toastId: '1'
          });
        }
      } else {
        toast(this.props.t('License.valid_date'), { type: 'info', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom, toastId: '1' });
      }
    }
  }

  render() {
    const dataToPass = Utils.getDataToPass();
    const currYear = dataToPass.year;
    const currMonth = dataToPass.month;
    let machineCount = 0;
    let toolCount = 0;
    let allocatedMachines = 0;
    let deallocatedMachines = 0;
    let activeTool = 0;
    let inActiveTool = 0;
    let lastMonthMachine = 0;
    let lastMonthMachinePercent = 0;
    let lastMonthAllocateCount = 0;
    let lastMonthDeallocateCount = 0;
    let lastMonthTool = 0;
    let lastMonthToolPercent = 0;
    let lastMonthAllocateTool = 0;
    let lastMonthDeAllocateTool = 0;
    let machineList = this.props.data.machinesList || [];
    let lastMonth = moment(currYear + "-" + (currMonth - 1)).format('YYYY-MM')

    if (!$.isEmptyObject(this.props.data)) {
      machineCount = this.props.data.machines.length;
      toolCount = this.props.data.tools.length;
      this.props.data.machines.map(machine => {
        if (lastMonth === moment(machine.createdDate).format('YYYY-MM')) {
          lastMonthMachine = lastMonthMachine + 1;
          machine.status === "Active" ? lastMonthAllocateCount = lastMonthAllocateCount + 1 : lastMonthDeallocateCount = lastMonthDeallocateCount + 1;
        }
        machine.status === 'Active' ? allocatedMachines = allocatedMachines + 1 : deallocatedMachines = deallocatedMachines + 1;
      })

      this.props.data.tools.map(tool => {
        if (lastMonth === moment(tool.createdDate).format('YYYY-MM')) {
          lastMonthTool = lastMonthTool + 1;
          tool.active === true ? lastMonthAllocateTool = lastMonthAllocateTool + 1 : lastMonthDeAllocateTool = lastMonthDeAllocateTool + 1;
        }
        tool.active === true ? activeTool = activeTool + 1 : inActiveTool = inActiveTool + 1;
      })
      lastMonthMachinePercent = (lastMonthMachine / machineCount) * 100;
      lastMonthToolPercent = (lastMonthTool / toolCount) * 100;

    }

    const { t } = this.props;
    const tableHeader = ['organization', 'Registerdate', 'membercount', 'actions'];
    return (
      <div className="container-fluid mt-2 px-0 ">
        <ToastContainer />
        {this.state.isConnecting && (
          <div className="main-spinner-div">
            <div className="vertical-align-middle">
              <div className="text-center">
                <i className="fa fa-spinner fa-spin font-28"></i>{" "}
                <span>{t("labels.loading")}</span>
              </div>
            </div>
          </div>
        )}
        {this.state.showActDeactModal === true && (
          <div id="shift-closed-alert">
            <div className="endcall-bk">
              <div className="endcall-alert text-left">
                <div className=" mb-2">
                  <h6 className="text-muted ">{this.state.licenseActDeactList?.organizationName}</h6>
                </div>
                <p className="mt-2 mb-3">{this.state.licenseActDeact ? t('License.activate') : t('License.deactivate')}</p>
                <div className="text-center">
                  <button
                    name="licenseActDeact"
                    className="btn btn-success"
                    onClick={() => { this.actDeactLicense() }}
                  >
                    {t('buttons.yes')}
                  </button>
                  <button
                    name="licenseActDeactModalCancel"
                    className="btn btn-default ml-2"
                    onClick={() => { this.setState({ showActDeactModal: false, licenseActDeactList: {} }) }}
                  >
                    {t('buttons.no')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.orgLicCreateOpen && (
          <>
            <OrgLicenseCreate handleorgcloser={this.handleorgcloser} handleLicenseCreate={this.handleLicenseCreate} handleLicenseUpdate={this.handleLicenseUpdate} handleOrgLicCreate={this.handleOrgLicCreate} isCreateButtonLoading={this.state.isCreateButtonLoading} />
          </>
        )}

        {this.state.orgModulesView && (
          <>
            <div id="rightbar" className="modal-content slide-show rightbar open delay-100">
              <div className="modal-header ">
                <h5 className="modal-title h4">
                  <b>{this.state.orgLicenseViewList?.organizationName}</b>
                </h5>
                <span id="orgCreationModal Close" >
                  <i className="fas fa-times font-size-14 text-muted cursor-pointer" onClick={() => this.handleUpdateOrgLic({})}></i>
                </span>
              </div>
              <div className="modal-body  orgcreation-scrollbox">
                <div className="scrollbox scrollbox_delayed request-body col-12 px-0" >
                  <div className="scrollbox-content">
                    <div className='col-12 px-0'>
                      {/**################# License Update ################# */}
                      <div className='border rounded p-3 mt-2'>
                        <p className='mb-0 d-justify-align cursor-pointer' onClick={() => this.handleLicenseEdit(this.props.orgLicenseById)}>
                          <span className='font-size-14'>{t("labels.licenseupdate")}</span>
                          <i className={`font-size-12 ${this.state.licenseEdit ? "fa fa-minus Manatee-Gray" : "fa fa-plus one-blue"}`}></i>
                        </p>
                        {this.state.licenseEdit && (
                          <div>
                            <div className="modal-body  orgcreation-scrollbox">
                              <div className="scrollbox scrollbox_delayed request-body col-12 px-0">
                                <div className="scrollbox-content">
                                  <div className='px-0 py-2'>
                                    <form id="orgLicenseUpdate">
                                      <div className='row px-0 mx-0'>
                                        <div className='form-group col-md-12 px-1 mb-2'>
                                          <label className='control-label font-size-14 mb-4-px required'>{t("pomodoro.organization")}</label>
                                          <span className='field-mandotary mr-2' />
                                          <input name="orgName" autoComplete="off" type="text"
                                            className="form-control" id=""
                                            value={this.state.orgLicenseViewList?.organizationName}
                                            placeholder={t("pomodoro.organization")}
                                            title={t("pomodoro.organization")}
                                            disabled
                                          />
                                        </div>
                                        <div className='form-group col-md-12 px-1 mb-2'>
                                          <label className='control-label font-size-14 mb-4-px required'>{t("labels.email")}</label>
                                          <span className='field-mandotary mr-2' />
                                          <input name="adminEmail" autoComplete="off" type="email"
                                            className="form-control" id=""
                                            defaultValue={this.state.orgLicenseViewList.adminEmail}
                                            placeholder={t("labels.email")}
                                            title={t("labels.email")}
                                            onChange={(e) => this.handleOrgLicUpdate(e, 'adminEmail')}
                                            data-parsley-trigger="change"
                                            data-parsley-required="true"
                                            data-parsley-error-message={t("errorMessages.email")}
                                            required
                                          />
                                        </div>
                                        <div className='form-group col-md-6 px-1 mb-2'>
                                          <label className='control-label font-size-14 mb-4-px'>{t("Projects.startdate")}</label>
                                          <span className='field-mandotary mr-2' />
                                          <input name="orgStartDate" autoComplete="off" type="date" className="form-control"
                                            defaultValue={`${this.state.orgLicenseViewList.startDate[0]}-${String(this.state.orgLicenseViewList.startDate[1]).padStart(2, '0')}-${String(this.state.orgLicenseViewList.startDate[2]).padStart(2, '0')}`}
                                            disabled
                                          />
                                        </div>
                                        <div className='form-group col-md-6 px-1 mb-2'>
                                          <label className='control-label font-size-14 mb-4-px'>{t("tasks.enddate")}</label>
                                          <span className='field-mandotary mr-2' />
                                          <input name="orgEndDate" autoComplete="off" type='date' className="form-control"
                                            onChange={(e) => this.handleOrgLicUpdate(e, 'enddate')}
                                            defaultValue={`${this.state.orgLicenseViewList.expiryDate[0]}-${String(this.state.orgLicenseViewList.expiryDate[1]).padStart(2, '0')}-${String(this.state.orgLicenseViewList.expiryDate[2]).padStart(2, '0')}`}
                                            data-parsley-trigger="change"
                                            data-parsley-required="true"
                                            data-parsley-error-message={t("errorMessages.Duedate")}
                                          />
                                        </div>
                                        <div className='form-group col-md-6 px-1 mb-2'>
                                          <label className='control-label font-size-14 mb-4-px'>{t('labels.userCount')}</label>
                                          <span className='field-mandotary mr-2' />
                                          <input name="orgUserCount" autoComplete="off" type="text" className="form-control" placeholder={t("placeholders.user_count")} title={t("placeholders.user_count")}
                                            defaultValue={this.state.orgLicenseViewList?.numberofUsers}
                                            onChange={(e) => this.handleOrgLicUpdate(e, 'usercount')}
                                            onKeyDown={this.keyCheckHandlar}
                                            min={1}
                                            data-parsley-validate
                                            data-parsley-pattern="^\d+$"
                                            data-parsley-trigger="change"
                                            data-parsley-required="true"
                                            data-parsley-error-message={t("errorMessages.users_Count")}
                                          />
                                        </div>
                                      </div>
                                    </form>
                                    <div className="text-right licenseModalupdate">
                                      <button name="licenseUpdateSubmit" type="button" onClick={() => this.handleSubmit()} className="btn bg_indgo btn-sm ml-2">{t("buttons.update")}</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* ##################### Add Modules ################ */}
                      <div className='border rounded p-3 mt-2'>
                        <p className='mb-0 d-justify-align cursor-pointer' onClick={() => this.handleModulesAdd()}>
                          <span className='font-size-14'>{t('Modules.modules_add')}</span>
                          <i className={`font-size-12 ${this.state.modulesAdd ? "fa fa-minus Manatee-Gray" : "fa fa-plus one-blue"}`}></i>
                        </p>
                        {this.state.modulesAdd && (this.state.moduleAddList?.length > 0 ? (
                          <div className="py-3">
                            <Multiselect
                              options={this.state.moduleAddList}
                              displayValue="name"
                              onSelect={this.onModulesAddRemove}
                              onRemove={this.onModulesAddRemove}
                              className="mb-2"
                            />
                            <button className="btn bg-cornflower btn-sm border-radius7 float-right text-right mb-2 font-size-12"
                              type="button" onClick={() => this.handleAddModulesSubmit()}>
                              <i className="fa fa-plus font-size-10 mr-1"></i>{t('buttons.add')}</button>
                          </div>
                        ) :
                          <React.Fragment>
                            <div className="text-capitalize text-center">
                              <p className="">{this.props.t("Modules.no_modules")}</p>
                            </div>
                          </React.Fragment>
                        )}
                      </div>

                      {/* ####################### Modules ################# */}

                      <div className='border rounded p-3 mt-2'>
                        <p className='mb-0 d-justify-align cursor-pointer' onClick={() => this.handleModules()}>
                          <span className='font-size-14'>
                            {t('Settings.modules')} ({t('Modules.act_deact')})</span>
                          <i className={`font-size-12 ${this.state.modulesView ? "fa fa-minus Manatee-Gray" : "fa fa-plus one-blue"}`}></i>
                        </p>

                        {this.state.modulesView && (this.state.orgLicenseViewList?.modules?.length !== 0 ? (
                          <div className="py-3 moduleList-creation">
                            <div className="table-responsive table-container">
                              <table className='table modul-tablelist'>
                                {this.state.orgLicenseViewList && this.state.orgLicenseViewList.modules && this.state.orgLicenseViewList?.modules?.slice().sort((a, b) => a.moduleName.localeCompare(b.moduleName))?.map((moduleObj, index) => {
                                  return (
                                    <tbody key={`modulelist-${index}`}>
                                      <tr>
                                        <td>
                                          <span>{moduleObj.moduleName}</span>
                                        </td>
                                        <td className='d-flex align-items-center justify-content-end'>
                                          <label className="switch"><input type="checkbox" defaultChecked={moduleObj.active} onChange={(e) => this.handleModuleActDeact(e, moduleObj)}
                                          /><span className="slider round"></span></label>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                })}
                              </table>
                            </div>
                            <div>

                            </div>
                          </div>
                        ) :
                          <React.Fragment>
                            <div className="text-capitalize text-center">
                              <p className="">{this.props.t("Modules.add_modules")}</p>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer request-footer">
                <div className="text-right">
                  <button name="licenseModulesAddAndActDeactCancel" onClick={() => this.handleUpdateOrgLic({})} className="btn bg_darkgray mr-1">{t("labels.cancel")}</button>
                  <button name="licenseModulesActDeactSave" onClick={() => this.handleActDeactSubmit()} hidden={this.state.modulesView ? false : true} type="button" className="btn bg_indgo ml-2" >{t("tasks.save")}</button>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='row clearfix mx-0'>
          <div className=" col-md-9 px-1 mb-1 input-focus-width ">
            <div className="task-filter-bar">
              <div className="d-flex align-items-center mr-2">
                <div className=" mb-0 position-relative  ">
                  <input
                    type="text"
                    autoComplete="off"
                    name="Taskheader Search"
                    className="custom-form-control input-focus-active"
                    value={this.state.filterObj.organizationName}
                    placeholder={t('Projects.search')}
                    onChange={(e) => this.handleSearchInputs(e, "organizationName")}
                  />
                  <i className="fa fa-search input-search-icon"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-2 ">
            <button className='btn bg_indgo text-right float-right mb-2'
              onClick={() => this.handleorgopen()}>{t("labels.licensecreation")}</button>
          </div>
        </div>
        <div className='row clearfix mx-0'>
          <div className='col-12 px-2 superadmin-userlist bg-white shadow1 border_redius '>
            {/* scroll starts here */}
            <div className='table-responsive table-container'>
              <table className='table table-orglist color-nightred' ref={this.tableRef}>
                {this.state?.orgLicenseList?.content?.length > 0 && <thead >
                  {/* <tr>
                    <th >{t('pomodoro.organization')}</th>
                    <th >{t('pomodoro.Registerdate')}</th>
                    <th >{t('labels.membercount')}</th>
                    <th >{t('Projects.action')}</th>
                  </tr> */}

                  {tableHeader.map((label) => (
                    <th key={label} className="text-left">{t(`pomodoro.${label}`)}</th>
                  ))}

                </thead>}
                <tbody>
                  <tr>
                    <td colSpan="9" >
                      {this.state.DataContentLoader && <ContentLoader lines={20} />}
                    </td>
                  </tr>

                  {!this.state.DataContentLoader && <>
                    {this.state?.orgLicenseList?.content?.length > 0 ? this.state?.orgLicenseList.content.map((orgLicObj, orgLicIndex) => {
                      return (
                        <tr key={`orglicenseList-${orgLicIndex}`}>
                          <td>
                            <>
                              <p className='mb-0 font-weight-600'> {orgLicObj?.organizationName}</p>
                            </>
                          </td>
                          <td>
                            <p className='' title={t("Dashboard.start_date")}>{`${orgLicObj.startDate[0]}-${String(orgLicObj.startDate[1]).padStart(2, '0')}-${String(orgLicObj.startDate[2]).padStart(2, '0')}`}</p>
                            <span title={t("labels.expiry_date")} className='text-warning'>{`${t("labels.expiry_date")} : ${orgLicObj.expiryDate[0]}-${String(orgLicObj.expiryDate[1]).padStart(2, '0')}-${String(orgLicObj.expiryDate[2]).padStart(2, '0')}`}</span></td>
                          <td>{orgLicObj?.numberofUsers}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <SwitchTab active={orgLicObj?.active} handleActDeact={this.handleActDeact} id={orgLicObj?.id} />

                              {orgLicObj?.active && (<img src="../../assets/images/Task/Edit.svg" id="orgLicenseEdit" alt="" onClick={() => this.handleUpdateOrgLic(orgLicObj)}
                                className="w30 mx-1 cursor-pointer" title={t("License.edit_license")} />)}
                            </div>
                          </td>
                        </tr>
                      )
                    }) :
                      <React.Fragment>
                        <tr>
                          <td>
                            <span className="Nodata-task"><img src="../../assets/images/icon-empty-task.svg" alt="" /><h6 className=" text-muted  font-size-14 ">{t('License.no_license')}</h6></span>
                          </td>
                        </tr>
                      </React.Fragment>
                    }</>}
                </tbody>
              </table>
            </div>
            {this.state?.orgLicenseList?.content?.length > 0 ?
              <div className='pagination-orgsettings d-flex justify-content-between align-items-center'>
                <span>
                  <span className='mr-2'>{t("labels.show")}</span>
                  <select value={this.state.size} data-testid="showmore"
                    id='pageSize'
                    onChange={(e) => this.handlePageSizeChange(Number(e.target.value))}>
                    {pageSizeDropdownOptions(this.state.totalRecords, t)}
                  </select>
                  <span className='ml-2'>{t("labels.entries")}</span>
                </span>
                {this.state.totalRecords > this.state.pageSize && <PaginatedList
                  className="pagination"
                  page={this.state.page}
                  size={this.state.size}
                  totalRecords={this.state.totalRecords}
                  handlePageClick={this.handlePageClick}
                />}
              </div>
              : ""}
          </div>
        </div>

      </div>
    );
  }
}


/**
 * Map the state to props. 
 */
const mapStateToProps = function (state) {
  return {
    data: state.employeeDashboard.data,
    orgLicenseList: state.license.orgLicenseList,
    orgLicenseCreate: state.license.orgLicenseCreate,
    orgLicenseUpdate: state.license.orgLicenseUpdate,
    orgLicenseById: state.license.orgLicenseById,
    addOrgLicenseModules: state.license.addOrgLicenseModules,
    orgLicenseActivate: state.license.orgLicenseActivate,
    orgLicenseDeactivate: state.license.orgLicenseDeactivate,
    orgModulesList: state.license.orgModulesList,
    orgLicenseModuleActivateDeactivate: state.license.orgLicenseModuleActivateDeactivate,
    onError: state.employeeDashboard.onError,
    licenseOnError: state.license.onError,
    orgOnError: state.organization.onError,
  };
};

/**
 * Map the actions to props.
 */
const mapDispatchToProps = (dispatch) => ({
  getEmpDashboardDataFunc: () => dispatch(getEmpDashboardData({})),
  createOrgLicense: (data) => dispatch(createOrgLicense(data)),
  updateOrgLicense: (data) => dispatch(updateOrgLicense(data)),
  actDeactModuleOrgLicense: (data) => dispatch(actDeactModuleOrgLicense(data)),
  activateOrgLicense: (data) => dispatch(activateOrgLicense(data)),
  deactivateOrgLicense: (data) => dispatch(deactivateOrgLicense(data)),
  getOrgLicenseById: (data) => dispatch(getOrgLicenseById(data)),
  addModulesOrgLicense: (data) => dispatch(addModulesOrgLicense(data)),
  getOrgLicenseModules: () => dispatch(getOrgLicenseModules()),

});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SuperAdminDashboard)));