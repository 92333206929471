import React, { Component } from "react";
import { connect } from "react-redux";
import OverviewChart from "../../Charts/OverviewChart";
import Piechart from "../../Charts/Piechart";
import Vaichart from "../../Charts/Vaichart";
import Teamprogress from "../../Charts/Teamprogress";
import { PRODUCTIVITY_METADATA } from "../../Productivity/metadata";
import { getUserToolProductivity } from "../../../actions/productivity";
import CruiserWorkCard from "./CruiserWorkCard";
import { withTranslation } from "react-i18next";
import { data } from "jquery";
import { convertMilliSecondsToHours } from "../../../utils";
import { getDayOfTheYear } from "../../../utils/dateFormatUtils";
import Utils from "../../../utils/utils";
import i18n from 'i18next';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import $ from 'jquery';
import _ from 'lodash';
import { getemployeeDashboardService } from '../../../services/employeeDashboard ';
import { getUserToolProductivites } from '../../../services/productivity'
import ScaleLoaderSpinner from "../../Shared/ScaleLoader";
class EmployeeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tsMonthFilter: "1",
      productivityOption: PRODUCTIVITY_METADATA.MONTHLY,
      isConnecting:false,
      data: {},
      onError: {},
      timesheetSummary: {},
      productivity: [],
      timesheetStatusMap: {
        REJECTED: 0,
        SUBMITTED: 0,
        APPROVED: 0,
      },
      userProductivities: [],
      currYear: Utils.getDataToPass().year,
      currMonth: Utils.getDataToPass().month,
      currDayOfYear: Utils.getDataToPass().day,
      currentMonthName: Utils.getMonthNames(Utils.getDataToPass().month),
      totalProductivityperMonthmSecs: 0,
      userWorkingDaysPerMonth: 0,
      crusierHours: 0,
      workedHours: 0,
      thinkTime: 0,
      productivityTotal: 0,
      totalWorkingHours: 0,
      users: {},
      toolProdList: [],
      allUserstTolData: {},
      cruiserWorkData: [],
      vaiChartData: { 
        labels: [], 
        series: [] 
      },
      chartData: [],
      setChartData: false
    };
  }
  componentDidMount() {
    this.setState({isConnecting:true});
    this.fetchEmployeeData()
    this.fetchToolData()
  }

  componentDidUpdate(prevProps, prevState){
  if(this.state.userProductivities !== prevState.userProductivities){
      const {totalProductivityperMonthmSecs, userWorkingDaysPerMonth} = this.productivityCalculator();
      const metrics = {
        crusierHours: convertMilliSecondsToHours(totalProductivityperMonthmSecs),
        workedHours: userWorkingDaysPerMonth * 8
      }
      this.setState({
        totalProductivityperMonthmSecs,
        userWorkingDaysPerMonth,
        ...metrics
      })
    }

    if(prevState.totalProductivityperMonthmSecs !== this.state.totalProductivityperMonthmSecs || prevState.userWorkingDaysPerMonth !== this.state.userWorkingDaysPerMonth){
      const crusierHours = this.state.crusierHours,
      totalProductivityperMonthmSecs = this.state.totalProductivityperMonthmSecs,
      workedHours = this.state.workedHours;
      const{ thinkTime, productivityTotal} = this.metricsCalculator(totalProductivityperMonthmSecs, workedHours, crusierHours);

      this.setState({
        thinkTime,
        productivityTotal
      })
    }

    if(prevState.toolProdList !== this.state.toolProdList){
      const {allUserstTolData, totalWorkingHours, users, cruiserWorkData} = this.toolProductivityCalculator(this.state.toolProdList, this.state.allUserstTolData)
      this.setState({
        totalWorkingHours,
        allUserstTolData,
        users,
        cruiserWorkData
      })
    }

    if(prevState.cruiserWorkData !== this.state.cruiserWorkData || prevState.productivityOption !== this.state.productivityOption){
      const {vaiChartData, chartData} = this.chartDataCalculator(this.state.cruiserWorkData)
      this.setState({
        vaiChartData,
        chartData,
        setChartData: true
      })
    }
  }

  tsMonthFileterHandler = (e) => {
    this.setState({ tsMonthFilter: e.target.value });
  };
  onProdutivityChange = (event) => {
    this.setState({ productivityOption: event.target.value });
  };

  productivityCalculator = () => {
    const currMonth=  Utils.getDataToPass().month, 
      currYear = Utils.getDataToPass().year, 
      userProductivities = this.state.userProductivities;

    let totalProductivityperMonthmSecs = 0;
    let userWorkingDaysPerMonth = 0;
    
    Array.isArray(userProductivities) &&
    userProductivities.forEach((user) => {
      user.years && user.years.map((yearData) => {
        if (yearData.year === currYear) {
          yearData.months.map((monthData) => {
            if (monthData.month === currMonth) {
              totalProductivityperMonthmSecs +=
                monthData.totalWorkingHoursPerMonth;
              userWorkingDaysPerMonth = monthData.days.length;
            }
          });
        }
      });
    });
    return {
      totalProductivityperMonthmSecs,
      userWorkingDaysPerMonth
    }
  }

  metricsCalculator = (totalProductivityperMonthmSecs, workedHours, crusierHours) => {
    const thinkTime = +(workedHours - crusierHours).toFixed(2);
    const productivityTotal = convertMilliSecondsToHours(
      ((totalProductivityperMonthmSecs * 1.3) / workedHours) * 100
    );
    return {
      thinkTime,
      productivityTotal
    }
  }

  toolProductivityCalculator = (toolProdListParam, allUserstTolDataParam) => {
    let allUserstTolData = {...allUserstTolDataParam},
    toolProdList = [...toolProdListParam],
    users = {},
    cruiserWorkData = [],
    totalWorkingHours = 0;
    toolProdList.forEach((user) => {
      users[user.userId] = true; // add userId to users object (will create unique users objects)
      Array.isArray(user.tools) &&
        user.tools.map((tool) => {
          let toolData = {
            data: [],
            toolName: tool.toolName,
            toolId: tool.toolId,
            todayUsage: 0,
            yesterDayUsage: 0,
            averageUsage: 0,
            totalUsage: 0,
            currentMonthUsage: 0,
            toolIcon: tool.base64ImageStr!==null
              ? tool.base64ImageStr
              : "../assets/images/bluebirdlogo.jpg",
            progress: parseInt(Math.random() * 100),
          };
          if (allUserstTolData.hasOwnProperty(tool.toolName)) {
            toolData = { ...toolData, ...allUserstTolData[tool.toolName] };
          }

          const dataToPass = Utils.getDataToPass();
          const currYear = dataToPass.year;
          const currMonth = dataToPass.month;
          const currDayOfYear = dataToPass.day;
				

          tool?.years?.map((yearData) => {
            if (yearData.year === currYear) {
              toolData.totalUsage =
                toolData.totalUsage + yearData.appHoursPerYear;
              totalWorkingHours = totalWorkingHours + yearData.appHoursPerYear;
              yearData.months.map((monthData) => {
                if (monthData.month === currMonth) {
                  toolData.currentMonthUsage =
                    toolData.currentMonthUsage + monthData.appHoursPerMonth;
                  toolData.averageUsage =
                    toolData.averageUsage + monthData.appHoursPerMonth;
                  const currDayData = monthData.days.find(
                    (day) => day.day === currDayOfYear
                  );
                  const yestDayData = monthData.days.find(
                    (day) => day.day === currDayOfYear
                  );
                  toolData.todayUsage =
                    toolData.todayUsage + currDayData
                      ? currDayData.appHoursPerDay
                      : 0;
                  toolData.yesterDayUsage =
                    toolData.yesterDayUsage + yestDayData
                      ? yestDayData.appHoursPerDay
                      : 0;
                  totalWorkingHours = totalWorkingHours + toolData.todayUsage;
                }
              });
            }
          });

          allUserstTolData[tool.toolName] = toolData;
        });
    });
    cruiserWorkData = Object.keys(allUserstTolData).map(
      (key) => allUserstTolData[key]
    );
    return {
      allUserstTolData,
      totalWorkingHours,
      users,
      cruiserWorkData
    }
  }

  chartDataCalculator = (cruiserWorkData) => {
    const vaiChartData = { labels: [], series: [] };
    const chartData = [];
    cruiserWorkData.forEach((tool) => {
      if (tool.toolId) {
        vaiChartData.labels.push(tool.toolName);
        if (this.state.productivityOption === PRODUCTIVITY_METADATA.DAILY) {
          chartData.push(convertMilliSecondsToHours(tool.yesterDayUsage));
        }
        if (this.state.productivityOption === PRODUCTIVITY_METADATA.MONTHLY) {
          chartData.push(convertMilliSecondsToHours(tool.currentMonthUsage));
        }
        if (this.state.productivityOption === PRODUCTIVITY_METADATA.YEARLY) {
          chartData.push(convertMilliSecondsToHours(tool.totalUsage));
        }
      }
    });
    vaiChartData.series.push({ name: i18n.t('TimeSheets.t_hours'), data: chartData });
    return {
      vaiChartData,
      chartData
    }
  }
  
  /**
   * Fetches employee data and updates the component's state with the result.
   * @function
   * @returns {void}
   */

  fetchEmployeeData = () => {
    getemployeeDashboardService({}, (respData) => {

      // if empty response is returned
      if(_.isEmpty(respData)){
        return;
      }
      
      //if data is returned in the response

      let {timesheetStatusMap, userProductivities, timesheetSummary} = respData;

      timesheetStatusMap = timesheetStatusMap ? timesheetStatusMap : this.state.timesheetStatusMap; 
      userProductivities = userProductivities ? userProductivities : this.state.userProductivities;
      timesheetSummary = timesheetSummary ? timesheetSummary : this.state.timesheetSummary;

      const initialData = {
            REJECTED: 0,
            SUBMITTED: 0,
            APPROVED: 0,
          }
      this.setState({
        data: respData,
        timesheetStatusMap: {...initialData, ...timesheetStatusMap},
        timesheetSummary,
        userProductivities,
        isConnecting: false
      })
    }, (error) => {
      toast(error.message, { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
      this.setState({
        isConnecting:false,
        onError: error
      });
    })
  }

  /**
   * Fetches tools data and updates the component's state with the result.
   * @function
   * @returns {void}
   */

  fetchToolData = () =>{
    getUserToolProductivites((response) => {
      //if empty response is returned
      if(_.isEmpty(response?.data)){
        return
      }
      //if data is returned in the response
      const toolProdList = response.data
      this.setState({toolProdList})
    }, (error) => {
      toast(error.message, { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
    });
  }
  render() {
    const { t } = this.props;
    const {data, timesheetSummary, timesheetStatusMap, userProductivities, currYear, currentMonthName, crusierHours, workedHours, thinkTime, productivityTotal, cruiserWorkData, vaiChartData, setChartData} = this.state;
    return (
      <>
      <ToastContainer />
      {this.state.isConnecting && <ScaleLoaderSpinner />}
                    <div className="container-fluid mt-2 px-0">
        <div className="row clearfix mx-0">
          <div className="col-md-9 col-sm-12 px-1">
            <div className="row mx-0">
            {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Productivity"}) && 
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 p-1 oneable-menu-row">
                <div className="card mb-0 ">
                  <div className="body p-0 Borderradius shadow h-70-px">
                    <div className="d-flex align-items-center">
                      <div className="icon-in-bg lightgray1 border-right leftradius ml-4">
                        <img
                          
                          src="../assets/images/Orgdashboard/productimg.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          alt="VAI Work"
                          className="w30 rounded mr-4"
                        />
                      </div>

                      <div className="ml-3 ">
                        <h6 className="mb-0 font-size-12">
                          <b>
                            {t("Dashboard.productivity")}
                            <span className="font-size-10 ml-1">
                              {"(" + t("months." + currentMonthName) + "-" + currYear + ")"}
                            </span>
                          </b>
                        </h6>
                        <p className="mb-0 font-weight-medium mt-0">
                          {productivityTotal}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

              {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Productivity"}) &&
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 p-1 oneable-menu-row">
                <div className="card mb-0 ">
                  <div className="body p-0 Borderradius shadow h-70-px">
                    <div className="d-flex align-items-center">
                      <div className="icon-in-bg lightgray1 border-right leftradius ml-4">
                        <img
                          src="../assets/images/Orgdashboard/vai-icon-new.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          alt="VAI Work"
                          className="w40 rounded mr-4"
                        />
                      </div>
                      <div className="ml-3 ">
                        <h6 className="mb-0 font-size-12">
                          <b>
                            {" "}
                            {/* {t("Dashboard.Work")} /{" "} */}
                            {t("Dashboard.cruiser_hours")}{" "}
                            <span className="font-size-10 ml-1">
                              {"(" + t("months." + currentMonthName) + "-" + currYear + ")"}
                            </span>{" "}
                          </b>
                        </h6>
                        <span>
                          {/* {workedHours}
                          <small className="font-size-12">
                            {t("Dashboard.Hrs")}
                          </small>{" "} */}
                           {crusierHours}
                          <small className="font-size-12 ml-1">
                            {t("Dashboard.Hrs")}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
              
              {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Timesheets"}) &&
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 p-1 oneable-menu-row">
                <div className="card mb-0">
                  <div className="body p-0 Borderradius shadow h-70-px">
                    <div className="d-flex align-items-center">
                      <div className="icon-in-bg lightgray1 border-right leftradius ml-4">
                        <img
                          src="../assets/images/Orgdashboard/timesheet.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          alt="VAI Work"
                          className="w30 rounded mr-4"
                        />
                      </div>

                      <div className="ml-3 mr-3 w-100">
                        <h6 className="mb-0 font-size-12">
                          <b>
                            {t("Dashboard.timesheet")}{" "}
                            <span className="font-size-10 ml-1">
                              {"(" + t("months." + currentMonthName) + "-" + currYear + ")"}
                            </span>
                          </b>
                        </h6>
                        <div className="d-flex justify-content-between align-items-center timesheet-in-icons">
                          <span
                            title={t("TimeSheets.approved")}
                            className="d-flex align-items-center"
                          >
                            <i
                              className="fas fa-check-circle approved font-size-16"
                              aria-hidden="true"
                              title={t("TimeSheets.approved")}
                            ></i>

                            <span className="ml-1">
                              {" "}
                              {timesheetStatusMap.APPROVED}
                            </span>
                          </span>
                          <span
                            title={t("OrgAdmin.pending")}
                            className="d-flex align-items-center"
                          >
                            <i
                              className="fas fa-exclamation-circle pending font-size-16"
                              aria-hidden="true"
                              title={t("OrgAdmin.pending")}
                            ></i>
                            <span className="ml-1">
                              {" "}
                              {timesheetStatusMap.SUBMITTED}
                            </span>
                          </span>
                          <span
                            title={t("OrgAdmin.rejected")}
                            className="d-flex align-items-center"
                          >
                            <i
                              className="fas fa-times-circle reject font-size-16 "
                              aria-hidden="true"
                              title={t("OrgAdmin.rejected")}
                            ></i>
                            <span className="ml-1">
                              {" "}
                              {timesheetStatusMap.REJECTED}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

              {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Timesheets"}) &&
              <div className="col-md-12 col-sm-12 col-xs-12 p-0">
                <div className="col-lg-12 col-md-12 p-1">
                  <div className="card mb-0">
                    <div className="body px-3 py-3 Borderradius shadow ">
                      <div className="d-flex justify-content-between mb-4">
                        <span className="font-size-12 oneable-text-bold">
                          {t("Dashboard.timesheet_summary")}
                          <span className="font-size-10 ml-1">
                            {"(" + t("Dashboard.last3months") + ")"}
                          </span>
                        </span>
                        <div>
                          {/* <select onChange={this.tsMonthFileterHandler} className="browser-default border-0 fontsmall">
														<option value="1">{t('Dashboard.this_month')}</option>
														<option value="2">{t('Dashboard.quarterly')} </option>
														<option value="3">{t('Dashboard.half_yearly')}</option>
														<option value="4">{t('Dashboard.yearly')}</option>
													</select> */}
                        </div>
                      </div>
                      <div
                        id="emp-barchart-bar-align"
                        className="p-2 text-center"
                      >
                        <OverviewChart
                          dateFilter={parseInt(this.state.tsMonthFilter)}
                          data = {data}
                          onError = {this.state.onError}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>}


              {/* {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Productivity"}) &&
              <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12 p-0">
                <div className="col-lg-12 col-md-12 p-1">
                  <div className="card mb-0">
                    <div className="body p-0 Borderradius shadow p-2 text-center">
                      <div className="oneable-body mb-2">
                        <h3>{timesheetSummary.totalWorkingDays}</h3>
                        <p className="font-size-12">
                          {t("Dashboard.total_working_days")}
                        </p>
                      </div>
                      <div className="oneable-flex-aligns mb-2">
                        <div>
                          <h5>{timesheetSummary.userTotalWorkingDays}</h5>
                          <p className="font-size-12">
                            {t("Dashboard.days_worked")}
                          </p>
                        </div>
                        <div>
                          <h5>{timesheetSummary.onleave}</h5>
                          <p className="font-size-12">
                            {t("Dashboard.on_leave")}
                          </p>
                        </div>
                        <div>
                          <h5>{timesheetSummary.holiDays}</h5>
                          <p className="font-size-12">
                            {t("Dashboard.holidays")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 p-1">
                  <div className="card mb-0">
                    <div className="body px-3 py-3 Borderradius shadow  text-center">
                      <div className="d-flex justify-content-between mb-3">
                        <span className="font-size-12 oneable-text-bold">
                          {t("Dashboard.work_summary")}{" "}
                          <span className="font-size-10 ml-1">
                            {"(" + t("months." + currentMonthName) + "-" + currYear + ")"}
                          </span>
                        </span>
                    
                      </div>

                      <div
                        id="emp-piechart-bar-align"
                        className="oneable-body mb-2"
                      >
                        <div className="w250 text-center">
                          <Piechart className="" data={{timesheetSummary, userProductivities}}/>
                        </div>
                      </div>
                      <div className="oneable-flex-aligns">
                        <div>
                          <h5>
                            {workedHours}
                            <span className="font-size-12 text-muted">
                              {t("Dashboard.Hrs")}
                            </span>
                          </h5>
                          <p className="font-size-12">
                            {t("Dashboard.total_time")}
                          </p>
                        </div>
                        <div>
                          <h5>
                            {crusierHours}
                            <span className="font-size-12 text-muted">
                              {t("Dashboard.Hrs")}
                            </span>
                          </h5>
                          <p className="font-size-12">
                            {t("OrgAdmin.cruiser_hours")}
                          </p>
                        </div>
                        <div>
                          <h5>
                            {thinkTime}
                            <span className="font-size-12 text-muted">
                              {t("Dashboard.Hrs")}
                            </span>
                          </h5>
                          <p className="font-size-12">
                            {t("Dashboard.think_time")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>} */}
            </div>
          </div>

          {/* Cruiser Work END */}

          {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Productivity"}) &&
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-1">
            <div className="card mb-0 ">
              <div
                className="body shadow p-1 Borderradius emp_dashbord_tools"
                style={{ height: "602px" }}
              >
                <div className=" px-3 py-2 mb-1">
                  <span className="font-size-12 oneable-text-bold">
                    {t("Dashboard.cruiser_work")}{" "}
                    <span className="font-size-10 ml-1">
                      {"(" + t("months." + currentMonthName) + "-" + currYear + ")"}
                    </span>
                  </span>
                  {/* <select className="browser-default border-0 fontsmall">
										<option>BIZ Org</option>
										
									</select> */}
                </div>

                <div className="scrollbox scrollbox_delayed">
                  <div className="scrollbox-content p-2 ">
                    {
                      cruiserWorkData.length!=0?
                    Array.isArray(cruiserWorkData) &&
                      cruiserWorkData.map((tool) => {
                        if (tool.toolId) {
                          return (
                            <CruiserWorkCard
                              averageUsage={tool.averageUsage}
                              todayUsage={tool.todayUsage}
                              yesterDayUsage={tool.yesterDayUsage}
                              toolIcon={tool.toolIcon}
                              toolName={tool.toolName}
                              progress={tool.progress}
                            />
                          );
                        }
                      }):<div className="text-center"><img src="../../assets/images/nodata.svg" className="nodata-img" />
                      <h6 className="text-muted mt-3">{i18n.t('productivity.no_data')}</h6></div>}
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>

        {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Productivity"}) &&
        <div className="row clearfix mx-0">
          <div className="col-md-12 p-1">
            <div className="card mb-0">
              <div className="body px-3 py-3 Borderradius shadow ">
                <div className="d-flex justify-content-between mb-4 mr-4">
                  <span className="font-size-12 oneable-text-bold">
                    {t("Dashboard.cruiser_application_summary")}
                  </span>
                  <div>
                    <select
                      name = "EmployeeCruiser AppSummary"
                      className="browser-default border-0 fontsmall"
                      onChange={this.onProdutivityChange}
                      value={this.state.productivityOption}
                    >
                      <option value={PRODUCTIVITY_METADATA.DAILY}>
                        {t("Dashboard.daily")}
                      </option>
                      <option value={PRODUCTIVITY_METADATA.MONTHLY}>
                        {t("Dashboard.monthly")}
                      </option>
                      <option value={PRODUCTIVITY_METADATA.YEARLY}>
                        {t("Dashboard.yearly")}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  id="emp-barchart-bar-align2"
                  className=" px-1 text-center w-100 "
                >
                  
                  <Vaichart data={vaiChartData} />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-6 p-1">
						<div className="card mb-0">
							<div className="body px-3 py-3 Borderradius shadow ">
								<div className="d-flex justify-content-between ">
									<span className="font-size-12 oneable-text-bold">{t('Dashboard.team_progress')}({t('Dashboard.hours')})</span>
									<select className="browser-default border-0 fontsmall">
										<option value="1">{t('Dashboard.this_month')}</option>
										<option value="2">{t('Dashboard.quarterly')}</option>
										<option value="3">{t('Dashboard.half_yearly')}</option>
										<option value="4">{t('Dashboard.yearly')}</option>
									</select>
								</div>
								<div className="Max-width text-center">
									<Teamprogress />
								</div>
							</div>
						</div>
					</div> */}
        </div>}
        {this.props.routeModules?.length > 0 && !this.props.routeModules?.find((modObj) => {return modObj.to === "/Productivity" || "/Timesheets"}) &&
          <div>
            <p>{t('Modules.no_data')}</p>
          </div>
        }

        {/* <!-- First row end ---> */}

        {/* employee table start */}

        {/* Table end */}
      </div>
      </>
      
    );
  }
}


/**
 * Map the state to props.
 */
const mapStateToProps = function (state) {
  return {
    routeModules : state.organization.routeModules,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation()(EmployeeDashboard));
