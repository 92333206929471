export default class Rtc {

    makeCall(fullName, roomId, localDomId, remoteDomId) {
        window?.oneAbleCollaboration?.rtc?.oneAbleConference?.makeCall(
            fullName,
            roomId,
            localDomId,
            remoteDomId,
            [{urls: ['stun:stun.l.google.com:19302',
                    'stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302',
                    'stun:stun.l.google.com:19302']}]
        );
    }

    endCall() {
        window?.oneAbleCollaboration?.rtc?.oneAbleConference?.endCall();
    }

    screenShare() {
        window?.oneAbleCollaboration?.rtc?.oneAbleConference?.screenShare();
    }

    muteAudio() {
        window?.oneAbleCollaboration?.rtc?.oneAbleConference?.muteAudio();
    }

    unMuteAudio() {
        window?.oneAbleCollaboration?.rtc?.oneAbleConference?.unMuteAudio();
    }

    muteVideo() {
        window?.oneAbleCollaboration?.rtc?.oneAbleConference?.muteVideo();
    }

    unMuteVideo() {
        window?.oneAbleCollaboration?.rtc?.oneAbleConference?.unMuteVideo();
    }
}