import httputils from '../utils/httputils';

const HTTP_GET = 'GET';
const HTTP_POST = 'POST';

const USER_PRODUCTIVITY = 'productivity/user';
const USER_TOOL_PRODUCTIVITY = 'productivity/tool/byUserIds';
const USER_MULTI_LEVEL_PRODUCTIVITY = 'productivity/user/team';
const USER_TOOL_MULTI_LEVEL_PRODUCTIVITY = 'productivity/tool/team';
const GET_USER_TOOL_PRODUCTIVITY = 'productivity/tool/user';
const GET_TOOL_NO_PRODUCTIVITY = 'productivity/tool/noproductivity';
const ADD_LOCAL_TOOL_LAUNCH_PRODUCTIVITY = 'productivity/user/save'
const UPDATE_LOCAL_TOOL_LAUNCH_PRODUCTIVITY = 'productivity/user/update';
const CREATE_LOCAL_LAUNCH_PRODUCTIVITY = "locallaunch/create";
const UPDATE_LOCAL_LAUNCH_PRODUCTIVITY = "locallaunch/update";

export const getUserProductivites = (successCallback, errorCallback) => {
    productivityService(null, USER_PRODUCTIVITY, HTTP_POST, successCallback, errorCallback);
};

export const getUserToolProductivites = (successCallback, errorCallback) => {
    productivityService(null, USER_TOOL_PRODUCTIVITY, HTTP_GET, successCallback, errorCallback);
};

export const getToolNoProductiviteService = (successCallback, errorCallback) => {
    productivityService(null, GET_TOOL_NO_PRODUCTIVITY, HTTP_GET, successCallback, errorCallback);
};

export const getProductivites = (value, successCallback, errorCallback) => {
    productivityService(null, value, HTTP_GET, successCallback, errorCallback);
}

export const  getMultilevelUserProductivites = (value ,successCallback , errorCallback) => {
    productivityService(value , USER_MULTI_LEVEL_PRODUCTIVITY , HTTP_POST , successCallback , errorCallback);
}

export const getMultilevelUserToolProductivites = (value , successCallback , errorCallback) => {
    productivityService(value, USER_TOOL_MULTI_LEVEL_PRODUCTIVITY , HTTP_POST , successCallback , errorCallback);
}

export const getEmployeeToolProductivity = (value , successCallback , errorCallback) => {
    productivityService(value , GET_USER_TOOL_PRODUCTIVITY , HTTP_POST , successCallback , errorCallback);
}

const productivityService = (data, endpoint, httpMethod, successCallback, errorCallback) => {
    httputils.httpRequest(endpoint, httpMethod, data, (response) => {
        if (response.status === 'Success') {
            successCallback && successCallback(response); 
        } else {
            errorCallback && errorCallback(response);
        }
    });
}

/* Newly added functionality */

const apiCaller = async (apiEndpoint, method, requestData) => {
    try {
        return await httputils.fetchData(apiEndpoint, method, requestData);
    } catch (error) {
        throw error;
    }
};

export const startLocalToolProductivity = async (data) => apiCaller(CREATE_LOCAL_LAUNCH_PRODUCTIVITY, HTTP_POST, data);
export const updateLocalToolProductivity = async (data) => apiCaller(UPDATE_LOCAL_LAUNCH_PRODUCTIVITY, HTTP_POST, data);