import React, { useState } from 'react';

const Button = ({ onClick, buttonText, buttonType, isLoading, type, isDisabled = false }) => {

  const buttonClassName = buttonType === 'cancel' || buttonType === 'previous' ? 'btn bg_darkgray mr-2' : 'btn btn-darkbrule';

  return (
    <button className={buttonClassName}  onClick={onClick} disabled={isLoading || isDisabled} type={type}>
      {isLoading ? (
        <span>
          <i className="spinner-border spinner-border-sm mr-1"></i> {buttonText}
        </span>
      ) : (
        buttonText
      )}
    </button>
  );
};

export default Button;
