import { SET_EMPLOYEE_DASHBOARD_PENDING, SET_EMPLOYEE_DASHBOARD_SUCCESS, SET_EMPLOYEE_DASHBOARD_ERROR } from '../constants/employeeDashboard';


const initialState = {
    isLoading: false,
    data: {},
    onError: null
};

export default (state = initialState, action) => {
    switch(action.type) {
        case SET_EMPLOYEE_DASHBOARD_PENDING: {
            return {...state}
        }

        case SET_EMPLOYEE_DASHBOARD_SUCCESS: {
            return {...state, data: action.payload}
			
        }

        case SET_EMPLOYEE_DASHBOARD_ERROR: {
            return Object.assign({}, state, {
				onError: action.error
			});
        }

        default:
            return {...state}
    }
}