import i18n from "i18next";

export const PRODUCTIVITY_METADATA = {
    DAILY: 'DAILY',
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY',
    USER_DAY: 'USER_DAY',
    USER_MONTHLY: 'USER_MONTHLY',
    USER_YEARLY: 'USER_YEARLY',
    TOOL_DAY: 'TOOL_DAY',
    TOOL_MONTHLY: 'TOOL_MONTHLY',
    TOOL_YEARLY: 'TOOL_YEARLY'
};

export const FILTER = {
    YESTERDAY: 'Yesterday',
    LAST_7_DAYS: 'Last 7 Days',
    THIS_MONTH: 'This Month',
    LAST_MONTH: 'Last Month',
    THIS_YEAR:'This Year',
    LAST_YEAR: 'Last Year',
    CUSTOM_RANGE: 'Custom Range'
}

export const EXTFILTER = {
    YESTERDAY: 'Yesterday',
    LAST_7_DAYS: 'LAST_7_DAYS',
    THIS_MONTH: 'THIS_MONTH',
    LAST_MONTH: 'LAST_MONTH',
    THIS_YEAR:'THIS_YEAR',
    LAST_YEAR: 'LAST_YEAR',
    CUSTOM_RANGE: 'Custom Range'
}



export const USER_FILTER = {
    USER_YESTERDAY: 'User Yesterday',
    USER_DAY: 'User Day',
    USER_LAST_7_DAYS: 'User Last 7 Days',
    USER_THIS_MONTH: 'User This Month',
    USER_LAST_MONTH: 'User Last Month',
    USER_MONTHLY: 'User Monthly',
    USER_CUSTOM_MONTHLY:'User Custom Monthly',
    USER_YEARLY: 'User Yearly',
    USER_LAST_YEAR: 'User Last Year',
    USER_CUSTOM_RANGE: 'User Custom Range',
    NO_ACTION: 'NO_ACTION'
};

export const TOOL_FILTER = {
    TOOL_YESTERDAY: 'Tool Yesterday',
    TOOL_DAY: 'Tool Day',
    TOOL_LAST_7_DAYS: 'Tool Last 7 Days',
    TOOL_THIS_MONTH: 'Tool This Month',
    TOOL_LAST_MONTH: 'Tool Last Month',
    TOOL_MONTHLY: 'Tool Monthly',
    TOOL_YEARLY:'Tool Yearly',
    TOOL_LAST_YEAR: 'Tool Last Year',
    TOOL_CUSTOM_RANGE: 'Tool Custom Range',
    NO_ACTION: 'NO_ACTION'
};