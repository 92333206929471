// import jQuery from 'jquery';
import httputils from '../utils/httputils';

const GET_MACHINE_DETAILS = "machines/search";
const GET_MACHINE_USER_NAMES = "machines/names";
const GET_MACHINE_TOOLS = "machines/tools";

const HTTP_POST = 'POST'

/* 
* New service API calls 
*/
export const getMachineDetailsService = async (data) => {
    return await httputils.fetchData(GET_MACHINE_DETAILS , HTTP_POST , data);
}

export const fetchUsersAndTools = async (data , type) => {
    return await httputils.fetchData(type === "User" ? GET_MACHINE_USER_NAMES : GET_MACHINE_TOOLS , HTTP_POST , data);
}

export const getemployeeDashboardService = (data, successCallback, errorCallback) => {
    const ENDPOINT = 'dashboard/';
    const HTTP_METHOD = 'POST';
    httputils.httpRequest(ENDPOINT, HTTP_METHOD, data, (response) => {
        if (response.status === 'Success') {
            successCallback && successCallback(response.data);
        } else {
            errorCallback && errorCallback(response);
        }
    })
};



