export default class Room {
  createChannel(data, success, error) {
    window?.oneAbleCollaboration?.room?.createChannel(data, success, error);
  }

  createGroup(data, success, error) {
    window?.oneAbleCollaboration?.room?.createGroup(data, success, error);
  }

  activate(data, success, error) {
    window?.oneAbleCollaboration?.room?.subscriptions?.activate(
      data,
      success,
      error
    );
  }

  createDirect(data, success, error) {
    window?.oneAbleCollaboration?.room?.createDirect(data, success, error);
  }

  get(data, success, error) {
    //window?.oneAbleCollaboration?.room?.get(data, success, error);
    window?.oneAbleCollaboration?.room?.subscriptions?.get(
      data,
      success,
      error
    );
  }

  browse(data, success, error) {
    window?.oneAbleCollaboration?.room?.browse(data, success, error);
  }

  update(data, success, error) {
    window?.oneAbleCollaboration?.room?.update(data, success, error);
  }

  delete(data, success, error) {
    window?.oneAbleCollaboration?.room?.delete(data, success, error);
  }

  subscribe(data, success, error) {
    window?.oneAbleCollaboration?.room?.subscriptions?.subscribe(
      data,
      success,
      error
    );
  }

  unSubscribe(data, success, error) {
    window?.oneAbleCollaboration?.room?.subscriptions?.unSubscribe(
      data,
      success,
      error
    );
  }

  inviteUsers(data, success, error) {
    window?.oneAbleCollaboration?.room?.subscriptions?.activate(
      data,
      success,
      error
    );
  }

  getSubscribers(data, success, error) {
    window?.oneAbleCollaboration?.room?.subscriptions?.getSubscribers(
      data,
      success,
      error
    );
  }

  roomParticipants(data, success, error) {
    window?.oneAbleCollaboration?.room?.subscriptions?.roomParticipants(
      data,
      success,
      error
    );
  }

  deactivate(data, success, error) {
    window?.oneAbleCollaboration?.room?.subscriptions?.deActivate(
      data,
      success,
      error
    );
  }
}
