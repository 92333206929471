export const TRANSLATIONS_JA = {
  headers: {
    login: "お客様のアカウントにログイン",
    lang: "ja",
    loginwlc: "組織 ワークフォースプランナー",
    my_profile: "マイプロフィール",
    newTimeSheet: "タイムシート",
    messages: "メッセージ",
    settings: "設定",
    log_out: "ログアウト",
    help: "ヘルプ",
    timeSheetView: "タイムシートビュー",
  },
  messages: {
    empty_data: "データは利用できません",
    No_timer_Off_data_found_in_organization_settings: "組織の設定にタイマーオフのデータが見つからない",
    No_timer_Off_data_found_in_settings: "設定にタイマーオフのデータがない",
    Are_you_sure_you_want_to_delete_this_workspace:
      "1 つ以上のアクティブなチャネルが存在することが通知されました。まだ {{name}} ワークスペースを削除しますか?",
    Are_you_sure_you_want_to_delete_this_channel:
      "このチャンネルを削除することを確認してください?.",
    Are_you_sure_you_want_to_leave_this_channel:
      "このチャンネルを離れたいですか？?",
    shift_update_success: "シフト更新に成功",
    productivity_update_success: "生産性アップデートの成功",
    timeOff_update_success: 'タイムオフ値更新成功',
    turnoff_productivity_alert: '生産性設定をオフにしますか？',
    turnoff_timeOff_alert: 'タイムオフ設定をオフにしますか？',
    turnoff_shift_alert: 'シフト設定をオフにしますか？',
    productivityTurnedOff: "生産性ログがオフ",
    shiftTurnedOff: "シフトオフ",
    timeOffTurnedOff: "タイムオフはオフ"
  },
  auth: {
    password_expire_text:
      "パスワードは期限切れになります {{day}} 日々）、ログイン後、[設定]画面に移動してパスワードをリセットします。",
    note: "注記",
    password_expire: "パスワードの有効期限が切れてください ",
    resetpassword: "パスワードを再設定する",
    You_want_to_reset_password_using:
      "使用してパスワードをリセットする必要があります",
    Old_Password: "以前のパスワード",
    OTP_over_email: "電子メールでOTP",
  },
  Twofactorauth: {
    Yourorganization:
      "あなたの組織では、ログインに多要素認証を導入しています。",
    scan_qrcode: "QRコードを読み取ってください",
    Multifact: "多要素",
    Authen: "認証",
    Passcode: "パスコードを入力してください",
    use_google:
      "グーグルを利用する オーセンティケーター または マイクロソフト オーセンティケーター  を携帯電話で見ることができます。",
    open_google:
      "1. グーグルを開く オーセンティケーター または マイクロソフト オーセンティケーター 携帯電話にて",
    qr_code: "2.QRコードを読み取るを選択",
    capture: "3. この画面にスマホを向けてコードを取り込みます",
    next_button: "4. 次のボタンをクリックする",
    next: "次のページ",
    user_secret: "ユーザーシークレット",
    secret: "秘密のパスワード",
    cancel: "キャンセル",
    submit: "提出する",
    connecting: "コネクティング",
    two_factor_auth: "ツーファクト認証",
    change_password: "パスワードの変更",
    Enable: "イネーブル",
    Disable: "無効にする",
    Select_All: "すべて選択",
    Two_Factor_Authentication: "二要素認証",
    resetMFA: "MFA のリセット",
    mfaReset: "多要素リセット成功",
    password_update: "パスワードの更新に成功しました。"
  },

  Dashboard: {
    productivity: "生産性",
    Work: "作品",
    cruiser_hours: "クルーザー時間",
    office_hours: "オフィスアワー",
    think_hours: "シンクアワー",
    Hrs: "時間",
    hrs: "時間",
    hours: "時間",
    BIZUnit: "BIZユニット",
    timesheet: "タイムシート",
    timesheet_summary: "タイムシートサマリー",
    this_month: "今月は",
    current_month: "(今月は)",
    last3months: "過去 3 か月",
    this_year: "(この年)",
    quarterly: "四半期",
    half_yearly: "半期",
    yearly: "年間",
    total_working_days: "全営業日",
    days_worked: "仕事をした日",
    on_leave: "休業中",
    holidays: "ホリデー",
    total_time: "合計時間",
    work_time: "作業時間",
    think_time: "シンクタイム",
    cruiser_work: "クルーザーの仕事",
    cruiser_application_summary: "クルーザーの応募概要",
    monthly: "月間",
    team_progress: "チームの進捗状況",
    average_usage: "平均使用量",
    todays_usage: "今日の使い方",
    team: "チーム",
    project_name: "プロジェクト名",
    start_date: "開始日",
    end_date: "終了日",
    progress: "プログレス",
    recent_tasks: "最近のタスク",
    work_summary: "作品概要",
    daily: "デイリー",
    yesterday_usage: "昨日の使い方",
    assigning_order: "割り当て順序",
    ascending_order: "昇順",
    descending_order: "降順",
    org_create: '組織の設立に成功',
    user_create: 'ユーザーの作成に成功',
    org_enable: "組織の有効化に成功",
    org_disable: "組織の無効化に成功",
    user_view: "ユーザーズビュー",
    tool_view: "ツールズビュー"
  },
  productivity: {
    team_pro: "チームの生産性",
    daily: "デイリー",
    monthly: "月間",
    yearly: "年間",
    application_pro: "アプリケーションの生産性",
    hours: "時間",
    no_data: "がある 選択した基準に使用できるデータがありません",
    showtrends: "ショートレンド",
    hidetrends: "トレンド隠す",
    delete_session: "セッションの記録を削除しますか",
    Teamproductivity: "チームの生産性",
    Appproductivity: "アプリの生産性",
    Mostused: "最も使用されている",
    Yesterday: "昨日",
    LAST_7_DAYS: "過去7日間",
    THIS_MONTH: "今月",
    LAST_MONTH: "先月",
    THIS_YEAR: "今年",
    LAST_YEAR: "昨年",
    custom_Range : "カスタム・レンジ",
  },

  Opsadmin: {
    total_profile: "トータルプロフィール",
    total_employee: "従業員総数",
    productivity: "生産性",
    client: "クライアント",
    expense: "費用",
    application_summary: "応募概要",
    this_month: "今月は",
    project_name: "プロジェクト名",
    start_date: "開始日",
    end_date: "終了日",
    phase: "フェーズ",
    status: "ステータス",
    filled: "フィルド",
    not_filled: "充填されていない",
    approved: "承認",
    rejected: "却下",
    hours: "時間",
    manager_name: "マネージャー名",
    join_date: "入社日",
    directories: "ディレクトリー",
    departments: "デパートメント",
    onboard: "オンボード",
    termination: "終了について",
    view: "表示",
    employees: "従業員",
    employee_name: "従業員名",
  },
  OrgAdmin: {
    productivity_month: "今月の生産性",
    emp: "従業員",
    machine: "マシン",
    tool: "ツール",
    Work: "作品",
    timesheet: "タイムシート",
    last_month: "先月",
    selectall: "すべて選択",
    select: "セレクト",
    unit: "ユニット",
    approved: "承認",
    rejected: "却下",
    pending: "保留",
    submitted: "投稿済み",
    cruiser_summary: "クルーザーの応募概要",
    yearly: "年間",
    monthly: "月間",
    tools: "ツール",
    login: "ログイン",
    log_out: "ログアウト",
    total_hours: "合計時間",
    time_zone: "タイムゾーン",
    emp_name: "従業員名",
    hrs: "時間",
    income: "インカム",
    revenue: "収入",
    productivity_of: "の生産性 ",
    cruiser_hours: "クルーザー時間",
    think_hours: "シンクアワー",
    think_time: "シンクタイム",
    listofemp: "従業員一覧",
    application_summary: "応募概要",
    daily: "デイリー",
    Mumbercount: "数のカウント",
    user_list: "ユーザーリスト",
    tool_list: "ツールリスト",
  },
  Sysadmin: {
    total_machines: "トータルマシン",
    de_allocate: "ディアロケート",
    total_tools: "トータルツール",
    created_machines: "創造された機械",
    created_tools: "作成ツール",
    this_month: "今月は",
    this_year: "今年度",
    last_month: "先月",
    completed: "完成品",
    pending: "保留",
    this_tools: "これが道具だ",
    team_progress: "チームの進捗状況",
    in_progress: "時間単位",
    machines: "機械類",
    tools: "ツール",
    machine_name: "機械名",
    employee_name: "従業員名",
    ip_address: "IPアドレス",
    processor: "プロセッサー",
    RAM: "ラム",
    BIZUnit: "BIZユニット",
    storage: "ストレージ",
    time_zone: "タイムゾーン",
    status: "ステータス",
    upgrade: "アップグレード",
    create_on: "で作成する。",
    active: "アクティブ",
    idle: "アイドル",
    requests: "要望",
    select_date: "日付選択",
    allocate: "割り当てる",
    deallocate: "わりふる",
    requesttype: "要求タイプ",
    inactive: "活動中",
    month: "月",
    pemkey: "PEMキー",
    t_action: "アクション",
    all : "すべて",
    users_tools : "ユーザー/ツール",
  },
  Employee: {
    chat: "チャット",
    edit: "編集",
    personal_info: "個人情報について",
    name: "名前",
    toolname: "ツール名",
    email: "電子メール",
    phone: "電話",
    lang: "言語",
    loc: "所在地",
    zp_code: "郵便番号",
    role: "役割",
    skills: "スキル",
    bio: "バイオグラフィー",
    tl_employees: "社員数",
    prjt_resource: "プロジェクトリソース",
    bnch_resource: "ベンチリソース",
    cnsltnt: "コンサルタント",
    filter: "フィルター",
    login: "ログイン",
    log_out: "ログアウト",
    profile: "プロフィール",
    emp: "従業員",
    name:"名称",
  },
  labels: {
    select_date: "[日付]を選択します",
    rejectRequest: "リクエスト拒否",
    OTP: "OTP",
    email: "電子メール",
    password: "パスワード",
    directorytype: "ディレクトリタイプ",
    RememberMe: "私を覚えてますか",
    // welcome: "ようこそ ワンエイブル",
    welcome: "ブルーバードへようこそ",
    welcome_to: "歓迎する",
    oneable: "ワンエーブル",
    bluebird: "ブルーバード",
    forgotPassword: "パスワードをお忘れですか？",
    dontHaveAccount: "アカウントをお持ちではありませんか？",
    reject_this_request:"このリクエストを拒否してよろしいですか？",
    register: "登録",
    logo: "ブルーバード",
    // logo: "Oneable",
    dashboard: "ダッシュボード",
    timesheet: "タイムシート",
    project: "タイムシート",
    collabration: "コラボレーション ",
    productivity: "生産性   ",
    employee: "社員",
    task: "タスク",
    schedular: "スケジュール",
    seeData: "データを見る  ",
    vai: "クルーザー",
    connecting: "接続しています...",
    ctrl: "ctrl",
    alt: "Alt",
    tab: "タブ",
    esc: "Esc",
    oops: "おっと",
    forgot_something: "何か忘れていませんか？",
    recover_password: "パスワードを回復するためのメールを入力してください。",
    kyp: "パスワードを忘れた場合",
    show: "ショー",
    entries: "エントリー",
    owp: "組織 ワークフォースプランナー",
    pwp: "組織のためのワークフォース・プランナーの提供",
    forgot_password: "パスワードを忘れた場合",
    knowyourpsw: "パスワードを忘れた場合 ログイン",
    newpsw: "新しいパスワード",
    confirm: "パスワードの確認",
    oldpsw: "旧パスワード",
    cancel: "キャンセル",
    savepsw: "パスワードの保存",
    faq: "よくある質問",
    self: "自己",
    team: "チーム",
    back: "背面",
    requets: "要望",
    department: "部署名",
    membercount: "メンバーカウント",
    registrationdate: "登録日",
    organizationowner: "組織所有者",
    orgcreation: "組織の創造",
    licensecreation: "ライセンスの作成",
    licenseupdate: "ライセンスの更新",
    workingweek: "週間あたりの勤務日数",
    workinghours: "日の労働時間",
    address: "アドレス",
    multifactor: "マルチファクター",
    create: "作成する",
    employeeid: "社員ID",
    employeerole: "社員の役割",
    organizationrole: "組織の役割",
    lastname: "ラストネーム",
    firstname: "ファーストネーム",
    projectcreation: "プロジェクトクリエーション",
    overview: "オーバービュー",
    Recordsession: "録音したセッション",
    sessioncount: "セッション数",
    Overtime: "残業",
    session_expire: "セッションの有効期限が切れている",
    login_again: "もう一度ログインしてください！",
    ok: "よっしゃー",
    Mycomputer: "マイコンピュータ",
    Drive: "ドライブ",
    Dropbox: "ドロップボックス",
    userCount: "ユーザー数",
    select_org: "組織を選択してください",
    shift_complete: "あなたのシフト時間完了",
    shift_close: 'シフトクローズ',
    Whiteboard: "ホワイトボード",
    Record: "記録",
    Security: "安全",
    live_chat: "ライブチャット",
    Yourshifttimecompleted: "あなたのシフト時間が完了しました",
    ApplicationUtilization: "アプリケーションの使用率",
    SelfProductivity: "自己生産性",
    Whiteboard: "ホワイトボード",
    Record: "記録",
    Security: "安全",
    live_chat: "ライブチャット",
    Yourshifttimecompleted: "あなたのシフト時間が完了しました",
    ApplicationUtilization: "アプリケーションの使用率",
    SelfProductivity: "自己生産性",
    reset_password: "パスワードを再設定する",
    user_details: "ユーザー詳細",
    expiry_date: "有効期限",
    shiftOvertime: "シフト/シフト/シフト拡張",
    shiftSettings: "シフト設定",
    shift: "シフト",
    overtime: "シフト/シフト拡張",
    timeOffInMin: "オフ時間 (分)",
    productivity_settings: "生産性設定",
    productivity_view_permissions: "生産性ビューの権限",
    disabled: '無効',
    enabled: '有効',
    share: "ダウンロード",
    pemkey: "PEMキー",
    totalEmployees: "従業員総数",
    noManagerAssigned: "マネージャーなし",
    notAssignedtoDept: "どの部署にも配属されていない",
    comment:'コメント',
    fileshare: "ファイル共有",
    fileTransfer: 'ファイル転送'
  },
  placeholders: {
    email: "電子メール",
    password: "パスワード",
    timeSheetProject: "プロジェクト名",
    timeSheetTaskTitle: "タスクのタイトルを入力します。",
    timeSheetDuration: "時間",
    timeSheetMeetingTitle: "会議のタイトルを入力してください",
    timeSheetMeetingDuration: "時間.分",
    newpassword: "新しいパスワード",
    oldpassword: "旧パスワード",
    confirmpassword: "パスワードの確認",
    work_days: "稼働日",
    work_hours: "労働時間",
    phone_number: "電話番号",
    user_count: "ユーザー数の入力",
    enterconfirmPassword: "パスワードの確認を入力します",
    enternewPassword: "新しいパスワードを入力してください",
    search_tasktitle: "タスクタイトルで検索",
    host: "ホスト",
    firstname: "姓を入力",
    lastname: "姓を入力",
    enter_email: "電子メールを入力",
    enter_phoneno: "電話番号を入力",
    enter_dayperweek: "週の勤務日数を入力",
    enter_workhrs: "勤務時間の入力",
    address: "住所を入力",
  },
  buttons: {
    login: "ログイン",
    reset_password: "パスワードの再設定",
    resend_otp: "再送信OTP",
    filter: "フィルター",
    download: "ダウンロード",
    approve: "承認",
    reject: "拒否する",
    update: "更新情報",
    submit: "提出する",
    updatepassword: "パスワードを更新します",
    resubmit: "再提供",
    cancel: "キャンセル",
    fillTimeSheet: "Fill Timesheet",
    Create: "作成",
    Send: "作成",
    Integrate: "インテグレート",
    yes: "はい",
    no: "いいえ",
    add: "追加",
    Downloadfile: "ダウンロードファイル",
    Recentfiles: "最近のファイル",
    Save: "セーブ",
    delete: "削除",
    leave: "出発",
    yes: 'はい',
    no: 'いいえ',
    join: "参加する",
    join: "参加する",
    Imageupload: "画像アップロード",
    Yes_end_call_now: "はい今すぐ通話を終了します",
    Return_to_call: "電話に戻ります",
    Shift_closed: "シフトは閉じています",
    continue: "続く",
    edit: "編集",
    share: "シェア",
    Confirm: "確認する",
  },

  errorMessages: {
    emptyResponse: "データを受信していない",
    invalidshift: "あなたはこのシフトのメンバーではありません。シフトの変更が必要な場合は、マネージャーに連絡してください",
    email: "有効なEメールを入力してください。",
    toolName: "ツール名は必須。",
    password: "パスワードが必要です。",
    date: "日付が必要です",
    timeSheetTaskTitle: "タイトルが必要です",
    timeSheetMeetingTitle: "会議のタイトルが必要です",
    timeSheetDuration: "期間が必要です",
    timeSheetMeetingDuration: "期間が必要です",
    timeSheetProject: "プロジェクト名が必要です",
    oldpassword: "古いパスワードを入力してください。",
    changepassword: "パスワードには8文字以上の長さを含める必要があります。",
    newpassword: "新しいパスワードとパスワードの確認が一致するはずです。",
    timeSheetMeetingTitle: "会議のタイトルは必須です",
    timeSheetMeetingDuration: "持続時間は必須",
    timeoff_empty: "有効時間（分）を 六十～四百八十 の間で入力します。",
    url: "プロジェクトURLを入力してください。",
    Name: "お名前を入力してください。",
    Project: "プロジェクトを選択してください",
    Department: "部門を選択してください",
    TaskTitle: "タスクのタイトルを入力してください",
    Description: "説明を入力してください",
    Duedate: "終了日を選択してください",
    startdate: "開始日を選択してください。",
    Priority: "優先度を選択してください",
    Issuetype: "問題の種類を選択してください",
    Task_Tool: "タスクツールを選択してください",
    Int_Tool: "統合ツールを選択してください",
    Assign: "チームメンバー/従業員を選択してください。",
    Reporter: "レポーターを選択してください。",
    Status: "ステータスを選択してください",
    verificationCode: "6桁の認証コードを入力してください",
    requestaselect: "リクエストの選択",
    request_Type: "リクエストの種類を選択してください",
    tool_select: "ツールを選択してください",
    machine_name_Enter: "マシン名を入力してください",
    operating_system_select: "オペレーティングシステムを選択してください",
    ram_select: "ラムを選択してください",
    processor_select: "プロセッサーを選択してください",
    storage_select: "ストレージを選択してください",
    location_select: "所在地を選択してください。",
    Plan_select: "プランを選択してください",
    org_Name: "組織を入力してください",
    org_Id: "組織IDを入力してください",
    users_Count: "ユーザー数を入力してください",
    module_Name: "モジュール名を入力してください",
    server_error: "サーバーがエラーを発行しました。",
    data_unavailable: "「データが利用できません",
    host_url: "有効なURLを入力",
    not_all_file_image:
      "すべてのファイルが画像であるわけではありません。画像ファイル (gif、png、jpg、jpeg、tiff) のみが許可されます。",
    mandatory: "必須項目の入力",
    org_role: "組織の役割を入力",
    org_name: "組織名を入力",
    host: "ホストURLを入力",
    employee_id: "従業員IDを入力",
    department: "部門を入力",
    enter_workhrs: "有効な勤務時間を入力",
    enter_dayperweek: "週の有効日数を入力",
    enternewpassword: "新しいパスワードを入力してください。",
    enterconfirmpass: "コンフォメーションパスワードを入力してください。",
    emailvalidoneable:
      "onable.aiドメインから有効なメールアドレスを入力してください。",
    passMinLength: "パスワードは、少なくとも{{number}}文字の長さである必要があります。",
    passMaxLength: "パスワードは{{number}}文字を超えてはなりません。",
    passUppercase: "パスワードに少なくとも1つの大文字の文字を含めてください。",
    passLowercase: "パスワードに少なくとも1つの小文字を含めてください。",
    passDigit: "パスワードに少なくとも1桁を含めてください。",
    passSpecialCharacter: "パスワードに少なくとも1つの特殊文字を含めてください。",
    work_break_time: "労働時間と休憩時間を合わせて24時間（1440分）を超えてはならない。",
    shift_update_failed: "シフトを更新できない",
    shiftList_fetch_failed: "シフト表を取得できない",
    productivityView: '生産性をログに記録するには、少なくとも1つの閲覧権限がオンになっている必要があります。',
    productivity_update_failed: "生産性設定の更新に失敗しました",
    min_work_time: "ワークタイムは1分以上でなければならない。",
    empty_break_time: '休憩時間フィールドは空であってはならない',
    timeOff_update_failed: "タイムオフ設定の更新に失敗しました",
    user_settings_failed: "ユーザー設定の取得に失敗しました",
    timeOff_error: 'タイムオフ設定不可',
    productivity_error: '生産性設定が利用できない',
    shift_error: 'シフト設定不可',
    validate_break_time: "休憩時間ユーザー設定の検証中にエラーが発生",
    update_break_time: "ユーザー設定の更新中にエラーが発生",
    unauthorized: "不正アクセス。",
    not_found: "見つからない。",
    req_failed: "APIリクエストに失敗しました。",
    parse_error: "API レスポンスの解析エラー。",
    fetch_error: "APIからのデータ取得エラー",
    file_size_validation_msg: "ファイル サイズ {{size}} は 100KB 以下である必要があります。",
    invalidDomain: 'は有効なドメインではありません。',
    taskTitleLength: "タスクのタイトルは255文字以内",
    reporterCannotBeSet: "レポーターは設定できません",
    unableToCreateTask: "タスクを作成できない",
    unableToEditTask: "タスクの編集ができない",
    taskTitleTooLong: "タスクのタイトルは120文字以内",
    timeSheetTaskTitleLength: "タスクのタイトルは255文字以内",
    timeSheetMeetingTitleLength: "ミーティングのタイトルは255文字以内",
    emailNotFound: "メールが見つかりません。メールIDを確認してください。",
    unableToLogin: "ログインできません。管理者に連絡してください。r",
    alreadyExists: "このプロジェクトはすでに存在している",
    shouldNot: "特殊文字のみでプロジェクト名を作成してはならない",
    shouldNotNum: "数字だけでプロジェクト名を作るべきではない",
    duplicateName: "すでにプロジェクトは存在する"
  },
  timesheetValidation: {
    timesheetFields: "フィールド必須",
  },
  vai: {
    CLIENT: {
      SERVER_BUSY:
        "サーバーがビジー状態のため、この接続は終了しました。数分待ってから再度お試しください。",
      UPSTREAM_TIMEOUT:
        "リモートデスクトップの応答に時間がかかりすぎるため、Oneableサーバーが接続を終了しました。もう一度お試しいただくか、システム管理者にお問い合わせください。",
      UPSTREAM_ERROR:
        "リモートデスクトップサーバーにエラーが発生し、接続を終了しました。もう一度お試しいただくか、システム管理者にお問い合わせください。",
      UPSTREAM_NOT_FOUND:
        "現在、リモートデスクトップサーバーに到達できません。それでも問題が解決しない場合は、システム管理者に連絡するか、システムログを確認してください。",
      UPSTREAM_UNAVAILABLE:
        "現在、リモートデスクトップサーバーが利用できません。それでも問題が解決しない場合は、システム管理者に連絡するか、システムログを確認してください。",
      SESSION_CONFLICT:
        "他の接続と競合するため、リモートデスクトップサーバーが接続を終了しました。後でもう一度お試しください",
      SESSION_TIMEOUT:
        "リモートデスクトップサーバーが、非アクティブであると思われるため、接続を閉じました。これが望ましくない場合は、システム管理者に連絡するか、システム設定を確認してください",
      SESSION_CLOSED:
        "リモートデスクトップサーバーが強制的に接続を終了しました。これが望ましくない場合は、システム管理者に連絡するか、システムログを確認してください。",
      CLIENT_UNAUTHORIZED:
        "ログインに失敗しました。再接続して再度お試しください。",
      CLIENT_FORBIDDEN:
        "リモートデスクトップサーバーがこの接続へのアクセスを拒否しました。アクセスが必要な場合は、システム管理者にアカウントのアクセスを許可してもらうか、システムの設定を確認してください。",
      CLIENT_TIMEOUT:
        "お使いのブラウザからの応答がない状態が長く続き、切断されたように見えたため、Oneableサーバーが接続を終了しました。この現象は、無線信号が不安定であったり、ネットワーク速度が非常に遅いなど、ネットワークの問題が原因であることが多いです。お手数ですが、ネットワークを確認してから再度お試しください。",
      CLIENT_TOO_MANY:
        "Oneableサーバーは、個々のユーザーによる同時接続使用の制限を超えているため、この接続へのアクセスを拒否しています。1つ以上の接続を閉じて、再度お試しください。",
      CLIENT_DEFAULT:
        "Oneableサーバー内で内部エラーが発生したため、接続を終了しました。それでも問題が解決しない場合は、システム管理者に連絡するか、システムログを確認してください。",
    },
    TUNNEL: {
      SERVER_BUSY:
        "アクティブな接続が多すぎるため、Oneableサーバーはこの接続試行を拒否しました。しばらく時間をおいてから、もう一度お試しください。",
      UPSTREAM_TIMEOUT:
        "サーバーの応答に時間がかかりすぎたため、接続が終了しました。これは通常、ワイヤレス信号が不安定であったり、ネットワーク速度が遅いなど、ネットワークの問題が原因です。ネットワーク接続を確認して再度お試しいただくか、システム管理者にお問い合わせください。",
      UPSTREAM_ERROR:
        "サーバーにエラーが発生し、接続を終了しました。もう一度お試しいただくか、システム管理者にお問い合わせください。",
      RESOURCE_NOT_FOUND:
        "要求された接続が存在しません。接続名を確認して再度お試しください。",
      RESOURCE_CONFLICT:
        "この接続は現在使用中であり、この接続への同時アクセスは許可されていません。後でもう一度お試しください。",
      UPSTREAM_NOT_FOUND:
        "Oneable社のサーバーには現在アクセスできません。お手数ですが、ネットワークをご確認の上、再度お試しください。",
      UPSTREAM_UNAVAILABLE:
        "Oneableサーバーが接続を受け付けていません。ネットワークをご確認の上、再度お試しください。",
      CLIENT_UNAUTHORIZED:
        "ログインしていないため、この接続にアクセスする権限がありません。ログインしてから再度お試しください。",
      CLIENT_FORBIDDEN:
        "この接続にアクセスする権限がありません。アクセスする必要がある場合は、システム管理者に許可されたユーザーのリストに追加してもらうか、システムの設定を確認してください。",
      CLIENT_TIMEOUT:
        "お使いのブラウザからの応答がない状態が長く続き、切断されたように見えたため、Oneableサーバーが接続を終了しました。この現象は、無線信号が不安定であったり、ネットワーク速度が非常に遅いなど、ネットワークの問題が原因であることが多いです。お手数ですが、ネットワークを確認してから再度お試しください。",
      CLIENT_TOO_MANY:
        "Oneableサーバーは、個々のユーザーによる同時接続使用の制限を超えているため、この接続へのアクセスを拒否しています。1つ以上の接続を閉じて、再度お試しください。",
      TUNNEL_DEFAULT:
        "Oneableサーバー内で内部エラーが発生したため、接続を終了しました。それでも問題が解決しない場合は、システム管理者に連絡するか、システムログを確認してください",
    },
    No_tools_provision_yet: "ツールはまだ用意されていません！",
    local_lanch_text: "このツールは、Oneable デスクトップアプリケーションから起動できます。"
  },
  TimeSheets: {
    Oneable: "ワンエイブル",
    review: "レビュー",
    Tark: "Tark",
    p_appsetup: "アプリの設定",
    p_3hrs: "3:00 時間",
    p_MC: "マネージャーコメント",
    b_reject: "リジェクト",
    b_approve: "承認する",
    new_timesheet: "新しいタイムシート",
    s_sp: "プロジェクトの選択",
    p_task_title: "タスクタイトルの入力",
    p_0hrs: "00:00 時間",
    b_cancel: "キャンセル",
    b_submit: "送信",
    pa_et: "タイムシートの編集",
    b_resubmit: "再提出",
    b_filter: "フィルター",
    b_download: "ダウンロード",
    b_filltimesheet: "タイムシート記入",
    t_date: "日付",
    t_projects: "プロジェクト",
    t_task: "タスク",
    t_hours: "時間",
    t_status: "ステータス",
    t_action: "アクション",
    t_name: "名前",
    mon: "月曜日",
    tue: "火曜日",
    wed: "水曜日",
    thu: "木曜日",
    fri: "金曜日",
    sat: "土曜日",
    sun: "日曜日",
    total: "合計",
    hours: "時間",
    dt: "タイムシートのダウンロード",
    op1: "オプション 1",
    op2: "オプション 2",
    op3: "オプション 3",
    approved: "承認",
    today: "今日",
    thisweek: "今週の",
    thismonth: "今月は",
    timesheet: "タイムシート",
    tsc: "タイムシートの作成が完了しました",
    self_title: "セルフ",
    team_title: "チーム",
    team_not_available: "このユーザーのチームは利用できません",
    reject_success: "タイムシートは正常に拒否された",
    approve_success: "「タイムシートが承認されました",
    hours_exceed: "時間が24時間を超えた",
    automated_edit: "自動タイムシートが編集できない",
    All: "すべて",
    worked_hours: "労働時間",
    click_select_date_and_add_task: "カレンダーから日付を選択し、タスクの追加をクリックしてください ",
    timesheet_update_success: "タイムシートは正常に更新されました",
    task_hours_exceeded: "この特定の日付のタスク時間は18時間を超えました",
    task_project_already_exist: "その特定のプロジェクトのタスクはすでに存在しています",
    Doyouwanttoapprove: "承認しますか？",
    Doyouwanttorejecte: "拒絶したいのか？",
  },
  Projects: {
    new_proj: "新企画",
    no_proj: "プロジェクトがない",
    create_proj: "新規プロジェクトの作成",
    search: "検索",
    allprojects: "すべてのプロジェクト",
    active: "アクティブプロジェクト",
    inactive: "休止中のプロジェクト",
    completed: "完成したプロジェクト",
    team: "チーム",
    task: "タスク",
    document: "ドキュメント",
    gantt: "ガント",
    teamname: "チーム名",
    members: "メンバー",
    productivity: "生産性",
    hours: "時間",
    taskname: "タスク名",
    startdate: "開始日",
    closedate: "終了日",
    status: "ステータス",
    priority: "優先順位",
    action: "アクション",
    filename: "ファイル名",
    project: "プロジェクト",
    employee: "従業員",
    date: "日付",
    filesize: "ファイルサイズ",
    actions: "アクション",
    Newfolder: "新しいフォルダー",
    Folders: "フォルダー",
    Projecttype: "プロジェクトタイプ",
    project_created: "プロジェクト正常に作成されました。",
    enter_project: "プロジェクト名を入力してください",
    select_projecttype: "プロジェクトタイプを選択する",
    project_templatekey: "プロジェクト・テンプレート・キー",
    select_projecttempkey: "プロジェクト・テンプレート・キーを選択する",
    project_error: "プロジェクトを作成できない",
    SearchbyProject: "プロジェクトで検索",
    duplicate_project: "プロジェクト名の重複不可",
    alphabet_required: "プロジェクト名には少なくとも1つのアルファベットを含めること",
  },
  seeddata: {
    search: "検索",
    createrole: "+ 役割の作成",
    rolename: "役名",
    roledesc: "役割",
    logintype: "ログインタイプ",
    action: "アクション",
  },
  tasks: {
    Previous: "前の",
    Next: "次",
    createtask: "タスクの作成",
    Jira: "JIRA",
    sp: "プロジェクトの選択",
    sd: "部門を選択する",
    select_priority: "優先順位を選択する",
    select_issuetype: "課題の種類を選択する",
    select_Inttool: "統合ツールを選択する",
    select_tasktool: "タスクツールを選択",
    Int_Tool: "統合ツール",
    Task_Tool: "タスクツール",
    product: "製品",
    tasktitle: "タスクタイトル",
    entertask: "タスクタイトルの入力",
    description: "説明",
    optional: "(オプション)",
    startdate: "開始日",
    enddate: "終了日",
    cancel: "キャンセル",
    close: "閉じる",
    save: "保存",
    search: "検索",
    one: "一つ",
    two: "2つの",
    three: "スリー",
    currentweek: "現在の週",
    planned: "計画中",
    progress: "進行中",
    complete: "完成",
    Priority: "優先順位",
    High_Priority: "高い",
    Medium_Priority: "ミディアム",
    Low_Priority: "低",
    Issue_Type: "課題の種類",
    Task: "タスク",
    Bug: "バグ",
    Story: "ストーリー",
    Epic: "エピック",
    Org_est: "当初見積り",
    Reporter: "レポーター",
    Project: "プロジェクト",
    Task_Details: "タスクの詳細",
    upload_file: "+ ファイルのアップロード（オプション）",
    Assignee: "アサイン先",
    edit_task: "タスクの編集",
    TO_DO: "をする",
    In_Progress: "進行中",
    Done: "完了",
    QA: "QA -テスト",
    Unassigned: "割り当てられていない",
    No_tasks: "タスクがない！",
    No_projects: "タスクはありません !",
    EstimatedHours: "推定時間",
    department: "部署名",
    discription: "説明",
    raise_req: "ツールアクセスに関するリクエストを出す",
    req: "リクエストを上げる",
    in_hours: "営業時間",
    select_assignee: "担当者を選択",
    select_resource: "リソースを選択",
    no_data: "データなし",
    req_raise: "リクエストは成功",
    valid_project: "有効なプロジェクトを入力してください",
    assign_team: "チームメンバーに割り当てる",
    del_attach: "この添付ファイルを削除しますか？",
    once_del: "一度削除すれば、それは永久に続く",
    log_hours: "記録された時間のステータスは、To Doと未割り当てに変更することはできません。",
    status_update: "ステータス更新成功",
    tasks_update: "タスクは正常に更新されました",
    bug_update: "バグは正常に更新されました",
    epic_update: "壮大な更新が正常に更新されました",
    story_update: "ストーリーは正常に更新されました",
    tasks_create: "タスクを正常に作成しました",
    tasks_delete: "タスクの削除に成功",
    bug_create: "バグが正常に作成されました",
    epic_create: "エピックが正常に作成されました",
    story_create: "ストーリーが正常に作成されました",
    req_task_tool: "このタスクのツールは提供されていません。",
    break_or_continue: "ツールを起動するには、ワークスペースの「休憩する」または「続ける」を選択してください。",
    estimated_hrs: "所要時間",
    task_view: "タスクビュー",
    tool_launch: "ツールローンチ",
    file_upload_success: "ファイルのアップロードは正常に完了しました。",
    upload_file_note: "注記* : アップロードするファイル サイズの制限は 100KB 以下です。",
    provisioned_tools: "提供ツール",
    unprovisioned_tools: "未提供ツール",
    unassigned_status_change : "タスクのステータスを変更するには、タスクを自分／チームに割り当てる。",
    to : "に",
    from : "から",
    status_changed : "ステータス変更",
    drag_here: "ここにドラッグする",
    del_task: "削除タスク",
    would_del_task:"このタスクを削除してよろしいですか？"
  },
  profile: {
    // kb:'ケビン・ベイカー',
    gd: "グラフィックデザイン",
    chat: "チャット",
    edit: "編集",
    pi: "個人情報",
    name: "名称",
    email: "電子メール",
    phone: "電話番号",
    languages: "言語",
    location: "所在地",
    zc: "郵便番号",
    role: "役割",
    skills: "スキル",
    biography: "バイオグラフィー",
    no_change: 'プロフィールの詳細を入力して更新する',
    remain_char: '残りのキャラクター',
    valid_lang: '有効な言語の追加',
    update: 'プロフィールの更新に成功',
    valid_loc: '無効な場所',
    valid_phone: '無効な電話番号',
    employeeId: "従業員ID",
    managers: "報告責任者",
    departments: "部署名",
    onboardedOn: '入社した'
  },
  collaboration: {
    Imageupload: "画像アップロード",
    fileupload: "ファイルアップロード",
    Uploadfile: "ファイルのアップロード",
    Hyperlink: "ハイパーリンク",
    Displaytext: "表示テキスト",
    Linkshare: "リンク",
    err_name: "名前を空にすることはできません",
    forward:" 前方",
    reply:"返信",
    pin:"ピン",
    copy:"コピー",
    delete:"削除",
    download: "ダウンロード",
  },
  pomodoro: {
    Integration: "統合化",
    Search_messages: "メッセージやユーザーを検索する",
    Pomodoro: "ポモドーロ",
    Add_Integration: "統合の追加",
    Jira_Integration: "との統合",
    organization_name: "組織名",
    organization: "組織",
    break: "休憩",
    actions: "アクション",
    membercount: "メンバーカウント",
    Pomodoro_timer: "ポモドーロタイマー",
    do_want_pomodoro: "タイムオフの統合を希望していますか",
    Select_All_Org: "全組織を選択",
    Org_Maangadi: "団体名 マーンガディ",
    Org_Oneable: "組織名 Oneable",
    Break_time: "休憩時間（分）",
    time_minite: "時間（単位 分）",
    break_extension: "ブレイクエクステンション",
    break_time_extension: "ブレークタイムエクステンション",
    Notification: "お知らせ",
    Auto_start_Breaks: "オートスタート ブレイク",
    timer_setting: "タイマー設定",
    time_off: "オフの時間",
    update: "アップデート時間 オフ",
    time_off_update: "タイムオフ更新",
    ok: "よっしゃー",
    activate: "タイムオフを有効にしますか",
    deactivate: "タイムオフを無効にしますか",
    Int_notification: "統合通知",
    Int_act: "統合を有効にしますか？",
    Int_deact: "統合を無効にしますか？",
    notification: "オフの時間 お知らせ",
    worked_for: "あなたはのために働いてきました",
    continue: "続ける",
    minutes: "議事録",
    seconds: "秒",
    usercreation: "ユーザークリエーション",
    user: "ユーザー",
    Registerdate: "登録された日付",
    Directory: "ディレクトリ",
    connection_name: "接続名",
    user_or_email: "「ユーザー名／Eメール",
    pass_or_apitoken: "「パスワード/APIトークン",
    integration_success: "統合に成功",
    inMinutes: "(議事録)",
    workTime: "仕事 時間",
    breakTime: "休憩時間",
    colon: " : ",
    breakTime_Launch_Tool: "本当にツールを起動しますか ,",
    breakTime_Left: "休憩時間はまだある",
    left: "左",
    launch_Tool: "起動ツール",
    continue_Break: "休憩を続ける",
  },
  chart: {
    thinktime: "シンクタイム",
    timesheethr: "タイムシート時間",
    officehours: "オフィスアワー",
  },
  Settings: {
    Selectapriority: "優先順位を選択する",
    Plan: "企て",
    Selectrequesttype: "リクエストの種類を選択",
    Selectarequest: "リクエストを選択する",
    all: "何れも",
    approved: "承認済み",
    submitted: "提出",
    rejected: "却下",
    closed: "閉鎖的",
    approve: "承認する",
    reject: "拒否する",
    projectname: "プロジェクト名",
    request: "リクエスト",
    requesttType: "リクエストタイプ",
    opsystem: "オペレーティングシステム",
    storge: "ストレージ",
    location: "所在地",
    multipleAppsToLaunch: "起動する複数の巡洋艦アプリケーション",
    Domain: "ドメイン",
    Adduser: "ユーザー追加",
    Adddomain: "ドメイン追加",
    Userinfo: "ユーザー情報",
    manager: "管理人",
    Directorytype: "ディレクトリタイプ",
    modules: "モジュール",
    Domaincreation: "ドメイン作成",
    Directorycreation: "ディレクトリ作成",
    Modulecreation: "モジュール作成",
    Moduleupdate: "モジュールのアップデート",
    Directoryname: "ディレクトリ名",
    Directorycode: "ディレクトリコード",
    Modulename: "モジュール名",
    Domainname: "ドメイン名",
    Authenticationtype: "認証タイプ",
    Truststore: "トラストストア",
    Serverprotocol: "サーバープロトコル",
    Serveraddress: "サーバーアドレス",
    Serverport: "サーバーポート",
    Aattribute: "認証属性",
    Securityprincipal: "セキュリティプリンシパル",
    Basename: "ベース名",
    Securitycredentials: "セキュリティ認証情報",
    Managerattribute: "マネージャーCNアトリビュート",
    Userobjectclass: "ユーザーオブジェクトクラス",
    Firstnameattribute: "ファーストネームのアトリビュート",
    Tenantid: "テナントID",
    Clientsecret: "クライアントシークレット",
    Clientid: "クライアントID",
    Titleattribute: "タイトル属性",
    EmployeeIdattribute: "社員ID属性",
    Photoattribute: "フォトアトリビュート",
    Emailattribute: "Eメール属性",
    LNameattribute: "ラストネーム属性",
    Createorg: "組織をつくる",
    req_notraise: "未提出のリクエスト",
    OrgadminView: "組織管理者",
    OpsadminView: "オペレーション・アドミニストレータ",
    SelfView: "セルフ",
    create_licensekey: "ライセンスキーの作成",
    license_createkey_success: "ライセンスキーの作成に成功"
  },
  Modules: {
    Modules: "モジュール",
    Create: "作成する",
    delAlert: "このモジュールを非アクティブ化しますか?",
    actAlert: "このモジュールを有効にしますか?",
    manager: "マネジャー",
    add_modules: 'ライセンスを作成するためにモジュールを追加する',
    modules_add: 'モジュールの追加',
    no_modules: '追加するモジュールはない！',
    nomodule_save: 'アップデート前のモジュール編集',
    atleast_one: '少なくとも1つのモジュールがアクティブでなければならない',
    modules_statusChange: '変更は保存されません。',
    modules_continue: '続けますか？',
    act_deact: 'アクティブ化／非アクティブ化',
    no_data: 'データなし',
    module_updt: "モジュールのアップデートに成功",
    module_create: "モジュールの作成に成功",
    no_module: "モジュールは存在しない。",
    edit_module: "更新前のモジュール編集",
  },
  License: {
    activate: "このライセンスをアクティブ化しますか?",
    deactivate: "このライセンスを非アクティブ化しますか?",
    activateModule: "このライセンス モジュールをアクティブ化しますか?",
    deactivateModule: "このライセンス モジュールを非アクティブ化しますか?",
    Activate: "活性化",
    Deactivate: "非アクティブ化",
    orgId: "組織ID",
    create: "ライセンスの作成に成功",
    edit_updt: "ライセンス更新前の編集",
    edit_module: 'モジュール更新前の編集',
    valid_date: "ライセンスの有効期限を追加",
    no_module: "アクティベーションは管理者に連絡",
    module_remove: "デフォルトのモジュールは削除できない",
    no_license: 'おっと！ライセンスが作成されていない',
    edit_license: "編集ライセンス",
    edit_license_modules: "ライセンスモジュールの編集",
  },
  collabration: {
    General: "一般",
    Channel: "チャンネル",
    // Channel_data: "スタディは印刷用のダミーテキスト",
    Chats: "( チャット",
    // Chats_Name1: "マリ・スピードワゴン",
    // Chats_Name2: "デブラ・スチュワート",
    // Chats_Name3: "ペティクルーザー",
    Create_A_Channel: "チャンネルを作成する",
    Create_A_Organization: "組織を作る",
    Name: "名称",
    Enter_Org_Name: "組織名を入力してください",
    Enter_Channel_Name: "チャンネル名を入力",
    Description_label: "説明文（任意）",
    Private: "プライベート",
    Public: "公共",
    welcome_to_Oneable: "ヘイ！Oneableへようこそ",
    Collaboration: "コラボレーション",
    Workspace: "ワークスペース",
    Personnel: "ペルソンネール",
    personal: "個人的",
    chats: "猫かぶり",
    channels: "チャンネル",
    Created: "作成",
    totalemployee: "従業員総数",
    totalchannel: "トータル・チャンネル",
    invitepeopleto: "人を誘う",
    allchannel: "全チャンネル",
    createchannel: "チャンネル作成",
    createworkspace: "ワークスペースの作成",
    deleteworkspace: "ワークスペースの削除",
    thereisnousers: "ユーザーがいない",
    subscribersinworkspace: "このワークスペースの購読者",
    uarentWorkSpace: "あ！ワークスペースに割り当てられていませんね。",
    contacturMngrorAdmr: "マネージャーまたはアドミニストレーターにご連絡ください",
    Hyperlink: "ハイパーリンク",
    Displaytext: "表示テキスト",
    Linkshare: "リンク",
    Totalchannels: "全チャンネル数",
    Totaljoined: "総参加",
    Imageupload: "画像アップロード",
    fileupload: "ファイルアップロード",
    Uploadfile: "ファイルのアップロード",
    Join: "参加する",
    View: "眺望",
    Leave: "出て行く",
    delete: "削除",
    Directmessages: "ダイレクトメッセージ",
    Teams_Talk: "チームトーク",
    Direct_chats: "ダイレクトチャット",
    No_Channel_alert_create: "このワークスペースには、チャンネルがありません！",
    Create_a_new_Channel: "新しいチャンネルを作成する",
    All_Channels: "すべてのチャネル",
    Search_Channels: "検索チャネル",
    There_is_no_channel: "チャンネルはありません !",
    Workspace_List: "ワークスペースリスト",
    Workspace_created_successfully: "ワークスペースの作成に成功",
    Workspace_Create: "ワークスペース作成",
    Invitation_sent_successfully: "招待状は正常に送信されました",
    Invitation_accept_successfully: "招待状は無事受理された。",
    No_user_subscriber: "このワークスペースにはユーザー/購読者がいません！",
    Enter_channel_name: "チャンネル名を入力してください",
    contact_admin: "おっと ！何か問題が発生しました。管理者に連絡してください。",
    CreateUrWorkspaceAdmin: "ワークスペースまたは管理者に連絡する",
    no_workspace: "ワークスペースはない。",
    Are_you_sure: "本気ですか",
    you_want_to_end_this_call: "あなたはこの呼び出しを終了したいです?",
    Are_you_sure_you_want_to_delete_this_data: "本当にこのデータを削除しますか？",
    Are_you_certain_about_your_intention_to_remove_this_user_from_the_channel: "このユーザーをチャンネルから削除する意図について確信していますか?",
    click_for_workspace: "以下のボタンをクリックして、新しいワークスペースを追加してください。",
    create_workspace: "ワークスペースの作成",
    name: "名称",
    ph_name: "名前を入力",
    description: "説明(オプション)",
    ph_description: "説明を入力",
    collabration: "コラボレーション",
    create_ws: "ワークスペースの作成",
    workspace: "ワークスペース",
    channel: "チャンネル",
    personnel: "人事",
    no_channel: "チャンネルはまだ作成されていない。",
    create_channel: "新しいチャンネルを作る。",
    make_private: "プライベート",
    channel_creation: "チャンネル作成",
    private_channel: "プライベートチャンネルの作成",
    invitepeople: "人々を招待する",
    acceptInvitation: "招待状は無事受理された。",
    rejectInvitation: "招待は正常に拒否された。",
    allEmployees: "全社員",
    noEmployee: "このワークスペースには従業員はいない！",
    err_name: "名前を空にすることはできません",
    channel_created: "チャンネルが正常に作成されました",
    select_user: "少なくとも1人のユーザーを選択",
    users_added: "ユーザーの追加に成功",
    delete_room: "部屋の削除に成功",
    unsubscribe_room: "ユーザー退出成功",
    add_personnel: "人員追加",
    view_personnel: "人員を見る",
    view_add_personnel: "ビュー/追加 担当者",
    view_add_remove_personnel: "ビュー/追加/取り除く 担当者",
    select_all: "すべて選択",
    no_team: "! チームがない",
    no_messages: "メッセージなし",
    direct_messages: "ダイレクトメッセージ",
    no_users: "利用不可",
    type_a_message: "メッセージを入力してください...",
    please_type_a_message_to_continue: "続行するにはメッセージを入力してください。",
    edit: "編集",
    remove: "削除",
    bold: "太字",
    italic: "斜体",
    underline: "下線",
    strike: "取り消し線",
    link: "リンクを取り付けます",
    list: "リスト",
    ordered: "順序付き",
    bullet: "箇条書き",
    blockquote: "引用",
    code: "コード",
    "code-block": "コードブロック",
    emoji: "絵文字を挿入します",
    images_attached: "画像を添付します",
    file_attached: "ファイルを添付する",
    send: "送信",
    Member: "メンバー",
    Remove: "取り除く",
    noMember: "メンバーはいません",
    Owner: "所有者",
    You: "あなた",
    Searchpersonnel: "検索担当者...",
    search: "検索...",
    There_is_no_User: "ユーザーはいません",
    create_room: "クリエイトルーム",
    setting: "コラボレーション・セッティング",
    channel_create: "チャンネル作成",
    create_Channel: "チャンネル作成",
    channel_subscribe: "チャンネル登録成功",
    channel_unsubscribe: "チャンネル登録解除成功",
    delete_workspace: "ワークスペースは正常に削除されました",
    Create_A_Private_Channel: "プライベートチャンネルを作成する",
    Integration: "統合",
    MakePrivate: "プライベート",
  },
  sessionRecording: {
    no_sessions: "おっと セッション録音はありません",
    proceed: "進む",
  },
  months: {
    jan: "睦月",
    feb: "如月",
    march: "弥生",
    april: "卯月",
    may: "皐月",
    june: "水無月",
    july: "文月",
    aug: "葉月",
    sep: "長月",
    oct: "神無月",
    nov: "霜月",
    dec: "師走",
    January: "睦月",
    February: "如月",
    March: "弥生",
    April: "卯月",
    May: "皐月",
    June: "水無月",
    July: "文月",
    August: "葉月",
    September: "長月",
    October: "神無月",
    November: "霜月",
    December: "師走",
  },
  whiteboard: {
    Select: "選択する",
    Pencil: "鉛筆",
    Brush: "みがきます",
    ColorPick: "カラーピック",
    Text: "文章",
    Polygon: "ポリゴン",
    Rectangle: "矩形",
    Circle: "丸",
    Line: "ライン",
    Erosser: "エロッサー",
    Image: "画像",
    Undo: "元に戻します",
    Redo: "やり直します"
  },
  pdf: {
    week: '週間',
    timeSheet: "タイムシート",
    name: '名称  ',
    timePeriod: '期間',
    totalWorkHours: '総労働時間',
    timeSheetStatus: 'タイムシートの状況',
    projects: 'プロジェクト',
    generatedOn: "生成日時",
    date: '日付',
    project: 'プロジェクト',
    task: 'タスク',
    workHours: '就業時間',
    type: 'タイプ',
    approved: '承認',
    all: 'すべて',
    rejected: '却下',
    submitted: '承認待ち',
    monthOf: '以下の期間のタイムシート',
    hasNo: 'がない',
    status: 'ステータス',
    pending: '申請中',
    selectProject: "少なくとも1つのプロジェクトを選択すること",
    downloadSelect: "ダウンロードオプションが選択されていない",
    download: "ダウンロード",
    manual: 'マニュアル',
    fileFormat: "ファイル形式",
    select: "選択",
    thismonth: '今月',
    fortnight1: '月前半',
    fortnight2: '月後半',
    week: '週間 ',
    lastMonth: '先月',
    thisYear: '今年',
    lastYear: '昨年',
    customRange: 'カスタム・レンジ',
    close: "閉じる",
    selectProjects: "プロジェクトを選択する",
    selectRange: "範囲を選択"
  },
  rtc: {
    videoOff: "ビデオをオフにする",
    videoOn: "ビデオをオンにする",
    mute: "ミュート",
    unMute: "ミュート解除",
    shareScreen: "画面を共有する",
    more: "もっと見る",
    endCall: '通話終了',
    liveChat: "ライブチャット",
    chat: "おしゃべり",
  },
  directories: {
    authActivate: "自動化タイプの作成に成功",
    authDeactivate: "自動化タイプが正常に無効化されました。",
    noFieldsUpdated: "フィールドは変更されませんでした",
    authModified: "認証タイプの更新に成功",
    directories: "ディレクトリ",
    directoryCreation: "ディレクトリの作成",
    directoryName: "ディレクトリ名",
    enterDirectoryName: "ディレクトリ名を入力",
    description: "説明",
    enterDesription: "説明を入力",
    cancel: "キャンセル",
    close: "閉じる",
    create: "作成",
    update: "更新",
  },
  domain:{	
    CreateDirectories : "ディレクトリの作成",	
    OnPremisesDirectories : "オンプレミス・ディレクトリの接続情報を入力します。",	
    AuthenticationType : "認証タイプ",	
    DomainName: "ドメイン名",	
    TrustStore: "トラスト・ストア",	
    serverProtocol: "サーバー・プロトコル",	
    serverAddress: "サーバーアドレス",	
    ServerPort: "サーバーポート",	
    authenticationAttribute: "認証属性",	
    securityPrincipal: "セキュリティ・プリンシパル",	
    baseName: "ベース名",	
    securitycredentials: "セキュリティ認証情報",	
    manager_CN_attribute: "マネージャーCNアトリビュート",	
    userobjectclass: "ユーザーオブジェクトクラス",	
    firstnameattribute: "ファーストネームのアトリビュート",	
    lastnameattribute: "ラストネーム属性",	
    emailattribute: "Eメール属性",	
    photoattribute: "写真属性",	
    employeeIdattribute: "社員ID属性",	
    titleattribute: "タイトル属性",	
    departmentIdAttribute: "部門 ID 属性",	
    clientid: "クライアントID",	
    clientsecret: "クライアントシークレット",	
    tenantid: "テナントID",	
    client_id: "「クライアントIDを入力してください。",	
    client_secret: "「クライアントシークレットの入力",	
    tenant_id: "「テナントIDの入力",	
    Enteryourdomainname: "ドメイン名を入力する",	
    Entertruststore: "トラストストアを入力",	
    Enterserverprotocol: "サーバープロトコルを入力",	
    Enterserveraddress: "サーバーアドレスの入力",	
    Enterserverport: "サーバーポートを入力",	
    Authenticationattribute: "認証属性を入力する",	
    Securityprincipal: "セキュリティプリンシパルを入力",	
    Enterbasename: "ベース名を入力 (DN)",	
    Enter_sec_credentials: "セキュリティ認証情報を入力する",	
    Entermanagercnattribute: "マネージャのcn属性を入力する",	
    Enteruserobjectclass: "ユーザーオブジェクトクラスを入力",	
    Enterfirstnameattribute: "ファーストネーム属性を入力",	
    Enterlastnameattribute: "ラストネーム属性の入力",	
    Enteremailattribute: "Eメール属性を入力する",	
    EnteremployeeIdattribute: "社員ID属性を入力",	
    EnterdepartmentIdattribute: "部門ID属性を入力する",	
    photo_attribute: "写真の属性を入力",	
    title_attribute: "タイトル属性を入力",	
  },
  pemKey: {
    select_file: "ファイルを選ぶ",
    unsupported_file_type:"サポートされていないファイル形式です。 PEM ファイルをアップロードしてください。",
    file_reader_error:"ファイルの読み取りエラー"
  },
  dateRangePicker: {
    yesterday: '昨日',
    lastSevenDays: '過去7日間',
    thisMonth: '今月',
    lastMonth: '先月',
    thisYear: '今年',
    lastYear: '昨年',
    customRange: 'カスタム・レンジ',
    selectRange: '日付範囲の選択',
    clear: 'クリア',
    to: '-'
  },
  pageSize: {
    all: 'すべて'
  },
  sessionList: {
    name: '名称',
    session_count: 'セッション数',
    tools: 'ツール',
    tool: '工具',
    action: 'アクション',
    loading: '読み込み中...',
    No_more_sessions_to_show: 'これ以上のセッションは表示されない。'
  },
  passwordPolicy: {
    minPasswordLength: "最小の長さ ",
    maxPasswordLength: "最大長 ",
    requiredUppercase: "大文字アルファベット1文字以上",
    requiredLowercase: "小文字アルファベット1文字以上",
    requiredSpecialCharacter: "最小1つの特殊文字",
    requiredDigits: "最小1つの数字",
    characters: "キャラクター",
    passwordPolicies: 'パスワードポリシー',
    passwordsDoNotMatch: "新しいパスワードと確認済みの新しいパスワードが一致しません。",
    passwordChangeSuccessful: "パスワード変更成功",
  },
  fileShare: {
    credentials: '認証情報',
    hostname: "ホスト名",
    username: "ユーザー名",
    password: "パスワード",
    sourceMachine: "送信元マシン",
    destinationMachine: "接続先マシン",
    enterHostname: "ホスト名を入力",
    enterUsername: "ユーザー名を入力",
    enterPassword: "パスワードを入力",
    cancel: 'キャンセル',
    submit: '送信',
    passwordRequired: "パスワードを入力してください",
    usernameRequired: "ユーザー名を入力してください",
    hostnameRequired: "ホストアドレスを入力してください",
    logout: "ログアウト",
    fileTransferSuccessful: 'ファイル転送成功',
    login:'ログイン'
  },
  fileShareErrors: {
    inaccessable: "ディレクトリにアクセスできない",
    rootLevelSharing: "ルートレベルでのファイル転送ができない",
    nonBinaryFolderShare: "すべてのバイナリファイルの転送に成功",
    nonBinaryFileShare: "バイナリ以外のファイル転送は不可",
    loginFailed: "ログインに失敗しました。認証情報を確認してください。",
    refreshFailed: "フォルダ構造の更新に失敗しました",
    toMachineNotLoggedIn: "対象マシンにログインしていません。"
  }
};