import { toast, Zoom } from "react-toastify";
import i18n from "i18next";

export default class Utils {

  static monthNames = [
    "jan",
    "feb",
    "march",
    "april",
    "may",
    "june",
    "july",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  static getDate(date) {
    const now = date !== undefined ? new Date(date) : new Date();
    const day = Math.floor(
      (now - new Date(now.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
    );
    let returnObj = {
      dayOfTheYear: date !== undefined ? day : day - 1,
      month: now.getMonth() + 1,
      year: now.getFullYear(),
      dayOfMonth: now.getDate(),
    };

    if (returnObj.dayOfTheYear === 0) {
      returnObj.month = 12;
      if ((returnObj.year - 1) % 4 === 0) {
        returnObj.dayOfTheYear = 366;
      } else {
        returnObj.dayOfTheYear = 365;
      }
    }
    // else{
    //     if(returnObj.dayOfMonth== 1 && returnObj.month > 1){
    //         returnObj.month = returnObj.month -1;
    //     }
    // }

    return returnObj;
  }

  static getMonthName(monthNumber) {
    const monthNames = [
      i18n.t("months.jan"),
      i18n.t("months.feb"),
      i18n.t("months.march"),
      i18n.t("months.april"),
      i18n.t("months.may"),
      i18n.t("months.june"),
      i18n.t("months.july"),
      i18n.t("months.aug"),
      i18n.t("months.sep"),
      i18n.t("months.oct"),
      i18n.t("months.nov"),
      i18n.t("months.dec"),
    ];

    return monthNames[monthNumber - 1];
  }

/**
 * 
 * Using this function for monthNames
 * return 
 */
  static getMonthNames(monthNumber) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',      
      'May',
      'June',
      'July',
      'August',      
      'September',
      'October',
      'November',
      'December'
    ];
    return monthNames[monthNumber - 1];
  }


  static getMonthNumber(monthName) {
    const monthNames = [
      i18n.t("months.jan"),
      i18n.t("months.feb"),
      i18n.t("months.march"),
      i18n.t("months.april"),
      i18n.t("months.may"),
      i18n.t("months.june"),
      i18n.t("months.july"),
      i18n.t("months.aug"),
      i18n.t("months.sep"),
      i18n.t("months.oct"),
      i18n.t("months.nov"),
      i18n.t("months.dec"),
    ];
    return monthNames.indexOf(monthName) + 1;
  }

  static getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  static getDataToPass = function () {
    const { dayOfTheYear, month, year, dayOfMonth } = Utils.getDate();
    let data = {};
    data.day = dayOfTheYear;
    data.month = month;
    data.year = year;
    data.dayOfMonth = dayOfMonth;

    let dataToPass = {};
    dataToPass.month = data.month;
    dataToPass.year = data.year;
    dataToPass.day = data.day;

    if (month === 1 && dayOfTheYear < 31) {
      if (dayOfMonth === 1) {
        dataToPass.month = 12;
        dataToPass.year = year - 1;
        let enddayofmonth = new Date(year, dataToPass.month, 0).getDate();
        dataToPass.day = enddayofmonth;
        //dataToPass.day=data.day;
      }
    } else {
      if (dayOfMonth === 1) {
        dataToPass.day = new Date(year, dataToPass.month, 0).getDate();
        dataToPass.month = data.month;
      }
      // }else{
      //     dataToPass.month=data.month+1;
      // }
    }

    return dataToPass;
  };

  static shiftTimimgs = (startTime, workHours) => {
    let endTime = parseFloat(startTime) + parseFloat(workHours) * 3600;
    let date = new Date();
    let hours = parseFloat(date.getHours()) * 3600;
    let minutes = parseFloat(date.getMinutes()) * 60;
    let seconds = parseFloat(date.getSeconds()) * 1;
    let currentTimeInSeconds = hours + minutes + seconds;
    let returnObj = { endTime: endTime, currentTime: currentTimeInSeconds };
    return returnObj;
  };

  static shiftValidations = (
    startTime,
    workHours,
    enableShift,
    enableOverTime
  ) => {
    if (enableShift == true && enableOverTime == false) {
      let endTime = parseFloat(startTime) + parseFloat(workHours) * 3600;
      let date = new Date();
      let hours = parseFloat(date.getHours()) * 3600;
      let minutes = parseFloat(date.getMinutes()) * 60;
      let seconds = parseFloat(date.getSeconds()) * 1;
      let currentTimeInSeconds = hours + minutes + seconds;
      let totalHoursInSeconds = 24 * 3600;
      if (endTime > totalHoursInSeconds) {
        endTime = endTime - totalHoursInSeconds;
      }

      if (startTime < endTime) {
        if (
          startTime < currentTimeInSeconds &&
          endTime > currentTimeInSeconds
        ) {
          return true;
        } else {
          return false;
        }
      }

      if (startTime > endTime) {
        if (
          (startTime < currentTimeInSeconds &&
            endTime < currentTimeInSeconds) ||
          (startTime > currentTimeInSeconds && endTime > currentTimeInSeconds)
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  };
}

export const getAvatarName = (name) => {
  if (name) {
    const splitStrings = name.split(" ");
    let avatarName = "";
    splitStrings.forEach((item) => {
      avatarName = avatarName + String(item).charAt(0);
    });
    return avatarName;
  }
};

export const getStringByMinLength = (title, length) => {
  if (title) {
    let str =
      title?.length > length ? `${title.substring(0, length)}...` : title;
    return str;
  }
};

export const toastErrorMessage = (collection) => {
  return toast(
    collection.onError.message
      ? collection.onError.message
      : collection.hasOwnProperty("error") && collection.error,
    {
      type: "error",
      theme: "colored",
      autoClose: 2000,
      position: "bottom-right",
      transition: Zoom,
    }
  );
};

export const toastMessage = (msg, type) => {
  return toast(msg, {
    type: type,
    theme: "colored",
    autoClose: 2000,
    position: "bottom-right",
    transition: Zoom,
  });
};

export const parseMillisecondsIntoReadableTime = (milliseconds) => {
  //Get hours from milliseconds
  var hours = milliseconds / (1000 * 60 * 60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

  //Get remainder from hours and convert to minutes
  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  var seconds = (minutes - absoluteMinutes) * 60;
  var absoluteSeconds = Math.floor(seconds);
  var s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;

  var time = h + ":" + m + ":" + s;

  return time;
};

export const defaultRoutes = [
  "/Settings",
  "/Profile",
  "/Faq",
  "/Task",
  "/Collaboration/settings",
];

export const loginRoutes = ["/twofactorauth", "/qrCode", "/resetmfa"];
