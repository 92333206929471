import {
  SET_RESETPASSWORD_SUCCESS,
  SET_PASSWORD_ROTATION_ERROR,
} from "../constants/passwordRotation";

const initialState = {
  isLoading: false,
  onError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RESETPASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        resetPassword: action.success,
      });
    }

    case SET_PASSWORD_ROTATION_ERROR: {
      return Object.assign({}, state, {
        onError: action.error,
      });
    }

    default:
      return { ...state };
  }
};
