import { 
	SET_TIMESHEET_CREATE_SUCCESS,
	SET_TIMESHEET_READ_SUCCESS,
	SET_TIMESHEET_UPDATE_SUCCESS,
    SET_TIMESHEET_SUBMIT_SUCCESS,
	SET_TIMESHEET_APPROVE_SUCCESS, 
	SET_TIMESHEET_REJECT_SUCCESS,
    SET_TIMESHEET_USERS_APPROVE_SUCCESS,
    SET_TIMESHEET_USERS_REJECT_SUCCESS,
	SET_TEAM_TIMESHEET_SUCCESS,
	SET_USER_TIMESHEET_SUCCESS,
    SET_MULTI_LEVEL_TIMESHEET_SUCCESS,
	SET_TIMESHEET_ERROR
} from '../constants/timesheet';

const initialState = {
    timeSheets: [],
    timeSheet: {},
    timeSheetUpdate: {},
    timeSheetSubmit: {},
    timeSheetApprove: {},
    timeSheetReject: {},
    usersTimeSheetApprove: {},
    usersTimeSheetReject: {},
    teamTimeSheets: [],
    userTimeSheets: [],
    multilevelTimeSheets : [],
    timeSheetStatus: {},
    onError: null
    
};

export default (state = initialState, action) => {
    switch(action.type) {

        case SET_TIMESHEET_CREATE_SUCCESS: {
            return Object.assign({}, state, {
				timeSheet: action.success
			});
        }        

        case SET_TIMESHEET_READ_SUCCESS: {
            return Object.assign({}, state, {
				timeSheets: action.success
			});
        }

        case SET_TIMESHEET_SUBMIT_SUCCESS: {
            return Object.assign({}, state, {
				timeSheetSubmit: action.success
			});
        }

        case SET_TIMESHEET_UPDATE_SUCCESS: {
            return Object.assign({}, state, {
				timeSheetUpdate: action.success
			});
        }

        case SET_TIMESHEET_APPROVE_SUCCESS: {
            return Object.assign({}, state, {
				timeSheetApprove: action.success
			});
        }

        case SET_TIMESHEET_REJECT_SUCCESS: {
            return Object.assign({}, state, {
				timeSheetReject: action.success
			});
        }

        case SET_TIMESHEET_USERS_APPROVE_SUCCESS: {
            return Object.assign({}, state, {
				usersTimeSheetApprove: action.success
			});
        }

        case SET_TIMESHEET_USERS_REJECT_SUCCESS: {
            return Object.assign({}, state, {
				usersTimeSheetReject: action.success
			});
        }

        case SET_TEAM_TIMESHEET_SUCCESS: {
            return Object.assign({}, state, {
				teamTimeSheets: action.success
			});
        }

        case SET_USER_TIMESHEET_SUCCESS: {
            return Object.assign({}, state, {
				userTimeSheets: action.success
			});
        }

        case SET_MULTI_LEVEL_TIMESHEET_SUCCESS: {
            return Object.assign({},state , {
                multilevelTimeSheets: action.success
            });
        }

        case SET_TIMESHEET_ERROR: {
            return Object.assign({}, state, {
				onError: action.error
			});
        }

        default:
            return {...state}
    }
}