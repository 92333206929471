import { SET_NOTIFICATION } from '../constants/workspace.js';
import { SET_SOCKET_CONNECTION_SUCCESS,SET_SOCKET_CONNECTION_ERROR } from '../constants/collabration.js';

const intialState = {
    isNotification : false,
    socketStatus:'',
    socketError:''
}

export default (state = intialState , action) => {
    switch(action.type){
        case SET_NOTIFICATION : {
            return Object.assign({} , state , {
                isNotification : action.payload
            });
        }

        case SET_SOCKET_CONNECTION_SUCCESS: {
            return Object.assign({}, state, {
                socketStatus: action.payload
            });
        }

        case SET_SOCKET_CONNECTION_ERROR: {
            return Object.assign({}, state, {
                socketError: action.payload
            });
        }

        default : 
           return {...state}
    }
}