import {
    GET_ORG_LICENSE_SUCCESS, 
    GET_ORG_MODULES_LICENSE_SUCCESS,
    SET_ORG_LICENSE_CREATE_SUCCESS , 
    SET_ORG_LICENSE_UPDATE_SUCCESS,
    SET_ORG_LICENSE_MODULE_ACTIVATE_DEACTIVATE_SUCCESS,
    SET_ORG_LICENSE_ADD_MODULES_SUCCESS,
    SET_ORG_LICENSE_ACTIVATE_SUCCESS,
    SET_ORG_LICENSE_DEACTIVATE_SUCCESS,
    SET_ORG_LICENSE_BY_ID_SUCCESS, 
    SET_ORG_LICENSE_ERROR,
} from '../constants/license';

const initialState = {
    orgLicenseList : {},
    orgLicenseUpdate: '',                 
    orgLicenseCreate: {},
    orgLicenseModuleActivate : {},
    addOrgLicenseModules: {},
    orgLicenseActivate : {},
    orgLicenseDeactivate : {},
    orgModulesList : [],
    orgLicenseById : {},              
    onError: null,
}

export default (state = initialState , action) => {
    switch(action.type) {
        case GET_ORG_LICENSE_SUCCESS : {
            return Object.assign({} , state , {
                orgLicenseList: action.success ,
            });
        }


        case GET_ORG_MODULES_LICENSE_SUCCESS : {
            return Object.assign({} , state , {
                orgModulesList: action.success ,
            });
        }

        case SET_ORG_LICENSE_CREATE_SUCCESS : {
            return Object.assign({} , state , {
                orgLicenseCreate: action.success,
            });
        }

        case SET_ORG_LICENSE_UPDATE_SUCCESS : {
            return Object.assign({} , state , {
                orgLicenseUpdate : action.success,
            });
        }

        case SET_ORG_LICENSE_MODULE_ACTIVATE_DEACTIVATE_SUCCESS : {
            return Object.assign({} , state , {
                orgLicenseModuleActivateDeactivate : action.success,
            });
        }

        case SET_ORG_LICENSE_ADD_MODULES_SUCCESS : {
            return Object.assign({} , state , {
                addOrgLicenseModules : action.success,
            });
        }

        case SET_ORG_LICENSE_ACTIVATE_SUCCESS : {
            return Object.assign({} , state , {
                orgLicenseActivate : action.success,
            });
        }

        case SET_ORG_LICENSE_DEACTIVATE_SUCCESS : {
            return Object.assign({} , state , {
                orgLicenseDeactivate : action.success,
            });
        }

        case SET_ORG_LICENSE_BY_ID_SUCCESS : {
            return Object.assign({} , state , {
                orgLicenseById : action.success, 
            });
        }

        case SET_ORG_LICENSE_ERROR : {
            return Object.assign({} , state , {
                onError : action.error,
            })
        }

        default:
        return { ...state };
    }

}