import {
  SET_BROWSER_LIST_CHANNEL_SUCCESS,
  SET_CHANNEL_ERROR,
  SET_CHANNEL_SUBSCRIBERS_SUCCESS,
  SET_CREATE_CHANNEL_SUCCESS,
  SET_CREATE_GROUP_SUCCESS,
  SET_CREATE_WORKSPACE_SUCCESS,
  SET_DELETE_CHANNEL_SUCCESS,
  SET_DELETE_WORKSPACE_SUCCESS,
  SET_LIST_CHANNEL_SUCCESS,
  SET_LIST_WORKSPACE_SUCCESS,
  SET_SOCKET_CONNECTION_SUCCESS,
  SET_UPDATE_CHANNEL_SUCCESS,
  SET_UPDATE_WORKSPACE_SUCCESS,
  SET_WORKSPACE_ERROR,
  SET_SOCKET_CONNECTION_ERROR,
  SET_WORKSPACE_SUBSCRIBERS_SUCCESS,
  SET_SUBSCRIBE_USER_TO_PUBLIC_CHANNEL_SUCCESS,
  SET_UNSUBSCRIBE_USER_TO_CHANNEL_SUCCESS,
  SET_SELECTED_WORKSPACE_SUCCESS,
  SET_COLLABORATION_LOADING_SUCCESS,
  SET_SELECTED_CHAT_SUCCESS,
  SET_SELECTED_CHANNEL_SUCCESS,
  SET_CHAT_HISTORY_SUCCESS,
  SET_NEW_MESSAGE_SUCCESS,
} from "../constants/collabration";
import { filterWorkspaceChannel } from "../utils/collaboration";

const initialState = {
  isSocketSetup: "NOT_CONNECTED",
  onError: null,
  isCollaborationLoading: true,
  workSpaceData: {
    workSpaces: [],
    subscribers: [],
  },
  channelsData: {
    channelList: [],
    subscribedChannels: [],
    channelBrowserList: [],
  },
  selectedWorkspaceData: {
    workspace: {},
    channel: [
      {
        roomId: "",
        roomType: "",
        workspaceId: "",
        userSubscription: {},
        roomName: "",
        isSocketSubscribed: false,
      },
    ],
    subscribers: [],
  },
  selectedChannel: {},
  selectedRoomChats: [],
  newMessage: {
    isNewComingMessage: false,
    message: {},
  },
};

export default (state = initialState, action) => {
  //no need of this
  // state = {
  //   ...state,
  //   isSocketSetup: state.isSocketSetup,
  //   isCollaborationLoading: state.isCollaborationLoading,
  //   workSpaceData: {
  //     ...state.workSpaceData,
  //   },
  //   channelsData: {
  //     ...state.channelsData,
  //   },
  //   selectedWorkspaceData: {
  //     ...state.selectedWorkspaceData,
  //   },
  //   selectedChannel: { ...state.selectedChannel },
  //   selectedRoomChats: [...state.selectedRoomChats],
  //   newMessage: {
  //     ...state.newMessage,
  //   },
  // };

  switch (action.type) {
    case SET_SOCKET_CONNECTION_SUCCESS: {
      return {
        ...state,
        isSocketSetup: action.payload,
      };
    }

    case SET_COLLABORATION_LOADING_SUCCESS: {
      return {
        ...state,
        isCollaborationLoading: action.payload,
      };
    }

    case SET_LIST_WORKSPACE_SUCCESS: {
      return {
        ...state,
        workSpaceData: { ...state.workSpaceData, workSpaces: action.payload },
      };
    }

    case SET_CREATE_WORKSPACE_SUCCESS: {
      state.workSpaceData.selectedWorkspace = action.payload;
      const filteredChannels = filterWorkspaceChannel(
        action.payload?.workSpaceId,
        state?.channelsData?.subscribedChannels
      );
      state.selectedWorkspaceData = {
        workspace: action.payload,
        channels: filteredChannels,
        subscribers: [],
      };
      return {
        ...state,
        workSpaceData: {
          ...state.workSpaceData,
          workSpacesCreate: action.payload,
          workSpaces: [...state.workSpaceData?.workSpaces, action.payload],
        },
      };
    }

    case SET_UPDATE_WORKSPACE_SUCCESS: {
      const index = state.workSpaceData?.workSpaces?.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.workSpaceData.workSpaces[index] = action.payload;
      }
      return {
        ...state,
        workSpaceData: {
          ...state.workSpaceData,
          workSpaceUpdate: action.payload,
          workSpaces: state.workSpaceData.workSpaces,
        },
      };
    }

    case SET_DELETE_WORKSPACE_SUCCESS: {
      return {
        ...state,
        workspaceDelete: action.payload,
      };
    }

    case SET_WORKSPACE_SUBSCRIBERS_SUCCESS: {
      if (!Object.keys(state.selectedChannel).length) {
        state.selectedChannel = action.payload[0];
      }
      return {
        ...state,
        selectedWorkspaceData: {
          ...state.selectedWorkspaceData,
          subscribers: action.payload,
        },
      };
    }

    case SET_LIST_CHANNEL_SUCCESS: {
      return {
        ...state,
        channelsData: { ...state.channelsData, channelList: action.payload },
      };
    }

    case SET_SUBSCRIBE_USER_TO_PUBLIC_CHANNEL_SUCCESS: {
      let index = state.channelsData.channelBrowserList.findIndex(
        (e) =>
          e.workSpaceId === action.payload.workSpaceId &&
          e.id === action.payload.roomId
      );
      if (index !== -1) {
        let joinChannelData = {
          roomId: state?.channelsData?.channelBrowserList[index]?.id,
          roomType: state?.channelsData?.channelBrowserList[index]?.roomType,
          workspaceId:
            state?.channelsData?.channelBrowserList[index]?.workSpaceId,
          userSubscription:
            state?.channelsData?.channelBrowserList[index]?.userSubscription,
          userSubscriptionList:
            state?.channelsData?.channelBrowserList[index]
              ?.userSubscriptionList,
          roomName: state?.channelsData?.channelBrowserList[index]?.name,
        };
        state.selectedWorkspaceData.channels.push(joinChannelData);
        state?.channelsData.subscribedChannels.push(joinChannelData);
        state.selectedChannel = joinChannelData;
      }
      return {
        ...state,
      };
    }

    case SET_UNSUBSCRIBE_USER_TO_CHANNEL_SUCCESS: {
      let updatedchannelList = state?.selectedWorkspaceData?.channels.filter(
        (e) =>
          e.workSpaceId !== action.payload.workSpaceId &&
          e.roomId !== action.payload.roomId
      );

      let updateSubscribedChannels =
        state?.channelsData?.subscribedChannels.filter(
          (e) =>
            e.workSpaceId !== action.payload.workSpaceId &&
            e.roomId !== action.payload.roomId
        );
      if (
        state.selectedChannel?.workspaceId === action.payload?.workSpaceId &&
        state.selectedChannel?.roomId === action.payload?.roomId &&
        updatedchannelList?.length
      ) {
        state.selectedChannel = updatedchannelList[0];
      } else {
        let index = state?.selectedWorkspaceData?.subscribers?.findIndex(
          (e) => e?.userSubscription?.userId === action?.payload?.userId
        );
        if (index !== -1) {
          state.selectedChannel =
            state?.selectedWorkspaceData?.subscribers[index];
        }
      }
      return {
        ...state,
        channelsData: {
          ...state.channelsData,
          subscribedChannels: updateSubscribedChannels,
        },
        selectedWorkspaceData: {
          ...state.selectedWorkspaceData,
          channels: updatedchannelList,
        },
      };
    }

    case SET_BROWSER_LIST_CHANNEL_SUCCESS: {
      const channelBrowserListData = action.payload.filter((object1) => {
        if (
          object1?.roomType === "CHANNEL" ||
          state?.selectedWorkspaceData?.channels.findIndex(
            (object2) =>
              object2?.id === object1?.roomId && object2?.roomType === "GROUP"
          ) !== -1
        ) {
          return object1;
        }
      });
      return {
        ...state,
        channelsData: {
          ...state.channelsData,
          channelBrowserList: channelBrowserListData,
        },
      };
    }

    case SET_CREATE_CHANNEL_SUCCESS: {
      state.selectedWorkspaceData.channels.push(action.payload);
      state.channelsData.subscribedChannels.push(action.payload);
      return {
        ...state,
        selectedChannel: action.payload,
      };
    }

    case SET_UPDATE_CHANNEL_SUCCESS: {
      const index = state.channelsData?.channelList?.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index !== -1) {
        state.channelsData.channelList[index] = action.payload;
      }
      return {
        ...state,
        channelsData: {
          ...state.channelsData,
          channelUpdate: action.payload,
          channelList: state.channelsData.channelList,
        },
      };
    }

    case SET_DELETE_CHANNEL_SUCCESS: {
      let updatedchannelList = state?.selectedWorkspaceData?.channels?.filter(
        (item) =>
          item.roomId !== action.payload.roomId &&
          item.roomName !== action.payload.roomName &&
          item.workSpaceId !== action.payload.workSpaceId
      );
      let updateSubscribedChannels =
        state.channelsData.subscribedChannels?.filter(
          (item) =>
            item.roomId !== action.payload.roomId &&
            item.roomName !== action.payload.roomName &&
            item.workSpaceId !== action.payload.workSpaceId
        );
      return {
        ...state,
        channelsData: {
          ...state.channelsData,
          subscribedChannels: updateSubscribedChannels,
        },
        selectedWorkspaceData: {
          ...state.selectedWorkspaceData,
          channels: updatedchannelList,
        },
      };
    }

    case SET_CREATE_GROUP_SUCCESS: {
      state.selectedWorkspaceData.channels.push(action.payload);
      state.channelsData.subscribedChannels.push(action.payload);
      return {
        ...state,
      };
    }

    case SET_CHANNEL_SUBSCRIBERS_SUCCESS: {
      return {
        ...state,
        channelsData: {
          ...state.channelsData,
          subscribedChannels: action.payload,
        },
      };
    }

    //selected Data
    case SET_SELECTED_WORKSPACE_SUCCESS: {
      const filteredChannels = filterWorkspaceChannel(
        action?.payload?.workSpaceId,
        state?.channelsData?.subscribedChannels
      );
      const data = {
        workspace: action.payload,
        channels: filteredChannels,
        subscribers: [],
      };
      let selectedChatData = {};
      const lastSelectedChannel = JSON.parse(
        window.localStorage.getItem("lastSelectedChannel")
      );
      if (
        lastSelectedChannel &&
        action?.payload?.workSpaceId === lastSelectedChannel.workspaceId
      ) {
        selectedChatData = JSON.parse(
          window.localStorage.getItem("lastSelectedChannel")
        );
      } else {
        selectedChatData =
          action?.payload?.workSpaceId !== state?.selectedChannel?.workspaceId
            ? filteredChannels?.length > 0
              ? filteredChannels[0]
              : {}
            : state?.selectedChannel;
      }
      window.localStorage.setItem(
        "lastSelectedWorkspace",
        JSON.stringify(action.payload)
      );
      window.localStorage.setItem(
        "lastSelectedChannel",
        JSON.stringify(selectedChatData)
      );
      return {
        ...state,
        selectedWorkspaceData: data,
        selectedChannel: selectedChatData,
      };
    }

    case SET_SELECTED_CHANNEL_SUCCESS: {
      window.localStorage.setItem(
        "lastSelectedChannel",
        JSON.stringify(action.payload)
      );
      return { ...state, selectedChannel: action.payload };
    }

    case SET_CHAT_HISTORY_SUCCESS: {
      return { ...state, selectedRoomChats: action.payload };
    }

    case SET_NEW_MESSAGE_SUCCESS: {
      return { ...state, newMessage: action.payload };
    }

    // case SET_SELECTED_CHAT_SUCCESS: {
    //   let index = state?.selectedWorkspaceData?.channels.findIndex(
    //     (e) => e?.roomId === action?.payload?.roomId
    //   );
    //   let isSelected = state?.selectedWorkspaceData?.channels.findIndex(
    //     (e) => e?.selected === true
    //   );
    //   if (isSelected !== -1) {
    //     state.selectedWorkspaceData.channels[isSelected].selected = false;
    //   }
    //   if (index !== -1) {
    //     state.selectedWorkspaceData.channels[index].selected = true;
    //   }
    //   return {
    //     ...state,
    //   };
    // }

    case SET_CHANNEL_ERROR: {
      return { ...state, onError: action.error };
    }

    case SET_WORKSPACE_ERROR: {
      return { ...state, onError: action.error };
    }

    case SET_SOCKET_CONNECTION_ERROR : {
      return {...state , onError : action.error , isCollaborationLoading : false};
    }

    default: {
      return { ...state };
    }
  }
};
