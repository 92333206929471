import {
  SET_CHANGE_PASSWORD_SUCCESS,
  SET_CHANGE_PASSWORD_ERROR
} from "../constants/changepassword";

const initialState = {
  changedata: {},
  onError: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CHANGE_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        changedata: action.success,
      });
    }

    case SET_CHANGE_PASSWORD_ERROR: {
      return Object.assign({}, state, {
        onError: action.error,
      });
    }

    default:
      return { ...state };
  }
};
