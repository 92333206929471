import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactApexChart from "react-apexcharts";
import { convertToDateFromDayOftheYear } from '../../utils/dateFormatUtils';
import { withTranslation } from 'react-i18next';
import i18n from '../../lang/i18n';

class Vaichart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 300,
                    stacked: true,
                    id:'appsummary',
                    toolbar: {
                        show: true
                    },
                },
                states: {
                    hover: {
                        filter: {
                            type: 'dark'
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        columnWidth:"50%",
                        width: 50,
                        horizontal: false,
                        colors: {
                            backgroundBarColors: new Array(this.props.data?.series[0]?.data?.length).fill(
                              "#4E9CEA45"
                            ),
                            backgroundBarOpacity: 1,
                            backgroundBarRadius: 2,
                        },
                    },
                },
                // fill: {
                //     colors: ['#8FC555', '#F8B912']
                // },
                yaxis: {
                    axisBorder: { show: true },
                    type: 'datetime',
                    title: {
                        text: i18n.t('Opsadmin.hours'),
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        // margin:10,
                        // padding:50,
                        // style: {
                        //     color: undefined,
                        //     fontSize: '12px',
                        //     fontFamily: 'Helvetica, Arial, sans-serif',
                        //     fontWeight: 600,
                        //     cssClass: 'apexcharts-yaxis-title',
                        // },
                    },
                },
                xaxis: {
                    type: 'category',
                    axisTicks: { show: false },
                    axisBorder: { show: true },
                    labels: { show: true,
                    rotate: -45,
                    // enabled: true,
                    rotateAlways: true,

                    },
                    categories: [],

                },
                states: {
                    active: {
                        filter: { type: 'none' }
                    }
                },

                legend: {
                    show:true,
                    position: 'bottom',
                    markers: {
                        radius: 0
                    },
                    onItemHover: {
                        highlightDataSeries: false
                    },
                    onItemClick: {
                        toggleDataSeries: false
                    },

                },
                grid: {
                    bottom: 20,
                    top: 0,
                    left: 5,
                    show: false,
                }
            },
            series: [

                // {
                //     name: "Chrome",
                //     data: ['10:00', '12:00', '09:00'],

                // },
                // {
                //     name: "Visual Studio Code",
                //     data: ['10:00', '12:00', '09:00']
                // },
                // {
                //     name: "Firefox",
                //     data: ['10:00', '12:00', '09:00']
                // }
            ],
            // labesls:["Chrome", "Visual Studio Code", "Firefox"]

        };
    }

    componentDidMount() {
        i18n.on("languageChanged", this.handleLanguageChange);
      }
     
      componentWillUnmount() {
        i18n.off("languageChanged", this.handleLanguageChange);
      }
     
      handleLanguageChange = () => {
        this.setState({
          options: {
            ...this.state.options,
            yaxis: {
              ...this.state.options.yaxis,
              title: {
                ...this.state.options.yaxis.title,
                text: i18n.t("Opsadmin.hours"),
              },
            },
          },
        });
      };
      
    render() {

        // options.xaxis.labels=this.props.data.userId
        const productivity = Array.isArray(this.props.productivity) ? this.props.productivity : [];

        const options = JSON.parse(JSON.stringify(this.state.options));


        const seriesData = {
            // chrome: {
            //     name: "chrome",
            //     data: []
            // },
            // vscode:  {
            //     name: "vscode",
            //     data: []
            // },,
        }
        // const lables = []

        // productivity.forEach(day => {
        //     if (Array.isArray(day.toolProductivityList)) {
        //         lables.push(convertToDateFromDayOftheYear(day.dayOfTheYear));
        //         day.toolProductivityList.forEach(tool => {
        //             const appHours = parseFloat((tool.appHours / (1000 * 60 * 60)).toFixed(2))
        //             if (seriesData.hasOwnProperty(tool.toolName)) {
        //                 seriesData[tool.toolName].data.push(appHours)

        //             } else {
        //                 seriesData[tool.toolName] = { name: tool.toolName, data: [appHours] }
        //             }
        //         })
        //     }

        // })
        // const categories = Object.keys(seriesData)
        //  options.xaxis.categories = categories;
        // console.log("this.props.data", this.props.data)
        options.xaxis.categories = this.props.data? this.props.data.labels:[];
        options.plotOptions.bar.colors.backgroundBarColors = new Array(this.props.data?.series[0]?.data?.length).fill(
            "#4E9CEA45"
          );
        // const series = categories.map(key => seriesData[key])
        const series = this.props.data?this.props.data.series:[];
        // const series = [
        //     {
        //         data
        //     },
        // ];
        // const series = [
        //     {
        //         name: "appHours",
        //         data
        //     },
        //     // {
        //     //     name: "thinkTime",
        //     //     data: thinkHrs.slice(0,4)
        //     // },
        //     // {
        //     //     name: "overTime",
        //     //     data: overtimeHrs.slice(0,4)
        //     // },
        //     // {
        //     //     name: "effortHours",
        //     //     data: [1000, 2000, 4000, 6000, 2000]
        //     // }
        // ]

        // console.log("vaichart  this.props.productivity  ",series, productivity, this.props.productivity );
        return (
            <div >
{/*                 
<div className="Barchart_scrollbox Barchart_scrollbox_delayed">
                  <div className="Barchart_scrollbox-content p-1"> */}
                <div className="row mb-0 mx-0">
                  <div className="col-12 text-center overflow-scroll ">
                  {series.length > 0 && series[0].data.some((t) => t!=0) ? 
                  
                    <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height="416px"
                    className="mb-1"
                />:
                <div className='text-center  '>
                        <img src="../../assets/images/nodata.svg" className="nodata-img "  />
                        <h6 className="text-muted mt-3 font-size-14">{i18n.t('productivity.no_data')}</h6>
                    </div>
                }
                  
                      </div>
                      </div>
                      {/* </div>
                      </div> */}
          
            </div>
        );
    }
}
/**
 * Map the state to props. 
 */
const mapStateToProps = function (state) {
    return {
        // data11: state.employeeDashboard.data,
        // onError: state.employeeDashboard.onError,
        // isLoading: state.employeeDashboard.isLoading,
        // dayProductivity: state.productivity.data,
        productivity: state.productivity.productivity,
    }
};
export default connect(mapStateToProps)(withTranslation()(Vaichart));
