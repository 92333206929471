import React, { Component } from "react";
import { Link } from "react-router-dom";
import OverviewChart from "../../Charts/OverviewChart";
import Opsonboard from "../../Charts/opsonboard";
import Teamprogress from "../../Charts/Teamprogress";
import { Tab, Tabs } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getEmpDashboardData } from "../../../actions/employeeDashboard";
import $ from "jquery";
import defaultIcon from "../../../assets/images/ic-default-user.jpg";
import moment from "moment";
import Utils from "../../../utils/utils";
import { ToastContainer, toast, Zoom } from "react-toastify";
import i18n from "i18next";
import LabelTab from "../../common/Label";
import _ from "lodash";
import { getemployeeDashboardService } from '../../../services/employeeDashboard ';
import ScaleLoaderSpinner from "../../Shared/ScaleLoader";
import NoData from "../../Shared/NoData"

const minOffset = 0;
const maxOffset = 60;
class OpaDashboard extends Component {
  constructor(props) {
    super(props);
    const thisYear = new Date().getFullYear();
    const thisMonth = new Date().toLocaleString("default", { month: "long" });
    this.state = {
      date: "",
      thisYear: thisYear,
      selectedYear: thisYear,
      selectedMonth: thisMonth,
      selectedYearGraph: thisYear,
      selectedMonthGraph: thisMonth,
      isConnecting: false,
      selectedSeries: "",

      employeeList: [],
      monYearWiseEmpList: [],
      activeEmpList: [],
      inactiveEmpList: [],
      departmentsList: [],
      empListGraph: [],
      options: [],
      monthNameList: Utils.monthNames,
      monthList: [],
      yearMonth: thisYear + "-" + thisMonth,
      yearMonthGraph: thisYear + "-" + thisMonth,
      yearGraph: thisYear,
      setOptions: true,
      setEmployeeDetails: true,
      initialRender: true,
    };
  }

  componentDidMount() {
    this.setState({ isConnecting: true });
    this.fetchEmployeeData()
  }

  componentDidUpdate(prevProps, prevState) {

    if (
      this.state.selectedYearGraph !== prevState.selectedYearGraph &&
      this.state.selectedSeries?.length
    ) {
      this.setState({ selectedSeries: "" });
    }

    if (prevState.selectedYear !== this.state.selectedYear || prevState.selectedMonth !== this.state.selectedMonth) {
      const yearMonth = this.state.selectedYear + "-" + this.state.selectedMonth;
      this.setState({
        yearMonth,
        setEmployeeDetails: true
      })
    }

    if (prevState.selectedYearGraph !== this.state.selectedYearGraph || prevState.selectedMonthGraph !== this.state.selectedMonthGraph) {
      const yearMonthGraph =
        this.state.selectedYearGraph + "-" + this.state.selectedMonthGraph;
      this.setState({
        yearMonthGraph,
        setEmployeeDetails: true
      })
    }

    if (prevState.selectedYearGraph !== this.state.selectedYearGraph) {
      const yearGraph = this.state.selectedYearGraph;;
      this.setState({
        yearGraph,
        setEmployeeDetails: true
      })
    }

    if (this.state.setOptions) {
      const options = [];
      const thisYear = new Date().getFullYear()
      for (let i = minOffset; i <= maxOffset; i++) {
        const year = thisYear - i;
        options.push(<option value={year}>{year}</option>);
      }
      this.setState({
        options,
        setOptions: false
      })
    }

    if (this.state.setEmployeeDetails && !_.isEmpty(this.state.employeeList)) {
      const { employeeList, yearMonth, yearGraph, yearMonthGraph } = this.state;
      const { monYearWiseEmpList, empListGraph, activeEmpList, inactiveEmpList } = this.employeeDetails(employeeList, yearMonth, yearGraph, yearMonthGraph)
      this.setState({
        monYearWiseEmpList,
        empListGraph,
        activeEmpList,
        inactiveEmpList,
        setEmployeeDetails: false
      })
    }
  }

  monthsList = () => {
    const monthList = [];
    this.state.monthNameList.map((monthNameObj) => {
      monthList.push(<option value={this.props.t("months." + monthNameObj)}>{this.props.t("months." + monthNameObj)}</option>);
    });
    return monthList;
  }


  /**
   * Fetches employee data and updates the component's state with the result.
   * @function
   * @returns {void}
   */

  fetchEmployeeData = () => {
    getemployeeDashboardService({}, (respData) => {

      // if empty response is returned
      if (_.isEmpty(respData)) {
        return
      }

      //if data is returned in response
      const employeeList = respData.userDetails
      this.setState({
        employeeList,
        isConnecting: false
      })
    }, (error) => {
      toast(error.message, {
        type: "error",
        theme: "colored",
        autoClose: 2000,
        position: "bottom-right",
        transition: Zoom,
      });
      this.setState({ isConnecting: false });
    });
  }

  handleDate = (event) => {
    this.setState({
      date: event.target.value,
      setEmployeeDetails: true
    });
  };

  //Table data
  handleYear = (event) => {
    this.setState({ selectedYear: event.target.value });
  };

  handleMonth = (event) => {
    this.setState({ selectedMonth: event.target.value });
  };

  //Graph data
  handleMonthGraph = (event) => {
    this.setState({ selectedMonthGraph: event.target.value });
  };

  handleYearGraph = (event) => {
    this.setState({ selectedYearGraph: event.target.value });
  };

  handleMonthSeries = (data, month) => {
    this.setState({ selectedMonthGraph: month, selectedSeries: data });
  };

  employeeDetails = (employeeList, yearMonth, yearGraph, yearMonthGraph) => {
    const monYearWiseEmpList = [];
    const activeEmpList = [];
    const inactiveEmpList = [];
    const empListGraph = [];
    employeeList.map((empObj) => {
      const date = new Date(empObj?.createdDate)
      const month = date.getMonth()
      const year = date.getFullYear()
      const monthName = Utils.getMonthName(month + 1)
      const objYearMonth = this.state.initialRender ? moment(empObj?.createdDate).format("YYYY-MMMM") : `${year}-${monthName}`
      if (objYearMonth === yearMonth) {
        monYearWiseEmpList.push(empObj);
      }

      if (
        this.state.selectedSeries === ""
          ? moment(empObj.createdDate).format("YYYY") === String(yearGraph)
          : objYearMonth === yearMonthGraph
      ) {
        empListGraph.push(empObj);
      }
      //Data for tabs
      if (this.state.date === "") {
        empObj.active
          ? activeEmpList.push(empObj)
          : inactiveEmpList.push(empObj);
      } else if (
        moment(empObj.createdDate).format("YYYY-MM-DD") === this.state.date
      ) {
        empObj.active
          ? activeEmpList.push(empObj)
          : inactiveEmpList.push(empObj);
      }
    });

    this.setState({ initialRender: false })
    return {
      monYearWiseEmpList,
      empListGraph,
      activeEmpList,
      inactiveEmpList
    }
  }

  render() {
    const { t } = this.props;
    const { thisYear, selectedYear, monthList, yearMonth, yearGraph, yearMonthGraph, options, employeeList, monYearWiseEmpList, empListGraph, activeEmpList, inactiveEmpList } = this.state;
    const tableHeader = ['employee_name', 'join_date', 'status'];

    return (
      <div className="container-fluid mt-2 px-0">
        <ToastContainer />
        {this.state.isConnecting && <ScaleLoaderSpinner />}
        <div className="row clearfix mx-0">
          <div className="col-12 col-md-8">
            <div className="row mx-0">
               <div className="card mb-0 p-1 ">
                  <div className="body p-0 Borderradius shadow opsbarchart-cardbody">
                    <div className="row mx-0 mt-4 d-flex justify-content-end bg-white ">
                      <select
                        name="Select Month"
                        className="form-control col-12 col-md-6 col-lg-2 mx-2 my-1 h30 opsdashboar-year-selection"
                        value={this.state.selectedMonthGraph}
                        onChange={this.handleMonthGraph}
                        hidden={this.state.selectedSeries ? false : true}
                      >
                        {this.monthsList()}
                      </select>
                      <select
                        name="Select Year"
                        className="form-control col-12 col-md-6 col-lg-2 mr-5 my-1 h30 opsdashboar-year-selection"
                        value={this.state.selectedYearGraph}
                        onChange={this.handleYearGraph}
                      >
                        {options}
                      </select>
                    </div>
                    <div className="ops_barchart" id="ops-barchart-bar-align">
                      <Opsonboard
                        empList={empListGraph}
                        month={this.state.selectedMonthGraph}
                        year={this.state.selectedYearGraph}
                        handleMonthSeries={this.handleMonthSeries}
                        selectedSeries={this.state.selectedSeries}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 p-1 ">
                  <div className="row mx-0">
                    <div className="card mb-0">
                      <div className="body shadow p-3 Borderradius bg-white opsadmin-table  ">
                        <div className="row mx-0 d-flex justify-content-end ">
                           <select
                            name="TableData SelectMonth"
                            className="form-control col-12 col-md-6 col-lg-2 my-2 h30 cursor-pointer"
                            value={this.state.selectedMonth}
                            onChange={this.handleMonth}
                          >
                            {this.monthsList()}
                          </select>
                          <select
                            name="TableData SelectYear"
                            className="form-control col-12 col-md-6 col-lg-2 mx-2 my-2 h30 "
                            value={this.state.selectedYear}
                            onChange={this.handleYear}
                          >
                            {options}
                          </select>
                        </div>
                    <div className="table-responsive table-container mt-2 ops_admin_Table ">
                      <table className="table table-hover table-custom spacing5 dashboard-table ">
                            <thead className="G-Bold">
                              <tr>
                                {tableHeader.map((label) => (
                                  <th key={label} className="text-left">{t(`Opsadmin.${label}`)}</th>
                                ))}
                              </tr>
                        </thead>
                        <tbody>
                              {monYearWiseEmpList.length
                                ? monYearWiseEmpList.map((empObj) => {
                                  return (
                                    <tr>
                                      <td className="text-capitalize">
                                        {empObj?.firstName + " " +
                                          empObj?.lastName?.toLowerCase()}
                                      </td>
                                      <td>
                                        {moment(
                                          empObj?.createdDate
                                        ).format("YYYY-MM-DD")}
                                      </td>
                                      {empObj.active ? (
                                        <td>
                                          <LabelTab
                                            type="warning"
                                            text={t("Sysadmin.active")}
                                          ></LabelTab>
                                        </td>
                                      ) : (
                                        <td>
                                          <LabelTab
                                            type="secondary"
                                            text={t("Sysadmin.in_active")}
                                          ></LabelTab>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })
                                : <tr><td colspan="4" className="text-center"><NoData/></td></tr>
                                }
                            </tbody>
                      </table>
                    </div>
                  </div>
                    </div>
                  </div>
                </div>
              </div>
            <div className="col-12 col-md-4 mb-2 p-1">
            <div className="card mb-0" style={{ height: "100%" }}>
            <div className="body p-0 shadow1 Borderradius opa_Organization p-2 bg-white ">
                <div className="row mx-0 mt-3">
                  <div className="col-6 pr-1">
                    <div className="text-center py-3  shadow1 Borderradius">
                      <img
                        src="../../assets/images/Opa_admin/opa_users.svg"
                        className="w60"
                      />
                      <h5 className="mb-0 mt-2">{activeEmpList.length}</h5>
                      <p className="text-muted font-size-14">
                        {t("Opsadmin.onboard")}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 pl-2 ">
                    <div className="text-center py-3  shadow1 Borderradius">
                      <img
                        src="../../assets/images/Opa_admin/opa_users.svg"
                        className="w60"
                      />
                      <h5 className="mb-0 mt-2">{inactiveEmpList.length}</h5>
                      <p className="text-muted font-size-14">
                        {t("Opsadmin.termination")}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mx-0 mt-3 pt-2">
                  <div className="col-md-6 col-12 pr-1">
                    <input
                      name="ActorInactUsers SelectDate"
                      autoComplete="off"
                      type="date"
                      className="form-control"
                      value={this.state.date}
                      max={moment().format("YYYY-MM-DD")}
                      onChange={this.handleDate}
                    />
                  </div>
                  <span className="col-md-6 col-12 mb-1 mt-1 text-center View_employe color_indgo">
                    <a className="">
                      {t("Opsadmin.view")}
                      <br />
                      {t("Opsadmin.employees")}
                    </a>
                  </span>
                </div>
     <div className="col-12 Ops_all_active mt-3">
                  <Tabs
                    defaultActiveKey="Active"
                    id="uncontrolled-tab-example"
                    className="mt-0 TimeTabs Ops_admin_Active pl-1 font-size-14"
                  >
                    <Tab
                      eventKey="Active"
                      title={t("Sysadmin.active")}
                      className="mt-0 pl-0 font-size-18 pb-3"
                      tabClassName="custom_tab"
                    >
                      <div class="scrollbox scrollbox_delayed pb-3 ">
                        <div class="scrollbox-content p-2 table-responsive">
                          <ul className="list-unstyled">
                            {activeEmpList.length
                              ? activeEmpList.map((activeEmpObj) => {
                                return (
                                  <li>
                                    <div className="media mb-0 d-justify-align border-bottom py-2 line-height-12 ">
                                    <div className="user-img mr-2" >
                                          <img 
                                            src={
                                              activeEmpObj.userIcon
                                                ? activeEmpObj.userIcon
                                                : defaultIcon
                                            }
                                          />
                                        </div>
                                        <div className="media-body text-dark text-capitalize">
                                          <h6
                                            className="name mr-1 mb-0 font-size-14 "
                                            title={(
                                              activeEmpObj?.firstName +
                                              " " +
                                              activeEmpObj?.lastName
                                            )?.toLowerCase()}
                                          >
                                            {(
                                              activeEmpObj?.firstName +
                                              " " +
                                              activeEmpObj?.lastName
                                            )?.toLowerCase()}
                                          </h6>
                                          <span className="font-size-12 text-capitalize ">
                                            {activeEmpObj.roles && activeEmpObj.roles.length > 0 ? activeEmpObj.roles[0].toLowerCase() : ''}
                                          </span>{" "}
                                        </div>
                                        <span className="statu_ative font-size-10">
                                        <img
                                          src="../../assets/images/Opa_admin/status_icon.png"
                                          className="mr-1"
                                        />
                                        {t("Sysadmin.active")}
                                      </span>
                                    </div>
                                  </li>
                                );
                              })
                              : <tr><td colspan="4" className="team-creatimage"><NoData/></td></tr>
                              }
                          </ul>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="InActive"
                      title={t("Sysadmin.in_active")}
                      className="mt-0 pl-0 font-size-18 pb-3"
                      tabClassName="custom_tab"
                    >
                      <div class="scrollbox scrollbox_delayed ">
                        <div class="scrollbox-content p-2 table-responsive">
                          <ul className="list-unstyled">
                            {inactiveEmpList.length
                              ? inactiveEmpList.map((inactiveEmpObj) => {
                                return (
                                  <li>
                                    <div className="media mb-0 d-justify-align border-bottom py-2 line-height-12">
                                      <div className="user-img mr-2">
                                          <img
                                            src={
                                              inactiveEmpObj.userIcon
                                                ? inactiveEmpObj.userIcon
                                                : defaultIcon
                                            }
                                          />
                                        </div>
                                        <div className="media-body text-dark">
                                          <h6
                                            className="name mr-1 mb-0 font-size-14 text-capitalize"
                                            title={(
                                              inactiveEmpObj?.firstName +
                                              " " +
                                              inactiveEmpObj?.lastName
                                            ).toLowerCase()}
                                          >
                                            {(
                                              inactiveEmpObj?.firstName +
                                              " " +
                                              inactiveEmpObj?.lastName
                                            ).toLowerCase()}
                                          </h6>
                                          <span className="font-size-12 text-capitalize ">
                                            {inactiveEmpObj?.roles[0].toLowerCase()}
                                          </span>{" "}
                                        </div>
                                       <span className="status_Inactive font-size-10">
                                        <img
                                          src="../../assets/images/Opa_admin/status_Inactive.png"
                                          className="mr-1"
                                        />
                                        {t("Sysadmin.in_active")}
                                      </span>
                                    </div>
                                  </li>
                                );
                              })
                              : <tr><td colspan="4" className="team-creatimage"><NoData/></td></tr>
                              }
                          </ul>
                        </div>
                      </div>
                    </Tab>
                   </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default (withTranslation()(OpaDashboard));