
import React from 'react';


const warning = {
    color: "#fff",
    backgroundColor: "DodgerBlue",
};

const SwitchTab = (props)  => {
  const handleChecked = (e) => {
    props.handleActDeact(e.target.checked , props.id);
  }

  return (
    <label className="switch"><input type="checkbox" onChange={handleChecked} checked={props.active}/><span className="slider round"></span>{props.text}</label>
    )
}


export default SwitchTab;