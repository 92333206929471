import { SET_INTEGRATION_ERROR } from "../constants/Jiraintegration";
import {
  SET_CREDENTIALS_CREATE_SUCCESS,
  GET_PROJECTS_SUCCESS,
  SET_FILE_UPLOAD_SUCCESS,
  GET_TASK_LIST_SUCCESS,
  GET_FILE_UPLOAD_SUCCESS,
  GET_TASK_DETAILS_SUCCESS,
  GET_ISSUE_TRANSITIONS_SUCCESS,
  SET_UPDATE_ISSUE_TASK_SUCCESS,
  SET_TASKS_CREATE_SUCCESS,
  GET_USERS_SUCCESS,
  GET_ALL_ORG_TOOLS_SUCCESS,
  SET_SELECT_PROJECT_SUCCESS,
  GET_TASK_INFO_DETAILS_SUCCESS,
  SET_TASK_MODAL,
  SET_EDIT_TASKS_SUCCESS,
  GET_CRUISER_TASKS_SUCCESS,
  SET_STATUS_CHANGE_SUCCESS,
  GET_USER_TOOLS_SUCCESS,
  GET_DEPARTMENTS_SUCCESS,
  GET_PRIORITY_SUCCESS,
  GET_ISSUE_TYPE_SUCCESS,
  GET_INTEGRATIONS_SUCCESS,
  SET_ACT_OR_DEACT_SUCCESS,
} from "../constants/Jiraintegration";

const initialState = {
  Credentialscreate: {},
  SelectProject: {},
  Taskscreate: {},
  EditTasks: {},
  Projects: [],
  FileUpload: {},
  Team: [],
  TaskList: [],
  TaskDetails: {},
  CruiserTasks: {},
  IssueTransitions: [],
  Integrations: [],
  taskinfoDetails: {},
  UpdateTask: {},
  StatusChange: {},
  OrgTools: [],
  UserTools: [],
  Departments:[],
  priority:[],
  issueType:[],
  IntegrationActDeAct:{},
  onError: null,
  taskModalType: null,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CREDENTIALS_CREATE_SUCCESS: {
      return Object.assign({}, state, {
        Credentialscreate: action.success,
      });
    }

    case SET_TASK_MODAL: {
      return Object.assign({}, state, {
        taskModalType: action.success,
      });
    }

    case SET_SELECT_PROJECT_SUCCESS: {
      return Object.assign({}, state, {
        SelectProject: action.success,
      });
    }

    case GET_PROJECTS_SUCCESS: {
      return Object.assign({}, state, {
        Projects: action.success,
      });
    }

    case GET_INTEGRATIONS_SUCCESS : {
      return Object.assign({} , state , {
        Integrations:action.success,
      });
    }

    case GET_USERS_SUCCESS: {
      return Object.assign({}, state, {
        Team: action.success,
      });
    }

    case GET_TASK_LIST_SUCCESS: {
      return Object.assign({}, state, {
        TaskList: action.success,
      });
    }

    case GET_CRUISER_TASKS_SUCCESS: {
      return Object.assign({}, state, {
        CruiserTasks: action.success,
      });
    }

    case SET_STATUS_CHANGE_SUCCESS: {
      return Object.assign({}, state, {
        StatusChange: action.success,
      });
    }

    case GET_TASK_DETAILS_SUCCESS: {
      return Object.assign({}, state, {
        TaskDetails: action.success,
      });
    }

    case GET_ISSUE_TRANSITIONS_SUCCESS: {
      return Object.assign({}, state, {
        IssueTransitions: action.success,
      });
    }

    case GET_TASK_INFO_DETAILS_SUCCESS: {
      return Object.assign({}, state, {
        taskinfoDetails: action.success,
      });
    }

    case SET_UPDATE_ISSUE_TASK_SUCCESS: {
      return Object.assign({}, state, {
        UpdateTask: action.success,
      });
    }

    case GET_ALL_ORG_TOOLS_SUCCESS: {
      return Object.assign({}, state, {
        OrgTools: action.success,
      });
    }

    case SET_FILE_UPLOAD_SUCCESS: {
      return Object.assign({}, state, {
        FileUpload: action.success,
      });
    }

    case GET_FILE_UPLOAD_SUCCESS: {
      return Object.assign({}, state, {});
    }

    case SET_TASKS_CREATE_SUCCESS: {
      let updateTaskList = state?.TaskList?.tasks?.length ? [...state?.TaskList?.tasks] : [];
      if (action?.success && Object.keys(action.success).length) {
        updateTaskList.push(action.success);
      };
      return Object.assign({}, state, {
        Taskscreate: action.success,
        TaskList: [...updateTaskList],
      });
    };

    case SET_EDIT_TASKS_SUCCESS: {
      return Object.assign({}, state, {
        EditTasks: action.success,
      });
    }

    case GET_USER_TOOLS_SUCCESS: {
      return Object.assign({}, state, {
        UserTools: action.success,
      });
    }

    case GET_DEPARTMENTS_SUCCESS: {
      return Object.assign({} , state , {
        Departments: action.success , 
      })
    }

    case GET_PRIORITY_SUCCESS : {
      return Object.assign({} , state , {
        priority: action.success ,
      });
    };

    case GET_ISSUE_TYPE_SUCCESS : {
      return Object.assign({} , state , {
        issueType:action.success ,
      });
    };

    case SET_ACT_OR_DEACT_SUCCESS : {
      return Object.assign({} , state , {
        IntegrationActDeAct:action.success , 
      });
    };

    case SET_INTEGRATION_ERROR: {
      return Object.assign({}, state, {
        onError: action.error,
      });
    };

    default:
      return { ...state };
  }
};
