import WorkSpace from "./workspacev2";
import Channels from "./channels";
import Socket from "./socket";
import Message from "./message";
import Rtc from "./rtc";
import Whiteboard from "./whiteboard";
import WorkSpaceV2 from "./workspacev2";
import Messages from "./message";
import Room from "./room";
import i18n from "i18next";


class Collaboration {
  constructor() {
    this.workSpace = new WorkSpace();
    this.channels = new Channels();
    this.socket = new Socket(this.channels);
    this.message = new Message();
    this.rtc = new Rtc();
    this.whiteboard = new Whiteboard();
    this.workSpaceV2 = new WorkSpaceV2();
    this.room = new Room();

  }

  init(token, host, socketHost) {
    window?.oneAbleCollaboration?.init(token, host, socketHost , i18n.language );
  }
}

export default new Collaboration();
