import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import MangerDashboard from "./Manager/component";
import EmployeeDashboard from "./Employee/component";
import OpaDashboard from "./Opsadmin/component";
import SysAdminDashboard from "./Sysadmin/component";
import OrgAdminDashboard from "./Orgadmin/component";
import SuperAdminDashboard from "./Superadmin/SuperAdminDashboard";
import { toast, ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { closeWindows } from "../../utils/childWindowUtils";
import collaboration from "../../services/collaboration";
import { setLogout } from "../../actions/settings";
import $ from "jquery";
import storageService from "../../services/storage";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curTime: null,
      shiftEndAlert: false,
    };
  }

  // componentDidUpdate(prevProps){
  //     if(this.props.userData !== prevProps.userData && this.props.userData){

  //         if(this.props.userData.enableShift==true && this.props.userData.enableOverTime==false){
  //             let timeObj=Utils.shiftTimimgs(this.props.userData.startTime,this.props.userData.workHours);
  //             let duration=(timeObj.endTime-timeObj.currentTime)*1000;
  //             setInterval( () => {
  //                 storageService.clear();
  //                 collaboration.socket.disconnect();
  //                 this.setState({shiftEndAlert:true});
  //               },30000)

  //         }
  //     }
  // }
  // closeWindow = () => {
  //    this.props.history.push('/login')
  // }

  componentDidUpdate(prevProps) {
    let token = storageService.getItem("userdata");
    window.addEventListener("popstate", (event) => {
        const modules = JSON.parse(sessionStorage.getItem("moduleStatus"));
        const userData = sessionStorage.getItem("userdata");
        let parseUserData = JSON.parse(window.atob(userData));
        if(event && (modules?.activeModuleIds?.length > 0 || modules?.inactiveModuleIds?.length > 0) && !$.isEmptyObject(modules) && !$.isEmptyObject(token)){
          return;
        } else {
          localStorage.clear();
          closeWindows(parseUserData?.userId);
          collaboration.socket.disconnect();
          setLogout();
          this.props.history.push("/email");
        }
    });
  }

  render() {
    const { userData } = this.props;
    if (!userData.roles) {
      return false;
    }

    return (
      <>
        {/* { this.state.shiftEndAlert&&
            <div id="shift-closed-alert">
            <div className="endcall-bk">
           <div className="endcall-alert">
             <p className="mb-4">Your shift timing is completed</p>
             <button className="btn btn-success" onClick={this.closeWindow}>Shift closed</button>
           </div>
          </div>
          </div>
    }    */}
        <ToastContainer />
        {userData.roles[0] === "MANAGER" && <MangerDashboard />}
        {userData.roles[0] === "EMPLOYEE" && <EmployeeDashboard />}
        {userData.roles[0] === "SUPERADMIN" && <SuperAdminDashboard />}
        {userData.roles[0] === "OPSADMIN" && <OpaDashboard />}
        {/*
				{userData.roles[0] === 'SYSADMIN' && <SysAdminDashboard />} */}
        {/* {userData.roles[0] === 'OPSADMIN' && <EmployeeDashboard />} */}
        {userData.roles[0] === "SYSADMIN" && <SysAdminDashboard />}
        {/* {userData.roles[0] === 'SYSADMIN' && <EmployeeDashboard />} */}
        {userData.roles[0] === "ORGSUPERADMIN" && <SuperAdminDashboard />}
        {/* {userData.roles[0] === "ORGSUPERADMIN" && <OrgSuprAdminDashboard />} */}
        {userData.roles[0] === "ORGADMIN" && <OrgAdminDashboard />}
      </>
    );
  }
}

/**
 * Map the state to props.
 */
const mapStateToProps = function (state) {
  return {
    userData: state.twofactorauth.userData,
  };
};

/**
 * Map the actions to props.
 */
const mapDispatchToProps = (dispatch) => ({
  setLogout: () => dispatch(setLogout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Dashboard));
