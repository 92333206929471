import {
    SET_PRODUCTIVITY_PENDING,
    SET_PRODUCTIVITY_ERROR,
    SET_PRODUCTIVITY_SUCCESS,
    SET_TOOL_PRODUCTIVITY_SUCCESS,
    SET_TOOL_NO_PRODUCTIVITY_SUCCESS,
    SET_USER_PRODUCTIVITY_SUCCESS,
    SET_USER_TOOL_PRODUCTIVITY_SUCCESS,
    SET_EMPLOYEE_TOOL_PRODUCTIVITY_SUCCESS,
    SET_PRODUCTIVITY_SERIES,
    SET_PRODUCTIVITY_FILTER,
    SET_PRODUCTIVITY_TOOL_SERIES,
    SET_PRODUCTIVITY_TOOL_FILTER,
    SET_USER_TOOL_PRODUCTIVITY_FOR_CSV
} from '../constants/productivity';

const initialState = {
    isLoading: false,
    userProductivity: [],
    toolProductivity: [],
    toolNoProductivity:[],
    userToolProductivity: [],
    series: [],
    toolSeries: [],
    toolFilter: '',
    filter: '',
    onError: null,
    userToolProductivityForCsv:[]
};

export default (state = initialState, action) => {
    switch(action.type) {
        case SET_PRODUCTIVITY_PENDING: {
            return {...state};
        }

        case SET_PRODUCTIVITY_SUCCESS: {
            return Object.assign({}, state, {
				productivity: action.success
			});
        }

        case SET_PRODUCTIVITY_ERROR: {
            return Object.assign({}, state, {
				onError: action.error
			});
        }

        case SET_TOOL_PRODUCTIVITY_SUCCESS: {
            return Object.assign({}, state, {
				toolProductivity: action.success
			});
        }

        case SET_TOOL_NO_PRODUCTIVITY_SUCCESS: {
            return Object.assign({}, state, {
				toolNoProductivity: action.success
			});
        }

        case SET_USER_PRODUCTIVITY_SUCCESS: {
            return Object.assign({}, state, {
				userProductivity: action.success
			});
        }

        case SET_USER_TOOL_PRODUCTIVITY_SUCCESS: {
            return Object.assign({}, state, {
				userToolProductivity: action.success
			});
        }

        case SET_USER_TOOL_PRODUCTIVITY_FOR_CSV: {
            return Object.assign({}, state, {
                userToolProductivityForCsv: action.success,
			});
        }

        case SET_EMPLOYEE_TOOL_PRODUCTIVITY_SUCCESS: {
            return Object.assign({} , state , {
                userToolProductivity: action.success

            });
        }

        case SET_PRODUCTIVITY_SERIES: {
            return Object.assign({}, state, {
                series: action.series
            });
        }

        case SET_PRODUCTIVITY_FILTER: {
            return Object.assign({}, state, {
                filter: action.filter
            });
        }

        case SET_PRODUCTIVITY_TOOL_SERIES: {
            return Object.assign({}, state, {
                toolSeries: action.toolSeries
            });
        }

        case SET_PRODUCTIVITY_TOOL_FILTER: {
            return Object.assign({}, state, {
                toolFilter: action.toolFilter
            });
        }

        default:
            return {...state}
    }
}