import {
    SET_SOCKET_CONNECTION_SUCCESS,
    SET_SOCKET_CONNECTION_ERROR,
    SET_CREATE_WORKSPACE_SUCCESS,
    SET_DELETE_WORKSPACE_SUCCESS,
    SET_UPDATE_WORKSPACE_SUCCESS,
    SET_WORKSPACE_ERROR,
    SET_WORKSPACE_LOADING,
    SET_WORKSPACE_READ_SUCCESS,
    SET_SELECTED_WORKSPACE_SUCCESS,
    SET_WORKSPACE_SUBSCRIBERS_SUCCESS,
    SET_WORKSPACE_USERS,
    SET_ACCEPT_WORKSPACE_SUCCESS,
    SET_REJECT_WORKSPACE_SUCCESS,
    SET_WORKSPACE_SUBSCRIPTION_ACTIVATE_SUCCESS,
    SET_WORKSPACE_SUBSCRIPTION_DEACTIVATE_SUCCESS,
    SET_WORKSPACE_SUBSCRIPTION_ERROR,
    SET_WORKSPACE_SUBSCRIPTION_INVITE_ACCEPT_SUCCESS,
    SET_WORKSPACE_SUBSCRIPTION_INVITE_ERROR,
    SET_WORKSPACE_SUBSCRIPTION_INVITE_LOADING,
    SET_WORKSPACE_SUBSCRIPTION_INVITE_REJECT_SUCCESS,
    SET_WORKSPACE_SUBSCRIPTION_INVITE_SUCCESS,
    SET_WORKSPACE_SUBSCRIPTION_LOADING,
    SET_WORKSPACE_SUBSCRIPTION_READ_SUBSCRIBER_SUCCESS,
    SET_WORKSPACE_SUBSCRIPTION_READ_SUCCESS,
    SET_WORKSPACE_SUBSCRIPTION_UNSUBSCRIBE_SUCCESS
} from "../constants/collabration";


const initialState = {
    socketStatus:{},
    wsListResponse: {},
    wsResponse: {},
    selectedWSObj:{},
    wsSubscribers:[],
    workspaceUpdate: {},
    workspaceDelete: {},
    workSpaceOnError: null,
    workSpaceLoading: false,

    workSpaceSubscriptionOnError: null,
    workSpaceSubscriptionLoading: false,
    workSpaceSubscriptions: [],
    workSpaceSubscriptionSubscribers: [],
    workSpaceSubscriptionActivate: {},
    workSpaceSubscriptionDeActivate: {},
    workSpaceSubscriptionUnsubscribe: {},

    workSpaceInvitationLoading: false,
    workSpaceInvitationError: null,
    workSpaceInvitationSuccess: {},
    workSpaceInvitationAccept: {},
    workSpaceInvitationReject: {}

}

export default (state = initialState , action) => {
    switch(action.type) {

        case SET_WORKSPACE_ERROR: {
            return Object.assign({}, state, {
                workSpaceOnError: action.error
            });
        }

        case SET_WORKSPACE_LOADING: {
            return Object.assign({}, state, {
                workSpaceLoading: action.loading
            });
        }

        case SET_CREATE_WORKSPACE_SUCCESS: {
            return Object.assign({}, state, {
                wsResponse: action.success
            });
        }
        case SET_SOCKET_CONNECTION_SUCCESS: {
            return Object.assign({}, state, {
                socketStatus: action.success
            });
        }

        case SET_WORKSPACE_READ_SUCCESS: {
            return Object.assign({}, state, {
                wsListResponse: action.success
            });
        }

        case SET_SELECTED_WORKSPACE_SUCCESS: {
            return Object.assign({}, state, {
                selectedWSObj: action.success
            });
        }
        
        case SET_WORKSPACE_USERS: {
            return Object.assign({}, state, {
                wsSubscribers: action.success
            });
        }

        case SET_UPDATE_WORKSPACE_SUCCESS: {
            return Object.assign({}, state, {
                workspaceUpdate: action.success
            });
        }

        case SET_DELETE_WORKSPACE_SUCCESS: {
            return Object.assign({}, state, {
                workspaceDelete: action.success
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_ERROR: {
            return Object.assign({}, state, {
                workSpaceSubscriptionOnError: action.error
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_LOADING: {
            return Object.assign({}, state, {
                workSpaceSubscriptionLoading: action.loading
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_READ_SUCCESS: {
            return Object.assign({}, state, {
                workSpaceSubscriptions: action.success
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_READ_SUBSCRIBER_SUCCESS: {
            return Object.assign({}, state, {
                workSpaceSubscribers: action.success
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_ACTIVATE_SUCCESS: {
            return Object.assign({}, state, {
                workSpaceSubscriptionSubscribers: action.success
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_ACTIVATE_SUCCESS: {
            return Object.assign({}, state, {
                workSpaceSubscriptionActivate: action.success
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_DEACTIVATE_SUCCESS: {
            return Object.assign({}, state, {
                workSpaceSubscriptionDeActivate: action.success
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_UNSUBSCRIBE_SUCCESS: {
            return Object.assign({}, state, {
                workSpaceSubscriptionUnsubscribe: action.success
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_INVITE_LOADING: {
            return Object.assign({}, state, {
                workSpaceInvitationLoading: action.loading
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_INVITE_ERROR: {
            return Object.assign({}, state, {
                workSpaceInvitationError: action.error
            });
        }

        case SET_WORKSPACE_SUBSCRIPTION_INVITE_SUCCESS: {
            return Object.assign({}, state, {
                workSpaceInvitationSuccess: action.success
            });
        }

        case SET_ACCEPT_WORKSPACE_SUCCESS: {
            return Object.assign({}, state, {
                workSpaceInvitationAccept: action.success
            });
        }

        case SET_REJECT_WORKSPACE_SUCCESS: {
            return Object.assign({}, state, {
                workSpaceInvitationReject: action.success
            });
        }

        default : 
           return {...state}
    }
}