import React, { useEffect , useRef } from 'react'
import i18n from '../../lang/i18n';
import { withTranslation } from 'react-i18next';


function Selecttag(props) {

    const Language = useRef(props?.i18n.language);

    useEffect(() => {
        i18n.changeLanguage(Language.current);
    },[])

    const { t } = props
    return (

        <div className="dropdown language-menu" >
            <img
                src="https://static.thenounproject.com/png/987-200.png"
                className="dropdown-toggle w26 cursor-pointer" data-toggle="dropdown"

            />

            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <span className="dropdown-item pt-2 pb-2 cursor-pointer "
                    onClick={() => { Language.current = 'en';
                        i18n.changeLanguage('en')
                    }} >
                     English </span>
                <div className="dropdown-divider"></div>
                <span className="dropdown-item pt-2 pb-2 cursor-pointer"
                    onClick={() => { Language.current = 'ja'; i18n.changeLanguage('ja') }}>
                    Japanese </span>


            </div>
            {/* <select onChange={(e) => {
                e.preventDefault()
                i18n.changeLanguage(e.target.value)

            }}>
                <option value='en' >English</option>
                <option value='ja' >日本語</option>
            </select> */}
        </ div >
    )
}
export default withTranslation()(Selecttag)
{/*{t('headers.lang')}*/ }