export const SET_TIMESHEET_CREATE_SUCCESS = 'SET_TIMESHEET_CREATE_SUCCESS';
export const SET_TIMESHEET_READ_SUCCESS = 'SET_TIMESHEET_READ_SUCCESS';
export const SET_TIMESHEET_UPDATE_SUCCESS = 'SET_TIMESHEET_UPDATE_SUCCESS';
export const SET_TIMESHEET_SUBMIT_SUCCESS = 'SET_TIMESHEET_SUBMIT_SUCCESS';
export const SET_TIMESHEET_APPROVE_SUCCESS = 'SET_TIMESHEET_APPROVE_SUCCESS';
export const SET_TIMESHEET_REJECT_SUCCESS = 'SET_TIMESHEET_REJECT_SUCCESS';
export const SET_TIMESHEET_USERS_APPROVE_SUCCESS = 'SET_TIMESHEET_USERS_APPROVE_SUCCESS';
export const SET_TIMESHEET_USERS_REJECT_SUCCESS = 'SET_TIMESHEET_USERS_REJECT_SUCCESS';
export const SET_TEAM_TIMESHEET_SUCCESS = 'SET_TEAM_TIMESHEET_SUCCESS';
export const SET_USER_TIMESHEET_SUCCESS = 'SET_USER_TIMESHEET_SUCCESS';
export const SET_MULTI_LEVEL_TIMESHEET_SUCCESS = 'SET_MULTI_LEVEL_TIMESHEET_SUCCESS';
export const SET_TIMESHEET_ERROR = 'SET_TIMESHEET_ERROR';