import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import i18n from '../../lang/i18n';

class machinepiechart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [50, 50],
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                    id:'machinesstatuslastmonth',
                    toolbar: {
                        show: true,
                    }
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none'
                        }
                    }
                },
                // '#08A9F1',
                colors: [ '#f8b912', '#8fc555'],
                labels: [ i18n.t('Sysadmin.allocate'), i18n.t('Sysadmin.deallocate')],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom'
                        },

                    }
                }],

                states: {
                    hover: {
                        filter: {
                            type: 'none'
                        }
                    }
                },
                legend: {
                    position: 'bottom',
                    markers: {
                        radius: 0
                    },
                    onItemHover: {
                        highlightDataSeries: false
                    },
                    onItemClick: {
                        toggleDataSeries: false
                    },

                },
            },

        };
    }
    render() {
        const machineData=this.props.data.machines||[]
        const date = new Date();
        const currYear = date.getFullYear();
        const currMonth = date.getMonth();
        let lastMonth= moment(currYear+"-"+(currMonth)).format('YYYY-MM');
        let lastMonthAllocate=0;
        let lastMonthDeallocate=0;
        
        machineData.map(machineObj=>{
                if(lastMonth===moment(machineObj.createdDate).format('YYYY-MM')){
                    machineObj.status=="Active"?lastMonthAllocate=lastMonthAllocate+1:lastMonthDeallocate=lastMonthDeallocate+1;
            }
            })
        
        let series=[50,50];
        series=[lastMonthAllocate,lastMonthDeallocate];
        return (
            <ReactApexChart options={this.state.options} series={series} type="pie" height={280 + Math.floor(Math.random() * 2) + 1} />
        );
    }
}
/**
 * Map the state to props. 
 */
const mapStateToProps = function (state) {
    return {
        data: state.employeeDashboard.data,
        // onError: state.employeeDashboard.onError,
        // isLoading: state.employeeDashboard.isLoading,
    };
};
export default connect(mapStateToProps)(withTranslation()(machinepiechart));
