import {
    SET_PRODUCTIVITY_PENDING,
    SET_PRODUCTIVITY_SUCCESS,
    SET_PRODUCTIVITY_ERROR,
    SET_USER_PRODUCTIVITY_SUCCESS,
    SET_TOOL_PRODUCTIVITY_SUCCESS,
    SET_USER_TOOL_PRODUCTIVITY_SUCCESS,
    SET_TOOL_NO_PRODUCTIVITY_SUCCESS,
    SET_EMPLOYEE_TOOL_PRODUCTIVITY_SUCCESS,
    SET_PRODUCTIVITY_SERIES,
    SET_PRODUCTIVITY_FILTER, SET_PRODUCTIVITY_TOOL_SERIES, SET_PRODUCTIVITY_TOOL_FILTER,
    SET_USER_TOOL_PRODUCTIVITY_FOR_CSV
} from '../constants/productivity';
import {getUserProductivites, getUserToolProductivites , getMultilevelUserProductivites , getMultilevelUserToolProductivites , getEmployeeToolProductivity, getToolNoProductiviteService} from '../services/productivity';
import userSeries from '../services/series';
import toolSeries from '../services/toolSeries';
import {TOOL_FILTER, USER_FILTER} from '../components/Productivity/constants';

const setProductivityPending = (isLoading) => {
    return {
        type: SET_PRODUCTIVITY_PENDING,
        isLoading
    };
};

const setProductivitySuccess = (success) => {
    return {
        type: SET_PRODUCTIVITY_SUCCESS,
        success
    };
};

export const setUserProductivitySuccess = (success) => {
    return {
        type: SET_USER_PRODUCTIVITY_SUCCESS,
        success
    };
};

export const setToolProductivitySuccess = (success) => {
    return {
        type: SET_TOOL_PRODUCTIVITY_SUCCESS,
        success
    };
};
export const setToolNoProductivitySuccess = (success) => {
    return {
        type: SET_TOOL_NO_PRODUCTIVITY_SUCCESS,
        success
    };
};

export const setUserToolProductivitySuccess = (success) => {
    return {
        type: SET_USER_TOOL_PRODUCTIVITY_SUCCESS,
        success
    };
};

export const setUserToolProductivityForCsv = (success) => {
    return {
        type: SET_USER_TOOL_PRODUCTIVITY_FOR_CSV,
        success
    };
};

const setEmployeeToolProductivitySuccess = (success) => {
    return {
        type: SET_EMPLOYEE_TOOL_PRODUCTIVITY_SUCCESS,
        success
    }
}

export const setProductivitySeries = (series) => {
    return {
        type: SET_PRODUCTIVITY_SERIES,
        series
    }
}

export const setProductivityFilter = (filter) => {
    return {
        type: SET_PRODUCTIVITY_FILTER,
        filter
    }
}

const setProductivityToolSeries = (toolSeries) => {
    return {
        type: SET_PRODUCTIVITY_TOOL_SERIES,
        toolSeries
    }
}

const setProductivityToolFilter = (toolFilter) => {
    return {
        type: SET_PRODUCTIVITY_TOOL_FILTER,
        toolFilter
    }
}

const setToolProductivitySeries = (toolSeries) => {
    return {
        type: SET_PRODUCTIVITY_TOOL_SERIES,
        toolSeries
    }
}


export const setProductivityError = (error) => {
    return {
        type: SET_PRODUCTIVITY_ERROR,
        error
    };
};


export const getUserProductivity = (data) => dispatch => {
    dispatch(setProductivityError(null));
    getUserProductivites((response) => {
        const userData = window.store.getState().profile.profiledata;
        const { roles } = userData;
        const filteredData = data && data.tab === "self" ? response.data
        : response.data.filter(userProductivity => {
            return roles.some(role => {
                const productivityKey = {
                    "ORGADMIN": "orgadminView",
                    "EMPLOYEE": "employeeView",
                    "MANAGER": "managerView",
                    "SYSADMIN": "managerView",
                    "OPSADMIN": "opsadminView"
                }[role];
                return userProductivity.settings.productivity[productivityKey];
            });
        }) || [];
        dispatch(setUserProductivitySuccess(filteredData));
        dispatch(setProductivityError(null));
    }, (error) => {
        dispatch(setProductivityError(error));
    });
};
export const getToolNoProductivity = () => dispatch => {
    dispatch(setProductivityError(null));
    dispatch(setUserToolProductivitySuccess([]));
    getToolNoProductiviteService((response) => {
        dispatch(setToolNoProductivitySuccess(response.data));
        dispatch(setProductivityError(null));
    }, (error) => {
        dispatch(setProductivityError(error));
    });
};

export const getUserToolProductivity = () => dispatch => {
    dispatch(setProductivityError(null));
    getUserToolProductivites((response) => {
        dispatch(setUserToolProductivitySuccess(response.data));
        dispatch(setUserToolProductivityForCsv(response.data));
        dispatch(setToolProductivitySuccess(processTools(JSON.parse(JSON.stringify(response.data)))));
        dispatch(setProductivityError(null));
    }, (error) => {
        dispatch(setProductivityError(error));
    });
};

export const getemployeetoolproductivity = (data) => dispatch => {
    dispatch(setProductivityError(null));
    dispatch(setUserToolProductivitySuccess(null));
    dispatch(setEmployeeToolProductivitySuccess(null));
    getEmployeeToolProductivity(data , (response) => {
        dispatch(setEmployeeToolProductivitySuccess(response.data));
        dispatch(setProductivityError(null));
    }, (error) => {
        dispatch(setProductivityError(error));
    });
};

export const getMultilevelUserProductivity = (data) => dispatch => {
    dispatch(setProductivityError(null));
    dispatch(setProductivitySeries(null))
    getMultilevelUserProductivites(data ,(response) => {
        const userData = window.store.getState().profile.profiledata;
        const { roles } = userData;
        const filteredData = response.data.filter(userProductivity => {
            return roles.some(role => {
                const productivityKey = {
                    "ORGADMIN": "orgadminView",
                    "EMPLOYEE": "employeeView",
                    "MANAGER": "managerView",
                    "SYSADMIN": "managerView",
                    "OPSADMIN": "opsadminView"
                }[role];
                return userProductivity.settings.productivity[productivityKey];
            });
        }) || [];
        dispatch(setUserProductivitySuccess(filteredData));
        dispatch(setProductivityError(null));
    } , (error) => {
        dispatch(setProductivityError(error));
    })
}

export const getMultilevelUserToolProductivity = (data) => dispatch => {
    dispatch(setProductivityError(null));
    getMultilevelUserToolProductivites(data , (response) => {
        dispatch(setUserToolProductivitySuccess(response.data));
        dispatch(setProductivityError(null));
    }, (error) => {
        dispatch(setProductivityError(error));
    });
}

export const yesterdaySeries = (date) => dispatch => {
    const series = userSeries.buildYesterdaySeries(date, window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_YESTERDAY));
}

export const lastWeekSeries = (startDate, endDate) => dispatch => {
    const series = userSeries.buildLastWeekSeries(startDate, endDate, window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_LAST_7_DAYS));
}

export const monthlySeries = (startDate, endDate) => dispatch => {
    const series = userSeries.buildMonthlySeries(startDate, endDate, window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_LAST_MONTH));
}

export const yearlySeries = (startDate, endDate) => dispatch => {
    const series = userSeries.buildLastYearSeries(startDate, endDate, window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_LAST_YEAR));
}

export const currentyearlySeries = (startDate , endDate) => dispatch => {
    const series = userSeries.buildCurrentYearSeries(startDate, endDate, window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_YEARLY));
}
 
export const customRangeSeries = (startDate, endDate) => dispatch => {
    const series = userSeries.buildCustomRange(startDate,endDate,window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_CUSTOM_RANGE));
}

export const userDaySeries = (selectedUserId, date) => dispatch => {
    const series = userSeries.buildUserDaySeries(selectedUserId, date, window.store.getState().productivity.userToolProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.NO_ACTION));
}

export const userWeeklySeries = (selectedUserId, startDate, endDate) => dispatch => {
    const series = userSeries.buildUserWeeklySeries(selectedUserId, startDate, endDate, window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_DAY));
}

export const userMonthlySeries = (selectedUserId, startDate, endDate) => dispatch => {
    const series = userSeries.buildUserMonthlySeries(selectedUserId, startDate, endDate, window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_DAY));
}

export const userCustomMonthlySeries = (selectedUserId , startDate , endDate , date) => dispatch => {
    const series = userSeries.buildCustomUserMonthlySeries(selectedUserId , startDate , endDate ,  date ,window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_DAY));
}

export const  userYearlySeries = (selectedUserId, date) => dispatch => {
    const series = userSeries.buildUserYearlySeries(selectedUserId, date, window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_MONTHLY));
}

export const userCurrentYearlySeries = (selectedUserId , date) => dispatch => {
    const series = userSeries.buildUserCurrentYearlySeries(selectedUserId, date, window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_MONTHLY));
}

export const userCustomRangeSeries = (selectedUserId, startDate ,endDate) => dispatch => {
    const series = userSeries.buildUserCustomRange(selectedUserId,startDate,endDate,window.store.getState().productivity.userProductivity);
    dispatch(setProductivitySeries(series));
    dispatch(setProductivityFilter(USER_FILTER.USER_CUSTOM_MONTHLY));
}

export const toolsYesterdaySeries = (date) => dispatch => {
    const series = toolSeries.toolBuildYesterdaySeries(date, window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.NO_ACTION));
}

export const toolsWeeklySeries = (startDate, endDate) => dispatch => {
    const series = toolSeries.toolBuildWeeklySeries(startDate, endDate, window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.TOOL_LAST_7_DAYS));
}

export const toolsMonthlySeries = (startDate, endDate) => dispatch => {
    const series = toolSeries.toolBuildMonthlySeries(startDate, endDate, window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.TOOL_LAST_MONTH));
}

export const toolsYearlySeries = (startDate, endDate) => dispatch => {
    const series = toolSeries.toolBuildYearlySeries(startDate, endDate, window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.TOOL_LAST_YEAR));
}

export const toolsCurrentYearlySeries = (startDate, endDate) => dispatch => {
    const series = toolSeries.toolBuildCurrentYearlySeries(startDate, endDate, window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.TOOL_YEARLY));
}

export const toolsCustomRange = (startDate , endDate) => dispatch => {
    const series = toolSeries.buildCustomRange(startDate, endDate, window.store.getState().productivity.toolProductivity)
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.TOOL_CUSTOM_RANGE));
}

export const toolCustomRangeSeries = (selectedToolId, startDate ,endDate) => dispatch => {
    const series = toolSeries.buildToolCustomRangeSeries(selectedToolId,startDate,endDate,window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.TOOL_MONTHLY));
}

export const toolDaySeries = (selectedToolId, date) => dispatch => {
    const series = toolSeries.buildToolDaySeries(selectedToolId, date , window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.NO_ACTION));
}

export const toolWeeklySeries = (selectedToolId, startDate, endDate) => dispatch => {
    const series = toolSeries.buildToolWeeklySeries(selectedToolId, startDate, endDate, window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.NO_ACTION));
}

export const toolMonthlySeries = (selectedToolId, startDate, endDate , date) => dispatch => {
    const series = toolSeries.buildToolMonthlySeries(selectedToolId, startDate, endDate, date , window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.NO_ACTION));
}

export const toolYearlySeries = (selectedToolId, date) => dispatch => {
    const series = toolSeries.buildToolYearlySeries(selectedToolId, date, window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.TOOL_MONTHLY));
}

export const toolCurrentYearlySeries = (selectedToolId, date) => dispatch => {
    const series = toolSeries.buildToolCurrentYearlySeries(selectedToolId, date, window.store.getState().productivity.toolProductivity);
    dispatch(setProductivityToolSeries(series));
    dispatch(setProductivityToolFilter(TOOL_FILTER.TOOL_MONTHLY));
}

const processTools = (data) => {
    const tools = [];
    data.forEach(element => {
        element.tools.forEach(t => {
            if (t.toolId != null) {
                const index = tools.findIndex(tool => tool.toolId === t.toolId);
                if (index !== -1) {
                    t.years.map(y => {
                        const year = tools[index].years.find(ye => ye.year === y.year);
                        if (year) {
                            year.appHoursPerYear += y.appHoursPerYear;
                            y.months.map(m => {
                                const month = year.months.find(mo => mo.month === m.month);
                                if (month) {
                                    month.appHoursPerMonth += m.appHoursPerMonth;
                                    m.days.map(d => {
                                        const day = month.days.find(da => da.day === d.day);
                                        if (day) {
                                            day.appHoursPerDay += d.appHoursPerDay;
                                        } else {
                                            month.days.push(d);
                                        }
                                    });
                                } else {
                                    year.months.push(m);
                                }

                            });
                        } else {
                            //t.years.push(y);
                            tools[index].years.push(y);
                        }

                    });
                } else {

                    tools.push({
                        toolId: t.toolId,
                        toolName: t.toolName,
                        years: t.years

                    })
                }
            }
        })
    });

    return tools;
}