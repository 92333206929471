import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts";
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class CrusireHours extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {

              
                chart: {
                    type: 'bar',
                    height: 300,
                    width:1000,
                    stacked: true,
                    id:"empworktime",
                    tools: {
                        download: false,
                    },
                    toolbar: {
                        show: true
                    },
                    zoom: {
                      enabled: true,
                      type: 'x'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        columnWidth:"50%",
                        width: 100,
                    },
                },
                fill: {
                    colors: ['#8fc555', '#F8b912' , '#B7E4F9']
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                        }
                    },
                },
                yaxis: {
                    axisBorder: { show: true },
                    title: {
                        text:this.props.t('OrgAdmin.total_hours'),
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,

                        // margin:10,
                        // padding:50,
                        style: {
                            color: '#000',
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            cssClass: 'apexcharts-yaxis-title',
                        },
                    },
                },
                xaxis: {
                    title: {
                       //text: this.props.t('OrgAdmin.listofemp'),
                        // rotate: -90,
                        offsetX: 0,
                        offsetY: 0,padding:30,
                        style: {
                            color: '#000',
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            cssClass: 'apexcharts-yaxis-title',
                        },
                       
                    },
                    axisTicks: { show: true },
                    axisBorder: { show: true },
                    categories: ['Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6'],
                    labels: {
                        hideOverlappingLabels : false ,
                        enabled: true,
                        rotate: -45,
                        rotateAlways: true,
                    },
                },
                
                legend: {
                    show:false
                   },
                grid: {
                    bottom: 20,
                    top: 0,
                    left: 6,
                    show: false,
                }
            },
        };
    }

   
componentDidMount() {
    i18n.on("languageChanged", this.handleLanguageChange);
    }
    
    componentWillUnmount() {
    i18n.off("languageChanged", this.handleLanguageChange);
    }
    
    handleLanguageChange = () => {
    this.setState({
        options: {
        ...this.state.options,
        yaxis: {
            ...this.state.options.yaxis,
            title: {
            ...this.state.options.yaxis.title,
            text: this.props.t('OrgAdmin.total_hours'),
            },
        },
        xaxis: {
            ...this.state.options.xaxis,
            title: {
            ...this.state.options.xaxis.title,
           // text: this.props.t("OrgAdmin.listofemp"),
            },
            style : {
                colors : ['#8fc555', '#F8b912','#B7E4F9']
            }
        },
        },
    });
    };

    render() {
        const options = JSON.parse(JSON.stringify(this.state.options))
        options.xaxis.categories = this.props.labels
        return (
            <div  >
            {/* <div className="Barchart_scrollbox Barchart_scrollbox_delayed">
                  <div className="Barchart_scrollbox-content p-2  text-center orgdashbard-crusirchat"> */}
                {this.props.series.length > 0 && this.props.series[0].data.length > 0 ?
                <div className="Barchart_scrollbox Barchart_scrollbox_delayed">
                <div className="Barchart_scrollbox-content p-2  text-center orgdashbard-crusirchat">
                  <div id="org-barchart-bar-align" className="text-center orgdasbord-barchart">
                    <div className="row mb-0 mx-0">
                        <div className="col-12 overflow-scroll">
                                <ReactApexChart
                                    options={options}
                                    series={this.props.series}
                                    type="bar"
                                    height="416px"
                                />
                        </div>
                    </div> 
                    </div>
                    </div>
                    </div>
                : <div className='text-center '>
                        <img src="../../assets/images/nodata.svg" className="nodata-img ApexChart" />
                        <h5 className="text-muted mt-3">{i18n.t('productivity.no_data')}</h5>
                    </div>}
                    
                {/* </div>
                </div> */}
            </div>
        );
    }
}

export default (withTranslation()(CrusireHours));