export const SET_OFFTIME = 'SET_OFFTIME';
export const SET_OFFTIME_ERROR = 'SET_OFFTIME_ERROR';
export const SET_TIMEOFF_CREATE = 'SET_TIMEOFF_CREATE';
export const SET_TIMEOFF_CREATE_ERROR = 'SET_TIMEOFF_CREATE_ERROR';
export const SET_TIMEOFF_UPDATE = 'SET_TIMEOFF_UPDATE';
export const SET_TIMEOFF_UPDATE_ERROR = 'SET_TIMEOFF_UPDATE_ERROR';
export const SET_TIMEOFF_DEACTIVATE = 'SET_TIMEOFF_DEACTIVATE';
export const SET_TIMEOFF_DEACTIVATE_ERROR = 'SET_TIMEOFF_DEACTIVATE_ERROR';
export const SET_TIMEOFF_ACTIVATE = 'SET_TIMEOFF_ACTIVATE';
export const SET_TIMEOFF_ACTIVATE_ERROR = 'SET_TIMEOFF_ACTIVATE_ERROR';
export const SET_TIME = 'SET_TIME';
export const SET_USER_SETTINGS_TIME_OFF = "SET_USER_SETTINGS_TIME_OFF";