import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";

class Teamprogress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    height: 416,
                    type: 'bar',
                    toolbar: {
                        show: false,
                       
                    },
                },

               
                       
                       
                

                states:{
                    hover:{
                       filter:{
                           type:'dark'
                       } 
                    }
                },
                colors: ['#8FC555'],
                grid: {
                    yaxis: {
                        lines: {
                            show: false,
                        },
                        plotOptions: {
                            bar: {
                                horizontal: true,
                                columnHeight: 16,
                                
                                width: 5,
                            }
                        },
                    },

                 
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                   
                },
                states: {
                    active: {
                         filter: { type: 'none', value: 1 }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        columnHeight: 16,
                        width: 5,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: ['UX ', 'Java', 'IOS', 'Android', 'React', 'Database', 'QA', 'UI', 'Python', 'Networking'],
                },
            },
            
            
            series: [{
                
                name:'oneable',
                data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 2180]
            }],

        };
    }
    
    render() {
        const productivity = Array.isArray(this.props.productivity) ? this.props.productivity : [];

        const options = JSON.parse(JSON.stringify(this.state.options));


        const seriesData = {
            // chrome: {
            //     name: "chrome",
            //     data: []
            // },
            // vscode:  {
            //     name: "vscode",
            //     data: []
            // },,
        }
        options.xaxis.categories = this.props.data? this.props.data.labels:[];
        // const series = categories.map(key => seriesData[key])
        const series = this.props.data?this.props.data.series:[];
        return (
            <div>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height="446px"
                />
            </div>
        );
    }
}
/**
 * Map the state to props. 
 */
 const mapStateToProps = function (state) {
    return {
        // data11: state.employeeDashboard.data,
        // onError: state.employeeDashboard.onError,
        // isLoading: state.employeeDashboard.isLoading,
        // dayProductivity: state.productivity.data,
        productivity: state.productivity.productivity,
    }
};
export default connect(mapStateToProps)(Teamprogress);
