import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Teamprogress from '../../Charts/Teamprogress';
import Bizunit from '../../Charts/Bizunit';
import Toolschart from '../../Charts/Toolschart';
import Machinepiechart from '../../Charts/machinepiechart';
import Toolspiechart from '../../Charts/toolspiechart';
import { Tab, Tabs, ThemeProvider } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import i18n from '../../../lang/i18n';
import { connect } from 'react-redux';
import { getEmpDashboardData } from '../../../actions/employeeDashboard';
import Utils from "../../../utils/utils";
import $ from 'jquery';
import moment from 'moment';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import defaultIcon from '../../../assets/images/Cruisericons/ic-empty.svg';
import { PRODUCTIVITY_METADATA } from '../../Productivity/metadata';
import _ from 'lodash';
import { getemployeeDashboardService , getMachineDetailsService , fetchUsersAndTools } from '../../../services/employeeDashboard ';
import handleApiError from "../../Shared/ErrorHandling";
import ContentLoader from '../../Shared/ContentLoader';
import { withRouter } from "react-router-dom";
import ScaleLoaderSpinner from '../../Shared/ScaleLoader';

class SysAdminDashboard extends Component {

    constructor(props) {
        super(props);
        const currYear = Utils.getDataToPass().year;
        const currMonth = Utils.getDataToPass().month;
        this.state = {
            isConnecting: false,
            machineuser: false,
            isopenUsersAndToolsModal: false,
            data: {},
            onError: {},
            selectedmachines: {},
            machineCount: 0,
            toolCount: 0,
            allocatedMachines: 0,
            deallocatedMachines: 0,
            activeTool: 0,
            inActiveTool: 0,
            lastMonthMachine: 0,
            lastMonthMachinePercent: 0,
            lastMonthAllocateCount: 0,
            lastMonthDeallocateCount: 0,
            lastMonthTool: 0,
            lastMonthToolPercent: 0,
            lastMonthAllocateTool: 0,
            lastMonthDeAllocateTool: 0,
            tools: [],
            machines: [],
            machineList: [],
            lastMonth: moment(currYear + "-" + (currMonth - 1)).format('YYYY-MM'),
            currentMonthName: Utils.getMonthNames(Utils.getDataToPass().month - 1),
            machineStatus:['active' , 'inactive'],
            machineFilters : {
                machineStatus : ""
            },
            contentLoader : false,
            machineWiseUserToolList: [],
            viewType : ""
        }

    }

    // userListView=(e)=>{
    //     this.setState({userList:!this.state.userList});
    // }


    /**
    * Toggles the Users and Tools view modal
    * @param {Object} machines - The machine object containing the host name.
    * @param {string} fieldName - The field name indicating the type of view
    */
    viewUsersAndToolsModal = async (machines , fieldName) => {
        const { isopenUsersAndToolsModal } = this.state;

        if(!isopenUsersAndToolsModal){
            let payload = {
                hostName : machines.hostName
            }

            try {
                this.setState({contentLoader : true});
                const response = await(fetchUsersAndTools(payload , fieldName));
                if(response && response.data && !_.isEmpty(response.data)){
                    this.setState({
                        machineWiseUserToolList : response.data , 
                        isopenUsersAndToolsModal : !isopenUsersAndToolsModal,
                        viewType : fieldName
                    });
                }
                this.setState({contentLoader : false});
            } catch (error) {
                this.setState({
                    contentLoader : false , 
                    viewType : ""
                });
                handleApiError(error , this.props);
            }
        } else {
            this.setState({isopenUsersAndToolsModal : false , viewType : ""});
        }
    };

    componentDidMount() {
        this.setState({ isConnecting: true });
        this.fetchEmployeeData();
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.machines !== this.state.machines || prevState.tools !== this.state.tools) {
            const { lastMonthTool, lastMonthAllocateTool, lastMonthDeAllocateTool, activeTool, inActiveTool, lastMonthMachine, lastMonthAllocateCount, lastMonthDeallocateCount, allocatedMachines, deallocatedMachines, lastMonthMachinePercent, lastMonthToolPercent, machineCount, toolCount } = this.setToolandMachineCount(this.state.machines, this.state.tools, this.state.lastMonth);
            this.setState({
                lastMonthTool,
                lastMonthAllocateTool,
                lastMonthDeAllocateTool,
                activeTool,
                inActiveTool,
                lastMonthMachine,
                lastMonthAllocateCount,
                lastMonthDeallocateCount,
                allocatedMachines,
                deallocatedMachines,
                lastMonthMachinePercent,
                lastMonthToolPercent,
                machineCount,
                toolCount
            })
        }
    }

    setToolandMachineCount = (machines, tools, lastMonth) => {
        let lastMonthTool = 0,
            lastMonthAllocateTool = 0,
            lastMonthDeAllocateTool = 0,
            activeTool = 0,
            inActiveTool = 0,
            lastMonthMachine = 0,
            lastMonthAllocateCount = 0,
            lastMonthDeallocateCount = 0,
            allocatedMachines = 0,
            deallocatedMachines = 0,
            lastMonthMachinePercent = 0,
            lastMonthToolPercent = 0;

        const machineCount = machines.length,
            toolCount = tools.length;

        machines.map(machine => {
            if (lastMonth === moment(machine.createdDate).format('YYYY-MM')) {
                lastMonthMachine++;
                machine.status === "Active" ? lastMonthAllocateCount++ : lastMonthDeallocateCount++;
            }
            machine.status === 'Active' ? allocatedMachines++ : deallocatedMachines++;
        })

        tools.map(tool => {
            if (lastMonth === moment(tool.createdDate).format('YYYY-MM')) {
                lastMonthTool++;
                tool.active === true ? lastMonthAllocateTool++ : lastMonthDeAllocateTool++
            }
            tool.active === true ? activeTool++ : inActiveTool++;
        })
        lastMonthMachinePercent = (lastMonthMachine / machineCount) * 100;
        lastMonthToolPercent = (lastMonthTool / toolCount) * 100;

        return {
            lastMonthTool,
            lastMonthAllocateTool,
            lastMonthDeAllocateTool,
            activeTool,
            inActiveTool,
            lastMonthMachine,
            lastMonthAllocateCount,
            lastMonthDeallocateCount,
            allocatedMachines,
            deallocatedMachines,
            lastMonthMachinePercent,
            lastMonthToolPercent,
            machineCount,
            toolCount
        }
    }


    /**
   * Fetches employee data and updates the component's state with the result.
   * @function
   * @returns {void}
   */

    fetchEmployeeData = () => {
        getemployeeDashboardService({}, (respData) => {
            //if empty response is returned
            if (_.isEmpty(respData)) {
                return
            }

            //if data is returned in the response
            const { machinesList, machines, tools } = respData

            this.setState({
                data: respData,
                //machineList: machinesList,
                machines,
                tools,
                isConnecting: false
            })
        }, (error) => {
            toast(error.message, { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
            this.setState({
                isConnecting: false,
                onError: error
            });
        });
        this.getMachineDetails();
    }

    /**
     * set the machinelist based on the selection
     * of filter and call the API to update
     * machineList
     * @function
     * @returns {void}
    */
    getMachineDetails = async () => {
        let Filters = [];
        const { machineFilters } = this.state;
        Object.keys(machineFilters)?.forEach(key => {
            if(machineFilters[key]){
                let obj = {};
                if(key === "machineStatus"){
                    obj.fieldName = "isActive";
                    obj.valueOne = machineFilters[key] === "active";
                }
                Filters.push(obj);
            }
        });
        
        let payload = {
            asc: true,
            filters : Filters,
            pageNationRequired : false
        };

        try {
            this.setState({contentLoader: true});
            const machineDetailsResponse = await getMachineDetailsService(payload);
            if(machineDetailsResponse && machineDetailsResponse.data && !_.isEmpty(machineDetailsResponse.data)){
                const { machineList } = machineDetailsResponse.data;
                this.setState({ machineList });
            }
            this.setState({contentLoader: false});
        } catch (error) {
            this.setState({contentLoader: false});
            handleApiError(error , this.props);
        }
    }

    handleMachineStatus = (e) => {
        const { value } = e.target;
        const { machineFilters } = this.state;
        this.setState({
            machineFilters : {
                ...machineFilters,
                machineStatus: value
            }
        }, () => {
            this.getMachineDetails();
        })
        
    }

    render() {
        const { t } = this.props;
        const { machineCount, toolCount, allocatedMachines, deallocatedMachines, activeTool, inActiveTool, lastMonthMachine, lastMonthMachinePercent, lastMonthAllocateCount, lastMonthDeallocateCount, lastMonthTool, lastMonthToolPercent, lastMonthAllocateTool, lastMonthDeAllocateTool, machineList, data, onError , 
            machineStatus , contentLoader , isopenUsersAndToolsModal , machineWiseUserToolList , viewType} = this.state
        const tableHeader = ['machine_name', 'ip_address', 'BIZUnit', 'processor', 'RAM', 'storage', 'time_zone', 'status', "users_tools"];
        const { currentMonthName } = this.state;
        const machineWiseList = viewType === 'User' 
            ? machineWiseUserToolList.userName 
            : machineWiseUserToolList.toolData;
        return (
            <div className="container-fluid mt-2">
                <ToastContainer />
                {this.state.isConnecting && <ScaleLoaderSpinner />}

                <div className="row clearfix mx-0">
                    <div className="col-lg-12 col-md-12 px-1">
                        <div className="row mx-0 ">
                            <div className="col-lg-3 col-md-6 col-12 p-1 oneable-menu-row">
                                <div className="card mb-0 ">
                                    <div className="body px-3 Borderradius shadow py-2 ">
                                        <div className="d-flex justify-content-between ">
                                            <div className=''>
                                                <span className="mb-0 font-size-12">
                                                    <b>{i18n.t('Sysadmin.total_machines')}  </b></span>
                                                {/* <span>{lastMonth}</span> */}
                                            </div>
                                            <div className=''>
                                                <span className="mb-0 font-size-12"><span className="text-warning">
                                                    <img
                                                        src="../assets/images/Orgdashboard/arrow-down.svg"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        alt="VAI Work"
                                                        className="rounded mr-1"
                                                    />
                                                    {lastMonthMachinePercent.toFixed(2)}%
                                                    {currentMonthName}
                                                </span>
                                                    {i18n.t('Sysadmin.month')}
                                                </span>
                                            </div>
                                        </div>
                                        <h6 className="mb-0 font-weight-medium mt-0"><b>{machineCount}</b></h6>
                                        <div className="d-flex justify-content-between ">
                                            <div>
                                                <span className="font-size-10 text-muted">{i18n.t('Sysadmin.active')} :
                                                    <b className='text-dark mx-1 font-size-12'>{allocatedMachines}</b>
                                                    / {i18n.t('Sysadmin.idle')} :
                                                    <b className='text-dark mx-1 font-size-12'>{deallocatedMachines}</b>
                                                    {/* / {i18n.t('Sysadmin.requests')} : 
                                            <b className='text-dark mx-1 font-size-12'>{deallocatedMachines}</b> */}
                                                </span>
                                            </div>
                                            <div className=" ">
                                                <img
                                                    src="../assets/images/Orgdashboard/progresspl.svg"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    alt="VAI Work"
                                                    className="w30 rounded position-absolute Org_Progress_icon"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 p-1 oneable-menu-row">
                                <div className="card mb-0 ">
                                    <div className="body px-3 Borderradius shadow py-2 ">
                                        <div className="d-flex justify-content-between ">
                                            <div className=" ">
                                                <span className="mb-0 font-size-12"><b>{i18n.t('Sysadmin.total_tools')}</b></span>
                                            </div>
                                            <div className=" ">
                                                <span className="mb-0 font-size-12 ml-2"><span className="text-warning">
                                                    <img
                                                        src="../assets/images/Orgdashboard/arrow-down.svg"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        alt="VAI Work"
                                                        className="rounded mr-1"
                                                    />
                                                    {lastMonthToolPercent.toFixed(2)}%</span>{i18n.t('Sysadmin.month')}</span>
                                            </div>
                                        </div>
                                        <h6 className="mb-0 font-weight-medium mt-0"><b>{toolCount}</b></h6>

                                        <div className="d-flex justify-content-between ">
                                            <div className=" ">
                                                <span className="font-size-10 text-muted">{i18n.t('Sysadmin.active')} :
                                                    <b className='text-dark mx-1 font-size-12'>{activeTool}</b>
                                                    / {i18n.t('Sysadmin.idle')} :
                                                    <b className='text-dark mx-1 font-size-12'>{inActiveTool}</b>
                                                    {/* / {i18n.t('Sysadmin.requests')} : 
                                            <b className='text-dark mx-1 font-size-12'>0</b> */}
                                                </span>
                                            </div>
                                            <div className="">
                                                <img
                                                    src="../assets/images/Orgdashboard/progresspl.svg"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    alt="VAI Work"
                                                    className="w30 rounded position-absolute Org_Progress_icon"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 p-1 oneable-menu-row">
                                <div className="card mb-0 ">
                                    <div className="body px-3 Borderradius shadow py-2">
                                        <div className="d-flex justify-content-between ">
                                            <div className="">
                                                <span className="mb-0 font-size-12">
                                                    <b>{i18n.t('Sysadmin.created_machines')}</b>
                                                </span>
                                            </div>
                                            <div className=''>
                                                <span className="mb-0 font-size-12"><span className="text-warning">
                                                    <img
                                                        src="../assets/images/Orgdashboard/arrow-down.svg"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        alt="VAI Work"
                                                        className="rounded mr-1" />
                                                    {lastMonthMachinePercent.toFixed(2)}%</span> {i18n.t('Sysadmin.month')}</span>
                                            </div>
                                        </div>
                                        <h6 className="mb-0 font-weight-medium mt-0"><b>{lastMonthMachine}</b></h6>
                                        <div className="d-flex justify-content-between ">
                                            <div className="">
                                                <span className="font-size-10 text-muted">{i18n.t('Sysadmin.allocate')}:
                                                    <b className='text-dark mx-1 font-size-12'>{lastMonthAllocateCount}</b>/{i18n.t('Sysadmin.de_allocate')} :
                                                    <b className='text-dark mx-2 font-size-12'>{lastMonthDeallocateCount}</b></span>
                                            </div>
                                            <div className="">
                                                <img
                                                    src="../assets/images/Orgdashboard/progresspl.svg"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    alt="VAI Work"
                                                    className="w30 rounded position-absolute Org_Progress_icon"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-12 p-1  oneable-menu-row">
                                <div className="card mb-0 ">
                                    <div className="body px-3 Borderradius shadow py-2">
                                        <div className="d-flex justify-content-between ">
                                            <div className=" ">
                                                <span className="mb-0 font-size-12"><b>{i18n.t('Sysadmin.created_tools')}</b></span>
                                            </div>
                                            <div className=" "><span className="mb-0 font-size-12"><span className="text-warning">
                                                <img
                                                    src="../assets/images/Orgdashboard/arrow-down.svg"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    alt="VAI Work"
                                                    className="rounded mr-1"
                                                />
                                                {lastMonthToolPercent.toFixed(2)}%</span> {i18n.t('Sysadmin.month')}</span>
                                            </div>
                                        </div>
                                        <h6 className="mb-0 font-weight-medium mt-0"><b>{lastMonthTool}</b></h6>
                                        <div className="d-flex justify-content-between ">
                                            <div className="">
                                                <span className="font-size-10 text-muted">{i18n.t('Sysadmin.allocate')} :
                                                    <b className='text-dark mx-2 font-size-12'>{lastMonthAllocateTool}</b> / {i18n.t('Sysadmin.de_allocate')} :
                                                    <b className='text-dark mx-2 font-size-12'>{lastMonthDeAllocateTool}</b></span>
                                            </div>
                                            <div className=" ">
                                                <img
                                                    src="../assets/images/Orgdashboard/progresspl.svg"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    alt="VAI Work"
                                                    className="w30 rounded position-absolute Org_Progress_icon"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* VAI Work END */}
                </div>

                <div className='row mx-0'>
                    <div className='col-md-6 p-1'>
                        <div className="card mb-0">
                            <div className="body px-3 py-3 Borderradius shadow ">
                                <div>
                                    <h6>{t("OrgAdmin.machine")}</h6>
                                </div>
                                <div id="system-admin-barcharts" className="">
                                    <Bizunit data={data} onError={onError} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 p-1'>
                        <div className="card mb-0">
                            <div className="body px-3 py-3 Borderradius shadow ">
                                <div>
                                    <h6>{t("OrgAdmin.tools")}</h6>
                                </div>
                                <div id="system-admin-barcharts" className="">
                                    <Toolschart data={data} />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


                {/* <div className="row clearfix mx-0">
                <div className="col-lg-3 col-md-12 col-12 col-xs-12 p-1">
                    <div className="card mb-0">
                        <div className="body px-3 py-3 Borderradius shadow sysadmin_pie_div ">
                            <div className=" mb-0 text-right select_month">
                             
                                    <p className='font-size-12 mr-4 text-muted'>{i18n.t('Sysadmin.last_month')}</p>
                                    </div>

                            </div>
                          
                            <div id="piechart-bar-align" className="text-center">
                                
                            <Tabs defaultActiveKey="Machines"  id="uncontrolled-tab-example sys_piechart" className="mt-0 TimeTabs pl-1 font-size-14 System_Machine ">

<Tab eventKey="Machines" title={i18n.t('Sysadmin.machines')} className="mt-0 pl-0 font-size-18" tabClassName="custom_tab">
<Machinepiechart />
                              
                              <div className="form-group text-left">
                                  <label className="d-block text-muted font-size-12">{i18n.t('Sysadmin.allocate')} <span className="float-right">{lastMonthAllocateCount}</span></label>
                                  <div className="progress progress-xs">
                                      <div className="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="77" aria-valuemin="10" aria-valuemax="100" style={{ width: `${lastMonthAllocateCount}%` }}></div>
                                  </div>
                              </div>
  
                              <div className="form-group text-left">
                                  <label className="d-block text-muted font-size-12">{i18n.t('Sysadmin.deallocate')} <span className="float-right">{lastMonthDeallocateCount}</span></label>
                                  <div className="progress progress-xs">
                                      <div className="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="77" aria-valuemin="10" aria-valuemax="100" style={{ width: `${lastMonthDeallocateCount}%` }}></div>
                                  </div>
                              </div>
                            
</Tab>
<Tab eventKey="Tools" title={i18n.t('Sysadmin.tools')} className="mt-0 pl-0 font-size-18" tabClassName="custom_tab">
    
    <Toolspiechart />
                              
                              <div className="form-group text-left">
                                  <label className="d-block text-muted font-size-12">{i18n.t('Sysadmin.allocate')} <span className="float-right">{lastMonthAllocateTool}</span></label>
                                  <div className="progress progress-xs">
                                      <div className="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="77" aria-valuemin="10" aria-valuemax="100" style={{ width: `${lastMonthAllocateTool}%` }}></div>
                                  </div>
                              </div>
  
                              <div className="form-group text-left">
                                  <label className="d-block text-muted font-size-12">{i18n.t('Sysadmin.deallocate')} <span className="float-right">{lastMonthDeAllocateTool}</span></label>
                                  <div className="progress progress-xs">
                                      <div className="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="77" aria-valuemin="10" aria-valuemax="100" style={{ width: `${lastMonthDeAllocateTool}%` }}></div>
                                  </div>
                              </div>
    
</Tab>
</Tabs>

                            
    
                                </div>

                        </div>
                    </div>

                </div> 

                <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 p-1">
                    <div className="card mb-0">
                        <div className="body px-3 py-3 Borderradius shadow ">

              

                            <div id="barchart-bar-align" className="">
                        



                            <Tabs defaultActiveKey="Machine"  id="uncontrolled-tab-example system_barchart" 
                            className="mt-0 TimeTabs System_Machine  pl-1 font-size-14">

<Tab eventKey="Machine" title={i18n.t('Sysadmin.machines')} className="mt-0 pl-0 font-size-18" tabClassName="custom_tab">


<Bizunit />



</Tab>

<Tab eventKey="Tools" title={i18n.t('Sysadmin.tools')} className="mt-0 pl-0 font-size-18" tabClassName="custom_tab">

    <Toolschart />
   
</Tab>
</Tabs>


                              

                            </div>


                        </div>
                    </div>

                </div>

            </div>*/}





                {/* employee table start */}
                <div className="row clearfix mx-0">

                    <div className="col-lg-12 col-md-12 p-1">
                        <div className="card">
                            <div className="body shadow p-3 Borderradius">
                                <div className='mb-2'>
                                    <select className='form-control col-md-2 cursor-pointer' onChange={(e) => this.handleMachineStatus(e)}>
                                        <option value={""}>{t("Sysadmin.all")}</option>
                                        {machineStatus.map((status) => {
                                            return <option value={status}>{t(`Sysadmin.${status}`)}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="table-responsive table-container">
                                    {contentLoader && <ContentLoader lines={20} lineHeight={15} />}
                                    {!contentLoader && machineList.length > 0 ? 
                                    <table className="table table-custom spacing5 dashboard-table system_table text-center mb-0">
                                        <thead>
                                            <tr>
                                                {tableHeader.map((label) => (
                                                    <th key={label} className="text-left">{t(`Sysadmin.${label}`)}</th>
                                                ))}
                                                {/* <th className='text-left'>{i18n.t('Sysadmin.machine_name')}</th>
                                                <th className='text-left'>{i18n.t('Sysadmin.employee_name')}</th>
                                                <th >{i18n.t('Sysadmin.ip_address')}</th>
                                                <th >{i18n.t('Sysadmin.BIZUnit')}</th>
                                                <th>{i18n.t('Sysadmin.processor')}</th>
                                                < th>{i18n.t('Sysadmin.RAM')}</th>
                                                <th>{i18n.t('Sysadmin.storage')}</th>
                                                <th>{i18n.t('Sysadmin.time_zone')}</th>
                                                <th>{i18n.t('Sysadmin.status')}</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                machineList.map((machineObj) => {
                                                    return (
                                                        <tr>
                                                            <td className='line-height-18 text-left'>
                                                                <b>{machineObj.hostName}</b>
                                                                <p className="text-muted font-size-12">
                                                                    <span>{i18n.t('Sysadmin.create_on')} : <span className="custom-violet">  {moment(machineObj.createdDate).format('YYYY-MM-DD')}</span>
                                                                    </span>
                                                                </p>
                                                            </td>
                                                            <td className="forgotPwd">{machineObj.publicIpAddress ? machineObj.publicIpAddress : "-"}</td>
                                                            <td className="forgotPwd">{machineObj.bizUnit ? machineObj.bizUnit : "-"}</td>
                                                            <td>{machineObj.processor ? machineObj.processor : "-"}</td>
                                                            <td>{machineObj.ram ? machineObj.ram : "-"}</td>
                                                            <td>{machineObj.storage ? machineObj.storage : "-"}</td>
                                                            <td>{machineObj.timezone ? machineObj.timezone : "-"}</td>
                                                            <td>{machineObj.active ? (
                                                                <span className="alert alert-warning">
                                                                    {t("Sysadmin.active")}</span>
                                                            ) : (
                                                                <span className="alert alert-danger">
                                                                    {t("Sysadmin.inactive")}</span>
                                                            )}
                                                            </td>
                                                            <td>
                                                                <img 
                                                                    src="../../assets/images/Orgdashboard/application.svg" 
                                                                    title={t("Dashboard.tool_view")} 
                                                                    className="text-right float-right mr-1 w30 cursor-pointer"
                                                                    alt=""
                                                                    onClick={() => this.viewUsersAndToolsModal(machineObj , "Tool")}
                                                                />
                                                                <img 
                                                                    src="../../assets/images/Orgdashboard/user_View.svg" 
                                                                    title={t("Dashboard.user_view")}
                                                                    className="text-right float-right mr-1 w30 cursor-pointer"
                                                                    alt=""
                                                                    onClick={() => this.viewUsersAndToolsModal(machineObj , "User")}
                                                                />
                                                            </td>

                                                        </tr>)

                                                })

                                            }
                                        </tbody>
                                    </table> : <div className='text-center mt-5'>
                                        <img src="../../assets/images/nodata.svg" className="nodata-img" />
                                        <h5 className="text-muted mt-3">{this.props.t('productivity.no_data')}</h5>
                                    </div>}
                                    {/* <button>Show Modal </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                {/* Table end */}

                {
                    isopenUsersAndToolsModal && (
                        <>
                            <div className="custom-alert-model">
                                <div className="custom-alert-box">
                                    <div className="custom-alert-header border-bottom">
                                        <span className="text-capitalize">
                                            {viewType === "User" ? t("OrgAdmin.user_list") : t("OrgAdmin.tool_list")}
                                        </span>
                                        <span
                                            id="MachineUsersModal"
                                            onClick={() => this.viewUsersAndToolsModal()}
                                        >
                                            <i
                                                className="fa fa-times text-muted cursor-pointer"
                                                title={t("tasks.close")}
                                            ></i>
                                        </span>
                                    </div>
                                    <div className="custom-alert-body text-left">
                                        <ul className='list-unstyled  sm margin-0 mb-0"'>
                                            {!_.isEmpty(machineWiseUserToolList) && 
                                                !_.isEmpty(machineWiseList) ?
                                                machineWiseList.map((machineDetailObj) => {
                                                    return (
                                                        <>        
                                                            <li className="">
                                                                    <div className="card mb-1">
                                                                        <div className="py-0 px-2">
                                                                            <div className="d-flex align-items-center justify-content-between ">
                                                                                <div className="line-height-12 text-left">
                                                                                    <h6 className="font-size-14 mb-0">
                                                                                        <img
                                                                                            src={viewType === "User" ? "../assets/images/ic-default-user.jpg" : machineDetailObj?.base64ImageStr}
                                                                                            alt=""
                                                                                            className="w30 rounded-circle mr-1"
                                                                                        />
                                                                                        {viewType === "User" ? 
                                                                                            (!machineDetailObj || machineDetailObj === "null null" ? "NA" : machineDetailObj) 
                                                                                            : machineDetailObj?.name || "NA"
                                                                                        }
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            </li>
                                                            
                                                        </>
                                                    );
                                                }) : 
                                                <div className="text-center">
                                                    <img
                                                        src="../../assets/images/nodata/No-Data.svg"
                                                        className="nodata_selftimg cursor-pointer"
                                                        alt=""
                                                    />
                                                </div>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        );
    }
}

export default withRouter((withTranslation()(SysAdminDashboard)));