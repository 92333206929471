import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactApexChart from "react-apexcharts";
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

// import { getEmpDashboardData } from '../../../actions/employeeDashboard';

class OverviewChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 300,
                    stacked: true,

                    toolbar: {
                        show: false
                    },
                    grid: { show: false },
                },
                states: {
                    hover: {
                        filter: {
                            type: 'dark'
                        }
                    }
                },
                dataLabels: {
                    enabled: false,
                    
                },
                plotOptions: {
                    bar: {
                        columnWidth: 16,
                        width: 5,
                    },


                },
                fill: {
                    //   colors: ['#7F1F8B', '#F8A940', ' #DC4C6A']
                    colors: ['#B7E4F9', '#F8B912', '#8FC555']
                },
                yaxis: {
                    axisBorder: { show: true },
                    title: {
                        text:i18n.t('Dashboard.hours'),
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,

                        // margin:10,
                        // padding:50,
                        style: {
                            color: '#000',
                            fontSize: '12px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            cssClass: 'apexcharts-yaxis-title',
                        },
                    },
                    // crosshairs: {
                    //     show: true,
                    //     position: 'back',
                    //     stroke: {
                    //         color: '#b6b6b6',
                    //         width: 1,
                    //         dashArray: 0,
                    //     },
                    // },
                },

                xaxis: {
                    axisTicks: { show: false,
                      
                    },
                    labels: { show: true,
                        rotate: -45,
                        // enabled: true,
                        rotateAlways: true,
    
                        },
                    axisBorder: { show: true },
                    categories: ['Week1', 'Week2', 'week3', 'Week4'],
                },
                legend: {
                    show: false,

                },
                grid: {
                    bottom: 20,
                    top: 0,
                    left: 6,
                    show: false,


                }
            },
            series: [
                {
                    name: "workedHours",
                    data: [8000, 12000, 3600, 1300, 6000]
                },
                {
                    name: "thinkTime",
                    data: [2000, 4000, 5000, 3000, 5000]
                },
                {
                    name: "overTime",
                    data: [1000, 2000, 4000, 6000, 2000]
                },
            ]
        };
    }

    componentDidMount() {
        i18n.on("languageChanged", this.handleLanguageChange);
      }
     
      componentWillUnmount() {
        i18n.off("languageChanged", this.handleLanguageChange);
      }
     
      handleLanguageChange = () => {
        this.setState({
          options: {
            ...this.state.options,
            yaxis: {
              ...this.state.options.yaxis,
              title: {
                ...this.state.options.yaxis.title,
                text: i18n.t("Dashboard.hours"),
              },
            },
          },
        });
      };
    
    render() {
        const timesheetModelsList = this.props.data.timesheetModels || []
        const dateFilter = this.props.dateFilter || '1'
        const getDateOfISOWeek = function(w, y) {
            var simple = new Date(y, 0, 1 + (w - 1) * 7);
            var dow = simple.getDay();
            var ISOweekStart = simple;
            if (dow <= 4)
                ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
            else
                ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
            return ISOweekStart;
        }

        let timesheetModels = []
        let diff = dateFilter
        const today = new Date()
        let last3months = new Date()
        last3months.setMonth(today.getMonth() - diff)
        let fromDate = last3months
        // if(this.props.userData && this.props.userData.userId && this.props.userData.roles[0] === 'EMPLOYEE')
        if (this.props.userData && this.props.userData.userId) {
            timesheetModels = timesheetModelsList.filter(obj => obj.userId === this.props.userData.userId)
            // timesheetModels = timesheetModelsList.filter(obj => obj.userId === this.props.userData.userId && new Date(obj.date) >= fromDate && new Date(obj.date) <= today)
        }
        const date = new Date();
         const currYear = date.getFullYear();
        const currMonth =  date.getMonth() + 1;
        let endMonth=date.getMonth()-1;
        let weekCount=0;
        const options = JSON.parse(JSON.stringify(this.state.options));
        const workedHrs = []
        const thinkHrs = []
        const overtimeHrs = []
        const xAxisCatg = []
        timesheetModels = timesheetModels.sort((a,b) => parseFloat(a.weeknumber)-parseFloat(b.weeknumber));

        timesheetModels.forEach((item, index) => {
             if (item.year === currYear) {
                 if(item.month>=endMonth){
                 weekCount=weekCount+1;
                let dateofIsoWeek = getDateOfISOWeek(item.weeknumber, item.year);
                let startDate=new Date(dateofIsoWeek);
                // startDate.setMonth(startDate.getMonth() + 1);
                let startmonthNo = startDate.getMonth()+ 1
                let startDateOfWeek=startDate.getDate()+"/"+startmonthNo+"/"+startDate.getFullYear();
                let enddateofweek = new Date(dateofIsoWeek);
                enddateofweek.setDate(dateofIsoWeek.getDate()+5)
                let endDate=new Date(enddateofweek);
                // endDate.setMonth(endDate.getMonth() + 1);
                let endmonthNo = endDate.getMonth()+1
                let weekEndDate=endDate.getDate()+"/"+endmonthNo+"/"+endDate.getFullYear();
                let dayOfFriday = enddateofweek.getDate();
                let tsHrs = 40;
                if(dayOfFriday <= 5 ){
                    tsHrs = dayOfFriday * 8;
                }
                // thinkHrs.push(item.weeknumber)
                if(item.effortHrs <= tsHrs){
                    // thinkHrs.push((tsHrs - item.effortHrs * 1).toFixed(2))
                    workedHrs.push(item.effortHrs.toFixed(2))
                    overtimeHrs.push(0)
                }
               
                else 
                if(item.effortHrs > tsHrs){
                    overtimeHrs.push((item.effortHrs * 1 - tsHrs).toFixed(2))
                    workedHrs.push(tsHrs)
                    thinkHrs.push(0)
                }
                
                xAxisCatg.push(startDateOfWeek+"-"+weekEndDate)
                //xAxisCatg.push(item.weeknumber)
            }
            }
        });

        //options.xaxis.categories = xAxisCatg.slice(0, 4);
        options.xaxis.categories = xAxisCatg;
        const series = [
            {
                name: "Over Time",
                data: overtimeHrs
                
            },
            {
                name: i18n.t('chart.thinktime'),
                data: thinkHrs
            },
            {
                name: i18n.t('chart.timesheethr'),
                data: workedHrs
            },
        ]
        // console.log("chart series data ", series);

        return (
            <div>
                <div className="Barchart_scrollbox Barchart_scrollbox_delayed">
                    <div className="Barchart_scrollbox-content p-1">
                        {series[0].data.length!=0 ?
                            <ReactApexChart
                                options={options}
                                series={series}
                                type="bar"
                                height="416px"
                            />:
                            <div className='row clear text-center manager-barchart-bar-align-nodata '>
                                <div className='text-center'>  <img src="../../assets/images/nodata.svg" className="nodata-img"  />
                                    <h6 className="text-muted my-5">{i18n.t('productivity.no_data')}</h6>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

// export default OverviewChart;

/**
 * Map the state to props. 
 */
const mapStateToProps = function (state) {
    return {
        userData:state.twofactorauth.userData,
    }
};


export default connect(mapStateToProps)(withTranslation()(OverviewChart));