import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChatHistory } from "../actions/channels";
import collaboration from "../services/collaboration";

const CollaborationContext = React.createContext();

const useCollaboration = () => {
  const context = React.useContext(CollaborationContext);
  if (!context) {
    throw new Error(
      `useCollaboration must be used within a Collaboration Provider`
    );
  }
  return context;
};

const CollaborationProvider = (props) => {
  const [allRoomsChat, setAllRoomsChat] = React.useState({});
  const [whiteboardCanvas, setWhiteboardCanvas] = React.useState([]);
  const [pageContent , setPageContent] = React.useState({pagenum:0 , roomId:''});

  React.useEffect(() => {
    if(Object.values(pageContent)?.length && pageContent?.roomId){
      fetchChannelChat(pageContent.roomId);
      setPageContent({...pageContent , roomId :''});
    }
  },[pageContent , allRoomsChat])

  const handleIncomingMessage = (newMessageData) => {
    const newMessageRoomId = newMessageData?.message?.roomId;
    setAllRoomsChat((allRoomsChat) => {
      let updatedAllRoomsChat = { ...allRoomsChat };
     
        if(updatedAllRoomsChat[newMessageRoomId]){
          updatedAllRoomsChat[newMessageRoomId]?.content.push(
            newMessageData.message
          );
          updatedAllRoomsChat[newMessageRoomId].newMessageCount =
            updatedAllRoomsChat[newMessageRoomId].newMessageCount + 1;

          return updatedAllRoomsChat;
        } else if (!updatedAllRoomsChat[newMessageRoomId]) {
          setPageContent({...pageContent , pagenum : 0 , roomId :newMessageRoomId});
          updatedAllRoomsChat[newMessageRoomId] = {content : []};
          updatedAllRoomsChat[newMessageRoomId]?.content.push(
            newMessageData.message
          );
          return  updatedAllRoomsChat;
        }
    });
  };

  const handleIncomingWhiteboard = (newWhiteboardData) => {
    const newCanvas = JSON.parse(newWhiteboardData.body);
    setWhiteboardCanvas(newCanvas);
  };

  const handleMessageSeen = (roomId) => {
    if (allRoomsChat[roomId]) {
      setAllRoomsChat((allRoomsChat) => {
        let updatedAllRoomsChat = { ...allRoomsChat };
        updatedAllRoomsChat[roomId].newMessageCount = 0;
        return updatedAllRoomsChat;
      });
    }
  };

  const handlePageCount = (count , roomId) => {
    setPageContent({...pageContent , pagenum : count , roomId :roomId});
  }

  const fetchChannelChat = React.useCallback((roomId) => {
    const data = { roomId: roomId, page: pageContent.pagenum, size: 20 };
    collaboration.socket.get(
      data,
      (resp) => {
        if(resp?.data?.content?.length == 0){
          setPageContent({...pageContent , roomId:''});
          return
        }
        resp.data.newMessageCount = 0;
        setAllRoomsChat((allRoomsChat) => {
          let updatedAllRoomsChat = { ...allRoomsChat };
          if(updatedAllRoomsChat[roomId]?.content.length && pageContent.pagenum > updatedAllRoomsChat[roomId]?.pageNumber){
            var newAllRoomsChat = updatedAllRoomsChat[roomId].content.concat(resp.data.content)
            updatedAllRoomsChat[roomId].content = newAllRoomsChat;
            updatedAllRoomsChat[roomId].pageNumber = pageContent.pagenum;
          } else {
            updatedAllRoomsChat[roomId] = resp.data;
          }
          return updatedAllRoomsChat;
        });
      },
      (err) => {}
    );
  }, [pageContent.pagenum]);

  return (
    <CollaborationContext.Provider
      value={{
        handleIncomingMessage,
        fetchChannelChat,
        handleMessageSeen,
        handlePageCount,
        allRoomsChat,
        setAllRoomsChat,
        whiteboardCanvas,
        setWhiteboardCanvas,
        handleIncomingWhiteboard,
      }}
    >
      {props.children}
    </CollaborationContext.Provider>
  );
};

export { CollaborationProvider, useCollaboration };
