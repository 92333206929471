import { HttpMethod } from "./HttpMethod";
import httputils from "../utils/httputils";

const ORG_LICENSE = 'license';
const ORG_MODULES = 'modules';
const ORG_LICENSE_ACTIVATE = 'license/activate';
const ORG_LICENSE_DEACTIVATE = 'license/deactivate';
const ORG_MODULE_LICENSE_ACTIVATE_DEACTIVATE = 'license/organization/moduleupdate';
const ORG_MODULE_LICENSE = 'license/add/modules';
const ORG_LICENSE_BY_ID = 'license/organization';
const ORG_LICENSE_KEY_CREATE = "organizations/createLicenceKeys";
const ORG_LICENSE_SERVICE= "license/pagination"
const HTTP_POST = 'POST';

export const getOrgLicenseService = (data ,  successCallback , errorCallback ) => {
  var licensePagUrl = `${ORG_LICENSE}?page=${data.page}&size=${data.size}`   
  licenseService(null , licensePagUrl , HttpMethod.GET , successCallback , errorCallback);
}

export const createOrgLicenseService = (data , successCallback , errorCallback) => {
    licenseService(data , ORG_LICENSE , HttpMethod.POST , successCallback , errorCallback);
}

export const updateOrgLicenseService = (data , successCallback , errorCallback) => {
    licenseService(data , ORG_LICENSE , HttpMethod.PUT , successCallback , errorCallback);
}

export const activateModuleOrgLicenseService = (data , successCallback , errorCallback) => {
    licenseService(data , ORG_MODULE_LICENSE_ACTIVATE_DEACTIVATE , HttpMethod.PUT , successCallback , errorCallback);
}

export const addModulesOrgLicenseService = (data , successCallback , errorCallback) => {
  licenseService(data , ORG_MODULE_LICENSE , HttpMethod.POST , successCallback , errorCallback );
} 

export const activateOrgLicenseService = (data , successCallback , errorCallback) => {
  licenseService(data , ORG_LICENSE_ACTIVATE, HttpMethod.PUT , successCallback , errorCallback );
}

export const deactivateOrgLicenseService = (data , successCallback , errorCallback) => {
    licenseService(data , ORG_LICENSE_DEACTIVATE, HttpMethod.DELETE , successCallback , errorCallback);
}

export const getModulesOrgLicenseService = (successCallback , errorCallback) => {
  licenseService(null , ORG_MODULES , HttpMethod.GET , successCallback , errorCallback);
}

export const getOrgLicenseByIdService = (data , successCallback , errorCallback) => {
  licenseService(data , ORG_LICENSE_BY_ID , HttpMethod.POST , successCallback , errorCallback);
}

/**
 * New service API calls
*/
export const createLicenseKeyService = async (data) => {
    return await httputils.fetchData(ORG_LICENSE_KEY_CREATE , HttpMethod.POST, data);
}


const licenseService = (
    data,
    endpoint,
    httpMethod,
    successCallback,
    errorCallback
  ) => {
    httputils.httpRequest(endpoint, httpMethod, data, (response) => {
      if (response.status === "Success") {
        successCallback && successCallback(response);
      } else {
        errorCallback && errorCallback(response);
      }
    });
};
  
/* Newly added functionality */

const makeApiCall = async (apiEndpoint, method, requestData) => {
  try {
      return await httputils.fetchData(apiEndpoint, method, requestData);
  } catch (error) {
      throw error;
  }
};

export const getOrgLicenseServiceData = async (data) => makeApiCall(ORG_LICENSE_SERVICE, HTTP_POST, data);
