import {
  SET_ORG_DOMAINS,
  SET_ORG_LIST_SUCCESS,
  SET_DEPT_LIST_SUCCESS,
  SET_ORG_CREATE_SUCCESS,
  SET_CREATE_USER_SUCCESS,
  SET_ORG_CREATE_DOMAIN_SUCCESS,
  SET_USER_LIST_SUCCESS,
  SET_ORG_LIST_ERROR,
  SET_ENABLE_ORG_SUCCESS,
  SET_DISABLE_ORG_SUCCESS,
  SET_DEPT_BY_ORG_SUCCESS,
  SET_ORG_MODULES_BY_ID_SUCCESS,
  SET_AUTH_TYPE_CREATE_SUCCESS,
  SET_AUTH_TYPE_LIST_SUCCESS,
  SET_MODULES_ROUTE_SUCCESS,
} from "../constants/organization";

const initialState = {
  orgList: [],
  deptList: [],
  orgCreationResponse: {},
  orgModules: [],
  routeModules:[],      
  userCreationResponse: {},
  userList: {},
  enableOrgResponse: {},
  disableOrgResponse: {},
  authTypeList: [],
  createAuthType: "",
  onError: null,
  domainList: [],
  createDomain:{},
  deptByOrg:[],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORG_LIST_SUCCESS: {
      return Object.assign({}, state, {
        orgList: action.response,
      });
    }

    case SET_DEPT_LIST_SUCCESS: {
      return Object.assign({}, state, {
        deptList: action.response,
      });
    }

    case SET_ORG_CREATE_SUCCESS: {
      return Object.assign({}, state, {
        orgCreationResponse: action.response,
      });
    }

    case SET_CREATE_USER_SUCCESS: {
      return Object.assign({}, state, {
        userCreationResponse: action.response,
      });
    }

    case SET_USER_LIST_SUCCESS: {
      return Object.assign({}, state, {
        userList: action.response,
      });
    }

    case SET_ENABLE_ORG_SUCCESS: {
      return Object.assign({}, state, {
        enableOrgResponse: action.response,
      });
    }

    case SET_DISABLE_ORG_SUCCESS: {
      return Object.assign({}, state, {
        disableOrgResponse: action.response,
      });
    }

    case SET_AUTH_TYPE_LIST_SUCCESS: {
      return Object.assign({}, state, {
        authTypeList: action.response,
      });
    }

    case SET_AUTH_TYPE_CREATE_SUCCESS: {
      return Object.assign({}, state, {
        createAuthType: action.response,
      });
    }

    case SET_ORG_LIST_ERROR: {
      return Object.assign({}, state, {
        onError: action.error,
      });
    }
    case SET_ORG_DOMAINS: {
      return Object.assign({}, state, {
        domainList: action.success,
      });
    }

    case SET_ORG_CREATE_DOMAIN_SUCCESS : {
      return Object.assign({} , state , {
        createDomain : action.success,
      })
    }

    case SET_ORG_MODULES_BY_ID_SUCCESS : {
      return Object.assign({} , state , {
        orgModules : action.success , 
      })
    }

    case SET_MODULES_ROUTE_SUCCESS : {
      return Object.assign({} , state , {
        routeModules : action.success
      })
    }

    case SET_DEPT_BY_ORG_SUCCESS : {
      return Object.assign({} , state , {
        deptByOrg : action.success
      })
    }

    default:
      return { ...state };
  }
};
