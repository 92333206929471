import moment from "moment";
import Utils from "../utils/utils";

class ToolSeries {

    toolBuildYesterdaySeries = (date, productivity) => {
        const day = {dayOfYear: date.dayOfYear(), year: date.year(), month: date.month() + 1};
        const series = [];
        productivity && productivity.forEach(element => {
            const yearProductivity = element?.years?.find(y => y.year === day.year);
            const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === day.month) : null;
            const dayProductivity = ((monthProductivity !== undefined)&& (monthProductivity != null)) ? monthProductivity.days.find(d => d.day === day.dayOfYear) : null;
            if(dayProductivity) {
                series.push({
                    x: element.toolId,
                    y: ((dayProductivity.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                });
            }
        });
        return series;
    }

    toolBuildWeeklySeries = (startDate, endDate, productivity) => {
        return this.buildCustomRange(startDate, endDate, productivity);
    }

    toolBuildMonthlySeries = (startDate, endDate, productivity) => {
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        const series = [];
        productivity && productivity.forEach(element => {
            const yearProductivity = element?.years?.find(y => y.year === start.year);
            const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === start.month) : null;
            if(monthProductivity) {
                series.push({
                    x: element.toolId,
                    y: ((monthProductivity.appHoursPerMonth / (1000 * 60 * 60)) ).toFixed(2)
                });
            }
        });
        return series;
    }

    toolBuildYearlySeries = (startDate, endDate, productivity) => {
        const series = [];
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        productivity && productivity.forEach(element => {
            const yearProductivity = element?.years?.find(y => y.year === start.year);
            if(yearProductivity) {
                series.push({
                    x: element.toolId,
                    y: ((yearProductivity.appHoursPerYear / (1000 * 60 * 60)) ).toFixed(2)
                });
            }
        });
        return series;
    }

    toolBuildCurrentYearlySeries = (startDate, endDate, productivity) => {
        const series = [];
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        productivity && productivity.forEach(element => {
            const yearProductivity = element?.years?.find(y => y.year === start.year);
            if(yearProductivity) {
                series.push({
                    x: element.toolId,
                    y: ((yearProductivity.appHoursPerYear / (1000 * 60 * 60)) ).toFixed(2)
                });
            }
        });
        return series;
    }

    buildToolDaySeries = (selectedToolId, date, productivity) => {
        if(selectedToolId === null || selectedToolId === undefined || selectedToolId === '') {
            throw 'ToolId is required';
        }
        const day = {dayOfYear: date.dayOfYear(), year: date.year(), month: date.month() + 1};
        const series = [];
        const toolProductivity = productivity.find(p => p.toolId === selectedToolId);
        const yearProductivity = toolProductivity.years?.find(y => y.year === day.year);
        const monthProductivity = yearProductivity ? yearProductivity.months?.find(m => m.month === day.month) : null;
        const dayProductivity =  ( monthProductivity && monthProductivity?.days?.length > 0 ) ?  monthProductivity.days?.find(d => d.day === day.dayOfYear) : monthProductivity?.days?.length === 0 ? [] : null;
        if(dayProductivity) {
            series.push({
                x: toolProductivity.toolName,
                y: ((dayProductivity.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
            });
        }
        return series;
    }

    buildToolWeeklySeries = (selectedToolId, startDate, endDate, productivity) => {
        if(selectedToolId === null || selectedToolId === undefined || selectedToolId === '') {
            throw 'ToolId is required';
        }
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        const series = [];
        const toolProductivity = productivity.find(p => p.toolId === selectedToolId);
        const yearProductivity = start.year === end.year ? toolProductivity.years.find( y => y.year === start.year) : toolProductivity.years.filter( y => y.year === end.year || y.year === start.year);
        const monthProductivity = yearProductivity !== undefined && start.year === end.year ? yearProductivity.months?.filter(m => start.month <= m.month && m.month <= end.month)  
        : start.year !== end.year ? yearProductivity?.map(t => {
            const months = t.months?.filter(m => start.month === m.month && start.year === t.year || m.month === end.month && end.year === t.year)
            return months;
        }) : null;
        start.year === end.year ? monthProductivity?.map(element => {
            const days = element?.days?.filter(d => start.dayOfYear <= d.day && d.day <= end.dayOfYear)
            if(days){
                days.sort((a, b) => a.day - b.day);
                days.forEach(day => {
                    series.push({
                        x:  moment(day.date).format('DD-MM-YYYY'),
                        y: ((day.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                    });
                });
            }
        }) : monthProductivity.map(element => {
                const days = element[0].days?.filter(d => start.dayOfYear <= d.day && element[0].year == start.year || d.day <= end.dayOfYear && element[0].year == end.year)
                if(days){
                    days.sort((a, b) => a.day - b.day);
                    days.forEach(day => {
                        series.push({
                            x:  moment(day.date).format('DD-MM-YYYY'),
                            y: ((day.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                        });
                    });
                }
        })

        return series;
    }

    buildToolMonthlySeries = (selectedToolId, startDate, endDate, date ,  productivity) => {
        if(selectedToolId === null || selectedToolId === undefined || selectedToolId === '') {
            throw 'ToolId is required';
        }

        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        const data = [];
        const calendardate = new Date()
        let currentfullyear = calendardate.getFullYear();
        let prevfullyear = currentfullyear-1;
        const toolProductivity = productivity.find(p => p.toolId === selectedToolId);
        let month = [];

        if (!date){
            const yearProductivity = toolProductivity?.years?.find(y => y.year === start.year);
            const monthProductivity = yearProductivity !== undefined ? yearProductivity.months.find(m => m.month === start.month) : null;
            if(monthProductivity){
                monthProductivity.days.sort((a, b) => a.day - b.day);
                monthProductivity.days.forEach(element => {
                    data.push({
                        x: moment(element.date).format("DD-MM-YYYY"),
                        y: ((element.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                    });
                });
            }
        } else {
            const diffmonths = {dayOfYear: date.dayOfYear(), year: date.year(), month: date.month() + 1};
            const yearProductivity = toolProductivity.years.find(y => start.year <= y.year && y.year <= end.year);
            if(start.year === end.year){
                if(start.month === diffmonths.month && start.month !== end.month){
                    const monthProductivity = yearProductivity !== undefined ? yearProductivity.months?.find(m => m.month === start.month) : null;
                    month = monthProductivity?.days?.filter(d=> d.day >= start.dayOfYear)
                    
                    if(month){
                        month.sort((a, b) => a.day - b.day);
        
                        month.forEach(element => {
                            data.push({
                                x: moment(element.date).format('DD-MM-YYYY'),
                                y: ((element.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                            });
                        });
                    }
    
                } else if (end.month === diffmonths.month && start.month !== end.month) {
                    const monthProductivity = yearProductivity !== undefined ? yearProductivity.months?.find(m => m.month === end.month) : null;
                    month = monthProductivity?.days?.filter(d=> d.day <= end.dayOfYear)
        
                    if(month){
                        month.sort((a, b) => a.day - b.day);
        
                        month.forEach(element => {
                            data.push({
                                x: moment(element.date).format('DD-MM-YYYY'),
                                y: ((element.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                            });
                        });
                    }
    
                } else if (start.month === diffmonths.month && start.month === end.month) {
                    const monthProductivity = yearProductivity !== undefined ? yearProductivity.months?.find(m => m.month === end.month) : null;
                    month = monthProductivity.days?.filter(d=> d.day >= start.dayOfYear && d.day <= end.dayOfYear)
        
                    if(month){
                        month.sort((a, b) => a.day - b.day);
        
                        month.forEach(element => {
                            data.push({
                                x: moment(element.date).format('DD-MM-YYYY'),
                                y: ((element.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                            });
                        });
                    }
    
                } else {
                    const monthProductivity = yearProductivity !== undefined ? yearProductivity.months?.find(m => m.month === diffmonths.month) : null;
                    if(monthProductivity){
                        monthProductivity.days.sort((a, b) => a.day - b.day);
        
                        monthProductivity.days.forEach(element => {
                            data.push({
                                x: moment(element.date).format('DD-MM-YYYY'),
                                y: ((element.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                            });
                        });
                    }
                }
    
            } else if (prevfullyear === start.year && currentfullyear === end.year){
                const yearProductivity = toolProductivity.years.filter(y => y.year >= start.year && y.year <= end.year);
                let months = []
                let years = []
                years = yearProductivity.find(y => y.year === diffmonths.year)
                months = years.months.find(m => m.month === diffmonths.month)
                
                let element = [];
                if(start.month === months.month && start.year === diffmonths.year){
                    element = months.days?.filter(d => d.day >= start.dayOfYear)
                    if(element){
                        element.sort((a,b) => a.day - b.day);
                        element.map( d => {
                            data.push({
                                x: moment(d.date).format('DD-MM-YYYY'),
                                y: ((d.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                            });
                        })
                    }
    
                } else if (end.month === months.month && end.year === diffmonths.year){
                    element = months.days?.filter(d => d.day <= end.dayOfYear)
                    if(element){
                        element.sort((a,b) => a.day - b.day);
                        element.map( d => {
                            data.push({
                                x: moment(d.date).format('DD-MM-YYYY'),
                                y: ((d.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                            });
                        })
                    }
                } else {
                    element = months.days;
                    if(element){
                        element.sort((a,b) => a.day - b.day);
                        element.map( d => {
                            data.push({
                                x: moment(d.date).format('DD-MM-YYYY'),
                                y: ((d.appHoursPerDay / (1000 * 60 * 60))).toFixed(2)
                            });
                        })
                    }
                }
            }
        }

        return data;
    }

    buildToolYearlySeries = (selectedToolId, date, productivity) => {
        if(selectedToolId === null || selectedToolId === undefined || selectedToolId === '') {
            throw 'ToolId is required';
        }
        const start = {dayOfYear: date.dayOfYear(), year: date.year(), month: date.month() + 1};
        const data = [];
        const toolProductivity = productivity && productivity.find(p => p.toolId === selectedToolId);
        const yearProductivity = toolProductivity?.years?.find(y => y.year ===  start.year);
        if(yearProductivity){
            yearProductivity.months.sort((a, b) => a.month - b.month);
            yearProductivity.months.forEach(element => {
                data.push({
                    x: `${Utils.getMonthName(element.month)} ${start.year}`,
                    y: ((element.appHoursPerMonth / (1000 * 60 * 60)) ).toFixed(2)
                });
            });
        }
        return data;
    }

    buildToolCurrentYearlySeries = (selectedToolId, date, productivity) => {
        if(selectedToolId === null || selectedToolId === undefined || selectedToolId === '') {
            throw 'ToolId is required';
        }
        const start = {dayOfYear: date.dayOfYear(), year: date.year(), month: date.month() + 1};
        const data = [];
        const toolProductivity = productivity && productivity.find(p => p.toolId === selectedToolId);
        const yearProductivity = toolProductivity?.years?.find(y => y.year ===  start.year);
        if(yearProductivity){
            yearProductivity.months.sort((a, b) => a.month - b.month);
            yearProductivity.months.forEach(element => {
                data.push({
                    x: `${Utils.getMonthName(element.month)} ${start.year}`,
                    y: ((element.appHoursPerMonth / (1000 * 60 * 60)) ).toFixed(2)
                });
            });
        }
        return data;
    }

    buildCustomRange = (startDate, endDate, productivity) => {
        const data = [];
        const date = new Date()
        let currentfullyear = date.getFullYear();
        let prevfullyear = currentfullyear-1;
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        let monthworkhrs = 0;
        if(start.year === end.year){
            productivity.forEach(element => {
                const years = element?.years?.filter(y => start.year == y.year);
                const months = years && years?.length !== 0 ?  years[0].months.filter(m=> m.month >= start.month && m.month <= end.month) : null ;
                monthworkhrs = 0;
                months && months?.forEach(m => {
                    let month = m.days.filter(d => d.day >= start.dayOfYear && d.day <= end.dayOfYear)
                    monthworkhrs = 0;
                    month?.map(d => {
                        monthworkhrs = monthworkhrs+d.appHoursPerDay
                    })
                    const index = data.findIndex(value => value.x === element.toolId);
                    if(index === -1) {
                        if(monthworkhrs !== 0){
                            data.push({
                                x: element.toolId,
                                y: (monthworkhrs / (1000 * 60 * 60)).toFixed(2)
                            });
                        }
                    } else {
                        data[index].y += ((monthworkhrs / (1000 * 60 * 60)) ).toFixed(2);
                    }
                });
            });
        } else if (prevfullyear === start.year && currentfullyear === end.year) {
            productivity.forEach(element => {
                const years = element?.years?.filter(y => start.year <= y.year && y.year <= end.year);
                years && years.forEach(year => {
                    let months = []
                    if(year.year === start.year){
                        months = year.months.filter(m => m.month >= start.month);
                    } else {
                        months  = year.months.filter(m =>  m.month <= end.month);
                    }
                    months && months?.forEach(m => {
                        monthworkhrs = 0;
                        if(m.month === start.month && m.year === start.year){
                            m = m.days.filter(d => d.day >= start.dayOfYear)
                            m.map( d => {
                                monthworkhrs = monthworkhrs+d.appHoursPerDay
                            })
                        } else if (m.month === end.month && m.year === end.year){
                            m = m.days.filter(d => d.day <= end.dayOfYear)
                            m.map( d => {
                                monthworkhrs = monthworkhrs+d.appHoursPerDay
                            })
                        } else {
                            monthworkhrs += m.appHoursPerMonth
                        }

                        const index = data.findIndex(value => value.x === element.toolId);
                        if(index === -1) {
                            data.push({
                                x: element.toolId,
                                y: (monthworkhrs / (1000 * 60 * 60)).toFixed(2)  
                            });
                        } else {
                            data[index].y += ((monthworkhrs / (1000 * 60 * 60)) ).toFixed(2);
                        }
                    })
                });
            });
        }
            
        return data;
    }

    buildToolCustomRangeSeries = (selectedToolId , startDate, endDate, productivity) => {
        if(selectedToolId === null || selectedToolId === undefined || selectedToolId === '') {
            throw 'toolId is required';
        }
        const data = [];
        const date = new Date()
        let currentfullyear = date.getFullYear();
        let prevfullyear = currentfullyear-1;
        const start = {dayOfYear: startDate.dayOfYear(), year: startDate.year(), month: startDate.month() + 1};
        const end = {dayOfYear: endDate.dayOfYear(), year: endDate.year(), month: endDate.month() + 1};
        const toolProductivity = productivity.find(p => p.toolId === selectedToolId);
        const yearProductivity = toolProductivity.years.filter(y => start.year <= y.year && y.year <= end.year);
        let monthworkhrs = 0;

        if(start.year === end.year){
            const monthProductivity = yearProductivity !== undefined ? yearProductivity[0].months.filter(m => m.month >= start.month && m.month <= end.month) : null;
            monthProductivity.forEach(element => {
                let month = element.days.filter(d => d.day >= start.dayOfYear && d.day <= end.dayOfYear)
                let monthworkhrs = 0;
                if(month.length > 0){
                    month?.map(d => {
                        monthworkhrs = monthworkhrs+d.appHoursPerDay
                    })
                    data.push({
                        x: `${Utils.getMonthName(element.month)} ${start.year}`,
                        y:  ((monthworkhrs / (1000 * 60 * 60))).toFixed(2)
                    });
                }
            });

        } else if(prevfullyear === start.year && currentfullyear === end.year){
            yearProductivity.forEach(year => {
                let months = []
                if(year.year === start.year){
                    months = year.months.filter(m => m.month >= start.month);
                    months.sort((a,b) => a.month - b.month);
                } else {
                    months  = year.months.filter(m =>  m.month <= end.month);
                    months.sort((a,b) => a.month - b.month);
                }
                
                months && months?.forEach(m => {
                    monthworkhrs = 0;
                    if(m.month === start.month && m.year === start.year){
                        let element = [];
                        element = m.days.filter(d => d.day >= start.dayOfYear)
                        element.map( d => {
                            monthworkhrs = monthworkhrs+d.appHoursPerDay
                        })
                    } else if (m.month === end.month && m.year === end.year){
                        let element = [];
                        element = m.days.filter(d => d.day <= end.dayOfYear)
                        element.map( d => {
                            monthworkhrs = monthworkhrs+d.appHoursPerDay
                        })
                    } else {
                        monthworkhrs += m.appHoursPerMonth
                    }

                    data.push({
                        x: `${Utils.getMonthName(m.month)} ${year.year}`,
                        y: ((monthworkhrs / (1000 * 60 * 60)) ).toFixed(2)
                    });

                })
            })
        }

        return data;
    }
}
const toolSeries = new ToolSeries();
export default toolSeries;