import React, { Component } from 'react';
import ReactApexChart from "react-apexcharts"
import moment from 'moment';
import i18n from 'i18next';
import Utils from '../../utils/utils';

export class opsonboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onBoardList: [],
      terminationList: [],
      categories:[],
      seriesMonth:'',
      seriesData:'',
    };
  }

  componentDidMount(){
    i18n.on("languageChanged" , this.handleLanguageChanged);
  }

  componentDidUpdate(prevProps){
    if(this.props.empList != prevProps.empList && this.props.empList){
      this.setSeries();
    }
  }

  componentWillUnmount(){
    i18n.off("languageChanged" , this.handleLanguageChanged);
  }

  handleLanguageChanged = () => {
    if(this.state.categories.length > 0){
      this.setSeries();
    }
  }



  series = () => {
    return [
      { 

        name: i18n.t("Opsadmin.onboard"),
        data: this.state.onBoardList
      },
      {
        name: i18n.t("Opsadmin.termination"),
        data: this.state.terminationList
      }
    ];
  }

  options = () => {
    return {
      chart: this.setChart(),
      grid: this.setGrid(),
      colors: this.setColors(),
      plotOptions: { bar: this.setPlotOptions() },
      stroke: this.setStroke(),
      dataLabels: this.setDataLabels(),
      xaxis: this.setXaxis(),
      yaxis: this.setYaxis(),
      fill: this.setOpacity(),
      tooltip: this.setToolTip(),
      legend : this.setLegend(),
      title : this.setTitle(),
    };
  }

  setChart = () => {
    return {
      events : {
        click : (event, chartContext, config) => {
          if(event.target.parentElement.getAttribute("data:realIndex") == 0 && this.props.selectedSeries == ''){
            this.setState({seriesMonth : config.globals.labels[config.dataPointIndex] , seriesData: 'Onboard'});
            this.props.handleMonthSeries('Onboard' , config.globals.labels[config.dataPointIndex]);
          } else if(event.target.parentElement.getAttribute("data:realIndex") == 1 && this.props.selectedSeries == ''){
            this.setState({seriesMonth : config.globals.labels[config.dataPointIndex] , seriesData:'Termination'});
            this.props.handleMonthSeries('Termination' , config.globals.labels[config.dataPointIndex]);
          }
        }
      }
    };
  };
  setTitle = () => {
    return {
      align: 'center',
      margin: 10
    }
  };
  setStroke = () => {
    return {
      show: true,
      radius: 2,
      width: [0, 2],
      colors: ["#fff"]
    }
  };
  setGrid = () => {
    return { 
          bottom: 20,
          top: 0,
          left: 6,
          show: false,
      };
  };

  setLegend = () => {
    return {
      position: "bottom",
      horizontalAlign: true,
      chart: {
        legendPosition: "center",
        legendAllowDrag: "2"
      },
      markers: {
        width: 15,
        height: 15,
      
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: '50%',
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      }
    }
  }

  setDataLabels = () => {
    return { 
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"]
      }
    };
  };

  setColors = () => {
    return ["#4F5FE2", "#09C1F0"];
  };

  setPlotOptions = () => {
    return {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
        columnWidth: 16,
        width: 25,
        dataLabels: {
          position: "top"
        }
      }
    }
  };

  setXaxis = () => {
    return {
      type: "category",
      tickPlacement: "between",
      categories : this.state.categories,
      
      labels: {
        enabled: true,
        rotate: -45,
        rotateAlways: true,
        formatter: (val)  => {
          return val
        },
      },
      
    };
  };


  setYaxis = () => {
    return {
      axisBorder: { show: true },
      tooltip: { enabled: false, offsetX: 0 },
      labels: { formatter: (val) =>Math.round(val) },
      title: {
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
      },
    };
  };

  setOpacity = () => {
    return { opacity: 1 };
  };

  setFill = () => {
    return { opacity: 1 };
  };

  setToolTip = () => {
    return {
      y: {
        formatter: function (val) {
          return `${val}`;
        },
      },
    };
  };

  setSeries = () => {
    let empList = this.props.empList;
    let month=this.props.month;
    let year=this.props.year;
    let onBoardList = [];
    let terminationList = [];
    let monthNameList = [];
    let monthList = empList?.map(q => q.createdDate!=null? moment(q.createdDate).format(this.props.selectedSeries == "" ? 'MM' : 'DD'):"");
    monthList = [... new Set(monthList)];
    monthList.map(Obj=>{
      let onboardCount=0;
      let terminationCount=0;
      empList.map(empObj=>{
          if(Obj===moment(empObj.createdDate).format(this.props.selectedSeries == "" ? 'MM' : 'DD')){
          empObj.active===true?onboardCount=onboardCount+1:terminationCount=terminationCount+1;
          monthNameList.push(
            this.props.selectedSeries == "" ? Utils.getMonthName(parseInt(Obj)) : Obj+' - '+month+' - '+year
          );
          monthNameList = [... new Set(monthNameList)];
          
      }
      })
      onBoardList.push(onboardCount);
      terminationList.push(terminationCount);
    })
    this.setState({onBoardList : onBoardList , terminationList : terminationList , categories : monthNameList});
  }

  render() {
    return (
      <>
        {this.props?.empList?.length !== 0 ? (
          <>
          <ReactApexChart
            height="425px"
            width="100%"
            options={this.options()}
            series={this.series()}
            type="bar"
          />
        </>
        ) : (
          // <div className="text-center opsonboard-nodata-chart">
          //   <img src="../../assets/images/nodata.svg" className="nodata-img" />
          //   <h5 className="text-muted mt-3">
          //     {i18n.t("productivity.no_data")}
          //   </h5>
          // </div>
          <div className='row clear text-center manager-barchart-bar-align-nodata '>
          <div className='text-center mb-3'>  <img src="../../assets/images/nodata.svg" className="nodata-img"  />
            <h6 className="text-muted my-3">{i18n.t('productivity.no_data')}</h6></div>
        </div>
        )}
      </>
    );
  }
}

export default opsonboard;
