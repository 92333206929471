import React from 'react';

 const NoData = () => {
    return (
        
        <div className="No_dataimage_timesheet">
            <img src="../../assets/images/No-Data.svg" className="nodata_selftimg" alt="nodata" />
        </div>
      
    );
};

export default NoData;
