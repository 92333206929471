import {
  GET_ORG_LICENSE_SUCCESS,
  GET_ORG_MODULES_LICENSE_SUCCESS,
  SET_ORG_LICENSE_CREATE_SUCCESS,
  SET_ORG_LICENSE_UPDATE_SUCCESS,
  SET_ORG_LICENSE_MODULE_ACTIVATE_DEACTIVATE_SUCCESS,
  SET_ORG_LICENSE_ADD_MODULES_SUCCESS,
  SET_ORG_LICENSE_ACTIVATE_SUCCESS,
  SET_ORG_LICENSE_DEACTIVATE_SUCCESS,
  SET_ORG_LICENSE_BY_ID_SUCCESS,
  SET_ORG_LICENSE_ERROR,
} from "../constants/license";

import {
  getOrgLicenseService,
  createOrgLicenseService,
  updateOrgLicenseService,
  activateModuleOrgLicenseService,
  addModulesOrgLicenseService,
  activateOrgLicenseService,
  getModulesOrgLicenseService,
  deactivateOrgLicenseService,
  getOrgLicenseByIdService,
} from "../services/license";

export const setOrgLicenseSuccess = (success) => {
  return {
    type: GET_ORG_LICENSE_SUCCESS,
    success,
  };
};

export const setOrgLicenseError = (error) => {
  return {
    type: SET_ORG_LICENSE_ERROR,
    error,
  };
};

export const setOrgLicenseCreateSucess = (success) => {
  return {
    type: SET_ORG_LICENSE_CREATE_SUCCESS,
    success,
  };
};

export const setOrgLicenseUpdateSucess = (success) => {
  return {
    type: SET_ORG_LICENSE_UPDATE_SUCCESS,
    success,
  };
};

export const setOrgLicenseModuleActivateDeactivateSuccess = (success) => {
  return {
    type: SET_ORG_LICENSE_MODULE_ACTIVATE_DEACTIVATE_SUCCESS,
    success,
  };
};

export const setOrgLicenseAddModulesSuccess = (success) => {
  return {
    type: SET_ORG_LICENSE_ADD_MODULES_SUCCESS,
    success,
  };
};

export const setOrgLicenseActivateSuccess = (success) => {
  return {
    type: SET_ORG_LICENSE_ACTIVATE_SUCCESS,
    success,
  };
};

export const setOrgLicenseDeactivateSuccess = (success) => {
  return {
    type: SET_ORG_LICENSE_DEACTIVATE_SUCCESS,
    success,
  };
};

export const setOrgModulesLicenseSuccess = (success) => {
  return {
    type: GET_ORG_MODULES_LICENSE_SUCCESS,
    success,
  };
};

export const setOrgLicenseByIdSuccess = (success) => {
  return {
    type: SET_ORG_LICENSE_BY_ID_SUCCESS,
    success,
  };
};

export const getOrgLicense = (data) => (dispatch) => {
  dispatch(setOrgLicenseError(null));
  dispatch(setOrgLicenseSuccess(null));
  getOrgLicenseService(
    data,
    (response) => {
      dispatch(setOrgLicenseSuccess(response.data));
      dispatch(setOrgLicenseError(null));
    },
    (error) => {
      dispatch(setOrgLicenseError(error));
    }
  );
};

export const getOrgLicenseModules = () => (dispatch) => {
  dispatch(setOrgLicenseError(null));
  dispatch(setOrgModulesLicenseSuccess(null));
  getModulesOrgLicenseService(
    (response) => {
      dispatch(setOrgModulesLicenseSuccess(response.data));
      dispatch(setOrgLicenseError(null));
    },
    (error) => {
      dispatch(setOrgLicenseError(error));
    }
  );
};

export const createOrgLicense = (data) => (dispatch) => {
  dispatch(setOrgLicenseError(null));
  dispatch(setOrgLicenseCreateSucess(null));
  createOrgLicenseService(
    data,
    (response) => {
      dispatch(setOrgLicenseCreateSucess(response.data));
      dispatch(setOrgLicenseError(null));
    },
    (error) => {
      dispatch(setOrgLicenseError(error));
    }
  );
};

export const updateOrgLicense = (data) => (dispatch) => {
  dispatch(setOrgLicenseError(null));
  dispatch(setOrgLicenseUpdateSucess(null));
  updateOrgLicenseService(
    data,
    (response) => {
      dispatch(setOrgLicenseUpdateSucess(response.data));
      dispatch(setOrgLicenseError(null));
    },
    (error) => {
      dispatch(setOrgLicenseError(error));
    }
  );
};

export const actDeactModuleOrgLicense = (data) => (dispatch) => {
  dispatch(setOrgLicenseError(null));
  dispatch(setOrgLicenseModuleActivateDeactivateSuccess(null));
  activateModuleOrgLicenseService(
    data,
    (response) => {
      dispatch(setOrgLicenseError(null));
      dispatch(setOrgLicenseModuleActivateDeactivateSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgLicenseError(error));
    }
  );
};

export const addModulesOrgLicense = (data) => (dispatch) => {
  dispatch(setOrgLicenseError(null));
  dispatch(setOrgLicenseAddModulesSuccess(null));
  addModulesOrgLicenseService(
    data,
    (response) => {
      dispatch(setOrgLicenseError(null));
      dispatch(setOrgLicenseAddModulesSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgLicenseError(error));
    }
  );
};

export const activateOrgLicense = (data) => (dispatch) => {
  dispatch(setOrgLicenseError(null));
  dispatch(setOrgLicenseActivateSuccess(null));
  activateOrgLicenseService(
    data,
    (response) => {
      dispatch(setOrgLicenseError(null));
      dispatch(setOrgLicenseActivateSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgLicenseError(error));
    }
  );
};

export const deactivateOrgLicense = (data) => (dispatch) => {
  dispatch(setOrgLicenseError(null));
  dispatch(setOrgLicenseDeactivateSuccess(null));
  deactivateOrgLicenseService(
    data,
    (response) => {
      dispatch(setOrgLicenseError(null));
      dispatch(setOrgLicenseDeactivateSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgLicenseError(error));
    }
  );
};

export const getOrgLicenseById = (data) => (dispatch) => {
  dispatch(setOrgLicenseError(null));
  dispatch(setOrgLicenseByIdSuccess(null));
  getOrgLicenseByIdService(
    data,
    (response) => {
      dispatch(setOrgLicenseError(null));
      dispatch(setOrgLicenseByIdSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgLicenseError(error));
    }
  );
};
