import {
  SET_ORG_DOMAINS,
  SET_ORG_LIST_SUCCESS,
  SET_ORG_CREATE_SUCCESS,
  SET_CREATE_USER_SUCCESS,
  SET_USER_LIST_SUCCESS,
  SET_ENABLE_ORG_SUCCESS,
  SET_DISABLE_ORG_SUCCESS,
  SET_ORG_LIST_ERROR,
  SET_DEPT_LIST_SUCCESS,
  SET_AUTH_TYPE_CREATE_SUCCESS,
  SET_AUTH_TYPE_LIST_SUCCESS,
  SET_ORG_CREATE_DOMAIN_SUCCESS,
  SET_ORG_MODULES_BY_ID_SUCCESS,
  SET_MODULES_ROUTE_SUCCESS,
  SET_DEPT_BY_ORG_SUCCESS
} from "../constants/organization";
import {
  orgListService,
  deptListService,
  createOrgService,
  createUserService,
  createDomainService,
  getUserService,
  orgEnable,
  orgDisable,
  createAuthTypeService,
  getAuthTypeService,
  getOrgDomainsList,
  getOrgModulesByIdService,
  getDeptByOrgService,
} from "../services/organization";

const setOrgListSuccess = (response) => {
  return {
    type: SET_ORG_LIST_SUCCESS,
    response,
  };
};

const setDeptListSuccess = (response) => {
  return {
    type: SET_DEPT_LIST_SUCCESS,
    response,
  };
};

const setCreateOrgSuccess = (response) => {
  return {
    type: SET_ORG_CREATE_SUCCESS,
    response,
  };
};

const setCreateUserSuccess = (response) => {
  return {
    type: SET_CREATE_USER_SUCCESS,
    response,
  };
};

const setUserListSuccess = (response) => {
  return {
    type: SET_USER_LIST_SUCCESS,
    response,
  };
};

const setEnableOrgSuccess = (response) => {
  return {
    type: SET_ENABLE_ORG_SUCCESS,
    response,
  };
};

const setDisableOrgSuccess = (response) => {
  return {
    type: SET_DISABLE_ORG_SUCCESS,
    response,
  };
};

//Oneable services[Oneable,Ad,Azure etc...]
const setAuthTypeListSuccess = (response) => {
  return {
    type: SET_AUTH_TYPE_LIST_SUCCESS,
    response,
  };
};

const setCreateAuthTypeSuccess = (response) => {
  return {
    type: SET_AUTH_TYPE_CREATE_SUCCESS,
    response,
  };
};

const setOrgListError = (error) => {
  return {
    type: SET_ORG_LIST_ERROR,
    error,
  };
};
const setOrgDomainsSucess = (success) => {
  return {
    type: SET_ORG_DOMAINS,
    success,
  };
};

const setOrgDomainsError = (error) => {
  return {
    type: SET_ORG_DOMAINS,
    error,
  };
};

export const setOrgCreateDomainSuccess = (success) => {
  return {
    type: SET_ORG_CREATE_DOMAIN_SUCCESS,
    success
  }
}

export const setOrgModulesByIdSuccess = (success) => {
  return {
    type: SET_ORG_MODULES_BY_ID_SUCCESS,
    success
  }
}

export const setRouteModulesSuccess = (success) => {
  return {
    type : SET_MODULES_ROUTE_SUCCESS,
    success
  }
}

export const orgRouteModules = (data) => (dispatch) => {
  dispatch(setRouteModulesSuccess(data));
}

export const setDeptByOrgSuccess = (success) => {
  return {
    type : SET_DEPT_BY_ORG_SUCCESS,
    success
  }
}

export const getOrgList = (data) => (dispatch) => {
  dispatch(setOrgListError(null));
  dispatch(setOrgListSuccess(null));
  orgListService(
    data,
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setOrgListSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgListError(error));
    }
  );
};

export const getDeptList = () => (dispatch) => {
  dispatch(setOrgListError(null));
  dispatch(setDeptListSuccess(null));
  deptListService(
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setDeptListSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgListError(error));
    }
  );
};

export const createOrganization = (data) => (dispatch) => {
  dispatch(setOrgListError(null));
  dispatch(setCreateOrgSuccess(null));
  createOrgService(
    data,
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setCreateOrgSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgListError(error));
    }
  );
};

export const createUSer = (data) => (dispatch) => {
  dispatch(setOrgListError(null));
  dispatch(setCreateUserSuccess(null));
  createUserService(
    data,
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setCreateUserSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgListError(error));
    }
  );
};

export const getUserList = (data) => (dispatch) => {
  dispatch(setOrgListError(null));
  dispatch(setUserListSuccess(null));
  getUserService(
    data,
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setUserListSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgListError(error));
    }
  );
};
export const getOrgDomainsLists = () => (dispatch) => {
  dispatch(setOrgDomainsSucess(null));
  dispatch(setOrgDomainsError(null));
  getOrgDomainsList(
    (response) => {
      dispatch(setOrgDomainsError(null));
      dispatch(setOrgDomainsSucess(response.data));
    },
    (error) => {
      dispatch(setOrgDomainsError(error));
    }
  );
};
export const enableOrg = (data) => (dispatch) => {
  dispatch(setOrgListError(null));
  dispatch(setEnableOrgSuccess(null));
  dispatch(setDisableOrgSuccess(null));
  orgEnable(
    data,
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setEnableOrgSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgListError(error));
    }
  );
};

export const disableOrg = (data) => (dispatch) => {
  dispatch(setOrgListError(null));
  dispatch(setDisableOrgSuccess(null));
  dispatch(setEnableOrgSuccess(null));
  orgDisable(
    data,
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setDisableOrgSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgListError(error));
    }
  );
};

export const getOrgModulesById = () => (dispatch) => {
  dispatch(setOrgModulesByIdSuccess(null));
  dispatch(setOrgListError(null));
  getOrgModulesByIdService(
    (response) => {
      dispatch(setOrgModulesByIdSuccess(response.data));
      dispatch(setOrgListError(null));
    },(error) => {
      dispatch(setOrgListError(error));
    }
  )
}

//Auth type list
export const getAuthList = () => (dispatch) => {
  dispatch(setOrgListError(null));
  dispatch(setAuthTypeListSuccess(null));
  getAuthTypeService(
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setAuthTypeListSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgListError(error));
    }
  );
};

//Create auth type
export const createAuthType = (data) => (dispatch) => {
  dispatch(setOrgListError(null));
  dispatch(setCreateAuthTypeSuccess(null));
  createAuthTypeService(
    data,
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setCreateAuthTypeSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgListError(error));
    }
  );
};


//Create Domains in superAdmin
export const domainCreate = (data) => (dispatch) => {
  dispatch(setOrgListError(null));
  dispatch(setOrgCreateDomainSuccess(null));
  createDomainService(
    data , 
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setOrgCreateDomainSuccess(response.data));
    },
    (error) =>{
      dispatch(setOrgListError(null));
    }
  );
}

export const deptbyorganization = (data) => (dispatch) => {
  dispatch(setDeptByOrgSuccess(null));
  dispatch(setOrgListError(null));
  getDeptByOrgService(
    data , 
    (response) => {
      dispatch(setOrgListError(null));
      dispatch(setDeptByOrgSuccess(response.data));
    },
    (error) => {
      dispatch(setOrgListError(error));
    }
  )
}
