import { 
    SET_PROJECTS_ERROR ,
    SET_CREATE_PROJECTS_SUCCESS, 
    SET_PROJECTS_STATUS_SUCCESS, 
} from "../constants/Projects";

const initialState = {
    projectsCreate: {},
    projectsStatus: {},
    onError: null,
};

export default (state = initialState , action) => {
    switch(action.type){

        case SET_CREATE_PROJECTS_SUCCESS : {
            return Object.assign({} , state , {
                projectsCreate:action.success ,
            });
        };

        case SET_PROJECTS_STATUS_SUCCESS : {
            return Object.assign({} , state , {
                projectsStatus:action.success ,
            });
        };

        case SET_PROJECTS_ERROR : {
            return Object.assign({} , state , {
                onError:action.error ,
            });
        };

        default: 
            return {...state};
    };
};