import { toast ,Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { closeWindows } from "../../utils/childWindowUtils";
import collaboration from "../../services/collaboration";
import {logout} from './Header'

const handleApiError = async (error, props) => {
    const { t } = props;
    if (error instanceof Response) {
        const status = error.status;
        const responseText = await error.text();
        const userData = sessionStorage.getItem("userdata");
        try {
            const responseData = JSON.parse(responseText);

            switch (status) {
                case 401:
                    toast(t("errorMessages.unauthorized"), { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
                    logout()
                    props.history.push('/email');
                    break;
                case 404:
                    toast(t("errorMessages.not_found"), { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
                    break;
                default:
                    /** Display the error message from the API response*/
                    const errorMessage = responseData.message || responseData.error || responseData.data?.errorMessage || t("errorMessages.req_failed");
                    if (!toast.isActive('apiError')) {
                        toast(errorMessage, { toastId: 'apiError', type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
                    }
                    if (responseData.code === "1001" || responseData.code === "1002" || responseData.message === "Session has expired.") {
                        let parseUserData = JSON.parse(window.atob(userData));
                        if (window && window.process && window.process.type) {
                            const { ipcRenderer } = window.require('electron');
                            ipcRenderer.send('closeallchildwindows');
                        } else {
                            closeWindows(parseUserData?.userId);
                        }
                        localStorage.removeItem("userData");
                        localStorage.removeItem("orgmodules");
                        sessionStorage.clear();
                        collaboration.socket.disconnect();
                        props.history.push('/email')
                    }
                    break;
            }
        } catch (jsonError) {
            /** If parsing JSON fails, display a generic error message*/
            toast(t("errorMessages.parse_error"), { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
        }
    } else {
        /**Handle other types of errors */ 
        toast(t("errorMessages.fetch_error"), { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
    }
};

export default handleApiError;