import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { connect } from 'react-redux';
import $ from 'jquery';
import moment from 'moment';

class Toolschart extends Component {


    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    type: 'bar',
                    height: 300,
                    stacked: true,
                    id:'toolstatusperyear',
                    toolbar: {
                        show: true
                    }
                },
                dataLabels: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        columnWidth: '10%',
                        width: 5,
                    },
                },
                fill: {
                    colors: ['#8FC555','#F8B912']
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none',
                        }
                    },
                },
                yaxis: {
                    axisTicks: { show: true },
                    axisBorder: { show: true },
                    show:true,
                    title: {
                        // text: i18n.t('Opsadmin.hours'),
                        rotate: -90,
                        offsetX: 0,
                        offsetY: 0,
                        // margin:10,
                        // padding:50,
                        // style: {
                        //     color: undefined,
                        //     fontSize: '12px',
                        //     fontFamily: 'Helvetica, Arial, sans-serif',
                        //     fontWeight: 600,
                        //     cssClass: 'apexcharts-yaxis-title',
                        // },
                    },
                },
                xaxis: {
                    axisTicks: { show: true },
                    axisBorder: { show: true },
                    categories: []
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    
                        barHeight: 20 ,
                       
                    }
                },
                legend: {
                    show: false
                },
                grid: {
                    bottom: 20,
                    top: 0,
                    left: 6,
                    show: false,
                }
            },
            series: []
        };
    }
   
    
    render() {
        {
            
            let uniqueYearList=[];
            let allocatedArray=[];
            let deAllocatedArray=[];
            const options = JSON.parse(JSON.stringify(this.state.options));
            if(!$.isEmptyObject(this.props.data)){
               let yearArray = this.props.data.tools.map(q =>
                q.createdDate!=null? moment(q.createdDate).format('YYYY'):"");
                
                let uniqueYear= [...new Set(yearArray)];
                uniqueYearList =  uniqueYear.filter(e =>  e);
                 uniqueYearList.map(yearObj=>{
                     let allocatedCount=0;
                     let deAllocatedCount=0;
                    this.props.data.tools.map(toolObj=>{
                        if(yearObj===moment(toolObj.createdDate).format('YYYY')){
                            toolObj.active===true?allocatedCount=allocatedCount+1:deAllocatedCount=deAllocatedCount+1;
                    }
                    })
                    allocatedArray.push(allocatedCount);
                    deAllocatedArray.push(deAllocatedCount);
                 })
                
               
            }
            options.xaxis.categories = uniqueYearList;
            const series = [
                {
                    name: "Allocated",
                    data: allocatedArray
                },
                {
                    name: "De-Allocated",
                    data: deAllocatedArray
                }
            ]

        return (
            <div>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    height="420px"
                />
            </div>
        );
        }
    }
}

export default Toolschart;