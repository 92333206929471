import {
    GET_USER_SESSION_COUNTS_SUCCESS,
    GET_USER_VIDEO_DETAILS_SUCCESS,
    GET_VIDEO_CONSENT_ACCEPT_SUCCESS,
    SET_VIEW_RECORDED_SESSIONS_SUCCESS,
    SET_RECORDED_SESSIONS_ERROR,
    SET_DELETE_RECORDED_SESSIONS_SUCCESS,
    SET_PLAY_RECORDED_SESSIONS_SUCCESS,
} from "../constants/recordedsessions";

const initialState={
    userSessionCountList:[],
    userVideoDetailsList : [],
    videoConsent: {},
    deleteRecordedSession:{},
    viewedRecordedSession: {},
    playRecordedSession: {},
    onError: null,
}

export default (state= initialState, action)=>{
    switch(action.type){
        case GET_USER_SESSION_COUNTS_SUCCESS: {
            return Object.assign({}, state, {
                userSessionCountList: action.response,
            });
        }

        case GET_USER_VIDEO_DETAILS_SUCCESS : {
            return Object.assign({} , state , {
                userVideoDetailsList : action.response,
            });
        }

        case GET_VIDEO_CONSENT_ACCEPT_SUCCESS : {
            return Object.assign({} , state , {
                videoConsent : action.response
            })
        }

        case SET_RECORDED_SESSIONS_ERROR: {
            return Object.assign({}, state, {
                onError: action.error,
            });
        }
        
        case SET_VIEW_RECORDED_SESSIONS_SUCCESS : {
            return Object.assign({} , state , {
                viewedRecordedSession : action.response
            });
        }

        case SET_DELETE_RECORDED_SESSIONS_SUCCESS : {
            return Object.assign({} , state , {
                deleteRecordedSession: action.response
            });
        }

        case SET_PLAY_RECORDED_SESSIONS_SUCCESS : {
            return Object.assign({} , state , {
                playRecordedSession : action.response
            });
        }

        default:
        return { ...state };
    }
}