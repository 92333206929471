export const PRODUCTIVITY_METADATA = {
    DAILY: 'DAILY', 
    MONTHLY: 'MONTHLY', 
    YEARLY: 'YEARLY',
    USER_DAY: 'USER_DAY',
    USER_MONTHLY: 'USER_MONTHLY',
    USER_YEARLY: 'USER_YEARLY',
    TOOL_DAY: 'TOOL_DAY',
    TOOL_MONTHLY: 'TOOL_MONTHLY',
    TOOL_YEARLY: 'TOOL_YEARLY'
};