import {
    SET_ORG_MODULES_SUCCESS,
    SET_ORG_MODULES_ERROR,
    SET_ORG_MODULES_CREATE_SUCCESS,
    SET_ORG_MODULES_UPDATE_SUCCESS,
    SET_ORG_MODULES_DELETE,
    SET_ORG_MODULES_ACTIVATE,
} from "../constants/modules"


const initialState={
    moduleList:[],
    createModule:{},
    deleteModule:'',
    activateModule:'',
    onError: null,
}

export default (state= initialState, action)=> {
    switch (action.type) {
      case SET_ORG_MODULES_SUCCESS: {
        return Object.assign({}, state, {
            moduleList: action.response,
        });
      }
      case SET_ORG_MODULES_ERROR: {
        return Object.assign({}, state, {
            onError: action.error,
        });
      }
     
      case SET_ORG_MODULES_CREATE_SUCCESS: {
        return Object.assign({}, state, {
          createModule: action.response,
        });
      }
      case SET_ORG_MODULES_UPDATE_SUCCESS: {
        return Object.assign({}, state, {
          updateModule: action.response,
        });
      }
      case SET_ORG_MODULES_DELETE: {
        return Object.assign({}, state, {
          deleteModule: action.response,
        });
      }
      case SET_ORG_MODULES_ACTIVATE: {
        return Object.assign({}, state, {
          activateModule: action.response,
        });
      }
     
      default:
        return { ...state };
    }
  };