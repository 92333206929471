export const SET_PRODUCTIVITY_PENDING = 'SET_PRODUCTIVITY_PENDING';
export const SET_PRODUCTIVITY_SUCCESS = 'SET_PRODUCTIVITY_SUCCESS';
export const SET_USER_PRODUCTIVITY_SUCCESS = 'SET_USER_PRODUCTIVITY_SUCCESS';
export const SET_TOOL_PRODUCTIVITY_SUCCESS = 'SET_TOOL_PRODUCTIVITY_SUCCESS';
export const SET_USER_TOOL_PRODUCTIVITY_SUCCESS = 'SET_USER_TOOL_PRODUCTIVITY_SUCCESS';
export const SET_TOOL_NO_PRODUCTIVITY_SUCCESS = 'SET_TOOL_NO_PRODUCTIVITY_SUCCESS';
export const SET_EMPLOYEE_TOOL_PRODUCTIVITY_SUCCESS = 'SET_EMPLOYEE_TOOL_SUCCESS';
export const SET_PRODUCTIVITY_ERROR = 'SET_PRODUCTIVITY_ERROR';

export const SET_PRODUCTIVITY_SERIES = 'SET_PRODUCTIVITY_SERIES';
export const SET_PRODUCTIVITY_FILTER = 'SET_PRODUCTIVITY_FILTER';

export const SET_PRODUCTIVITY_TOOL_SERIES = 'SET_PRODUCTIVITY_TOOL_SERIES';
export const SET_PRODUCTIVITY_TOOL_FILTER = 'SET_PRODUCTIVITY_TOOL_FILTER';

export const SET_USER_TOOL_PRODUCTIVITY_FOR_CSV = 'SET_USER_TOOL_PRODUCTIVITY_FOR_CSV';