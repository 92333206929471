import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { convertMilliSecondsToHours, precisionRound } from '../../utils';
import i18n from 'i18next';

class Piechart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [50, 50],
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                    id:'worktimecurmonth',
                    toolbar: {
                        show: true
                    }
                },
                states: {
                    hover: {
                        filter: {
                            type: 'none'
                        }
                    }
                },
                colors: ['#8FC555', '#F8B912'],
                labels: [i18n.t('OrgAdmin.cruiser_hours'), i18n.t('OrgAdmin.think_time')],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom'
                        },

                    }
                }],
                legend: {
                    position: 'bottom',
                    markers: {
                        radius: 0
                    },
                    onItemHover: {
                        highlightDataSeries: false
                    },
                    onItemClick: {
                        toggleDataSeries: false
                    },

                },
            },

        };
    }

    componentDidMount() {
        i18n.on("languageChanged", this.handleLanguageChange);
      }
     
      componentWillUnmount() {
        i18n.off("languageChanged", this.handleLanguageChange);
      }
     
      handleLanguageChange = () => {
        this.setState({
          ...this.state,
          options: {
            ...this.state.options,
            labels: [
              i18n.t("OrgAdmin.cruiser_hours"),
              i18n.t("OrgAdmin.think_time"),
            ],
          },
        });
      };

    // shouldComponentUpdate(nextProps, nextState) {
    //     return this.props.data !== nextProps.data
    // }

    render() {

        const timesheetSummary = this.props.data.timesheetSummary || {};
        const userProductivities = this.props.data.userProductivities;
        const date = new Date();
        const currYear = date.getFullYear();
        const currMonth = date.getMonth() + 1;
        let totalProductivityperMonthmSecs = 0;
        let numberOfDaysWorked = 0;
        Array.isArray(userProductivities) && userProductivities.forEach(user => {

            user.years.map(yearData => {
                if (yearData.year === currYear) {
                    // console.log("userProductivities", { user, yearData });
                    yearData.months.map(monthData => {
                        if (monthData.month === currMonth) {
                            totalProductivityperMonthmSecs += monthData.totalWorkingHoursPerMonth;
                            numberOfDaysWorked += monthData.days.length;
                        }
                    })
                }
            });
        })
        const crusierHours = convertMilliSecondsToHours(totalProductivityperMonthmSecs)
        //const workedHours = (timesheetSummary.userTotalWorkingDays) * (timesheetSummary.workTime )|| 8;
        const workedHours = numberOfDaysWorked * 8;
        //const totalOfcHours = crusierHours + workedHours;
        /*const cHrs = (crusierHours / totalOfcHours) * 100;
        const wHrs = (workedHours / totalOfcHours) * 100;*/

        let idleHrs = workedHours - crusierHours;
        let totalTime = idleHrs + crusierHours * 1.0;
        let cHrs =0
        let thinkTime = 0;
        if (totalTime > 0) {
            cHrs = (crusierHours / totalTime) * 100;
            thinkTime = (idleHrs / totalTime) * 100;
        }
        const series = [precisionRound(cHrs, 2), precisionRound(thinkTime, 2)];
        // const thinkHours = 100 - (cHrs + wHrs);
        console.log("piechart", { cHrs: precisionRound(cHrs, 2), thinkTime: precisionRound(thinkTime, 2) });
        /* 
        total = 134+160=294
        cHrs  =  485552794msec=134.87hrs=(134/294)100=45%
        wHrs = 20*8=160hrs=(160/294)*100=55%
        diff = 
        */
        return (
            <div>
                {
                    series[0]!=0?
                    <ReactApexChart options={this.state.options} series={series} type="pie" height={280} />:
                    <div className='text-center nodata-alert-img' className="h250">
                        <img src="../../assets/images/nodata.svg"  className="nodata-img" />
                        <h6 className="text-muted mt-3 font-size-14">{i18n.t('productivity.no_data')}</h6>
                    </div>
                }

            </div>
            
        );
    }
}

export default (withTranslation()(Piechart));
