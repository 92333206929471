import { SET_OFFTIME,SET_OFFTIME_ERROR } from "../constants/timeoff";
import { SET_TIMEOFF_CREATE,SET_TIMEOFF_CREATE_ERROR } from "../constants/timeoff";
import {SET_TIMEOFF_UPDATE,SET_TIMEOFF_UPDATE_ERROR } from "../constants/timeoff";
import {SET_TIMEOFF_DEACTIVATE,SET_TIMEOFF_DEACTIVATE_ERROR } from "../constants/timeoff";
import {SET_TIMEOFF_ACTIVATE,SET_TIMEOFF_ACTIVATE_ERROR } from "../constants/timeoff";
import { SET_TIME , SET_USER_SETTINGS_TIME_OFF } from "../constants/timeoff";


const initialState = {
    offTimeData:{},
    timeofData:{},
    timeofupdate:{},
    timeoffdeactivate:{},
    timeoffactivate:{},
    time:'',
    userSettingsTimeOff : {},
    onError: null
}
export default (state = initialState, action) => {
    switch(action.type) {

        case SET_OFFTIME: {
            return Object.assign({}, state, {
				offTimeData: action.response
			});
        }

        case SET_OFFTIME_ERROR: {
            return Object.assign({}, state, {
				onError: action.error
			});
        }
        
        case SET_TIMEOFF_CREATE: {
            return Object.assign({}, state, {
                timeofData: action.success
			});
        }

        case SET_TIMEOFF_CREATE_ERROR: {
            return Object.assign({}, state, {
				onError: action.error
			});
        }  
        case SET_TIMEOFF_UPDATE: {
            return Object.assign({}, state, {
                timeofupdate: action.response
			});
        }

        case SET_TIMEOFF_UPDATE_ERROR: {
            return Object.assign({}, state, {
				onError: action.error
			});
        }  
        case SET_TIMEOFF_DEACTIVATE: {
            return Object.assign({}, state, {
                timeoffdeactivate: action.response
			});
        }

        case SET_TIMEOFF_DEACTIVATE_ERROR: {
            return Object.assign({}, state, {
				onError: action.error
			});
        }

        case SET_TIMEOFF_ACTIVATE: {
            return Object.assign({}, state, {
                timeoffactivate: action.response
			});
        }

        case SET_TIMEOFF_ACTIVATE_ERROR: {
            return Object.assign({}, state, {
				onError: action.error
			});
        }

        case SET_TIME: {
            return Object.assign({} , state , {
                time: action.response
            })
            
        }

        case SET_USER_SETTINGS_TIME_OFF : 
        state = {
            ...state , 
            userSettingsTimeOff : action.response
        }
        return state;

        
        default:
            return {...state} 
    }
}