export const SET_COLLABORATION_LOADING_SUCCESS =
  "SET_COLLABORATION_LOADING_SUCCESS";
export const SET_WORKSPACE_LOADING = "SET_WORKSPACE_LOADING";
export const SET_CREATE_WORKSPACE_SUCCESS = "SET_CREATE_WORKSPACE_SUCCESS";
export const SET_WORKSPACE_READ_SUCCESS = "SET_WORKSPACE_READ_SUCCESS";
export const SET_SELECTED_WORKSPACE_SUCCESS = "SET_SELECTED_WORKSPACE_SUCCESS";
export const SET_UPDATE_WORKSPACE_SUCCESS = "SET_UPDATE_WORKSPACE_SUCCESS";
export const SET_DELETE_WORKSPACE_SUCCESS = "SET_DELETE_WORKSPACE_SUCCESS";
export const SET_WORKSPACE_SUBSCRIPTION_INVITE_SUCCESS =
  "SET_WORKSPACE_SUBSCRIPTION_INVITE_SUCCESS";
export const SET_ACCEPT_WORKSPACE_SUCCESS = "SET_ACCEPT_WORKSPACE_SUCCESS";
export const SET_REJECT_WORKSPACE_SUCCESS = "SET_REJECT_WORKSPACE_SUCCESS";
export const SET_LIST_WORKSPACE_SUCCESS = "SET_LIST_WORKSPACE_SUCCESS";
export const SET_WORKSPACE_USERS = "SET_WORKSPACE_USERS";
export const SET_WORKSPACE_SUBSCRIBERS_SUCCESS =
  "SET_WORKSPACE_SUBSCRIBERS_SUCCESS";
export const SET_WORKSPACE_ERROR = "SET_WORKSPACE_ERROR";
export const SET_SOCKET_CONNECTION_ERROR = "SET_SOCKET_CONNECTION_ERROR";
export const SET_DEACTIVATE_WORKSPACE_SUCCESS =
  "SET_DEACTIVATE_WORKSPACE_SUCCESS";
export const SET_ACTIVATE_WORKSPACE_SUCCESS = "SET_ACTIVATE_WORKSPACE_SUCCESS";
export const SET_WORKSPACE_SUBSCRIPTION_ACTIVATE_SUCCESS =
  "SET_WORKSPACE_SUBSCRIPTION_ACTIVATE_SUCCESS";
export const SET_WORKSPACE_SUBSCRIPTION_DEACTIVATE_SUCCESS =
  "SET_WORKSPACE_SUBSCRIPTION_DEACTIVATE_SUCCESS";
export const SET_WORKSPACE_SUBSCRIPTION_ERROR =
  "SET_WORKSPACE_SUBSCRIPTION_ERROR";
export const SET_WORKSPACE_SUBSCRIPTION_INVITE_ERROR =
  "SET_WORKSPACE_SUBSCRIPTION_INVITE_ERROR";
export const SET_WORKSPACE_SUBSCRIPTION_INVITE_LOADING =
  "SET_WORKSPACE_SUBSCRIPTION_INVITE_LOADING";
export const SET_WORKSPACE_SUBSCRIPTION_LOADING =
  "SET_WORKSPACE_SUBSCRIPTION_LOADING";
export const SET_WORKSPACE_SUBSCRIPTION_READ_SUBSCRIBER_SUCCESS =
  "SET_WORKSPACE_SUBSCRIPTION_READ_SUBSCRIBER_SUCCESS";
export const SET_WORKSPACE_SUBSCRIPTION_READ_SUCCESS =
  "SET_WORKSPACE_SUBSCRIPTION_READ_SUCCESS";
export const SET_WORKSPACE_SUBSCRIPTION_UNSUBSCRIBE_SUCCESS =
  "SET_WORKSPACE_SUBSCRIPTION_UNSUBSCRIBE_SUCCESS";

export const SET_ROOM_BROWSE_SUCCESS = "SET_ROOM_BROWSE_SUCCESS";
export const SET_ROOM_CHANNEL_CREATE_SUCCESS =
  "SET_ROOM_CHANNEL_CREATE_SUCCESS";
export const SET_ROOM_DELETE_SUCCESS = "SET_ROOM_DELETE_SUCCESS";
export const SET_ROOM_SUBSCRIBE_SUCCESS = "SET_ROOM_SUBSCRIBE_SUCCESS";
export const SET_ROOM_UNSUBSCRIBE_SUCCESS = "SET_ROOM_UNSUBSCRIBE_SUCCESS";
export const SET_ROOM_DIRECT_CREATE_SUCCESS = "SET_ROOM_DIRECT_CREATE_SUCCESS";
export const SET_ROOM_ERROR = "SET_ROOM_ERROR";
export const SET_ROOM_GROUP_CREATE_SUCCESS = "SET_ROOM_GROUP_CREATE_SUCCESS";
export const SET_SELECTED_ROOM_SUCCESS = "SET_SELECTED_ROOM_SUCCESS";
export const SET_INVITE_USERS_TO_ROOM = "SET_INVITE_USERS_TO_ROOM";
export const SET_ROOMWISE_USERS = "SET_ROOMWISE_USERS";
export const SET_ROOM_LOADING = "SET_ROOM_LOADING";
export const SET_ROOM_READ_SUCCESS = "SET_ROOM_READ_SUCCESS";
export const SET_ROOM_UPDATE_SUCCESS = "SET_ROOM_UPDATE_SUCCESS";
export const SET_ROOM_SUBSCRIPTION_ACTIVATE_SUCCESS =
  "SET_ROOM_SUBSCRIPTION_ACTIVATE_SUCCESS";
export const SET_ROOM_SUBSCRIPTION_DEACTIVATE_SUCCESS =
  "SET_ROOM_SUBSCRIPTION_DEACTIVATE_SUCCESS";
export const SET_ROOM_SUBSCRIPTION_ERROR = "SET_ROOM_SUBSCRIPTION_ERROR";
export const SET_ROOM_SUBSCRIPTION_LOADING = "SET_ROOM_SUBSCRIPTION_LOADING";
export const SET_ROOM_SUBSCRIPTION_PARTICIPANTS_SUCCESS =
  "SET_ROOM_SUBSCRIPTION_PARTICIPANTS_SUCCESS";
export const SET_ROOM_SUBSCRIPTION_READ_SUCCESS =
  "SET_ROOM_SUBSCRIPTION_READ_SUCCESS";

export const SET_CREATE_CHANNEL_SUCCESS = "SET_CREATE_CHANNEL_SUCCESS";
export const SET_CREATE_DIRECT_ROOM_SUCCESS = "SET_CREATE_DIRECT_ROOM_SUCCESS";
export const SET_CREATE_GROUP_SUCCESS = "SET_CREATE_GROUP_SUCCESS";
export const SET_ACTIVATE_GROUP_SUCCESS = "SET_ACTIVATE_GROUP_SUCCESS";
export const SET_SELECTED_CHANNEL_SUCCESS = "SET_SELECTED_CHANNEL_SUCCESS";
export const SET_UPDATE_CHANNEL_SUCCESS = "SET_UPDATE_CHANNEL_SUCCESS";
export const SET_DELETE_CHANNEL_SUCCESS = "SET_DELETE_CHANNEL_SUCCESS";
export const SET_LIST_CHANNEL_SUCCESS = "SET_LIST_CHANNEL_SUCCESS";
export const SET_CHANNEL_SUBSCRIBERS_SUCCESS =
  "SET_CHANNEL_SUBSCRIBERS_SUCCESS";
export const SET_CHANNEL_ERROR = "SET_CHANNEL_ERROR";
export const SET_BROWSER_LIST_CHANNEL_SUCCESS =
  "SET_BROWSER_LIST_CHANNEL_SUCCESS";
export const SET_SUBSCRIBE_USER_TO_PUBLIC_CHANNEL_SUCCESS =
  "SET_SUBSCRIBE_USER_TO_PUBLIC_CHANNEL_SUCCESS";
export const SET_UNSUBSCRIBE_USER_TO_CHANNEL_SUCCESS =
  "SET_UNSUBSCRIBE_USER_TO_CHANNEL_SUCCESS";

export const SET_COLLABORATION_MESSAGE_ERROR =
  "SET_COLLABORATION_MESSAGE_ERROR";
export const SET_COLLABORATION_MESSAGE_LOADING =
  "SET_COLLABORATION_MESSAGE_LOADING";
export const SET_COLLABORATION_MESSAGE_READ_SUCCESS =
  "SET_COLLABORATION_MESSAGE_READ_SUCCESS";
export const SET_COLLABORATION_MESSAGE_SEND_SUCCESS =
  "SET_COLLABORATION_MESSAGE_SEND_SUCCESS";

export const SET_CHAT_HISTORY_SUCCESS = "SET_CHAT_HISTORY_SUCCESS";
export const SET_SELECTED_CHAT_SUCCESS = "SET_SELECTED_CHAT_SUCCESS";

export const SET_SOCKET_CONNECTION_SUCCESS = "SET_SOCKET_CONNECTION_SUCCESS";

export const SET_SELECTED_DATA_SUCCESS = "SET_SELECTED_DATA_SUCCESS";
export const SET_SELECTED_DATA_WORKSPACE_SUCCESS =
  "SET_SELECTED_DATA_WORKSPACE_SUCCESS";
export const SET_SELECTED_DATA_CHANNEL_SUCCESS =
  "SET_SELECTED_DATA_CHANNEL_SUCCESS";

export const SET_NEW_MESSAGE_SUCCESS = "SET_NEW_MESSAGE_SUCCESS";
export const SET_ROOMS_MESSAGE_INFO = "SET_ROOMS_MESSAGE_INFO";

export const SET_DEACTIVE_USERS = "SET_DEACTIVE_USERS";
