export default class Whiteboard {
  publishCanvas(data, success, error) {
    // window.oneAbleCollaboration.whiteboard.publish(
    //   {
    //     roomId: "632da3317d4d3b5205a35716",
    //     body: JSON.stringify(data),
    //   },
    //   success,
    //   error
    // );
  }
}
