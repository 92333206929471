import { useSelector } from "react-redux";
import {
  SET_COLLABORATION_LOADING_SUCCESS,
  SET_LIST_WORKSPACE_SUCCESS,
  SET_NEW_MESSAGE_SUCCESS,
  SET_SELECTED_WORKSPACE_SUCCESS,
  SET_SOCKET_CONNECTION_SUCCESS,
} from "../constants/collabration";
import collaboration from "../services/collaboration";
import { getListOfParticipantsForRoom } from "../utils/collaboration";
import {
  setChannelSubscribersSuccess,
  setSelectedChannelSuccess,
} from "./channels";
import { getWorkSpaceSubscribers } from "./workSpace";

export const setSocketConnection = (payload) => {
  return {
    type: SET_SOCKET_CONNECTION_SUCCESS,
    payload,
  };
};

export const setListWorkSpaceSuccess = (payload) => {
  return {
    type: SET_LIST_WORKSPACE_SUCCESS,
    payload,
  };
};

export const setSelectedWorkspaceSuccess = (payload) => {
  return {
    type: SET_SELECTED_WORKSPACE_SUCCESS,
    payload,
  };
};

export const setIsCollaborationLoading = (payload) => {
  return {
    type: SET_COLLABORATION_LOADING_SUCCESS,
    payload,
  };
};

export const setNewMessage = (payload) => {
  return {
    type: SET_NEW_MESSAGE_SUCCESS,
    payload,
  };
};

export const initCollaborationCalls =
  (data, fetchChannelChat, handleIncomingMessage, handleIncomingWhiteboard) =>
  (dispatch) => {
    dispatch(setIsCollaborationLoading(true));
    collaboration.workSpace.getUserSubscribedWorkspaces(
      data,
      (workSpaceData) => {
        collaboration.channels.getSubscribedChannelList(
          data,
          (channelData) => {
            dispatch(setIsCollaborationLoading(false));
            dispatch(setListWorkSpaceSuccess(workSpaceData.data?.content));
            let channels = channelData.data?.content;
            // channels = getListOfParticipantsForRoom(channels, "roomId");
            dispatch(setChannelSubscribersSuccess(channels));
            if (channels?.length >= 0) {
              channels.map((channel) => {
                if (channel?.roomId) {
                  fetchChannelChat(channel.roomId);
                  collaboration.socket.subscribe(
                    channel?.roomId,
                    (message) => {
                      const data = {
                        isNewComingMessage: true,
                        message: message,
                      };
                      handleIncomingMessage(data);

                      // To assign event
                      const event = new CustomEvent("newMessage", {
                        detail: {
                          platform: data,
                        },
                      });

                      // To trigger the Event
                      document.dispatchEvent(event);
                    },
                    (whiteboard) => {
                      handleIncomingWhiteboard(whiteboard);
                    }
                  );
                }
              });
            }
            if (workSpaceData?.data?.content?.length >= 0) {
              dispatch(
                setSelectedWorkspaceSuccess(
                  JSON.parse(
                    window.localStorage.getItem("lastSelectedWorkspace")
                  ) || workSpaceData?.data?.content[0]
                )
              );
              let isLengthOfChannel = channelData?.data?.content?.length
                ? true
                : false;
              dispatch(
                getWorkSpaceSubscribers(
                  {
                    workSpaceId:
                      JSON.parse(
                        window.localStorage.getItem("lastSelectedWorkspace")
                      )?.workSpaceId ||
                      workSpaceData?.data?.content[0].workSpaceId,
                  },
                  !isLengthOfChannel
                )
              );
              if (isLengthOfChannel) {
                const { selectedWorkspaceData } = useSelector(
                  ({ collaboration }) => collaboration?.selectedWorkspaceData
                );
                dispatch(
                  setSelectedChannelSuccess(
                    JSON.parse(
                      window.localStorage.getItem("lastSelectedChannel")
                    ) || selectedWorkspaceData.channels[0]
                  )
                );
              }
            }
          },
          (error) => {
            //   dispatch(setChannelError(error));
          }
        );
      },
      (error) => {
        //   dispatch(setWorkSpaceError(error));
      }
    );
  };
