export const convertMilliSecondsToHours = (mSecs) => {
  if (Number.isFinite(mSecs)) {
    if (mSecs >= 1000) {
      const seconds = Math.floor((mSecs / 1000) % 60);
      const minutes = Math.floor((mSecs / (1000 * 60)) % 60);
      const hours = Math.floor(mSecs / (1000 * 60 * 60));
      // const hours = (mSecs / (1000 * 60 * 60)%24);
      // const days = (mSecs / (1000 * 60 * 60 * 24));

      return `${hours}.${minutes}`;
    } else {
      return 0;
    }
  }
  return 0;
};

export const precisionRound = (number, precision) => {
  if (isNaN(number)) {
    return number;
  }
  let preci = precision || 0;
  let factor = Math.pow(10, preci);
  return Math.round(parseFloat(number) * factor) / factor;
};

export const getEnv = (key) => process.env[`REACT_APP_${key}`];

export const getRandomDarkColor = () => {
  // Generate random values for the RGB components in the dark color range
  const randomR = Math.floor(Math.random() * 128); // Range for R: 0-127
  const randomG = Math.floor(Math.random() * 128); // Range for G: 0-127
  const randomB = Math.floor(Math.random() * 128); // Range for B: 0-127

  // Convert the random RGB values into a hexadecimal color code
  const darkColorHex = `#${randomR.toString(16).padStart(2, "0")}${randomG
    .toString(16)
    .padStart(2, "0")}${randomB.toString(16).padStart(2, "0")}`;

  return darkColorHex;
};
