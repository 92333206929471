import { SET_USERS_SUCCESS, SET_USERS_ERROR } from "../constants/users";

const initialState = {
  usersList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS_SUCCESS: {
      return { ...state, usersList: action.payload };
    }

    case SET_USERS_ERROR: {
      return { ...state, onError: action.error };
    }

    default:
      return { ...state };
  }
};
