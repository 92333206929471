
export default class WorkSpace {
    create(data, success, error) {
        window?.oneAbleCollaboration?.workSpace?.create(data, success, error);
    }

    getWorkSpaces(data, success, error) {
        //window?.oneAbleCollaboration?.workSpace?.get(data, success, error);
        window?.oneAbleCollaboration?.workSpace?.subscriptions?.get(
            data,
            success,
            error
          );
    }

    getSubscribers(data, success, error) {
        window?.oneAbleCollaboration?.workSpace?.subscriptions?.getSubscribers(
          data,
          success,
          error
        );
      }

    update(data, success, error) {
        window?.oneAbleCollaboration?.workSpace?.update(data, success, error);
    }

    delete(data, success, error) {
        window?.oneAbleCollaboration?.workSpace?.delete(data, success, error);
    }

    activate(data, success, error) {
        window?.oneAbleCollaboration?.workSpace?.subscriptions.activate(
            data,
            success,
            error
        );
    }

    deactivate(data, success, error) {
        window?.oneAbleCollaboration?.workSpace?.subscriptions.deactivate(
            data,
            success,
            error
        );
    }

    inviteUsers(data, success, error) {
      window?.oneAbleCollaboration?.workSpace?.invitation.send(
        data,
        success,
        error
      );
    }

    acceptInvitation(data, success, error) {
        window?.oneAbleCollaboration?.workSpace?.invitation.accept(
          data,
          success,
          error
        );
      }
    
      rejectInvitation(data, success, error) {
        window?.oneAbleCollaboration?.workSpace?.invitation.reject(
          data,
          success,
          error
        );
      }
}
