import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  getUserToolProductivity,
  getToolNoProductivity, processTools
} from "../../../actions/productivity";
import Crusirehoures from "../../Charts/crusirehoures";
import { PRODUCTIVITY_METADATA } from "../../Productivity/constants";
import { getEmpDashboardData } from "../../../actions/employeeDashboard";
import { convertMilliSecondsToHours, precisionRound } from "../../../utils";
import Vaichart from "../../Charts/Vaichart";
import Piechart from "../../Charts/Piechart";
import chromIcon from "../../../assets/images/Cruisericons/chrome.svg";
import SpringToolSuite from "../../../assets/images/Cruisericons/spring-tool-suite4.svg";
import Studio3T from "../../../assets/images/Cruisericons/Studio 3T.svg";
import vs from "../../../assets/images/Cruisericons/vs-code.svg";
import Robo3T from "../../../assets/images/Cruisericons/ic-robo3t.svg";
import RDP from "../../../assets/images/Cruisericons/RDP.svg";
import SSH from "../../../assets/images/Cruisericons/SSH.svg";
import firefox from "../../../assets/images/Cruisericons/firefox.svg";
import AdobeCreativeCloud from "../../../assets/images/Cruisericons/cloud.svg";
import notepad from "../../../assets/images/Cruisericons/notepad++.svg";
import VNC from "../../../assets/images/Cruisericons/VNC.svg";
import oneableDc from "../../../assets/images/Cruisericons/ic-window.svg";
import defaultIcon from "../../../assets/images/Cruisericons/ic-empty.svg";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import Utils from "../../../utils/utils";
import { ToastContainer, toast, Zoom } from "react-toastify";
import $ from "jquery";
import { getemployeeDashboardService } from '../../../services/employeeDashboard ';
import { getUserToolProductivites, getToolNoProductiviteService } from '../../../services/productivity';
import _ from "lodash";
import ScaleLoaderSpinner from "../../Shared/ScaleLoader";
class OrgAdminDashboard extends Component {
  constructor(props) {
    super(props);
    const dataToPass = Utils.getDataToPass();
    this.state = {
      isConnecting: false,
      tsMonthFilter: "1",
      productivityOption: PRODUCTIVITY_METADATA.MONTHLY,
      toolmodal: false,
      selectedUser: {},
      selectedUserDetails: {},
      noProdTools: [],
      sortOrder: "asc",
      sortField: "",
      currYear: dataToPass.year,
      currMonth: dataToPass.month,
      data: {},
      employees: 0,
      machineCount: 0,
      toolCount: 0,
      toolNoProductivity: [],
      toolProdList: [],
      timesheetSummary: {},
      productivity: [],
      timesheetStatusMap: {
        REJECTED: 0,
        SUBMITTED: 0,
        APPROVED: 0,
      },
      userProductivities: [],
      employees: 0,
      toolCount: 0,
      machineCount: 0,
      rejected: 0,
      pending: 0,
      approved: 0,
      totalProductivityperMonthmSecs: 0,
      userWorkingDaysPerMonth: 0,
      labels: [],
      workHrs: [],
      balHrs: [],
      overtimeHrs: [],
      series: [],
      crusierHours: 0,
      workedHours: 0,
      productivityTotal: 0,
      setCruiserHrsChart: false,
      employeeWiseTableData: [],
      filterEmpData: [],
      unFilteredEmpData: [],
      totalWorkingHours: 0,
      users: {},
      allUserstTolData: {},
      cruiserWorkData: [],
      vaiChartData: { 
        labels: [], 
        series: [] 
      },
      chartData: [],
      setChartData: false,
    };
  }
  handleSort = (field) => {
    if (this.state.sortOrder === "asc") {
      this.setState({
        ...this.state,
        sortOrder: "desc",
        sortField: field,
      });
    } else {
      this.setState({
        ...this.state,
        sortOrder: "asc",
        sortField: field,
      });
    }
  };
  ToolmodalOpen = (user) => {
    this.setState({ toolmodal: true, selectedUser: user });
  };
  ToolmodalClose = () => {
    this.setState({ toolmodal: false });
  };
  componentDidMount() {
    this.fetchEmployeeData();
    this.fetchToolNoProductivity();
    this.fetchUserToolProductivity();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(prevState.toolNoProductivity, this.state.toolNoProductivity) &&
      this.state.toolNoProductivity
    ) {
      if (!_.isEmpty(this.state.toolNoProductivity)) {
          let toolList = [];
          this.state.toolNoProductivity.map((toolObj) => {
            toolList = [...toolObj.tools];
          });
          this.setState({ isConnecting: false, noProdTools: toolList });
        } else {
          this.setState({ isConnecting: false, noProdTools: [] });
      }
    }

    if(!_.isEqual(prevState.timesheetStatusMap, this.state.timesheetStatusMap)){
        const timesheetCount =
          this.state.timesheetStatusMap.REJECTED +
          this.state.timesheetStatusMap.SUBMITTED +
          this.state.timesheetStatusMap.APPROVED;
        
          let rejected = 0,
        pending = 0,
        approved = 0;
          
        const calculator = (data) => precisionRound((data / timesheetCount) * 100);
        
        if(timesheetCount){
          rejected = calculator(this.state.timesheetStatusMap.REJECTED)
          pending = calculator(this.state.timesheetStatusMap.SUBMITTED)
          approved = calculator(this.state.timesheetStatusMap.APPROVED)
        }

        this.setState({
          rejected,
          pending,
          approved
        })
        
      }

    if(!_.isEqual(prevState.userProductivities, this.state.userProductivities)) {
      const {totalProductivityperMonthmSecs, userWorkingDaysPerMonth, labels, workHrs, balHrs, overtimeHrs, series, crusierHours, workedHours, productivityTotal} = this.metricsCalculator(this.state.userProductivities);

      this.setState({
        totalProductivityperMonthmSecs,
        userWorkingDaysPerMonth, 
        labels, 
        workHrs, 
        balHrs, 
        overtimeHrs,
        series,
        crusierHours,
        workedHours,
        productivityTotal,
        setCruiserHrsChart: true
      }) 
    }

    if(!_.isEqual(prevState.toolProdList, this.state.toolProdList) || !_.isEqual(prevState.noProdTools, this.state.noProdTools) ){
      const {toolProdList, noProdTools} = this.state
      const {employeeWiseTableData, cruiserWorkData, users, allUserstTolData, totalWorkingHours, filterEmpData} = this.toolProductivityCalculator(toolProdList, noProdTools);

      this.setState({
        employeeWiseTableData,
        cruiserWorkData,
        users,
        allUserstTolData,
        totalWorkingHours,
        filterEmpData,
        unFilteredEmpData: filterEmpData
      })
    }

    if(!_.isEqual(prevState.cruiserWorkData, this.state.cruiserWorkData) || prevState.productivityOption !== this.state.productivityOption){
      const {cruiserWorkData, productivityOption} = this.state
      const {vaiChartData, chartData} = this.chartDataCalculator(cruiserWorkData, productivityOption)

      this.setState({
        vaiChartData,
        chartData,
        setChartData: true
      })
    }

    if(!_.isEmpty(this.state.filterEmpData)){
      if(prevState.sortField !== this.state.sortField || prevState.sortOrder !== this.state.sortOrder){
        const {unFilteredEmpData, sortField, sortOrder} = this.state
        const filteredEmpData = this.filterEmpDataFunc(unFilteredEmpData, sortField, sortOrder);
        this.setState({
          filterEmpData: filteredEmpData,
        })
      }
    }
  }

  tsMonthFileterHandler = (e) => {
    this.setState({ tsMonthFilter: e.target.value });
  };
  onProdutivityChange = (event) => {
    this.setState({ productivityOption: event.target.value });
  };


  /**
   * Fetches employee data and updates the component's state with the result.
   * @function
   * @returns {void}
   */

  fetchEmployeeData = () => {
    getemployeeDashboardService({}, (respData) => {
      // if empty response is returned
      if(!respData || _.isEmpty(respData)) {
        return;
      }

      //if data is returned in the response
      const data = respData
      let {employees, machineCount, toolCount, timesheetStatusMap, userProductivities} = data;
  
      timesheetStatusMap = timesheetStatusMap ? timesheetStatusMap : this.state.timesheetStatusMap;
      userProductivities = userProductivities ? userProductivities : this.state.userProductivities; 
      employees = employees ? employees : this.state.employees;
      machineCount = machineCount ? machineCount : this.state.machineCount;
      toolCount = toolCount ? toolCount : this.state.toolCount;

      const initialData = {
            REJECTED: 0,
            SUBMITTED: 0,
            APPROVED: 0,
          }

      this.setState({
        data,
        timesheetStatusMap: {...initialData, ...timesheetStatusMap},
        userProductivities,
        employees,
        toolCount,
        machineCount,
        isConnecting: false
      })
    }, (error) => {
      toast(error.message, {
        type: "error",
        theme: "colored",
        autoClose: 2000,
        position: "bottom-right",
        transition: Zoom,
      });
      this.setState({ 
        isConnecting: false,
        onError: error
       });
    })
  }

  /**
   * Fetches tool productivity data and updates the component's state with the result.
   * @function
   * @returns {void}
   */

  fetchUserToolProductivity = () => {
    getUserToolProductivites((response) => {
      // if empty response is returned
      if(!response || _.isEmpty(response)) {
        return;
      }

      //if data is returned in the response
      const data = response.data
      this.setState({toolProdList : data})
    }, (error) => {
        toast(error.message, { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
    });
  }

  /**
   * Fetches tool noProductivity data and updates the component's state with the result.
   * @function
   * @returns {void}
   */

  fetchToolNoProductivity = () => {
    getToolNoProductiviteService((response) => {
      // if empty response is returned
      if(!response || _.isEmpty(response)) {
        return;
      }

      //if data is returned in the response
      const data = response.data
      this.setState({toolNoProductivity: data})
    }, (error) => {
        toast(error.message, { type: 'error', theme: 'colored', autoClose: 2000, position: 'bottom-right', transition: Zoom });
    });
  }

  metricsCalculator = (userProductivities) => {
    const {t} = this.props
    const dataToPass = Utils.getDataToPass(),
    currYear = dataToPass.year,
    currMonth = dataToPass.month,
    labels = [],
    workHrs = [],
    balHrs = [],
    overtimeHrs = [];
    

    let totalProductivityperMonthmSecs = 0,
    series = [],
    userWorkingDaysPerMonth = 0,
    crusierHours = 0,
    workedHours = 0,
    productivityTotal = 0

    Array.isArray(userProductivities) &&
      userProductivities.forEach((user) => {
        user.years && user.years.map((yearData) => {
          if (yearData.year === currYear) {
            yearData.months.map((monthData) => {
              if (monthData.month === currMonth) {
                labels.push(`${user.firstName} ${user.lastName}`);
                totalProductivityperMonthmSecs +=
                  monthData.totalWorkingHoursPerMonth;
                userWorkingDaysPerMonth += monthData.days.length;
                const cHours = convertMilliSecondsToHours(
                  monthData.totalWorkingHoursPerMonth
                );
                const userthinktime = monthData.days.length;
                let overTime = precisionRound(userthinktime * 8 - cHours, 2);
                let remaininghrs = parseFloat(cHours) + parseFloat(overTime);
                if (Math.sign(overTime) === -1) {
                  workHrs.push(precisionRound(remaininghrs, 2));
                  overtimeHrs.push(Math.abs(overTime));
                  balHrs.push(0);
                } else {
                  workHrs.push(precisionRound(cHours, 2));
                  balHrs.push(overTime);
                  overtimeHrs.push(0);
                }
              }
            });
          }
        });
      });

  series = [
      {
        name: this.props.t("OrgAdmin.cruiser_hours"),
        data: workHrs,
      },
      {
        name: this.props.t("OrgAdmin.think_hours"),
        data: balHrs,
      },
      {
        name: this.props.t("labels.Overtime"),
        data: overtimeHrs,
      },
    ];
    crusierHours = convertMilliSecondsToHours(
      totalProductivityperMonthmSecs
    );
    // const workedHours = (timesheetSummary.userTotalWorkingDays) * (timesheetSummary.workTime || 8);
    workedHours = userWorkingDaysPerMonth * 8;
    productivityTotal = convertMilliSecondsToHours(
      ((totalProductivityperMonthmSecs * 1.3) / workedHours) * 100
    );
    const toolIcons = {
      xd: "../assets/images/xd.png",
      "Visual Studio Code": vs,
      "Notepad++": notepad,
      Chrome: chromIcon,
      SpringToolSuite4: SpringToolSuite,
      SSH: SSH,
      "Studio 3T": Studio3T,
      Robo3T: Robo3T,
      RDP: RDP,
      Firefox: firefox,
      VNC: VNC,
      "Oneable DC": oneableDc,
      "SSH Build machine": SSH,
      "Adobe Creative Cloud": AdobeCreativeCloud,
      default: defaultIcon,
    };


    return {
      totalProductivityperMonthmSecs,
      userWorkingDaysPerMonth, 
      labels, 
      workHrs, 
      balHrs, 
      overtimeHrs,
      series,
      crusierHours,
      workedHours,
      productivityTotal
    }
  }

  toolProductivityCalculator = (toolProdList, noProdTools) => {
    const employeeWiseTableData = [],
    users = {},
    allUserstTolData = {};

    let filterEmpData = [],
    cruiserWorkData = [],
    totalWorkingHours = 0;
    
    toolProdList.forEach((user) => {
      const userData = {
        userId: user.userId,
        fullName: `${user.firstName} ${user.lastName}`,
        role: user.role,
        tHours: 0,
        timezone: "IST",
        tools: [],
      };
      users[user.userId] = true; // add userId to users object (will create unique users objects)
      Array.isArray(user.tools) &&
        user.tools.map((tool) => {
          const toolIcon =
            tool.base64ImageStr !== null
              ? tool.base64ImageStr
              : "../assets/images/bluebirdlogo.jpg";
          let toolData = {
            data: [],
            toolName: tool.toolName,
            toolId: tool.toolId,
            todayUsage: 0,
            yesterDayUsage: 0,
            averageUsage: 0,
            totalUsage: 0,
            currentMonthUsage: 0,
            toolIcon:
              tool.base64ImageStr !== null
                ? tool.base64ImageStr
                : "../assets/images/bluebirdlogo.jpg",
            progress: parseInt(Math.random() * 100),
          };

          let appHours = {
            yesterDayUsage : 0,
          }

          if (allUserstTolData.hasOwnProperty(tool.toolName)) {
            toolData = { ...toolData, ...allUserstTolData[tool.toolName] };
          }

          const date = new Date();
          const dataToPass = Utils.getDataToPass();
          const currYear = dataToPass.year;
          const currMonth = dataToPass.month;
          const currDayOfYear = dataToPass.day;

          tool.years.map((yearData) => {
            if (yearData.year === currYear) {
              toolData.totalUsage =
                toolData.totalUsage + yearData.appHoursPerYear;
              totalWorkingHours = totalWorkingHours + yearData.appHoursPerYear;
              yearData.months.map((monthData) => {
                if (monthData.month === currMonth) {
                  toolData.currentMonthUsage =
                    toolData.currentMonthUsage + monthData.appHoursPerMonth;
                  toolData.averageUsage =
                    toolData.averageUsage + monthData.appHoursPerMonth;
                  const currDayData = monthData.days.find(
                    (day) => day.day === currDayOfYear
                  );
                  const yestDayData = monthData.days.find(
                    (day) => day.day === currDayOfYear
                  );
                  toolData.todayUsage = currDayData?.appHoursPerDay ?
                    toolData.todayUsage + currDayData?.appHoursPerDay
                      : 0 ;
                  toolData.yesterDayUsage = yestDayData?.appHoursPerDay ?
                    toolData.yesterDayUsage + yestDayData?.appHoursPerDay 
                      : 0;
                  appHours.yesterDayUsage = yestDayData?.appHoursPerDay ? yestDayData?.appHoursPerDay : 0;
                }
              });
            }
          });
          userData.tHours = userData.tHours + appHours.yesterDayUsage;
          userData.tools.push({ toolName: tool.toolName, toolIcon: toolIcon });
          allUserstTolData[tool.toolName] = toolData;
        });
      employeeWiseTableData.push(userData);
      filterEmpData = [...employeeWiseTableData].sort((a, b) =>
        a.fullName.localeCompare(b.fullName)
      );
    });


    if (noProdTools.length !== 0) {
      noProdTools.map((toolObj) => {
        allUserstTolData[toolObj.toolName] = {
          data: [],
          toolName: toolObj.toolName,
          toolId: toolObj.toolId,
          todayUsage: 0,
          yesterDayUsage: 0,
          averageUsage: 0,
          totalUsage: 0,
          currentMonthUsage: 0,
          progress: parseInt(Math.random() * 100),
        };
      });
    }
    cruiserWorkData = Object.keys(allUserstTolData).map(
      (key) => allUserstTolData[key]
    );

    return {
      employeeWiseTableData,
      cruiserWorkData,
      users,
      allUserstTolData,
      totalWorkingHours,
      filterEmpData
    }
  }

  chartDataCalculator = (cruiserWorkData, productivityOption) => {
    const {t} = this.props
    const vaiChartData = { labels: [], series: [] };
    const chartData = [];
    cruiserWorkData.forEach((tool) => {
      if (tool.toolId) {
        vaiChartData.labels.push(tool.toolName);
        if (productivityOption === PRODUCTIVITY_METADATA.DAILY) {
          chartData.push(convertMilliSecondsToHours(tool.yesterDayUsage));
        }
        if (productivityOption === PRODUCTIVITY_METADATA.MONTHLY) {
          chartData.push(convertMilliSecondsToHours(tool.currentMonthUsage));
        }
        if (productivityOption === PRODUCTIVITY_METADATA.YEARLY) {
          chartData.push(convertMilliSecondsToHours(tool.totalUsage));
        }
      }
    });
    vaiChartData.series.push({
      name: this.props.t("TimeSheets.t_hours"),
      data: chartData,
    });

    return {
      vaiChartData,
      chartData
    }
  }

  filterEmpDataFunc = (data, sortField, sortOrder) => {
    let filterEmpData = [...data]
    filterEmpData = this.state.sortField
      ? [...filterEmpData].sort((a, b) => {
          if (sortField === "tHours") {
            let aNumber = Number(convertMilliSecondsToHours(a.tHours)) || 0;
            let bNumber = Number(convertMilliSecondsToHours(b.tHours)) || 0;
            if (sortOrder === "asc") {
              return aNumber - bNumber;
            } else {
              return bNumber - aNumber;
            }
          } else {
            if (sortOrder === "asc") {
              return a[sortField].localeCompare(
                b[sortField]
              );
            } else {
              return b[sortField].localeCompare(
                a[sortField]
              );
            }
          }
        })
      : [...filterEmpData];

    return filterEmpData
  }

  render() {
    const { t } = this.props;
    const {data, employees, machineCount, toolCount, timesheetStatusMap, approved, pending, rejected, labels, currMonth, currYear, series, productivityTotal, setCruiserHrsChart, filterEmpData, vaiChartData, setChartData} = this.state;
      
    return (
      <div className="container-fluid mt-2 px-0">
        <ToastContainer />

        {this.state.isConnecting && <ScaleLoaderSpinner />}

        <div className="row clearfix mx-0">
          <div className="col-md-12 px-1 ">
            <div className="row mx-0 ">
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 p-1 oneable-menu-row">
                <div className="card mb-0 ">
                  <div className="body p-0 Borderradius shadow h-70-px">
                    <div className="d-flex align-items-center">
                      <div className="icon-in-bg lightgray1 border-right leftradius ml-4">
                        <img
                          src="../assets/images/Orgdashboard/employee.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          alt="VAI Work"
                          className="w32 rounded mr-4"
                        />
                      </div>

                      <div className="ml-3 ">
                        <h6 className="mb-0 font-size-12">
                          <b>{t("OrgAdmin.emp")}</b>
                        </h6>
                        <p className="mb-0 font-weight-medium mt-0">
                          {employees}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 p-1 oneable-menu-row">
                <div className="card mb-0 ">
                  <div className="body p-0 Borderradius shadow h-70-px">
                    <div className="d-flex align-items-center">
                      <div className="icon-in-bg lightgray1 border-right leftradius ml-4 ">
                        <img
                          src="../assets/images/Orgdashboard/machines.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          alt="VAI Work"
                          className="w30 rounded mr-4"
                        />
                      </div>

                      <div className="ml-3 ">
                        <h6 className="mb-0 font-size-12">
                          <b>{t("OrgAdmin.machine")}</b>
                        </h6>
                        <p className="mb-0 font-weight-medium mt-0">
                          {machineCount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 p-1 oneable-menu-row">
                <div className="card mb-0 ">
                  <div className="body p-0 Borderradius shadow h-70-px">
                    <div className="d-flex align-items-center">
                      <div className="icon-in-bg lightgray1 border-right leftradius ml-4 ">
                        <img
                          src="../assets/images/Orgdashboard/tools.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          alt="VAI Work"
                          className="w32 rounded mr-4"
                        />
                      </div>

                      <div className="ml-3 ">
                        <h6 className="mb-0 font-size-12">
                          <b>{t("OrgAdmin.tool")}</b>
                        </h6>
                        <p className="mb-0 font-weight-medium mt-0">
                          {toolCount}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Timesheets"}) &&
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 p-1 oneable-menu-row">
                <div className="card mb-0 ">
                  <div className="body p-0 Borderradius shadow h-70-px">
                    <div className="d-flex align-items-center">
                      <div className="icon-in-bg lightgray1 border-right leftradius ml-4 ">
                        <img
                          src="../assets/images/Orgdashboard/timesheet.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          alt="VAI Work"
                          className="w28 rounded mr-4"
                        />
                      </div>

                      <div className="ml-3 mr-3 w-100 ">
                        <h6 className="mb-0 font-size-12">
                          <b>
                            {t("OrgAdmin.timesheet")}
                            <span className="font-size-10 ml-1">
                              {"(" +
                                Utils.getMonthName(currMonth) +
                                "-" +
                                currYear +
                                ")"}
                            </span>
                          </b>
                        </h6>

                        <div className="d-flex justify-content-between align-items-center timesheet-in-icons">
                          <span
                            title={t("TimeSheets.approved")}
                            className="d-flex align-items-center"
                          >
                            {/* <img src="../assets/images/review-approved-icon.svg" />  */}
                            <i
                              className="fas fa-check-circle approved font-size-16"
                              aria-hidden="true"
                              title={t("TimeSheets.approved")}
                            ></i>
                            <span className="ml-1">
                              {" "}
                              {timesheetStatusMap.APPROVED}
                            </span>
                          </span>
                          <span
                            title={t("OrgAdmin.pending")}
                            className="d-flex align-items-center"
                          >
                            {/* <img src="../assets/images/review-pending-icon.svg" /> */}
                            <i
                              className="fas fa-exclamation-circle pending font-size-16"
                              aria-hidden="true"
                              title={t("OrgAdmin.pending")}
                            ></i>
                            <span className="ml-1">
                              {" "}
                              {timesheetStatusMap.SUBMITTED}
                            </span>
                          </span>
                          <span
                            title={t("OrgAdmin.rejected")}
                            className="d-flex align-items-center"
                          >
                            {/* <img src="../assets/images/review-reject-icon.svg" /> */}
                            <i
                              className="fas fa-times-circle reject font-size-16 mb-1"
                              aria-hidden="true"
                              title={t("OrgAdmin.rejected")}
                            ></i>
                            <span className="ml-1">
                              {" "}
                              {timesheetStatusMap.REJECTED}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
            <div className="row mx-0">
             {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Productivity"}) &&
              <div className="col-lg-9 col-md-6 col-12 p-1">
                <div className="card mb-0">
                  <div
                    className="body px-3 py-3 Borderradius shadow "
                    style={{ height: "545px" }}
                  >
                    <span className="font-size-12 oneable-text-bold d-flex">
                      {t("OrgAdmin.emp")}
                      <span className="font-size-10 ml-1">
                        {"(" +
                          Utils.getMonthName(currMonth) +
                          "-" +
                          currYear +
                          ")"}
                          
                      </span>
                      <span className="d-flex">
                      <span className="d-flex"><div className="width_Cruiser mr-2"></div> {t("OrgAdmin.cruiser_hours") }</span>
                      <span className="d-flex"><div className="width_think mx-2"></div> {t("OrgAdmin.think_hours") }</span>
                      </span>
                    </span>
                    <div className="">
                    <Crusirehoures series={series} labels={labels} />
                      </div>
                    {/* <div className="Barchart_scrollbox Barchart_scrollbox_delayed">
                  <div className="Barchart_scrollbox-content p-2  text-center orgdashbard-crusirchat">
                    <div id="org-barchart-bar-align" className="text-center orgdasbord-barchart">
                       <Crusirehoures series={series} labels={labels} />
                      
                     
                    </div>

                  </div>
                  </div> */}
                  </div>

                </div>
              </div>}

              <div className="col-lg-3 col-md-6 col-12 p-1 oneable-menu-row">
              {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Productivity"}) &&
                <div className="card mb-2 ">
                  <div className="body p-0 Borderradius shadow h-70-px">
                    <div className="d-flex align-items-center">
                      <div className="icon-in-bg lightgray1 border-right leftradius ml-4 ">
                        <img
                          src="../assets/images/Orgdashboard/productimg.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          alt="VAI Work"
                          className="w30 rounded mr-4"
                        />
                      </div>

                      <div className="ml-3 ">
                        <h6 className="mb-0 font-size-12">
                          <b>{productivityTotal}%</b>
                        </h6>
                        <p className="mb-0 font-weight-medium mt-0">
                          {t("OrgAdmin.productivity_month")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>}

                {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Timesheets"}) &&
                  <div className="card mb-0">
                  <div className="body px-3 py-3 Borderradius shadow ">
                    <div className="d-flex justify-content-between mb-4">
                      <span className="font-size-12 oneable-text-bold">
                        {t("OrgAdmin.timesheet")}
                        <span className="font-size-10 ml-1">
                          {"(" +
                            Utils.getMonthName(currMonth) +
                            "-" +
                            currYear +
                            ")"}
                        </span>
                      </span>
                      <div>
                        {/* <select className="browser-default border-0 fontsmall">
                                            <option>Select Biz Unit</option>
                                            <option value="1">Org 1</option>
                                            <option value="2">Org 2</option>
                                            <option value="3">Org 3</option>
                                        </select> */}
                      </div>
                    </div>
                    <div
                      id="org-piechart-bar-align"
                      className="Max-width text-center"
                    >
                      {/* <Timebizunit /> */}
                      <Piechart data = {data}/>

                      <div className="form-group text-left">
                        <label className="d-block text-muted font-size-12">
                          {t("OrgAdmin.approved")}{" "}
                          <span className="float-right">{approved} %</span>
                        </label>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar progress-bar-primary"
                            role="progressbar"
                            aria-valuenow="77"
                            aria-valuemin="10"
                            aria-valuemax="100"
                            style={{ width: `${approved}%` }}
                          ></div>
                        </div>
                      </div>

                      <div className="form-group text-left">
                        <label className="d-block text-muted font-size-12">
                          {t("OrgAdmin.rejected")}{" "}
                          <span className="float-right">{rejected} %</span>
                        </label>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar progress-bar-primary"
                            role="progressbar"
                            aria-valuenow="77"
                            aria-valuemin="10"
                            aria-valuemax="100"
                            style={{ width: `${rejected}%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="form-group text-left">
                        <label className="d-block text-muted font-size-12">
                          {t("OrgAdmin.submitted")}{" "}
                          <span className="float-right">{pending} %</span>
                        </label>
                        <div className="progress progress-xs">
                          <div
                            className="progress-bar progress-bar-primary"
                            role="progressbar"
                            aria-valuenow="90"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: `${pending}%` }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
            {/* ############### chart and graph row division closed here ################## */}
            <div className="row mx-0">
            {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Productivity"}) &&
              <div className="col-md-12 p-1">
                <div className="card mb-0">
                  <div className="body px-3 py-3 Borderradius shadow ">
                    <div className="d-flex justify-content-between mb-2 mr-4">
                      <span className="font-size-12 oneable-text-bold">
                        {t("OrgAdmin.application_summary")}
                      </span>
                      <div>
                        <select
                          className="browser-default border-0 fontsmall cursor-pointer"
                          name="OrgAdmin CruiserAppSummary"
                          onChange={this.onProdutivityChange}
                          value={this.state.productivityOption}
                        >
                          <option value={PRODUCTIVITY_METADATA.DAILY}>
                            {t("OrgAdmin.daily")}
                          </option>
                          <option value={PRODUCTIVITY_METADATA.MONTHLY}>
                            {t("OrgAdmin.monthly")}
                          </option>
                          <option value={PRODUCTIVITY_METADATA.YEARLY}>
                            {t("OrgAdmin.yearly")}
                          </option>
                        </select>
                      </div>
                    </div>
                       <div className="Barchart_scrollbox Barchart_scrollbox_delayed">
                    <div className="Barchart_scrollbox-content p-2  text-center orgdashbard-crusirchat">
                      <div id="org-barchart-bar-align" className="text-center orgdasbord-barchart"> 
                    
                        <Vaichart data={vaiChartData} />
                        </div>
                        </div>
                      </div> 
                      {/* <div className="">
                      <Vaichart data={vaiChartData}/>
                      </div> */}
                  </div>
                </div>
              </div>}
            </div>
            {/* ################## Application Summary tools using ############### */}

            {/* employee table start */}
            {this.props.routeModules?.length > 0 && this.props.routeModules?.find((modObj) => {return modObj.to === "/Productivity"}) &&
            <div className="row mx-0">
              <div className="col-lg-12 col-md-12 col-12 p-1">
                {/* <EmployeeDataTable/> */}
                <div className="card">
                  <div className="body shadow p-3 Borderradius">
                    <div className="table-responsive table-container">
                      {filterEmpData.length > 0 ? <table className="table table-hover table-custom spacing5 dashboard-table">
                        <thead>
                          <tr>
                            <th>
                              {t("OrgAdmin.emp_name")}
                              <span
                                onClick={() => this.handleSort("fullName")}
                                className="ml-2 font-size-12 text-muted"
                              >
                                <i
                                  className={
                                    this.state.sortField === "fullName"
                                      ? this.state.sortOrder === "asc"
                                        ? `fa fa-arrow-up cursor-pointer mr-1`
                                        : "fa fa-arrow-down mr- cursor-pointer"
                                      : `fa fa-arrow-up cursor-pointer mr-1`
                                  }
                                  title={
                                    this.state.sortField === "fullName"
                                      ? this.state.sortOrder === "asc"
                                        ? t("Dashboard.ascending_order")
                                        : t("Dashboard.descending_order")
                                      : t("Dashboard.ascending_order")
                                  }
                                ></i>
                                <i
                                  className={
                                    this.state.sortField === "fullName"
                                      ? this.state.sortOrder !== "asc"
                                        ? `fa fa-arrow-up cursor-pointer mr-1`
                                        : "fa fa-arrow-down mr- cursor-pointer"
                                      : "fa fa-arrow-down mr- cursor-pointer"
                                  }
                                  title={
                                    this.state.sortField === "fullName"
                                      ? this.state.sortOrder !== "asc"
                                        ? t("Dashboard.ascending_order")
                                        : t("Dashboard.descending_order")
                                      : t("Dashboard.descending_order")
                                  }
                                ></i>
                              </span>
                            </th>

                            <th>
                              {t("Employee.role")}
                              <span
                                onClick={() => this.handleSort("role")}
                                className="ml-2 font-size-12 text-muted"
                              >
                                <i
                                  className={
                                    this.state.sortField === "role"
                                      ? this.state.sortOrder === "asc"
                                        ? `fa fa-arrow-up cursor-pointer mr-1`
                                        : "fa fa-arrow-down mr- cursor-pointer"
                                      : `fa fa-arrow-up cursor-pointer mr-1`
                                  }
                                  title={
                                    this.state.sortField === "role"
                                      ? this.state.sortOrder === "asc"
                                        ? t("Dashboard.ascending_order")
                                        : t("Dashboard.descending_order")
                                      : t("Dashboard.ascending_order")
                                  }
                                ></i>
                                <i
                                  className={
                                    this.state.sortField === "role"
                                      ? this.state.sortOrder !== "asc"
                                        ? `fa fa-arrow-up cursor-pointer mr-1`
                                        : "fa fa-arrow-down mr- cursor-pointer"
                                      : "fa fa-arrow-down mr- cursor-pointer"
                                  }
                                  title={
                                    this.state.sortField === "role"
                                      ? this.state.sortOrder !== "asc"
                                        ? t("Dashboard.ascending_order")
                                        : t("Dashboard.descending_order")
                                      : t("Dashboard.descending_order")
                                  }
                                ></i>
                              </span>
                            </th>
                            {/* <th>Login</th>
                            < th>Logout</th> */}
                            <th>
                              {t("OrgAdmin.total_hours")}
                              <span
                                onClick={() => this.handleSort("tHours")}
                                className="ml-2 font-size-12 text-muted"
                              >
                                <i
                                  className={
                                    this.state.sortField === "tHours"
                                      ? this.state.sortOrder === "asc"
                                        ? `fa fa-arrow-up cursor-pointer mr-1`
                                        : "fa fa-arrow-down mr- cursor-pointer"
                                      : `fa fa-arrow-up cursor-pointer mr-1`
                                  }
                                  title={
                                    this.state.sortField === "tHours"
                                      ? this.state.sortOrder === "asc"
                                        ? t("Dashboard.ascending_order")
                                        : t("Dashboard.descending_order")
                                      : t("Dashboard.ascending_order")
                                  }
                                ></i>
                                <i
                                  className={
                                    this.state.sortField === "tHours"
                                      ? this.state.sortOrder !== "asc"
                                        ? `fa fa-arrow-up cursor-pointer mr-1`
                                        : "fa fa-arrow-down mr- cursor-pointer"
                                      : "fa fa-arrow-down mr- cursor-pointer"
                                  }
                                  title={
                                    this.state.sortField === "tHours"
                                      ? this.state.sortOrder !== "asc"
                                        ? t("Dashboard.ascending_order")
                                        : t("Dashboard.descending_order")
                                      : t("Dashboard.descending_order")
                                  }
                                ></i>
                              </span>
                            </th>
                            {/* <th>{i18n.t("OrgAdmin.time_zone")}</th> */}
                            <th>{i18n.t("OrgAdmin.tools")}</th>

                          </tr>
                        </thead>
                        <tbody>
                          {filterEmpData.map((user, index) => {
                            return (
                              <tr key={user.userId + "-" + index}>
                                <td>
                                  {user.fullName.charAt(0).toUpperCase() +
                                    user.fullName.slice(1)}
                                </td>
                                <td className="text-capitalize">
                                  {user.role.toLowerCase()}
                                </td>
                                {/* <td>{user.login}</td>
                                                                  <td>{user.logout}</td> */}
                                <td>
                                  {8} {this.props.t("Dashboard.Hrs")}/
                                  {convertMilliSecondsToHours(user.tHours)} {this.props.t("Dashboard.Hrs")}
                                </td>
                                {/* <td>{user.timezone}</td> */}
                                <td>
                                  <ul className='list-unstyled team-info sm margin-0 mb-0"'>
                                    {user.tools.slice(0, 5).map((tool) => {
                                      return (
                                        <>
                                          <li>
                                            <img
                                              key={tool.toolName}
                                              src={tool.toolIcon}
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title={tool.toolName}
                                              alt="tool"
                                              className="mr-2 w25 rounded-circle"
                                            />
                                          </li>
                                        </>
                                      );
                                    })}
                                    <li>
                                      {user.tools.length > 5 && (
                                        <button
                                          name="UserToollist Open"
                                          type="button"
                                          onClick={() =>
                                            this.ToolmodalOpen(user, index)
                                          }
                                          className="btn btn-custom-dark toolcount-dashboard mt-2"
                                        >
                                          +
                                          {user.tools.length > 5
                                            ? user.tools.length - 5
                                            : ""}
                                        </button>
                                      )}
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table> : <div className='text-center mt-5'>
                        <img src="../../assets/images/nodata.svg" className="nodata-img" />
                        <h5 className="text-muted mt-3">{this.props.t('productivity.no_data')}</h5>
                    </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>}
            {/* Table end */}
          </div>
        </div>

        {/*############### ToolList-modal ####################  */}
        {this.state.toolmodal ? (
          <>
            <div className="custom-alert-model">
              <div className="custom-alert-box">
                <div className="custom-alert-header border-bottom">
                  <span className="text-capitalize">
                    {this.state.selectedUser.fullName.toLowerCase()}
                  </span>

                  <span
                    id="UserToollist Close"
                    onClick={() => this.ToolmodalClose()}
                  >
                    <i
                      className="fa fa-times text-muted cursor-pointer"
                      title={this.props.t("tasks.close")}
                    ></i>
                  </span>
                </div>
                <div className="custom-alert-body text-left">
                  <ul className='list-unstyled  sm margin-0 mb-0"'>
                    {this.state.selectedUser.tools
                      .slice(5)
                      .map((tool, index) => {
                        return (
                          <>
                            <li
                              className="d-flex align-items-center py-1"
                              key="{tool}"
                            >
                              <span>
                                <img
                                  key={tool.toolName}
                                  src={tool.toolIcon}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={tool.toolName}
                                  alt=""
                                  className=" w25 rounded-circle"
                                />
                              </span>
                              <span className="ml-2">{tool.toolName}</span>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>

                <div className="custom-alert-footer text-right"></div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

/**
 * Map the state to props.
 */
 const mapStateToProps = function (state) {
  return {
    routeModules : state.organization.routeModules,
  };
};

export default connect(mapStateToProps,null)(withTranslation()(OrgAdminDashboard));
