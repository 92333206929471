import storageService from '../services/storage';
import i18n from "i18next";
class HttpUtils {
	constructor() { }
	httpRequest(endpoint, method, data, cb) {
		const token = this.getToken() || null;
		// console.log(process.env)
		const host = process.env.REACT_APP_API_URL || window.location.origin;
		const url = `${host}/api/${endpoint}`
		const xmlhttp = new XMLHttpRequest();
		
		if (xmlhttp) {
			xmlhttp.open(method, url, true);
			xmlhttp.setRequestHeader('Content-type', 'application/json; charset=utf-8');
			xmlhttp.setRequestHeader('Accept-Language', i18n.language);
            xmlhttp.setRequestHeader('x-access-token', token || '');			
			xmlhttp.onreadystatechange = function () {
				if (xmlhttp.readyState === 4) {
					try {
						if(xmlhttp.status === 309) {
							window.location.href = xmlhttp.getResponseHeader('redirect');
						} else {
							const response = JSON.parse(xmlhttp.response);
							if(response.code === "1001" || response.code === "1002"){
								var continueEvent = new CustomEvent("sessionExpired", { detail: { isDetails: true, data: response }, });
								window.dispatchEvent(continueEvent);
							} else {
								cb && cb(JSON.parse(xmlhttp.response));
								
								
							}
						}
					} catch(e) {    
						console.log(e);
						cb && cb({
							status: 'Error',
							message: xmlhttp.response
						});
					}                    
				}
			}
            xmlhttp.send(JSON.stringify(data));
		}
	}

fetchData(endpoint, method, data) {
	return new Promise((resolve, reject) => {
		const token = this.getToken() || null;
		const host = process.env.REACT_APP_API_URL || window.location.origin;
		const url = `${host}/api/${endpoint}`;
		const xhr = new XMLHttpRequest();
		xhr.open(method, url, true);
		xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
		xhr.setRequestHeader("x-access-token", token || "");
		xhr.setRequestHeader("Accept-Language", i18n.language);
		
		xhr.onreadystatechange = function () {
			try {
				if (xhr.readyState === 4) {
					if (xhr.status >= 200 && xhr.status < 300) {
						const result = JSON.parse(xhr.responseText);
						resolve(result);
					} else {
						const errorResponse = new Response(xhr.responseText, {
							status: xhr.status,
							statusText: xhr.statusText,
						});
						throw errorResponse;
					}
				}
			} catch (error) {
				reject(error);
			}
		};
		
		xhr.send(JSON.stringify(data));
	});
		
}

videoFetchData(endpoint, method, data, userToken) {
	return new Promise((resolve, reject) => {
		const token = userToken || null;
		const host = process.env.REACT_APP_API_URL || window.location.origin;
		const url = `${host}/api/${endpoint}`;
		const xhr = new XMLHttpRequest();
		xhr.open(method, url, true);
		xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
		xhr.setRequestHeader("x-access-token", token || "");
		xhr.setRequestHeader("Accept-Language", i18n.language);
		xhr.setRequestHeader("Range" , 'bytes=0-');
		xhr.responseType = 'blob';
		
		xhr.onreadystatechange = function () {
			try {
				if (xhr.readyState === 4) {
					if (xhr.status >= 200 && xhr.status < 300) {
						//const url = window.URL.createObjectURL(new Blob([xhr.response], { type: 'video/mp4' }));
						resolve(xhr.response);
					} else {
						const errorResponse = new Response(xhr.responseText, {
							status: xhr.status,
							statusText: xhr.statusText,
						});
						throw errorResponse;
					}
				}
			} catch (error) {
				reject(error);
			}
		};
		
		xhr.send(JSON.stringify(data));
		
	});
		
}

  getToken() {
    const userData = storageService.getItem("userdata");
    return userData && userData.token;
  }

	workSpaceFetchData(endpoint, method, data, userToken) {
		return new Promise((resolve, reject) => {
			const token = userToken || null;
			const host = process.env.REACT_APP_API_URL || window.location.origin;
			const url = `${host}/api/${endpoint}`;
			const xhr = new XMLHttpRequest();
			xhr.open(method, url, true);
			xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");
			xhr.setRequestHeader("x-access-token", token || "");
			xhr.setRequestHeader("Accept-Language", i18n.language);
			
			xhr.onreadystatechange = function () {
				try {
					if (xhr.readyState === 4) {
						if (xhr.status >= 200 && xhr.status < 300) {
							const result = JSON.parse(xhr.responseText);
							resolve(result);
						} else {
							const errorResponse = new Response(xhr.responseText, {
								status: xhr.status,
								statusText: xhr.statusText,
							});
							throw errorResponse;
						}
					}
				} catch (error) {
					reject(error);
				}
			};
			
			xhr.send(JSON.stringify(data));
		});	
	}
}

export default new HttpUtils();
