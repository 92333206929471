import React from 'react';


const warning = {
    color: "#fff",
    backgroundColor: "DodgerBlue",
  };

const LabelTab = (props)  => {
    return <span className={`alert alert-${props.type}`}>{props.text}</span>
}


export default LabelTab;