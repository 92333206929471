import { combineReducers } from "redux";
import login from "./login";
import timesheet from "./timesheet";
import settings from "./settings";
import employeeDashboard from "./employeeDashboard";
import productivity from "./productivity";
import changepassword from "./changepassword";
import vai from "./vai";
import timeoff from "./offtime";
import twofactorauth from "./twofactorauth";
import profile from "./profile";
import collaboration from "./collaboration";
import users from "./users";
import workspace from "./workspace";
import organization from "./organization";
import Jiraintegration from "./Jiraintegration";
import request from "./request";
import Projects from "./Projects";
import modules from "./modules";
import recordedsessions from "./recordedsessions";
import license from './license'
import room from "./room";
import workspacev2 from "./workspacev2";
import message from "./message";
import passwordRotation from "./passwordRotation";
import forgotpassword from "./forgotpassword";

export default combineReducers({
  login,
  forgotpassword,
  changepassword,
  timesheet,
  settings,
  employeeDashboard,
  productivity,
  vai,
  twofactorauth,
  profile,
  timeoff,
  collaboration,
  users,
  workspace,
  organization,
  Jiraintegration,
  request,
  Projects,
  modules,
  recordedsessions,
  license,
  workspacev2,
  room,
  message,
  passwordRotation,
});
