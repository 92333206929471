import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import "./App.css";
import $ from "jquery";
import storageService from "./services/storage";
import { loadConfig } from "./helper/customerConfig";
import dashboard from "./components/Dashboard/dashboard";
import Loader from "react-js-loader"

const Login = lazy(() => import("./components/Authentication/login"));
const Twofactorauth = lazy(() => import("./components/Tofactauth/twofactorauth"));
const ForgotPassword = lazy(() => import("./components/Authentication/forgotpassword"));
const Layout = lazy(() => import("./components/Shared/Layout"));
const WorkSpace = lazy(() => import("./components/Vai/workspace"));
const Newpassword = lazy(() => import("./components/Authentication/newpassword"));
const Otppassword = lazy(() => import("./components/Authentication/otppassword"));
const Qrcode = lazy(() => import("./components/Tofactauth/qrcode"));
const Rtc = lazy(() => import("./components/Rtc"));
const Whiteboard = lazy(() => import("./components/Collabration/Components/Whiteboard"));
const Email = lazy(() => import("./components/Authentication/email"));
const RegenerateQRCode = lazy(() => import("./components/Tofactauth/RegenerateQRCode"));
const Player = lazy(() => import("./components/Productivity/Videoplayer/player"));
class App extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      customersData: {},
    };
  }

  componentDidMount() {
    this.loadCustomData();
  }

  /**
   * To load the customers JSON
   * based on the name before start or
   * default name is considered if it doesnt match
   */
  loadCustomData = async () => {
    const customData = await loadConfig();
    this.setState({ customersData: customData });
  }

  render() {
    const {
      themeColor,
      fontStyle,
      lightVersion,
      RtlVersion,
      offcanvas,
      miniSidebar,
      horizontalMenu,
      miniHover,
    } = this.props;
    document.getElementsByTagName(
      "body"
    )[0].className = `${themeColor} ${fontStyle}${lightVersion ? " light_version" : ""
    }${RtlVersion ? " rtl" : ""}${offcanvas ? " offcanvas-active" : ""}${horizontalMenu ? " h-menu" : ""
      } ${miniSidebar ? " mini_sidebar" : ""}${miniHover ? " mini_hover" : ""}`;

    const { customersData } = this.state;
    /**
    * set the href in the index.html
    */
    if (customersData && customersData.image) {
      const link = document.querySelector('link[rel="icon"]');
      const imageSrc = document.querySelector('link[rel="icon"]').getAttribute('href')
      if (link && imageSrc !== customersData.image) {
        link.setAttribute('href', customersData.image);
      }
    }

    return (
      <div>
        <div
          ref={(leftSidebar) => {
            this.leftSidebar = leftSidebar;
          }}
        >
          <Router>
            <Suspense fallback={<div className={"item"}>
              <Loader type="box-rectangular" size={100} />
            </div>}>
              <Switch>
                <Route exact path="/" render={() => {
                  !$.isEmptyObject(storageService.getItem("userdata")) && storageService.clear();
                  return <Email />;
                }} />
                <Route exact path="/email" render={() => {
                  !$.isEmptyObject(storageService.getItem("userdata")) && storageService.clear();
                  return <Email />;
                }} />
                <Route exact path="/login" render={() => {
                  const modules = JSON.parse(sessionStorage.getItem("moduleStatus"))
                  !$.isEmptyObject(storageService.getItem("userdata")) && $.isEmptyObject(modules) && storageService.clear();
                  return <Login />;
                }} />
                <Route exact path="/otppassword" component={Otppassword} />;
                <Route exact path="/password" component={Newpassword} />;
                <Route exact path="/twofactorauth" component={Twofactorauth} />;
                <Route exact path="/qrCode" component={Qrcode} />
                <Route exact path="/resetmfa" component={RegenerateQRCode} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route path="/workspace" component={WorkSpace} />
                <Route path="/video-call" component={Rtc} />
                <Route path="/whiteboard" component={Whiteboard} />
                <Route path="/videoPlayer" component={Player} />
                <Route component={Layout} />
              </Switch>
            </Suspense>
          </Router>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  themeColor: state.settings.themeColor,
  fontStyle: state.settings.fontStyle,
  lightVersion: state.settings.lightVersion,
  RtlVersion: state.settings.RtlVersion,
  offcanvas: state.settings.offcanvas,
  horizontalMenu: state.settings.horizontalMenu,
  miniSidebar: state.settings.miniSidebar,
  miniHover: state.settings.miniHover,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
