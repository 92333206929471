import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { convertMilliSecondsToHours } from '../../../utils';

class CruiserWorkCard extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { t } = this.props;
        // const { averageUsage = 0, todayUsage = 0, toolIcon = "", toolName = "", progress = 0 } = this.props;
        const { averageUsage = 0, yesterDayUsage = 0, toolIcon = "", toolName = "", progress = 0 } = this.props;

        return (
            <div className="card mb-1">
                <div className="body shadow px-3 py-2" >
                    <div className="d-flex align-items-center">
                        <img src={toolIcon} data-toggle="tooltip" data-placement="top" title={toolName} alt="Avatar" className="w-h-30 rounded" />
                        <div className="ml-3 line-height-14">
                            <a className="font-size-12"><b>{toolName}</b></a>
                            <p class="mb-0 font-size-10 text-muted">{t('Dashboard.average_usage')} : {convertMilliSecondsToHours(averageUsage)} {t('Dashboard.hrs')}</p>
                            {/* <p class="mb-0 font-size-10 text-muted">Average usage : {averageUsage} hrs</p> */}
                            <p class="mb-0 font-size-10 text-muted">{t('Dashboard.yesterday_usage')} : {convertMilliSecondsToHours(yesterDayUsage)} {t('Dashboard.hrs')}</p>
                        </div>

                        <div className="ml-3 float-right-align">
                            <div className=" ">
                                {/* <Donut
                                    className="knob"
                                    diameter={36}
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={progress}
                                    theme={{
                                        donutThickness: 3,
                                        donutColor: '#F8B912',
                                        // centerColor: '#282B2F',
                                        // bgrColor: '#FAC898'
                                    }}
                                /> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default (withTranslation()(CruiserWorkCard));
