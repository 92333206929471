import React from 'react';
import ReactPaginate from 'react-paginate';

const PaginatedList = (props) => {

  const getPaginationClassName = () => {
    switch (props.displaySize) {
      case 'small':
        return 'pagination pagination-small';
      default:
        return 'pagination';
    }
  };

  return (
    <div>
      <ReactPaginate
        pageCount={Math.ceil(props.totalRecords / props.size)}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        onPageChange={props.handlePageClick}
        containerClassName={getPaginationClassName()}
        activeClassName={'active'}
        forcePage={props.page}
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default PaginatedList;