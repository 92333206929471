export const TRANSLATIONS_EN = {
  headers: {
    login: "Login into your account",
    loginwlc: "Secure Workforce Platform",
    newTimeSheet: "Time Sheet",
    timeSheetView: "Timesheet View",
    lang: "en",
    my_profile: "My Profile",
    log_out: "Logout",
    settings: "Settings",
    help: "Help",
  },
  messages: {
    empty_data: "Data not available",
    No_timer_Off_data_found_in_organization_settings: "No timer Off data found in organisation settings",
    No_timer_Off_data_found_in_settings: "No timer Off data found in settings",
    Are_you_sure_you_want_to_delete_this_workspace:
      "Be informed that one or more active channel(s) exists, do you still want to delete {{name}} Workspace?.",
    Are_you_sure_you_want_to_delete_this_channel:
      "Are you sure you want to delete this channel?.",
    Are_you_sure_you_want_to_leave_this_channel:
      "Are you sure you want to leave this channel?",
    shift_update_success: "Shift Successfully Updated",
    productivity_update_success: "Productivity Update Success",
    timeOff_update_success: 'Timeoff Values Update Success',
    turnoff_productivity_alert: 'Do you want to turn off Productivity Settings',
    turnoff_timeOff_alert: 'Do you want to turn off Time Off Settings',
    turnoff_shift_alert: 'Do you want to turn off Shift Settings',
    productivityTurnedOff: "Productivity Logging successfully turned off",
    shiftTurnedOff: "Shift successfully turned off",
    timeOffTurnedOff: "TimeOff successfully turned off"
  },
  auth: {
    password_expire_text:
      "Your password will expire in {{day}} day(s), after log-in go to settings screen to reset password.",
    note: "Note",
    password_expire: "Your password expired, go to ",
    resetpassword: "reset password",
    You_want_to_reset_password_using: "You want to reset password using",
    Old_Password: "Old Password",
    OTP_over_email: "OTP over email",
  },
  Twofactorauth: {
    Yourorganization:
      "Your organisation is enforcing multi factor authentication to login into",
    scan_qrcode: "Please scan the QRcode",
    Multifact: "Multifactor",
    Authen: "Authentication",
    Passcode: "Enter Passcode ",
    use_google:
      "Use Google Authenticator or Microsoft Authenticator on your mobile phone.",
    open_google:
      "1. Open Google Authenticator or Microsoft Authenticator on your mobile phone",
    qr_code: "2. Select Scan QR Code",
    capture: "3. Point your phone at this screen to capture the code",
    next_button: "4. Click the next button",
    next: "Next",
    user_secret: "User Secret",
    secret: "Secret Password",
    cancel: "Cancel",
    submit: "Submit",
    connecting: "Connecting",
    two_factor_auth: "Two Fact Authentication",
    change_password: "Change Password",
    Enable: "Enable",
    Disable: "Disable",
    Select_All: "Select All",
    Two_Factor_Authentication: "Two Factor Authentication",
    resetMFA: "Reset MFA",
    mfaReset: "Multifactor reset successfully",
    password_update: "Password updated successfully.",
  },
  Dashboard: {
    productivity: "Productivity",
    Work: "Work",
    BIZUnit: "BIZ Unit",
    cruiser_hours: "Cruiser Hours",
    office_hours: "urs",
    think_hours: "Think Hours",
    Hrs: "Hrs",
    hrs: "hrs",
    hours: "Hours",
    timesheet: "Timesheet",
    timesheet_summary: "Timesheet Summary",
    this_month: "This Month",
    current_month: "(This Month)",
    last3months: "Last 3 months",
    this_year: "(This Year)",
    quarterly: "Quarterly",
    half_yearly: "Half yearly",
    yearly: "Yearly",
    total_working_days: "Total Working Days",
    days_worked: "Days Worked",
    on_leave: "On Leave",
    holidays: "Holidays",
    total_time: "Total Time",
    work_time: "Work Time",
    think_time: "Think Time",
    cruiser_work: "Cruiser Work",
    cruiser_application_summary: "Cruiser Application Summary",
    monthly: "Monthly",
    team_progress: "Team Progress",
    average_usage: "Average usage",
    todays_usage: "Today's usage",
    team: "Team",
    project_name: "Project Name",
    start_date: "Start Date",
    end_date: "End Date",
    progress: "Progress",
    recent_tasks: "Recent Tasks",
    work_summary: "Work Summary",
    daily: "Daily",
    yesterday_usage: "Yesterday's usage",
    ascending_order: "Ascending order",
    descending_order: "Descending order",
    org_create: "organisation created successfully",
    user_create: "User created successfully",
    org_enable: "organisation enabled successfully",
    org_disable: "organisation disabled successfully",
    user_view: "Users View",
    tool_view: "Tools View"
  },
  Opsadmin: {
    total_employee: "Total Employee",
    productivity: "Productivity",
    client: "Client",
    expense: "Expense ",
    application_summary: "Application Summary",
    this_month: "This Month",
    project_name: "Project Name",
    start_date: "Start Date",
    end_date: "End Date",
    phase: "Phase",
    status: "Status",
    filled: "Filled",
    not_filled: "Not Filled",
    approved: "Approved",
    rejected: "Rejected",
    total_profile: "Total Profile",
    hours: "Hours",
    manager_name: "Manager Name",
    join_date: "Join Date",
    directories: "Directories",
    departments: "Departments",
    onboard: "Onboard",
    termination: "Termination",
    view: "View",
    employees: "Employees",
    employee_name: "Employee Name",

  },

  OrgAdmin: {
    productivity_month: "Productivity this month",
    emp: "Employees",
    machine: "Machines",
    tool: "Tool",
    Work: "Work",
    timesheet: "Timesheet",
    last_month: "Last month",
    selectall: "Select All",
    select: "Select",
    unit: "Unit",
    approved: "Approved",
    rejected: "Rejected",
    submitted: "Submitted",
    pending: "Pending",
    cruiser_summary: "Cruiser Application Summary",
    yearly: "Yearly",
    monthly: "Monthly",
    tools: "Tools",
    login: "Login",
    log_out: "Logout",
    total_hours: "Total Hours",
    time_zone: "Time Zone",
    emp_name: "Employee Name",
    hrs: "hrs",
    income: "Income",
    revenue: "Revenue",
    productivity_of: "Productivity Of",
    cruiser_hours: "Cruiser Hours",
    think_hours: "Think Hours",
    think_time: "Think Time",
    listofemp: "List of Employees",
    application_summary: "Application Summary",
    daily: "Daily",
    Mumbercount: "Mumber count",
    user_list: "user list",
    tool_list: "Tool list",
    name: "Name",
  },

  Sysadmin: {
    total_machines: "Total Machines",
    de_allocate: "De-Allocate",
    total_tools: "Total Tools",
    created_machines: "Created Machines",
    created_tools: "Created Tools",
    this_month: "This Month",
    this_year: "This Year",
    last_month: "Last Month",
    completed: "Completed",
    in_progress: "Inprogress",
    pending: "Pending",
    this_tools: "this is tools",
    team_progress: "Team Progress",
    in_hours: "In Hours",
    machines: "Machines",
    tools: "Tools",
    machine_name: "Machine Name",
    employee_name: "Employee Name",
    ip_address: "IP Address",
    processor: "Processor",
    RAM: "RAM",
    BIZUnit: "BIZ Unit",
    storage: "Storage",
    time_zone: "Time Zone",
    status: "Status",
    active: "Active",
    upgrade: "Upgrade",
    create_on: "Create on",
    idle: "Idle",
    requests: "Requests",
    select_date: "Select Date",
    allocate: "Allocate",
    deallocate: "De Allocate",
    requesttype: "Request type",
    in_active: "In-Active",
    inactive: "In-Active",
    month: "Month",
    pemkey: "PEM KEY",
    t_action: "Action",
    all: "All",
    users_tools: "Users/Tools",
  },

  Employee: {
    chat: "chat",
    edit: "Edit",
    personal_info: "Personal Information",
    name: "Name",
    toolname: "Tool Name",
    email: "Email",
    phone: "Phone",
    lang: "Languages",
    loc: "Location",
    zp_code: "Zip Code",
    role: "Role",
    skills: "Skills",
    bio: "Biography",
    tl_employees: "Total Employees",
    prjt_resource: "Project Resource",
    bnch_resource: "Bench Resource",
    cnsltnt: "Consultant",
    filter: "Filter",
    login: "Login",
    log_out: "Logout",
    profile: "Profile",
    emp: "Employees",
  },
  labels: {
    OTP: "OTP",
    select_date: "Select date",
    email: "Email",
    password: "Password",
    directorytype: "Directory type",
    rememberMe: "Remember me",
    forgotPassword: "Forgot Password?",
    dontHaveAccount: "Don't have an account?",
    rejectRequest: "Reject Request",
    // welcome: "Welcome to Oneable",
    welcome: "Welcome to Oneable",
    welcome_to: "Welcome to",
    oneable: "OneAble",
    bluebird: "Bluebird",
    register: "Register",
    // logo: "Oneable",
    logo: "Oneable",
    dashboard: "Dashboard",
    timesheet: "Timesheet",
    project: "Projects",
    collabration: "Collaboration",
    productivity: "Productivity",
    employee: "Employee",
    task: "Task",
    faq: "Faq",
    schedular: "Schedular",
    seeData: "Seedata",
    vai: "Cruiser",
    connecting: "Connecting...",
    loading: "Loading...",
    ctrl: "Ctrl",
    alt: "Alt",
    tab: "Tab",
    esc: "Esc",
    show: "Show",
    entries: "Entries",
    newpsw: " New Password",
    oldpsw: " Old Password",
    confirm: "Confirm Password",
    savepsw: "Save Password",
    knowyourpsw: "Know your Password? Login",
    self: "Self",
    team: "Team",
    oops: "Oops",
    forgot_something: "forgot something",
    recover_password: "Type your email to recover your password.",
    kyp: "Do you know your password?",
    owp: "Secure Workforce Platform",
    pwp: "Enabling every employee",
    forgot_password: "Forgot Password",
    cancel: "Cancel",
    back: "Back",
    requets: "Requests",
    membercount: "Member Count",
    registrationdate: "Registration Date",
    organizationowner: "organisation owner",
    orgcreation: "organisation creation",
    orgcreation: "Organisation creation",
    licensecreation: "License creation",
    licenseupdate: "License Update",
    workingweek: "Working days per week",
    workinghours: "Working hours per day",
    address: "Address",
    multifactor: "Multifactor",
    create: "Create",
    employeeid: "Employee ID",
    employeerole: "Employee Role",
    organizationrole: "Organisation role",
    lastname: "Last Name",
    firstname: "First Name",
    projectcreation: "Project Creation",
    overview: "Overview",
    Recordsession: "Recorded Sessions",
    sessioncount: "Session-Count",
    Overtime: "Shift Ext.",
    session_expire: "Session has expired",
    login_again: "Login Again!",
    ok: "OK",
    Mycomputer: "My Computer",
    Drive: "Drive",
    Dropbox: "Dropbox",
    userCount: "User Count",
    select_org: "Select a Organisation",
    shift_complete: "Your's shift time completed",
    shift_close: 'Shift closed',
    Whiteboard: "Whiteboard",
    Record: "Record",
    Security: "Security",
    live_chat: "Live Chat",
    Yourshifttimecompleted: "Yours shift time completed",
    ApplicationUtilization: "Application Utilisation",
    SelfProductivity: "Self Productivity",
    reset_password: "Reset Password",
    user_details: "Users Details",
    expiry_date: "Expiry Date",
    shiftOvertime: "Shift/Shift Ext.",
    shiftSettings: "Shift Settings",
    shift: "Shift",
    overtime: "Shift Ext.",
    timeOffInMin: "Time Off (In Minutes)",
    productivity_settings: "Productivity Settings",
    productivity_view_permissions: "Productivity View Permissions",
    reject_this_request: "Are you sure for reject this request",
    disabled: 'Disabled',
    enabled: 'Enabled',
    user_details: "Users Details",
    expiry_date: "Expiry Date",
    time: "Time",
    share: "Download",
    pemkey: "PEM KEY",
    totalEmployees: "Total Employees",
    noManagerAssigned: "No Manager Assigned",
    notAssignedtoDept: "Not Assigned to any Department",
    comment:'Comment',
    fileshare:"File share",
    fileTransfer: 'File Transfer'
  },
  placeholders: {
    email: "Email",
    timeSheetProject: "Project Name",
    timeSheetTaskTitle: "Enter your task title",
    timeSheetDuration: "Hours",
    timeSheetMeetingTitle: "Enter your meeting title",
    timeSheetMeetingDuration: "Hrs.Min",
    password: "Password",
    newPassword: "New Password",
    oldPassword: "Old Password",
    confirmPassword: "Confirm Password",
    work_days: "Working days",
    work_hours: "Working hours",
    phone_number: "Phone number",
    user_count: "Enter User Count",
    enterconfirmPassword: "Enter Confirm Password",
    enternewPassword: "Enter New Password",
    search_tasktitle: "Search On TaskTitle",
    host: "Host",
    firstname: "Enter First Name",
    lastname: "Enter Last Name",
    enter_email: "Enter Email",
    enter_phoneno: "Enter Phone Number",
    enter_dayperweek: "Enter Work Days Per Week",
    enter_workhrs: "Enter Work Hours",
    address: "Enter Address"
  },
  buttons: {
    login: "Login",
    filter: "Filter",
    download: "Download",
    approve: "Approve",
    reject: "Reject",
    update: "Update",
    submit: "Submit",
    updatepassword: "Update Password",
    resubmit: "Re-Submit",
    cancel: "Cancel",
    fillTimeSheet: "Fill Timesheet",
    reset_password: "Reset Password",
    resend_otp: "Resend OTP",
    Create: "Create",
    Integrate: "Integrate",
    yes: "Yes",
    no: "No",
    add: "Add",
    Downloadfile: "Download File",
    Recentfiles: "Recent files",
    Save: "Save",
    delete: "Delete",
    leave: "Leave",
    join: "Join",
    Imageupload: "Image Upload",
    Yes_end_call_now: "Yes end call now",
    Return_to_call: "Return to call",
    Shift_closed: "Shift closed",
    continue: "Continue",
    edit: "edit",
    share: "Share or Download",
    Confirm:"Confirm",

  },
  errorMessages: {
    emptyResponse: "Empty response recieved",
    invalidshift: "You are not a member of this shift. Please contact your manager for a shift change if needed.",
    email: "Please enter a valid email.",
    toolName: "The tool name is required",
    password: "Password is required",
    date: "Date is required",
    timeSheetTaskTitle: "Task title is required",
    timeSheetMeetingTitle: "Meeting title is required",
    timeSheetDuration: "Duration is required",
    timeSheetMeetingDuration: "Meeting duration is required",
    timeSheetProject: "Project name is required",
    oldpassword: "Please enter the old password.",
    changepassword: "Password should be at least 8 characters long.",
    newpassword: "New password and confirm password should match.",
    forgotpassword: "Email is not correct.",
    timeoff_empty: "Please enter a valid time in minutes between 60 and 480.",
    url: "Please enter a project URL.",
    Name: "Please enter your name.",
    Link: "Please enter your link.",
    DisplayText: "Please enter display text.",
    Project: "Please select a project.",
    Department: "Please select a department.",
    TaskTitle: "Please enter a task title.",
    Description: "Please enter a description",
    Duedate: "Please select an end date.",
    startdate: "Please select a start date",
    Priority: "Please select a priority",
    Issuetype: "Please select an issue type.",
    Task_Tool: "Please select a task tool",
    Int_Tool: "Please select an integration tool",
    Assign: "Please assign a team member/employee.",
    Reporter: "Please select a reporter",
    Status: "Please select a status.",
    verificationCode: "Enter 6-digit verification code.",
    requestaselect: "Please select a request.",
    request_Type: "Please select a request type.",
    tool_select: "Please select a tool.",
    machine_name_Enter: "Please enter a machine name.",
    operating_system_select: "Please select an operating system.",
    ram_select: "Please select RAM.",
    processor_select: "Please select a processor.",
    storage_select: "Please select storage.",
    location_select: "Please select a location.",
    Plan_select: "Please select a plan.",
    org_Name: "Please enter an organisation.",
    org_Id: "Please enter the organisation ID.",
    users_Count: "Please enter the user count.",
    module_Name: "Enter the module name.",
    server_error: "There is an issue from Server",
    data_unavailable: "Data is unavailable.",
    host_url: "Enter a valid URL.",
    not_all_file_image: "Only image files (gif, png, jpg, jpeg, tiff) are allowed.",
    mandatory: "Please fill mandatory fields.",
    org_role: "Enter the organisation role.",

    org_name: "Enter the organisation name",
    host: "Please Enter the host URL.",
    employee_id: "Please Enter employee ID.",
    department: "Please Enter department.",
    enter_workhrs: "Please Enter valid work hours.",
    enter_dayperweek: "Please Enter valid days per week.",
    enternewpassword: "Please enter new password.",
    enterconfirmpass: "Please enter confirm password.",
    emailvalidoneable:
      "Please enter a valid email address from oneable.ai domain.",
    passMinLength: "Password should be atleast {{number}} characters",
    passMaxLength: "Password should not exceed {{number}} characters.",
    passUppercase: "Please include atleast one uppercase letter in your password.",
    passLowercase: "Please include atleast one lowercase letter in your password.",
    passDigit: "Please include atleast one digit in your password.",
    passSpecialCharacter: "Please include atleast one special character in your password.",
    work_break_time: "The combined Work Time and Break Time should not exceed 24 hours (1440 minutes)",
    shift_update_failed: "Unable to update Shift",
    shiftList_fetch_failed: "Unable to get the Shift List",
    productivityView: 'View Permission must be turned on for atleast one user for Productivity to be logged',
    productivity_update_failed: "Failed to update productivity settings",
    min_work_time: "Work Time should be minimum 1 minute",
    empty_break_time: 'Break Time field should not be empty',
    timeOff_update_failed: "Failed to update TimeOff Settings",
    user_settings_failed: "Failed to fetch User Settings",
    timeOff_error: 'TimeOff settings not available',
    productivity_error: 'Productivity settings not available',
    shift_error: 'Shift settings not available',
    userNoApprovedSheets: "You have no approved timesheets available for this month",
    teamNoApprovedSheets: "has no approved timesheets available for this month",
    validate_break_time: "Error while validate break time user settings",
    update_break_time: "Error while update user settings",
    unauthorized: "Unauthorized access.",
    not_found: "Not found.",
    req_failed: "API request failed.",
    parse_error: "Error parsing API response.",
    fetch_error: "Error fetching data from the API",
    file_size_validation_msg: "File size {{size}} must be 100KB or less.",
    invalidDomain: 'Is not a valid domain',
    taskTitleLength: "Task title should not exceed 255 characters",
    reporterCannotBeSet: "Reporter cannot be set",
    unableToCreateTask: "Unable to Create Task",
    unableToEditTask: "Unable to Edit Task",
    taskTitleTooLong: "Task title should not exceed 120 characters",
    timeSheetTaskTitleLength: "Task title should not exceed 255 characters",
    timeSheetMeetingTitleLength: "Meeting title should not exceed 255 characters",
    emailNotFound: "E-Mail not found. Please Check your email ID",
    unableToLogin: "Unable to login. Please contact your administrator",
    alreadyExists: "Project name already exists.",
    shouldNot: "Project names should not consist of only special characters.",
    shouldNotNum: "Project names should not consist of only numbers.",
    duplicateName: "Project name Exists"
  },
  timesheetValidation: {
    timesheetFields: "Field required",
  },
  vai: {
    CLIENT: {
      SERVER_BUSY:
        "This connection has been closed as the server is busy. Please wait a few minutes and try again.",
      UPSTREAM_TIMEOUT:
        "The Oneable server has closed the connection as the remote desktop is taking too long to respond. Please try again or contact your system administrator.",
      UPSTREAM_ERROR:
        "The remote desktop server encountered an error and has closed the connection. Please try again or contact your system administrator.",
      UPSTREAM_NOT_FOUND:
        "The remote desktop server is currently unreachable. If the problem persists, please notify your system administrator, or check your system logs.",
      UPSTREAM_UNAVAILABLE:
        "The remote desktop server is currently unavailable. If the problem persists, please notify your system administrator, or check your system logs.",
      SESSION_CONFLICT:
        "The remote desktop server has closed the connection because it conflicts with another connection. Please try again later.",
      SESSION_TIMEOUT:
        "The remote desktop server has closed the connection because it appeared to be inactive. If this is undesired or unexpected, please notify your system administrator, or check your system settings.",
      SESSION_CLOSED:
        "The remote desktop server has forcibly closed the connection. If this is undesired or unexpected, please notify your system administrator, or check your system logs.",
      CLIENT_UNAUTHORIZED: "Log in failed. Please reconnect and try again.",
      CLIENT_FORBIDDEN:
        "The remote desktop server has denied access to this connection. If you require access, please ask your system administrator to grant your account access, or check your system settings.",
      CLIENT_TIMEOUT:
        "The Oneable server has closed the connection because there has been no response from your browser for long enough that it appeared to be disconnected. This is commonly caused by network problems, such as spotty wireless signal, or simply very slow network speeds. Please check  your network and try again.",
      CLIENT_TOO_MANY:
        "The Oneable server is denying access to this connection because you have exhausted the limit for simultaneous connection use by an individual user. Please close one or more connections and try again.",
      CLIENT_DEFAULT:
        "An internal error has occurred within the Oneable server, and the connection has been terminated. If the problem persists, please notify your system administrator, or check your system logs.",
    },
    TUNNEL: {
      SERVER_BUSY:
        "The Oneable server has rejected this connection attempt because there are too many active connections. Please wait a few minutes and try again.",
      UPSTREAM_TIMEOUT:
        "The connection has been closed because the server is taking too long to respond. This is usually caused by network problems, such as a spotty wireless signal, or slow network speeds. Please check your network connection and try again or contact your system administrator.",
      UPSTREAM_ERROR:
        "The server encountered an error and has closed the connection. Please try again or contact your system administrator.",
      RESOURCE_NOT_FOUND:
        "The requested connection does not exist. Please check the connection name and try again.",
      RESOURCE_CONFLICT:
        "This connection is currently in use, and concurrent access to this connection is not allowed. Please try again later.",
      UPSTREAM_NOT_FOUND:
        "The Oneable server is not currently reachable. Please check your network and try again.",
      UPSTREAM_UNAVAILABLE:
        "The Oneable server is not accepting connections. Please check your network and try again.",
      CLIENT_UNAUTHORIZED:
        "You do not have permission to access this connection because you are not logged in. Please log in and try again.",
      CLIENT_FORBIDDEN:
        "You do not have permission to access this connection. If you require access, please ask your system administrator to add you the list of allowed users, or check your system settings.",
      CLIENT_TIMEOUT:
        "The Oneable server has closed the connection because there has been no response from your browser for long enough that it appeared to be disconnected. This is commonly caused by network problems, such as spotty wireless signal, or simply very slow network speeds. Please check  your network and try again.",
      CLIENT_TOO_MANY:
        "The Oneable server is denying access to this connection because you have exhausted the limit for simultaneous connection use by an individual user. Please close one or more connections and try again.",
      TUNNEL_UNSTABLE: "The network connection appears unstable reconnecting",
      TUNNEL_DEFAULT:
        "An internal error has occurred within the Oneable server, and the connection has been lost. If the problem persists, please contact your system administrator, or check  your system logs.",
    },
    No_tools_provision_yet: "Tools are not provisioned yet!",
    local_lanch_text: "This tool can be launched from the Oneable desktop application."
  },
  TimeSheets: {
    Oneable: "Oneable",
    review: "Review",
    Tark: "Tark",
    p_appsetup: "App Setup",
    p_3hrs: "3:00Hrs",
    p_MC: "Manager Comments",
    b_reject: "Reject",
    b_approve: "Approve",
    new_timesheet: "New Timesheet",
    s_sp: "Select a Project",
    p_task_title: "Enter your task title",
    p_0hrs: "00:00Hrs",
    b_cancel: "Cancel",
    b_submit: "Submit",
    pa_et: "Edit Timesheets",
    b_resubmit: "Resubmit",
    b_filter: "Filter",
    b_download: "Download",
    b_filltimesheet: "Fill Timesheet",
    t_date: "Date",
    t_projects: "Projects",
    t_task: "Task",
    t_hours: "Hours",
    t_status: "Status",
    t_action: "Action",
    t_name: "Name",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
    total: "Total",
    hours: "Hours",
    dt: "Download Timesheet",
    op1: "Option 1",
    op2: "Option 2",
    op3: "Option 3",
    approved: "Approved",
    today: "Today",
    thisweek: "This Week",
    thismonth: "This Month",
    timesheet: "Timesheet",
    tsc: "Timesheet Successfully Created",
    self_title: "Self",
    team_title: "Team",
    team_not_available: "Team not available for this user",
    reject_success: "Timesheet Rejected successfully",
    approve_success: "Timesheet Approved successfully",
    hours_exceed: "Hours exceeded more than 24",
    automated_edit: "Automated Timesheet is not Editable",
    All: "All",
    worked_hours: "Worked Hours",
    click_select_date_and_add_task: "Please select Date from Calendar and Click on Add Task",
    timesheet_update_success: "Timesheets updated successfully",
    task_hours_exceeded: "Task hours for this particular date exceeded 18 hours",
    task_project_already_exist: "There is a task associated with this Project already",
    Doyouwanttoapprove: "Do you want to approve",
    Doyouwanttorejecte:"Do you want to reject",
  },
  Projects: {
    new_proj: "New Project",
    no_proj: "OOPS! Project is not created Yet..",
    create_proj: "Create a new project",
    search: "search...",
    allprojects: "All Projects",
    active: "Active Projects",
    inactive: "Inactive Projects",
    completed: "Completed Projects",
    team: "Team",
    task: "Tasks",
    document: "Document",
    gantt: "Gantt",
    teamname: "Team Name",
    members: "Members",
    productivity: "Productivity",
    hours: "Hours",
    action: "Action",
    taskname: "Task Name",
    startdate: "Start Date",
    closedate: "Close Date",
    status: "Status",
    priority: "Priority",
    filename: "File Name",
    project: "Project",
    employee: "Employee",
    date: "Date",
    filesize: "File Size",
    actions: "Actions",
    Newfolder: "New Folder",
    Folders: "Folders",
    Projecttype: "Project Type",
    project_created: "Project Created successfully.",
    enter_project: "Enter project name",
    select_projecttype: "Select a project type",
    project_templatekey: "Project Template key",
    select_projecttempkey: "Select a project template key",
    project_error: "Unable to create project",
    SearchbyProject: "Search by Project",
    duplicate_project: "Duplicate Project Names not allowed",
    alphabet_required: 'Project name must contain atleast one alphabet'
  },
  seeddata: {
    search: "Search...",
    createrole: "+ Create Role",
    rolename: "Role Name",
    roledesc: "Role desc",
    logintype: "Login Type",
    action: "Action",
  },
  tasks: {
    Previous: "Previous",
    Next: "Next",
    createtask: "Create Task",
    Jira: "JIRA",
    sp: "Select a Project",
    sd: "Select a Department",
    select_priority: "Please select a Priority",
    select_issuetype: "Please select an Issue Type",
    select_Inttool: "Please select an Integration Tool",
    select_tasktool: "Please select a Tool for the Task",
    Int_Tool: "Integration Tool",
    Task_Tool: "Task Tool",
    product: "product",
    tasktitle: "Task Title",
    entertask: "Enter Task Title",
    description: "Description",
    optional: "(optional)",
    startdate: "Start Date",
    enddate: "End Date",
    cancel: "Cancel",
    close: "Close",
    save: "Save",
    search: "Search...",
    one: "One",
    two: "Two",
    three: "Three",
    currentweek: "Current Week",
    planned: "Planned",
    progress: "In progress",
    complete: "Complete",
    Priority: "Priority",
    High_Priority: "High",
    Medium_Priority: "Medium",
    Low_Priority: "Low",
    Issue_Type: "Issue Type",
    Task: "Task",
    Bug: "Bug",
    Story: "Story",
    Epic: "Epic",
    Org_est: "Original Estimate ",
    Reporter: "Reporter",
    Project: "Project",
    Task_Details: "Task Details",
    upload_file: "+ Upload Files (optional)",
    Assignee: "Assignee",
    edit_task: "Edit Task",
    TO_DO: "To Do",
    In_Progress: "In Progress",
    Done: "Done",
    QA: "QA -Testing",
    Unassigned: "Unassigned",
    No_tasks: "There are no tasks !",
    No_projects: "There are no Projects !",
    EstimatedHours: "Estimated",
    department: "Department",
    discription: "Description",
    raise_req: "Raise a request for tool access",
    req: "Raise a Request",
    in_hours: "In Hours",
    select_assignee: "Select a assignee",
    select_resource: "select resource",
    no_data: "No data available",
    req_raise: "Request raised successfully",
    valid_project: "Please enter valid Project",
    assign_team: "Assign To The Team Member",
    del_attach: "Delete this attachment?",
    once_del: "Once you delete it's for good",
    log_hours: "Logged hours status cannot be changed to To Do and Unassigned",
    status_update: "Status Updated successfully",
    tasks_update: "Task Updated successfully",
    bug_update: "Bug Updated successfully",
    epic_update: "Epic Updated successfully",
    story_update: "Story Updated successfully",
    tasks_create: "Created Task Successfully",
    tasks_delete: "Deleted Task Successfully",
    bug_create: "Created Bug Successfully",
    epic_create: "Created Epic Successfully",
    story_create: "Created Story Successfully",
    req_task_tool: "Please Raise a Request In Setting,Tool for this task is not provisioned",
    break_or_continue: "Please select Take a Break or Continue in the workspace to Launch the tools",
    estimated_hrs: "Estimated hours",
    task_view: "Task View",
    tool_launch: "Tool Launch",
    file_upload_success: "Your file has been uploaded successfully.",
    upload_file_note: "Note : Upload a file size limit of 100KB or lower.",
    provisioned_tools: "Provisioned Tools",
    unprovisioned_tools: "Unprovisioned Tools",
    unassigned_status_change: "To change status of the task , assign the task to Self/Team",
    to: "to",
    from: "from",
    status_changed: "Status changed",
    drag_here: "Drag here",
    del_task: "Delete Task",
    would_del_task: "Are you sure to delete this task?"
  },

  productivity: {
    team_pro: "Team Productivity",
    daily: "Daily",
    monthly: "Monthly",
    yearly: "Yearly",
    application_pro: "Application Productivity",
    hours: "Hours",
    no_data: "There is no data for the selected criteria",
    showtrends: "Show Trends",
    hidetrends: "Hide Trends",
    delete_session: "Do you want to delete recorded session ?",
    Teamproductivity: "Team Productivity",
    Appproductivity: "App Productivity",
    Mostused: "Most Used",
    Yesterday: 'Yesterday',
    LAST_7_DAYS: 'Last 7 Days',
    THIS_MONTH: 'This Month',
    LAST_MONTH: 'Last Month',
    THIS_YEAR: 'This Year',
    LAST_YEAR: 'Last Year',
    custom_Range: "Custom Range",
  },
  profile: {
    gd: "Graphic Design",
    chat: "Chat",
    edit: "Edit",
    pi: "Personal Information",
    name: "Name",
    email: "Email",
    phone: "Phone",
    languages: "Languages",
    location: "Location",
    zc: "Zip Code",
    role: "Role",
    skills: "Skills",
    biography: "Biography",
    remain_char: "Remaining Characters:",
    no_change: 'Enter Profile Details To Update',
    remain_char: 'Remaining Characters',
    valid_lang: 'Add Valid Languages',
    update: 'Profile updated successfully',
    valid_loc: 'Invalid location',
    valid_phone: 'Invalid Phone Number',
    employeeId: "Employee ID",
    managers: 'Manager(s)',
    departments: 'Department(s)',
    onboardedOn: 'Onboarded On'
  },
  collaboration: {
    Imageupload: "Image Upload",
    fileupload: "File Upload",
    Uploadfile: "Upload File",
    Hyperlink: "HYPER LINK",
    Displaytext: "Display Text",
    Linkshare: "Link",
    err_name: "Name should not be empty",
    forward: "Forward",
    reply: "Reply",
    pin: "Pin",
    copy: "Copy",
    delete: "Delete",
    download: "Download",

  },
  pomodoro: {
    Integration: "Integration",
    Search_messages: "Search messages or users",
    pomodoro: "Pomodoro",
    Add_Integration: "Add Integration",
    Jira_Integration: "Integration",
    organization_name: "Organisation Name",
    organization: "Organisation ",
    actions: "Actions",
    membercount: "Member Count",
    Pomodoro_timer: "Pomodoro Timer",
    do_want_pomodoro: "Do you want to integrate Time Off",
    select_All_Org: "Select All Organisations",
    // Org_Maangadi: "Organisation  Maangadi",
    Org_Oneable: "Organisation  Oneable",
    Break_time: "Break",
    time_minite: "Time ( In Minutes )",
    break_extension: "Break Extension",
    break_time_extension: "Break Time Extension",
    Notification: "Notification",
    Auto_start_Breaks: "Auto_start_Breaks",
    timer_setting: "Timer Setting",
    time_off: "Time Off",
    update: "Update Time Off",
    ok: "OK",
    activate: "Do you want to activate time off",
    deactivate: "Do you want to de-activate time off",
    Int_act: "Do you want to activate integration",
    Int_deact: "Do you want to deactivate integration",
    notification: "Time off notification",
    Int_notification: "Integration notification",
    break: "Take a break",
    worked_for: "You have been working for",
    continue: "Continue",
    minutes: "minutes",
    seconds: "seconds",
    usercreation: "User Creation",
    user: "User",
    Registerdate: "Registered Date",
    Directory: "Directory",
    connection_name: "Connection Name",
    user_or_email: "Username / Email",
    pass_or_apitoken: "Password / API token",
    integration_success: "Integrated successfully.",
    inMinutes: "(in Minutes)",
    workTime: "Work Time",
    breakTime: "Break Time",
    colon: " : ",
    breakTime_Launch_Tool: "Are you sure, do you want to launch the tool?,",
    breakTime_Left: "You still have break time",
    left: "Left",
    launch_Tool: "Launch tool",
    continue_Break: "Continue break",
  },
  chart: {
    thinktime: "Think Time",
    timesheethr: "Timesheet Hours",
    officehours: "Office Hours",
  },
  Settings: {
    Selectapriority: "Select a priority",
    Plan: "Plan",
    Selectrequesttype: "Select request type",
    Selectarequest: "Select a request",
    all: "All",
    approved: "Approved",
    submitted: "Submitted",
    rejected: "Rejected",
    closed: "Closed",
    approve: "Approve",
    reject: "Reject",
    projectname: "Project name",
    request: "Request",
    requesttType: "Request type",
    opsystem: "Operating System",
    storge: "Storge",
    location: "Location",
    multipleAppsToLaunch: "Multiple Apps To launch",
    Domain: "Domain",
    Adduser: "Add User",
    Adddomain: "Add Domain",
    Userinfo: "User Info",
    manager: "Manager",
    Directorytype: "Directory Type",
    modules: "Modules",
    Domaincreation: "Domain Creation",
    Directorycreation: "Directory Creation",
    Modulecreation: "Module Creation",
    Moduleupdate: "Module update",
    Directoryname: "Directory Name",
    Directorycode: "Directory Code",
    Modulename: "Module Name",
    Domainname: "Domain Name",
    Authenticationtype: "Authentication Type",
    Truststore: "Trust Store",
    Serverprotocol: "Server Protocol",
    Serveraddress: "Server Address",
    Serverport: "Server Port",
    Aattribute: "Authentication Attribute",
    Securityprincipal: "Security Principal",
    Basename: "Base Name",
    Securitycredentials: "Security Credentials",
    Managerattribute: "Manager CN Attribute",
    Userobjectclass: "User Object Class",
    Firstnameattribute: "First Name Attribute",
    Tenantid: "Tenant Id",
    Clientsecret: "Client Secret",
    Clientid: "Client Id",
    Titleattribute: "Title Attribute",
    EmployeeIdattribute: "EmployeeId Attribute",
    Photoattribute: "Photo Attribute",
    Emailattribute: "Email Attribute",
    LNameattribute: "Last Name Attribute",
    Createorg: "Create Organisation",
    req_notraise: "Requests not yet raised",
    OrgadminView: "Organisation Admin",
    OpsadminView: "Operations Admin",
    SelfView: "Self",
    create_licensekey: "Create Licensekey",
    license_createkey_success: "License key created successfully"
  },
  Modules: {
    Modules: "Modules",
    Create: "Create",
    delAlert: "Do you want to de-activate this module?",
    actAlert: "Do you want to activate this module?",
    manager: "Manager",
    add_modules: 'Add a module to create license',
    modules_add: 'Add modules',
    no_modules: 'There are no modules to add!',
    nomodule_save: 'Edit modules before update',
    atleast_one: 'Atleast one module should be active',
    modules_statusChange: 'Your changes will not be saved.',
    modules_continue: 'Do you want to continue ?',
    act_deact: 'Activate/Deactivate',
    no_data: 'No data available',
    module_updt: "Module Update successfully",
    module_create: 'Module Created Successfully',
    no_module: "No modules exist.",
    edit_module: "Edit module befor updating",
  },
  License: {
    activate: "Do you want to activate this license?",
    deactivate: "Do you want to de-activate this license?",
    activateModule: "Do you want to activate these license modules?",
    deactivateModule: "Do you want to de-activate these license modules?",
    Activate: "Activate",
    Deactivate: "Deactivate",
    orgId: "Organisation Id",
    create: "License created successfully",
    edit_updt: "Edit Before Updating License",
    edit_module: "Edit Before Updating Module/s",
    valid_date: "Add Valid License Date",
    no_module: "Contact admin to activate",
    module_remove: "Default modules cannot be removed",
    no_license: 'Oops! No License created',
    edit_license: "Edit License",
    edit_license_modules: "Edit License Modules",
  },
  collabration: {
    All_Channels: "All Channels",
    Search_Channels: "Search Channels",
    There_is_no_channel: "There is no channel !",
    Workspace_List: "Workspace List",
    Workspace_created_successfully: "Workspace created successfully",
    Workspace_Create: "Workspace Create",
    Invitation_sent_successfully: "Invitation sent successfully",
    Invitation_accept_successfully: "Invitation accepted successfully.",
    No_user_subscriber: "There are no users/subscribers in this workspace !",
    Enter_channel_name: "Please enter channel name",
    contact_admin: "Oops! something went wrong , please contact your administrator.",
    uarentWorkSpace: "Oh! you are not allocated to any Work Space",
    contacturMngrorAdmr: "Please contact your Manager or Administrator",
    CreateUrWorkspaceAdmin: "Create your WorkSpace or Contact Administrator",
    no_workspace: "There is no workspace",
    Are_you_sure: " Are you sure",
    you_want_to_end_this_call: "Do you want to end this call ?",
    Are_you_sure_you_want_to_delete_this_data: "Are you sure, you want to delete this data?",
    Are_you_certain_about_your_intention_to_remove_this_user_from_the_channel: "Are you certain about your intention to remove this user from the channel?",
    click_for_workspace: "Please click on Create Worskpace button to add a new workspace",
    create_workspace: "Create Workspace",
    name: "Name",
    ph_name: "Enter name",
    description: "Description(Optional)",
    ph_description: "Enter description",
    create_ws: "Create workspace",
    collabration: "Collaboration",
    workspace: "Workspace",
    channel: "Channel",
    channels: "Channels",
    personnel: "Personnel",
    personal: "Personal",
    no_channel: "Channel not yet created.",
    create_channel: "Create new channel.",
    make_private: "Make Private",
    channel_creation: "Create channel",
    private_channel: "Create private channel",
    totalemployee: "Total Employee",
    totalchannel: "Total Channel",
    allchannel: "All Channel",
    createchannel: "Create Channel",
    invitepeople: "Invite People To ",
    createworkspace: "Create Workspace",
    deleteworkspace: "Delete Workspace",
    acceptInvitation: "Invitation accepted successfully.",
    rejectInvitation: "Invitation rejected successfully.",
    allEmployees: "All Employees",
    noEmployee: "There is no employee in this workspace !",
    err_name: "Name should not be empty",
    channel_created: "Channel created successfully",
    select_user: "Select atleast one user",
    users_added: "Users added successfully",
    delete_room: "Room deleted successfully",
    unsubscribe_room: "User left successfully",
    add_personnel: "Add personnel",
    view_personnel: "View personnel",
    view_add_personnel: "View/Add personnel",
    view_add_remove_personnel: "View/Add/Remove personnel",
    select_all: "Select All",
    no_team: "! Oh , there is no team",
    no_messages: "No messages",
    direct_messages: "Direct Messages",
    no_users: "Users not available",
    type_a_message: "Type a message....",
    please_type_a_message_to_continue: "Please type a message to continue.",
    edit: "Edit",
    remove: "Remove",
    bold: "Bold",
    italic: "Italic",
    underline: "Underline",
    strike: "Strike",
    link: "Attach Link",
    list: "List",
    ordered: "Ordered",
    bullet: "Bullet",
    blockquote: "Blockquote",
    code: "Code",
    "code-block": "Codeblock",
    emoji: "Insert Emoji",
    images_attached: "Attach Image",
    file_attached: "Attach File",
    send: "Send",
    Member: "Member",
    Remove: "Remove",
    noMember: "No member",
    Owner: "Owner",
    You: "You",
    Searchpersonnel: "Search personnel...",
    search: "Search...",
    There_is_no_User: "There is no User",
    create_room: "Create Room",
    setting: "Collaboration Setting",
    channel_create: "channel Create",
    create_Channel: "Create Channel",
    channel_subscribe: "Channel subscribed successfully",
    channel_unsubscribe: "Channel unsubscribed successfully",
    General: "General",
    Channel: "Channel",
    // Channel_data: "Study is dummy text for printing",
    Chats: "Chats",
    // Chats_Name1: "Mari Speedwagon",
    // Chats_Name2: "Debra Stewart",
    // Chats_Name3: "Petey Cruiser",
    Create_A_Channel: "Create a channel",
    Create_A_Private_Channel: "Create a private channel",
    Create_A_Organization: "Create a workspace",
    Name: "Name",
    Enter_Org_Name: "Enter workspace name",
    Enter_Channel_Name: "Enter channel name",
    Description_label: "Description (optional)",
    Private: "Private",
    Public: "Public",
    welcome_to_Oneable: "Hey !!  Vadde Welcome to Oneable",
    Integration: "Integration",
    MakePrivate: "Make Private",
    Collaboration: "Collaboration",
    Workspace: "Workspace",
    Personnel: "Personnel",
    chats: "Chats",
    Created: "Created",
    invitepeopleto: "Invite People to",
    thereisnousers: "There is no users",
    subscribersinworkspace: "Subscribers in this workspace",
    Hyperlink: "HYPER LINK",
    Displaytext: "Display Text",
    Linkshare: "Link",
    Totalchannels: "Total Channels",
    Totaljoined: "Total Joined",
    Imageupload: "Image Upload",
    fileupload: "File Upload",
    Uploadfile: "Upload File",
    Join: "Join",
    View: "View",
    Leave: "Leave",
    delete: "Delete",
    Directmessages: "Direct Messages",
    Teams_Talk: "Teams Talk",
    Direct_chats: "Direct Chats",
    No_Channel_alert_create: "There are no Channels in this workspace !",
    Create_a_new_Channel: "Create a new Channel",
    delete_workspace: "Workspace deleted successfully",
  },
  sessionRecording: {
    no_sessions: "oops! There are no session recordings",
    proceed: "Proceed",
  },
  months: {
    jan: "January",
    feb: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
  },
  whiteboard: {
    Select: "Select",
    Pencil: "Pencil",
    Brush: "Brush",
    ColorPick: "Color Pick",
    Text: "Text",
    Polygon: "Polygon",
    Rectangle: "Rectangle",
    Circle: "Circle",
    Line: "Line",
    Erosser: "Erosser",
    Image: "Image",
    Undo: "Undo",
    Redo: "Redo"
  },
  pdf: {
    week: 'Week',
    timeSheet: "Timesheet",
    name: "Name",
    timePeriod: "Time Period",
    totalWorkHours: "Total Worked Hours",
    timeSheetStatus: "Time Sheet Status",
    projects: "Project(s)",
    generatedOn: "Generated on",
    date: 'Date',
    project: 'Project',
    task: 'Task',
    workHours: 'Work Hours',
    type: 'Type',
    approved: "Approved",
    rejected: 'Rejected',
    submitted: "Pending Approval",
    all: 'All',
    monthOf: 'Time Sheets for the period of',
    hasNo: 'has no',
    status: 'Status',
    pending: 'Pending',
    selectProject: "Atleast one project must be selected",
    downloadSelect: "Download option not selected",
    download: "Download",
    manual: 'MANUAL',
    fileFormat: "File Format",
    select: "Select",
    thismonth: 'This Month',
    fortnight1: 'Fortnight 1',
    fortnight2: 'Fortnight 2',
    week: 'Week ',
    lastMonth: 'Last Month',
    thisYear: 'This Year',
    lastYear: 'Last Year',
    customRange: 'Custom Range',
    close: 'Close',
    selectProjects: "Select a Project",
    selectRange: "Select Range"
  },
  rtc: {
    videoOff: "Turn Off Video",
    videoOn: "Turn on Video",
    mute: "Mute",
    unMute: "Unmute",
    shareScreen: "Share Screen",
    more: "more",
    endCall: 'End Call',
    liveChat: "Live Chat",
    chat: "Chat",
  },
  directories: {
    authActivate: "Authentication type created successfully",
    authDeactivate: "Authentication type de-activated successfully",
    noFieldsUpdated: "No fields were modified",
    authModified: "Authentication type updated successfully",
    directories: "Directories",
    directoryCreation: "Directories Creation",
    directoryName: "Directory Name",
    enterDirectoryName: "Enter directory name",
    description: "Description",
    enterDescription: 'Enter Description',
    cancel: "Cancel",
    close: "Close",
    create: 'Create',
    update: 'Update'
  },
  domain: {
    CreateDirectories: "Create Directories",
    OnPremisesDirectories: "Enter connection information for your on-premises directories",
    AuthenticationType: "Authentication Type",
    DomainName: "Domain Name",
    TrustStore: "Trust Store",
    serverProtocol: "Server Protocol",
    serverAddress: "Server address",
    ServerPort: "Server Port",
    authenticationAttribute: "Authentication Attribute",
    securityPrincipal: "Security Principal",
    baseName: "Base Name",
    securitycredentials: "Security Credentials",
    manager_CN_attribute: "Manager CN Attribute",
    userobjectclass: "User Object Class",
    firstnameattribute: "First Name Attribute",
    lastnameattribute: "Last Name Attribute",
    emailattribute: "Email Attribute",
    photoattribute: "Photo Attribute",
    employeeIdattribute: "Employee Id Attribute",
    titleattribute: "Title Attribute",
    departmentIdAttribute: "Department Id Attribute",
    clientid: "Client Id",
    clientsecret: "Client Secret",
    tenantid: "Tenant Id",
    client_id: "Enter Client Id",
    client_secret: "Enter Client Secret",
    tenant_id: "Enter Tenant Id",
    Enteryourdomainname: "Please Enter your domain name",
    Entertruststore: "Please Enter trust store",
    Enterserverprotocol: "Please Enter Server protocol",
    Enterserveraddress: "Please Enter server address",
    Enterserverport: "Please Enter server port",
    Authenticationattribute: " Please Enter authentication attribute",
    Securityprincipal: " Please Enter security principal",
    Enterbasename: " Please Enter base name (DN)",
    Enter_sec_credentials: " Please Enter security credentials",
    Entermanagercnattribute: "Please Enter manager cn attribute",
    Enteruserobjectclass: " Please Enter user object class",
    Enterfirstnameattribute: " Please Enter first name attribute",
    Enterlastnameattribute: " Please Enter last name attribute",
    Enteremailattribute: " Please Enter email attribute",
    EnteremployeeIdattribute: "Please Enter employee Id attribute",
    EnterdepartmentIdattribute: " Please Enter Department Id Attribute",
    photo_attribute: "Please Enter photo attribute",
    title_attribute: "Please Enter Title Attribute",
  },
  pemKey: {
    select_file: "Select file",
    unsupported_file_type: "Unsupported file type. Please upload a PEM file.",
    file_reader_error: "Error reading file"
  },
  dateRangePicker: {
    yesterday: 'Yesterday',
    lastSevenDays: 'Last 7 Days',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    thisYear: 'This Year',
    lastYear: 'Last Year',
    customRange: 'Custom Range',
    selectRange: 'Select Date Range',
    clear: 'Clear',
    to: 'to'
  },
  pageSize: {
    all: 'All',
  },
  sessionList: {
    name: 'Name',
    session_count: 'Session Count',
    tools: 'Tools',
    tool: 'Tool',
    action: 'Actions',
    loading: 'Loading...',
    No_more_sessions_to_show: 'No more sessions to show'
  },
  passwordPolicy: {
    minPasswordLength: "Minimum Length: ",
    maxPasswordLength: "Maximum Length: ",
    requiredUppercase: "Minimum of one Upper Case Alphabet",
    requiredLowercase: "Minimum of one Lower Case Alphabet",
    requiredSpecialCharacter: "Minimum of one Special Character",
    requiredDigits: "Minimum of One Number",
    characters: "Characters",
    passwordPolicies: 'Password Policy',
    passwordsDoNotMatch: "The new password and the confirmed new password do not match",
    passwordChangeSuccessful: "Password change successful",
  },
  fileShare: {
    login: "Login",
    credentials: 'Credentials',
    hostname: "Hostname",
    username: "Username",
    password: "Password",
    sourceMachine: "Source Machine",
    destinationMachine: "Destination Machine",
    enterHostname: "Enter Hostname",
    enterUsername: "Enter Username",
    enterPassword: "Enter Password",
    cancel: 'Cancel',
    submit: 'Submit',
    passwordRequired: "Please enter the password",
    usernameRequired: "Please enter the username",
    hostnameRequired: "Please enter the host address",
    logout: "Logout",
    fileTransferSuccessful: 'File Transfer Successful'
  },
  fileShareErrors: {
    inaccessable: "Directory cannot be accessed",
    rootLevelSharing: "Files transfer cannot be done at root level",
    nonBinaryFolderShare: "All Binary Files successfully transferred",
    nonBinaryFileShare: "Transferring of Non-Binary files not allowed",
    loginFailed: 'Login Failed. Please check credentials',
    refreshFailed: "Failed to refresh Folder Structure",
    toMachineNotLoggedIn: "You have not logged in to the destination machine"
  }
};