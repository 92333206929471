import {
  SET_ROOM_BROWSE_SUCCESS,
  SET_ROOM_CHANNEL_CREATE_SUCCESS,
  SET_ROOM_DELETE_SUCCESS,
  SET_ROOM_DIRECT_CREATE_SUCCESS,
  SET_ROOM_ERROR,
  SET_ROOM_GROUP_CREATE_SUCCESS,
  SET_ACTIVATE_GROUP_SUCCESS,
  SET_ROOM_LOADING,
  SET_ROOM_READ_SUCCESS,
  SET_SELECTED_ROOM_SUCCESS,
  SET_ROOM_SUBSCRIPTION_ACTIVATE_SUCCESS,
  SET_ROOM_SUBSCRIPTION_DEACTIVATE_SUCCESS,
  SET_ROOM_SUBSCRIPTION_ERROR,
  SET_ROOM_SUBSCRIPTION_LOADING,
  SET_ROOM_SUBSCRIPTION_PARTICIPANTS_SUCCESS,
  SET_ROOM_SUBSCRIPTION_READ_SUCCESS,
  SET_INVITE_USERS_TO_ROOM,
  SET_ROOMWISE_USERS,
  SET_ROOM_UPDATE_SUCCESS,
  SET_ROOM_SUBSCRIBE_SUCCESS,
  SET_ROOM_UNSUBSCRIBE_SUCCESS,
  SET_DEACTIVE_USERS,
  SET_ROOMS_MESSAGE_INFO
} from "../constants/collabration";

const initialState = {
  rooms: [],
  selectedRoomObj: {},
  roomBrowse: [],
  room: {},
  roomUpdate: {},
  roomDelete: {},
  activateGroupObj: {},
  roomOnError: null,
  roomOnLoading: false,

  roomSubscriptionOnLoading: false,
  roomSubscriptionOnError: null,
  inviteUserObj: {},
  roomwiseUserList: [],
  roomSubscriptions: [],
  roomParticipants: [],
  roomSubscriptionActivate: {},
  roomSubscriptionDeActivate: {},
  roomSubscriptionSubscribe: {},
  roomSubscriptionUnSubscribe: {},
  usersDeActivate: null,
  messageCountInfo: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ROOM_LOADING: {
      return Object.assign({}, state, {
        roomOnLoading: action.loading,
      });
    }

    case SET_ROOM_ERROR: {
      return Object.assign({}, state, {
        roomOnError: action.error,
      });
    }

    case SET_ROOM_DIRECT_CREATE_SUCCESS:
    case SET_ROOM_GROUP_CREATE_SUCCESS:
    case SET_ROOM_CHANNEL_CREATE_SUCCESS: {
      return Object.assign({}, state, {
        room: action.success,
      });
    }

    case SET_ACTIVATE_GROUP_SUCCESS: {
      return Object.assign({}, state, {
        activateGroupObj: action.success,
      });
    }

    case SET_ROOM_READ_SUCCESS: {
      return Object.assign({}, state, {
        rooms: action.success,
      });
    }

    case SET_SELECTED_ROOM_SUCCESS: {
      return Object.assign({}, state, {
        selectedRoomObj: action.success,
      });
    }

    case SET_ROOM_BROWSE_SUCCESS: {
      return Object.assign({}, state, {
        roomBrowse: action.success,
      });
    }

    case SET_ROOM_UPDATE_SUCCESS: {
      return Object.assign({}, state, {
        roomUpdate: action.success,
      });
    }

    case SET_ROOM_DELETE_SUCCESS: {
      return Object.assign({}, state, {
        roomDelete: action.success,
      });
    }

    case SET_ROOM_SUBSCRIPTION_LOADING: {
      return Object.assign({}, state, {
        roomOnLoading: action.loading,
      });
    }

    case SET_ROOM_SUBSCRIPTION_ERROR: {
      return Object.assign({}, state, {
        roomOnError: action.error,
      });
    }

    case SET_ROOM_SUBSCRIPTION_READ_SUCCESS: {
      return Object.assign({}, state, {
        roomSubscriptions: action.success,
      });
    }

    case SET_ROOM_SUBSCRIPTION_PARTICIPANTS_SUCCESS: {
      return Object.assign({}, state, {
        roomParticipants: action.success,
      });
    }

    case SET_ROOM_SUBSCRIPTION_ACTIVATE_SUCCESS: {
      return Object.assign({}, state, {
        roomSubscriptionActivate: action.success,
      });
    }

    case SET_ROOM_SUBSCRIPTION_DEACTIVATE_SUCCESS: {
      return Object.assign({}, state, {
        roomSubscriptionDeActivate: action.success,
      });
    }

    case SET_ROOM_SUBSCRIBE_SUCCESS: {
      return Object.assign({}, state, {
        roomSubscriptionSubscribe: action.success,
      });
    }

    case SET_ROOM_UNSUBSCRIBE_SUCCESS: {
      return Object.assign({}, state, {
        roomSubscriptionUnSubscribe: action.success,
      });
    }

    case SET_INVITE_USERS_TO_ROOM: {
      return Object.assign({}, state, {
        inviteUserObj: action.success,
      });
    }

    case SET_ROOMWISE_USERS: {
      return Object.assign({}, state, {
        roomwiseUserList: action.success,
      });
    }

    case SET_DEACTIVE_USERS: {
      return Object.assign({}, state, {
        usersDeActivate: action.success,
      });
    }

    case SET_ROOMS_MESSAGE_INFO: {
      return Object.assign({}, state, {
        messageCountInfo: action.success,
      });
    }

    default:
      return { ...state };
  }
};
