const subscriptionMap = new Map();
export default class Socket {
    constructor(channels) {
        this.channles = channels;
    }

    connect(data, success, error) {
        const self = this;
        window?.oneAbleCollaboration?.socket?.connect(data, success, error);
    }

    send(data, success, error) {
        console.log("in send data");
        console.log(data);
        console.log(window);
        window?.oneAbleCollaboration?.message?.send(data, success, error);
    }

    get(data, success, error) {
        window?.oneAbleCollaboration?.message?.get(data, success, error);
    }

    subscribe(roomId, callback, whiteboardCallback) {
        const sub = window?.oneAbleCollaboration?.socket?.subscribe(
            roomId,
            (message) => {
                callback && callback(message);
            },
            (whiteboard) => {
                whiteboardCallback && whiteboardCallback(whiteboard);
            }
        );
        subscriptionMap[roomId] = sub;
    }

    ack(roomId) {
        if (!roomId || subscriptionMap.size == 0 || !subscriptionMap.get(roomId)) {
            return;
        }
        window?.oneAbleCollaboration?.message?.send(
            roomId,
            subscriptionMap[roomId].subscription.id
        );
    }

    isConnected() {
        return window?.oneAbleCollaboration?.socket?.isConnected();
    }

    disconnect() {
        return window?.oneAbleCollaboration?.socket?.disConnect();
    }
}
