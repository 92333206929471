export const SET_INTEGRATION_ERROR = "SET_INTEGRATION_ERROR";

//                     ******* CREDENTIALS CREATE*******

export const SET_CREDENTIALS_CREATE_SUCCESS = "SET_CREDENTIALS_CREATE_SUCCESS";

//                     ******* GET INTEGRATIONS *******

export const GET_INTEGRATIONS_SUCCESS = "GET_INTEGRATIONS_SUCCESS";

//                     ******* GET PROJECTS *******

export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";

//                     ******* GET USERS *******

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";

//                     ******* GET TOOLS *******
export const GET_ALL_ORG_TOOLS_SUCCESS = "GET_ALL_ORG_TOOLS_SUCCESS";

//                     ******* GET TASKLIST *******
export const GET_TASK_LIST_SUCCESS = "GET_TASK_LIST_SUCCESS";

//                     ******* GET ISSUE DETAILS FROM JIRA *******
export const GET_TASK_DETAILS_SUCCESS = "GET_TASK_DETAILS_SUCCESS";

//                     ******* GET ISSUE TRANSITIONS *******
export const GET_ISSUE_TRANSITIONS_SUCCESS = "GET_ISSUE_TRANSITIONS_SUCCESS";

export const SET_UPDATE_ISSUE_TASK_SUCCESS = "SET_UPDATE_ISSUE_TASK_SUCCESS";

//                     ******* FILE UPLOAD *******
export const SET_FILE_UPLOAD_SUCCESS = "SET_FILE_UPLOAD_SUCCESS";

export const GET_FILE_UPLOAD_SUCCESS = "GET_FILE_UPLOAD_SUCCESS";

//                     ******* TASKS CREATE *******

export const SET_TASKS_CREATE_SUCCESS = "SET_TASKS_CREATE_SUCCESS";

//                     ******* SELECT PROJECT CREATE *******

export const SET_SELECT_PROJECT_SUCCESS = "SET_SELECT_PROJECT_SUCCESS";

export const SET_TASK_MODAL = "SET_TASK_MODAL";

export const GET_TASK_INFO_DETAILS_SUCCESS = "GET_TASK_INFO_DETAILS_SUCCESS";

//                     ******* EDIT TASKS  *******

export const SET_EDIT_TASKS_SUCCESS = "SET_EDIT_TASKS_SUCCESS";

//                    ******* GET CRUISER  TASKS  *******

export const GET_CRUISER_TASKS_SUCCESS = "GET_CRUISER_TASKS_SUCCESS";

//                    ******* SET STATUS CHANGE   *******

export const SET_STATUS_CHANGE_SUCCESS = "SET_STATUS_CHANGE_SUCCESS";

//                    ******* GET USER PROVISIONED TOOLS   *******

export const GET_USER_TOOLS_SUCCESS = "GET_USER_TOOLS_SUCCESS";

//                    ******* GET USER PROVISIONED TOOLS   *******

export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENT_SUCCESS";

//                    ******* GET PRIORITY   *******

export const GET_PRIORITY_SUCCESS = "GET_PRIORITY_SUCCESS";

//                    ******* GET ISSUE TYPE   *******

export const GET_ISSUE_TYPE_SUCCESS = "GET_ISSUE_TYPE_SUCCESS";

//                    ******* INTEGRATION ACTIVATE/DEACTIVATE  *******

export const SET_ACT_OR_DEACT_SUCCESS = "SET_ACT_OR_DEACT_SUCCESS";


