import collaboration from "../services/collaboration";

// To filter workspace channels from user's subscribed channel
export const filterWorkspaceChannel = (
  selectedWorkspaceId,
  subscribedChannelList = []
) => {
  return subscribedChannelList.filter(
    (item) => item.workspaceId === selectedWorkspaceId
  );
};

export const getListOfParticipantsForRoom = (channels, matchKey) => {
  let channelParticipantsData = channels?.map((item) => {
    collaboration.channels.getSubscribers(
      item?.[matchKey],
      (channelSubscriber) => {
        item.userSubscriptionList = channelSubscriber?.data?.content || [];
      },
      (error) => {}
    );
    return item;
  });
  return channelParticipantsData;
};
