import { SET_EMPLOYEE_DASHBOARD_PENDING, SET_EMPLOYEE_DASHBOARD_SUCCESS, SET_EMPLOYEE_DASHBOARD_ERROR } from '../constants/employeeDashboard';
import { getemployeeDashboardService } from '../services/employeeDashboard ';


const setEmpDashboardPending = (isLoading) => {
	return {
		type: SET_EMPLOYEE_DASHBOARD_PENDING,
		isLoading
	};
}

const setEmpDashboardSuccess = (payload) => {
	return {
		type: SET_EMPLOYEE_DASHBOARD_SUCCESS,
		payload
	};





}

const setEmpDashboardError = (error) => {
	return {
		type: SET_EMPLOYEE_DASHBOARD_ERROR,
		error
	};
}

export const getEmpDashboardData = (reqData) => dispatch => {
	dispatch(setEmpDashboardPending(true));
	dispatch(setEmpDashboardSuccess({}));
	dispatch(setEmpDashboardError(null));
	getemployeeDashboardService(reqData, (respData) => {
		dispatch(setEmpDashboardSuccess(respData));
		dispatch(setEmpDashboardPending(false));
		dispatch(setEmpDashboardError(null));
	}, (error) => {
		dispatch(setEmpDashboardPending(false));
		dispatch(setEmpDashboardError(error));
	});
}

