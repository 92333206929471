import { toast, Zoom } from "react-toastify";
import {
  SET_CREATE_WORKSPACE_SUCCESS,
  SET_LIST_WORKSPACE_SUCCESS,
  SET_WORKSPACE_SUBSCRIBERS_SUCCESS,
  SET_SELECTED_WORKSPACE_SUCCESS,
  SET_UPDATE_WORKSPACE_SUCCESS,
  SET_DELETE_WORKSPACE_SUCCESS,
  SET_WORKSPACE_ERROR,
  SET_DEACTIVATE_WORKSPACE_SUCCESS,
  SET_ACTIVATE_WORKSPACE_SUCCESS,
  SET_SOCKET_CONNECTION_SUCCESS,
  SET_SOCKET_CONNECTION_ERROR,
} from "../constants/collabration";
import { SET_NOTIFICATION } from "../constants/workspace";
import collaboration from "../services/collaboration";
import { setSelectedChannelSuccess } from "./channels";
import { setNewMessage } from "./collaboration";

export const setSocketConnection = (payload) => {
  return {
    type: SET_SOCKET_CONNECTION_SUCCESS,
    payload,
  };
};

export const setCreateWorkSpaceSuccess = (payload) => {
  return {
    type: SET_CREATE_WORKSPACE_SUCCESS,
    payload,
  };
};

export const setListWorkSpaceSuccess = (payload) => {
  return {
    type: SET_LIST_WORKSPACE_SUCCESS,
    payload,
  };
};

export const setWorkSpaceSubscribersSuccess = (payload) => {
  return {
    type: SET_WORKSPACE_SUBSCRIBERS_SUCCESS,
    payload,
  };
};

export const setUpdateWorkSpaceSuccess = (payload) => {
  return {
    type: SET_UPDATE_WORKSPACE_SUCCESS,
    payload,
  };
};

export const setDeleteWorkSpaceSuccess = (payload) => {
  return {
    type: SET_DELETE_WORKSPACE_SUCCESS,
    payload,
  };
};

export const setSelectedWorkspaceSuccess = (payload) => {
  return {
    type: SET_SELECTED_WORKSPACE_SUCCESS,
    payload,
  };
};

export const setActivateWorkspaceSuccess = (payload) => {
  return {
    type: SET_ACTIVATE_WORKSPACE_SUCCESS,
    payload,
  };
};

export const setDeactivateWorkspaceSuccess = (payload) => {
  return {
    type: SET_DEACTIVATE_WORKSPACE_SUCCESS,
    payload,
  };
};

export const setWorkSpaceError = (error) => {
  return {
    type: SET_WORKSPACE_ERROR,
    error,
  };
};

export const setSocketConnectionError = (error) => {
  return {
    type: SET_SOCKET_CONNECTION_ERROR,
    error,
  };
};

export const setNotification = (payload) => {
  return {
    type: SET_NOTIFICATION,
    payload,
  };
};

export const createWorkSpace = (data) => (dispatch) => {
  dispatch(setWorkSpaceError(null));
  collaboration.workSpace.create(
    data,
    (response) => {
      dispatch(setCreateWorkSpaceSuccess(response.data));
      dispatch(
        getWorkSpaceSubscribers(
          { workSpaceId: response.data.workSpaceId },
          true
        )
      );
    },
    (error) => {
      toast(error?.message, {
        type: "error",
        theme: "colored",
        autoClose: 2000,
        position: "bottom-right",
        transition: Zoom,
      });
    }
  );
};

export const getAllWorkSpaceList = (data) => (dispatch) => {
  dispatch(setWorkSpaceError(null));
  collaboration.workSpace.getAllWorkspaces(
    data,
    (response) => {
      dispatch(setListWorkSpaceSuccess(response.data?.content));
      if (response?.data?.content?.length > 0) {
        const firstWorkSpace =
          JSON.parse(window.localStorage.getItem("lastSelectedWorkspace")) ||
          response.data.content[0];
        dispatch(setSelectedWorkspaceSuccess(firstWorkSpace));
        dispatch(getWorkSpaceSubscribers({ workSpaceId: firstWorkSpace.id }));
      }
    },
    (error) => {
      dispatch(setWorkSpaceError(error));
    }
  );
};

export const getUserSubscribedWorkSpaceList = (data) => (dispatch) => {
  dispatch(setWorkSpaceError(null));
  collaboration.workSpace.getUserSubscribedWorkspaces(
    data,
    (response) => {
      dispatch(setListWorkSpaceSuccess(response.data?.content));
      if (response?.data?.content?.length > 0) {
        dispatch(
          getWorkSpaceSubscribers({
            workSpaceId: response?.data?.content[0].id,
          })
        );
      }
    },
    (error) => {
      dispatch(setWorkSpaceError(error));
    }
  );
};

export const getWorkSpaceSubscribers = (data, isCreated) => (dispatch) => {
  dispatch(setWorkSpaceError(null));
  collaboration.workSpace.getSubscribers(
    data,
    (response) => {
      const workspaceSubscriber = response.data;
      dispatch(setWorkSpaceSubscribersSuccess(workspaceSubscriber));
      if (workspaceSubscriber?.length && isCreated) {
        dispatch(setSelectedChannelSuccess(workspaceSubscriber[0]));
      }
    },
    (error) => {
      dispatch(setWorkSpaceError(error));
    }
  );
};

export const updateWorkSpace = (data) => (dispatch) => {
  dispatch(setWorkSpaceError(null));
  collaboration.workSpace.update(
    data,
    (response) => {
      dispatch(setUpdateWorkSpaceSuccess(response.data));
    },
    (error) => {
      dispatch(setWorkSpaceError(error));
    }
  );
};

export const deleteWorkSpace = (data, onError) => (dispatch) => {
  dispatch(setWorkSpaceError(null));
  collaboration.workSpace.delete(
    data,
    (response) => {
      dispatch(setDeleteWorkSpaceSuccess(data));
      onError({ isError: false });
    },
    (error) => {
      onError({ isError: true, error: error });
    }
  );
};

export const activateWorkspace = (data) => (dispatch) => {
  dispatch(setWorkSpaceError(null));
  collaboration.workSpace.activate(
    data,
    (response) => {
      dispatch(setActivateWorkspaceSuccess(response.data));
    },
    (error) => {
      dispatch(setWorkSpaceError(error));
    }
  );
};

export const deactivateWorkspace = (data) => (dispatch) => {
  dispatch(setWorkSpaceError(null));
  collaboration.workSpace.deactivate(
    data,
    (response) => {
      dispatch(setDeactivateWorkspaceSuccess(response.data));
    },
    (error) => {
      dispatch(setWorkSpaceError(error));
    }
  );
};

export const modalNotification = (data) => (dispatch) => {
  dispatch(setNotification(data));
};
