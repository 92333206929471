import { SET_PROFILE_SUCCESS , SET_PROFILE_UPDATE_SUCCESS , SET_PROFILE_ERROR } from '../constants/profile';

const initialState = {
      profiledata: {},
      profileupdate:{},
      onError: null
};

export default (state = initialState, action) => {
      switch (action.type) {
            case SET_PROFILE_SUCCESS: {
                  return Object.assign({}, state, {
                        profiledata: action.success
                  });
            }

            case SET_PROFILE_UPDATE_SUCCESS: {
                  return Object.assign({}, state, {
                        profileupdate: action.success
                  });
            }

            case SET_PROFILE_ERROR: {
                  return Object.assign({}, state, {
                        onError:action.error
                  });
            }

            default:
            return {...state}
      }
}