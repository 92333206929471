import httputils from "../utils/httputils";

const HTTP_POST = "POST";
const HTTP_GET = "GET";
const HTTP_PUT = "PUT";
const HTTP_DELETE = "DELETE";

const GET_ORG_LIST = "organizations/all";
const GET_DEPT_LIST = "departments/all";
const CREATE_ORG = "organizations";
const CREATE_USER = "users/create";
const GET_USER_LIST = "users/byOrganizationId";
const ENABLE_ORG = "organizations/activate";
const DISABLE_ORG = "organizations/deactivate";
const GET_ORG_DOMAINS = "organizations/domains";
const GET_ORG_MODULES_BY_ID = 'organizations/modules/id';
const GET_DEPT_BY_ORG = 'departments/get/organizations';
const GET_ORG_LIST_ALL = "organizations/organisation/all"

//Auth type
const GET_AUTH_TYPE_LIST = "auth-type";
const CREATE_AUTH_TYPE = "auth-type";
const DELETE_AUTH_TYPE = "auth-type/de-activate"
const UPDATE_AUTH_TYPE = "auth-type/update"

export const orgListService = (data, successCallback, errorCallback) => {
  OrgAndUserService(
    data,
    GET_ORG_LIST,
    HTTP_GET,
    successCallback,
    errorCallback
  );
};

export const deptListService = (successCallback, errorCallback) => {
  OrgAndUserService(
    null,
    GET_DEPT_LIST,
    HTTP_GET,
    successCallback,
    errorCallback
  );
};

export const createOrgService = (data, successCallback, errorCallback) => {
  OrgAndUserService(
    data,
    CREATE_ORG,
    HTTP_POST,
    successCallback,
    errorCallback
  );
};

export const createUserService = (data, successCallback, errorCallback) => {
  OrgAndUserService(
    data,
    CREATE_USER,
    HTTP_POST,
    successCallback,
    errorCallback
  );
};

export const createDomainService = (data , successCallback , errorCallback) => {
  OrgAndUserService(
    data,
    GET_ORG_DOMAINS,
    HTTP_POST,
    successCallback,
    errorCallback
  );
}

export const getUserService = (data, successCallback, errorCallback) => {
  OrgAndUserService(
    data,
    GET_USER_LIST,
    HTTP_POST,
    successCallback,
    errorCallback
  );
};

export const orgEnable = (data, successCallback, errorCallback) => {
  OrgAndUserService(data, ENABLE_ORG, HTTP_PUT, successCallback, errorCallback);
};

export const orgDisable = (data, successCallback, errorCallback) => {
  OrgAndUserService(
    data,
    DISABLE_ORG,
    HTTP_DELETE,
    successCallback,
    errorCallback
  );
};

export const getAuthTypeService = (successCallback, errorCallback) => {
  OrgAndUserService(
    null,
    GET_AUTH_TYPE_LIST,
    HTTP_GET,
    successCallback,
    errorCallback
  );
};

export const createAuthTypeService = (data, successCallback, errorCallback) => {
  OrgAndUserService(
    data,
    CREATE_AUTH_TYPE,
    HTTP_POST,
    successCallback,
    errorCallback
  );
};
export const getOrgDomainsList = (successCallback, errorCallback) => {
  OrgAndUserService(
    null,
    GET_ORG_DOMAINS,
    HTTP_GET,
    successCallback,
    errorCallback
  );
};

export const  getOrgModulesByIdService = (successCallback , errorCallback) => {
  OrgAndUserService(
    null,
    GET_ORG_MODULES_BY_ID,
    HTTP_GET,
    successCallback,
    errorCallback
  );
}

export const getDeptByOrgService = (data , successCallback , errorCallback) => {
  OrgAndUserService(
    data , 
    GET_DEPT_BY_ORG,
    HTTP_POST,
    successCallback,
    errorCallback
  );
}

const OrgAndUserService = (
  data,
  endpoint,
  httpMethod,
  successCallback,
  errorCallback
) => {
  httputils.httpRequest(endpoint, httpMethod, data, (response) => {
    if (response.status === "Success") {
      successCallback && successCallback(response);
    } else {
      errorCallback && errorCallback(response);
    }
  });
};

export const deleteDirectoriesService = async (data) => {
  return await httputils.fetchData(DELETE_AUTH_TYPE, HTTP_PUT, data)
}

export const updateDirectoriesService = async (data) => {
  return await httputils.fetchData(UPDATE_AUTH_TYPE, HTTP_PUT, data)
} 

/* Newly added functionality */

const makeApiCall = async (apiEndpoint, method, requestData) => {
  try {
      return await httputils.fetchData(apiEndpoint, method, requestData);
  } catch (error) {
      throw error;
  }
};

export const getOrgListAll = async (data) => makeApiCall(GET_ORG_LIST_ALL, HTTP_POST, data);
export const getOrganizationModulesById = () => makeApiCall(GET_ORG_MODULES_BY_ID, HTTP_GET, null)
