/**
 * To get check whether the terminal has customer's
 * name or not if it has customers name return the path from the 
 * config file else return the default json file path
 */

 export const getCustomerName = () => {
    const client = process.env.REACT_APP_CUSTOMER_NAME;
    return client;
  }
  
  /** 
   * To load the JSON file based on the path
  */
  
  export const loadConfig = async () => {
    const customerName = getCustomerName();
    try {
      const response = await fetch(`/config/${customerName}.json`);
      const data = await response.json();
      return data;
    } catch (error) {
      const response = await fetch(`/config/oneable.json`);
      const data = await response.json();
      return data;
    }
  };